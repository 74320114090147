import { css } from 'styled-components';

import { themeColor, themeOpaqueColor, antdDisabledButtonStyle } from 'utils/mixins';

export type ButtonColorOptions = 'fadedOrange' | 'red' | 'green' | 'grey';

export const BUTTON_COLOR_MAP: {
  [key in ButtonColorOptions]: {
    primary: Parameters<typeof themeColor>[0];
  } & Record<'defaultColor' | 'hover' | 'active' | 'focus' | 'disabled', ReturnType<typeof css>>;
} = {
  fadedOrange: {
    primary: 'orange',
    defaultColor: css`
      background: linear-gradient(
        0deg,
        ${themeOpaqueColor('orange', 0.2)},
        ${themeOpaqueColor('orange', 0.2)}
      );
    `,
    hover: css`
      background: linear-gradient(
        0deg,
        ${themeOpaqueColor('orange', 0.35)},
        ${themeOpaqueColor('orange', 0.35)}
      );
    `,
    active: css`
      background: linear-gradient(
        0deg,
        ${themeOpaqueColor('orange', 0.5)},
        ${themeOpaqueColor('orange', 0.5)}
      );
    `,
    focus: css`
      background: linear-gradient(
        0deg,
        ${themeOpaqueColor('orange', 0.4)},
        ${themeOpaqueColor('orange', 0.4)}
      );
    `,
    disabled: antdDisabledButtonStyle(
      css`
        color: ${themeOpaqueColor('orange', 0.6)};
      `
    ),
  },

  red: {
    primary: 'red',
    defaultColor: css`
      background: linear-gradient(
        0deg,
        ${themeOpaqueColor('red', 0.1)},
        ${themeOpaqueColor('red', 0.1)}
      );
    `,
    hover: css`
      background: linear-gradient(
        0deg,
        ${themeOpaqueColor('red', 0.2)},
        ${themeOpaqueColor('red', 0.2)}
      );
    `,
    active: css`
      background: linear-gradient(
        0deg,
        ${themeOpaqueColor('red', 0.4)},
        ${themeOpaqueColor('red', 0.4)}
      );
    `,
    focus: css`
      background: linear-gradient(
        0deg,
        ${themeOpaqueColor('red', 0.3)},
        ${themeOpaqueColor('red', 0.3)}
      );
    `,
    disabled: antdDisabledButtonStyle(
      css`
        color: ${themeOpaqueColor('red', 0.6)};
      `
    ),
  },

  green: {
    primary: 'green',
    defaultColor: css`
      background: linear-gradient(
        0deg,
        ${themeOpaqueColor('green', 0.1)},
        ${themeOpaqueColor('green', 0.1)}
      );
    `,
    hover: css`
      background: linear-gradient(
        0deg,
        ${themeOpaqueColor('green', 0.2)},
        ${themeOpaqueColor('green', 0.2)}
      );
    `,
    active: css`
      background: linear-gradient(
        0deg,
        ${themeOpaqueColor('green', 0.4)},
        ${themeOpaqueColor('green', 0.4)}
      );
    `,
    focus: css`
      background: linear-gradient(
        0deg,
        ${themeOpaqueColor('green', 0.3)},
        ${themeOpaqueColor('green', 0.3)}
      );
    `,
    disabled: antdDisabledButtonStyle(
      css`
        color: ${themeOpaqueColor('green', 0.6)};
      `
    ),
  },

  grey: {
    primary: 'darkBlue',
    defaultColor: css`
      background: linear-gradient(
        0deg,
        ${themeOpaqueColor('darkBlue', 0.1)},
        ${themeOpaqueColor('darkBlue', 0.1)}
      );
    `,
    hover: css`
      background: linear-gradient(
        0deg,
        ${themeOpaqueColor('darkBlue', 0.2)},
        ${themeOpaqueColor('darkBlue', 0.2)}
      );
    `,
    active: css`
      background: linear-gradient(
        0deg,
        ${themeOpaqueColor('darkBlue', 0.4)},
        ${themeOpaqueColor('darkBlue', 0.4)}
      );
    `,
    focus: css`
      background: linear-gradient(
        0deg,
        ${themeOpaqueColor('darkBlue', 0.3)},
        ${themeOpaqueColor('darkBlue', 0.3)}
      );
    `,
    disabled: antdDisabledButtonStyle(
      css`
        color: ${themeOpaqueColor('darkBlue', 0.6)};
      `
    ),
  },
};
