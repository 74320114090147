import { createGlobalStyle, css } from 'styled-components';

import { themeColor, themeOpaqueColor, forDeviceType } from 'utils/mixins';

const disabledActivableInputStyle = css`
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: pointer;
  opacity: 1;
`;

export default createGlobalStyle`
  ::-moz-selection { /* Code for Firefox */
    color: inherit;
    background: ${themeOpaqueColor('lightBlue', 0.4)};
  }
  
  ::selection {
    color: inherit;
    background: ${themeOpaqueColor('lightBlue', 0.4)};
  }

  body {
    margin: 0;
    font-size: ${({ theme }) => theme.fontSizes.sm};
    line-height: ${({ theme }) => theme.lineHeights.sm};
  }

  html {
   -ms-overflow-style: -ms-autohiding-scrollbar;
  }

  h1, h2, h3, h4, b, strong, sub b, sub strong, details b, details strong {
    font-family: 'Proxima Nova Semibold', Corbel, Arial, sans-serif;
    font-weight: bold;
  }

  h1 {
    font-family: 'Proxima Nova', Corbel, Arial, sans-serif;
    font-size: ${({ theme }) => theme.fontSizes.h1};
    line-height: ${({ theme }) => theme.lineHeights.xxlg};
  }

  h2 {
    font-size: ${({ theme }) => theme.fontSizes.h2};
    line-height: ${({ theme }) => theme.lineHeights.lg};
    letter-spacing: 0.5px;
    text-transform: uppercase;
  }

  h3 {
    font-size: ${({ theme }) => theme.fontSizes.h3};
    line-height: ${({ theme }) => theme.lineHeights.md};
    letter-spacing: 0.5px;
    text-transform: capitalize;

    ${forDeviceType(
      'mobile',
      `
      font-family: 'Proxima Nova', Corbel, Arial, sans-serif;
    `
    )}
  }

  sub, sub b, sub strong {
    font-size: ${({ theme }) => theme.fontSizes.sm};
    line-height: ${({ theme }) => theme.lineHeights.sm};
    letter-spacing: 0.2px;
    text-transform: uppercase;
  }

  p {
    font-size: ${({ theme }) => theme.fontSizes.md};
    line-height: ${({ theme }) => theme.lineHeights.sm};
  }

  b {
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    font-size: ${({ theme }) => theme.fontSizes.sm};
    line-height: ${({ theme }) => theme.lineHeights.sm};
  }

  details, details b, details strong {
    font-size: ${({ theme }) => theme.fontSizes.xsm};
    line-height: ${({ theme }) => theme.lineHeights.xsm};
    letter-spacing: 0.2px;
    text-transform: uppercase;
  }

  .ant-message {
    top: auto;
    bottom: 40px;
    position: fixed;
    z-index: 999999999;
  }

  .ant-tooltip {
    z-index: 999999999;
  }

  .ant-message-notice-content {
    margin: auto;
    padding: 0px;
    border-radius: ${({ theme }) => theme.radius.lg};
    font-size: ${({ theme }) => theme.fontSizes.sm};
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
    & span {
    word-break: keep-all;
    } 
  }

  .ant-message-success,
  .ant-message-error {
    padding: 20px 26px;
    border-radius: ${({ theme }) => theme.radius.lg};
    color: white;
    max-width: 372px;
    display: flex;
    text-align: left;
  }

  .ant-message-error {
    background-color: ${themeColor('red')}
  }

  .ant-message-success {
    background-color: ${themeColor('green')}
  }

  .ant-select-item.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: ${themeColor('darkBlue')};
    color: ${themeColor('white')};
  }

  label.ant-checkbox-wrapper + label.ant-checkbox-wrapper {
    margin-left: 0;
  }

  div.ant-select-dropdown {
    padding: 0;
    filter: drop-shadow(0px 4px 8px ${themeOpaqueColor('darkBlack', 0.15)});
    border-radius: ${({ theme }) => theme.radius.sm};

    text-transform: uppercase;

    div.ant-select-item {
      padding: 12px;
    }
  }
  
  .ant-tooltip-inner {
    background-color: ${themeColor('darkBlue')};
    border-radius: ${({ theme }) => theme.radius.sm};
    padding: ${({ theme }) => `${theme.spacings.sm} ${theme.spacings.md}`};
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
    line-height: 20px;
  }
  
  .centered-tooltip .ant-tooltip-inner {
    text-align: center;
  }
  

  .orange-tooltip .ant-tooltip-inner {
    background-color: ${themeColor('orange')};
  }

  .orange-tooltip .ant-tooltip-arrow-content {
    background-color: ${themeColor('orange')}
  }

  div.ant-modal-mask {
    z-index: 999999;
    background-color: ${themeOpaqueColor('darkBlue', 0.7)};
  }

  div.ant-select-dropdown {
    z-index: 99999999;
  }

  div.ant-modal-wrap {
    z-index: 99999999;
  }

  .onboarding-tutorial-mask {
    color: ${themeOpaqueColor('darkBlue', 0.4)};
  }

  .ant-card {
    min-height: fit-content;
  }

  div.ant-select-dropdown {
    text-transform: initial;
  }

  .mobile-only {
    display: none;
    ${forDeviceType('mobile', `display: block !important;`)}
  }

  .desktop-only {
    display: block;
    ${forDeviceType('mobile', `display: none !important;`)}
  }

  .activable-disabled-input {
    cursor: pointer;
  }

  .ant-input.activable-disabled-input {
    &&& {
      ${disabledActivableInputStyle}
    }
  }

  .ant-select.activable-disabled-input {
    &&& .ant-select-selector {
      ${disabledActivableInputStyle}

      & .ant-select-selection-item {
        color: rgba(0, 0, 0, 0.25);
      }
    }
  }

  .ant-checkbox-wrapper.activable-disabled-input {
    &&& .ant-checkbox-checked .ant-checkbox-inner {
      opacity: 0.6;
    }
  }

  .ant-table-filter-dropdown {
    &&& {
      border-radius: ${({ theme }) => theme.radius.lg};
    }
  }
`;
