import React, { Fragment } from 'react';
import styled from 'styled-components';
import { RouteChildrenProps, NavLink } from 'react-router-dom';

import { themeColor } from 'utils/mixins';

import { Arrow } from '../styles';

const WhiteTextNavLink = styled(NavLink)`
  color: ${themeColor('white')};
`;

const Template: React.FC<RouteChildrenProps<{ id: string }>> = ({ match }) => {
  const templateId = match?.params.id;

  return (
    <>
      <Fragment key={`/filming-groups`}>
        <NavLink to={`/filming-groups`}>filming groups</NavLink>
      </Fragment>
      <Fragment key={`/template/${templateId}`}>
        <Arrow />
        <WhiteTextNavLink to={`/template/${templateId}`}>
          videoguide template detail
        </WhiteTextNavLink>
      </Fragment>
    </>
  );
};

export default Template;
