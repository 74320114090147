const mapToLowerCase = (arr: string[]) => arr.map((item) => item.toLowerCase());
const joinWithComma = (arr: string[]) => arr.join(',');

// https://github.com/storyvine/storyvine-backend/blob/95e1ad635cd231cdc203d5d29d27d5aaafcc336e/app/services/package_services/asset_validator.rb#L6
export const ALLOWED_ASSET_TYPES = {
  image: mapToLowerCase(['.jpg', '.jpeg', '.gif', '.bmp', '.png']),
  video: mapToLowerCase(['.mov', '.mp4']),
};

export const ALLOW_ASSET_TYPES_STRING = {
  image: joinWithComma(ALLOWED_ASSET_TYPES['image']),
  video: joinWithComma(ALLOWED_ASSET_TYPES['video']),
};

export const VIRTUALIZED_DROPDOWN_PER_PAGE = 20;

// dashboard user roles overview
// https://storyvine.atlassian.net/wiki/spaces/SN/pages/559218698/Dashboard+User+Roles+Permissions
export enum AdminUsers {
  Manager = 'Manager',
  Moderator2 = 'Moderator 2.0',
  AccountAdmin = 'Account Admin',
  ModeratorSecure = 'Moderator - Secure',
  ModeratorSecure2 = 'Moderator - Secure 2.0',
  Superadmin = 'Superadmin',
  Moderator = 'Moderator',
}

export enum StorymakerUsers {
  Reviewer = 'reviewer',
}
