import { History } from 'history';

import { NavigationPath, NavigationNested } from 'utils/navigation';

export const currentNavItemFinder =
  (history: History) => (item: NavigationNested | NavigationPath) => {
    const startsWith = (item as NavigationPath).startsWith;
    const pathname = history.location.pathname;

    return startsWith
      ? startsWith.some((s) => pathname.startsWith(s))
      : (item as NavigationPath).path === pathname;
  };
