import React from 'react';
import { SpinSize } from 'antd/lib/spin';
import { LoadingComponentProps } from 'react-loadable';

import * as S from './styles';

type Props = Partial<LoadingComponentProps> & {
  size?: SpinSize;
  backgroundColor?: Colors;
};

const Loading: React.FC<Props> = ({ size = 'large', backgroundColor = 'orange' }) => (
  <S.Wrap $loadingColor={backgroundColor} className="loading" data-testid="loading">
    <S.Spinner size={size} />
  </S.Wrap>
);

export default Loading;
