import React from 'react';

import Breadcrumb from './Breadcrumb';
import * as S from './styles';

type Props = {
  className?: string;
};

const SubHeader: React.FC<Props> = ({ className }) => {
  return (
    <S.Wrap className={className}>
      <Breadcrumb />
    </S.Wrap>
  );
};

export default SubHeader;
