import { useEffect, useCallback } from 'react';

import useUrlSearchParams from './useUrlSearchParams';

const START_TUTORIAL_QUERY_PARAM = 'startTutorial';

const availableUrlSearchParams = [START_TUTORIAL_QUERY_PARAM] as const;

/**
 * This hook calls the callback whenever the URL has startTutorial=true param.
 * The function returned from this hook sets startTutorial=true param to the URL.
 */
const useStartTutorialBrowserRedirect = (callback?: () => void) => {
  const { startTutorial: tutorialInfo } = useUrlSearchParams(availableUrlSearchParams);

  useEffect(() => {
    if (tutorialInfo) {
      callback?.();
    }
  }, [callback, tutorialInfo]);

  return useCallback(() => {
    window.location.replace(`${window.location.pathname}?${START_TUTORIAL_QUERY_PARAM}=true`);
  }, []);
};
export default useStartTutorialBrowserRedirect;
