import React, { useState, useCallback, useMemo } from 'react';

import { MenuIcon } from '../styles';

import * as S from './styles';

type Props = {
  itemKey: string;
  icon: { normal: string; hovered: string };
  title: string;
  className?: string;
};

const SidebarMenuAccordion: React.FC<Props> = ({ itemKey, title, icon, children, ...props }) => {
  const [hovered, setHovered] = useState('none');

  const currentIcon = useMemo(
    () => icon[itemKey !== hovered ? 'normal' : 'hovered'],
    [hovered, itemKey, icon]
  );
  const onPointerOver = useCallback(() => setHovered(itemKey), [itemKey]);
  const onPointerLeave = useCallback(() => setHovered('none'), []);

  return (
    <S.Accordion
      {...props}
      data-testid={`SideBarMenuAccodrion-${itemKey}`}
      key={itemKey}
      icon={<MenuIcon src={currentIcon} alt={`Main sidebar menu icon for ${title} item`} />}
      title={title}
      onTitleMouseEnter={onPointerOver}
      onTitleMouseLeave={onPointerLeave}
    >
      {children}
    </S.Accordion>
  );
};

export default SidebarMenuAccordion;
