import styled from 'styled-components';
import { Layout } from 'antd';

export const Content = styled(Layout.Content)`
  &.ant-layout-content {
    background: transparent;
    margin-top: ${({ theme }) => theme.spacings.xlg};
    height: 100%;

    min-height: calc(100vh - ${({ theme }) => theme.sizes.headerHeightPx / 2}px);
    min-width: 1000px;
  }
`;
