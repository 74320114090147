import React from 'react';
import { RouteChildrenProps } from 'react-router-dom';

import { useAdminUserDetailQuery } from 'codegen';

const AdminUserName: React.FC<RouteChildrenProps<{ id: string }>> = ({ match }) => {
  const adminUserIdParam = match?.params.id;
  const adminUserId = Number(adminUserIdParam);

  const { data, loading } = useAdminUserDetailQuery({
    fetchPolicy: 'cache-only',
    variables: { filter: { adminUserId } },
    skip: isNaN(adminUserId) || adminUserId == null,
  });

  const { firstName, lastName } = data?.adminUsers.data[0] || {};

  return (
    <span>{loading ? '...' : `${firstName || ''} ${lastName || ''}`.trim() || adminUserId}</span>
  );
};

export default AdminUserName;
