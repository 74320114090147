import styled from 'styled-components';
import { Menu } from 'antd';

import { themeOpaqueColor, themeColor, bold } from 'utils/mixins';

// FIXME - Antd 4.10.3 does not provide children prop for Menu.SubMenu
// Find out if children is rendered!
export const Accordion = styled(Menu.SubMenu)<{ children: React.ReactNode }>`
  &.ant-menu-submenu .ant-menu-submenu-arrow::before,
  &.ant-menu-submenu .ant-menu-submenu-arrow::after {
    background-image: linear-gradient(
      to right,
      ${themeOpaqueColor('white', 0.6)},
      ${themeOpaqueColor('white', 0.6)}
    );
  }

  &.ant-menu-submenu:hover,
  & .ant-menu-submenu-title:hover {
    color: ${({ theme }) => theme.colors.white};
    ${bold}

    &.ant-menu-submenu .ant-menu-submenu-arrow::before,
  &.ant-menu-submenu .ant-menu-submenu-arrow::after {
      background-image: linear-gradient(to right, ${themeColor('white')}, ${themeColor('white')});
    }
  }

  &.ant-menu-submenu {
    transition: color 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    color: ${themeOpaqueColor('white', 0.6)};
    margin-bottom: 20px !important;
    align-items: center;

    .ant-menu-submenu-title {
      margin: 0;
      padding-top: 4px;
      padding-bottom: 4px;
      height: 48px;
    }

    > .ant-menu {
      background: none;
      & > * {
        margin-bottom: 0px !important;
      }

      > *:nth-child(odd) {
        margin-bottom: 6px !important;
      }
    }
  }
`;
