const visualController = (step: number) => {
  const defaultControls = {
    addStorymakersMenuOpen: false,
    addStorymakersModalOpen: false,
    uploadBrandLogosModalOpen: false,
    editBrandColorsModalOpen: false,
    editEndCardInfoModalOpen: false,
    navMenuOpenKeys: [] as string[],
    navHighlightKeys: [] as string[],
  };

  const applyDefaultControls = (newControls?: Partial<typeof defaultControls>) => ({
    ...defaultControls,
    ...newControls,
  });

  switch (step) {
    case 1:
      return applyDefaultControls({ navHighlightKeys: ['storymakers'] });
    case 3:
      return applyDefaultControls({ addStorymakersMenuOpen: true });
    case 4:
      return applyDefaultControls({ addStorymakersModalOpen: true });
    case 5:
      return applyDefaultControls({ navMenuOpenKeys: ['settings'], navHighlightKeys: ['brand'] });
    case 7:
      return applyDefaultControls({ uploadBrandLogosModalOpen: true });
    case 8:
      return applyDefaultControls({ editBrandColorsModalOpen: true });
    case 9:
      return applyDefaultControls({ editEndCardInfoModalOpen: true });
    default:
      return applyDefaultControls();
  }
};

export default visualController;
