import { useCallback, useRef } from 'react';
import { SortOrder } from 'antd/lib/table/interface';
import { SortOrder as APISortOrder } from 'codegen';
import { DelimitedArrayParam, useQueryParam } from 'use-query-params';

const mapSorterOrder = (order?: SortOrder) => {
  switch (order) {
    case 'ascend':
      return APISortOrder.Asc;
    case 'descend':
      return APISortOrder.Desc;
    default:
      return order;
  }
};

type SorterValue = [string, SortOrder] | undefined;

const useTableSorting = (
  defaultColumnKey?: string,
  defaultColumnOrder?: SortOrder,
  queryParamName = 'order'
) => {
  const sortChanged = useRef(false);
  const [value, setValue] = useQueryParam(queryParamName, DelimitedArrayParam) as [
    SorterValue,
    (val: SorterValue) => void
  ];
  const [sortColumnKey, sortOrder] = value || [];

  const handleSortChange = useCallback(
    (newColumnKey?: string | number | null, newSortOrder?: SortOrder) => {
      if (!sortChanged.current && newColumnKey && newSortOrder) {
        sortChanged.current = true;
      }

      if (!newColumnKey || !newSortOrder) {
        setValue(undefined);
        sortChanged.current = false;
        return;
      }

      return setValue([`${newColumnKey}`, newSortOrder]);
    },
    [setValue]
  );

  const getOrderForColumn = useCallback(
    (columnKey: string) => (sortColumnKey === columnKey ? sortOrder : undefined),
    [sortColumnKey, sortOrder]
  );

  return {
    sortColumnKey,
    sortOrder,
    sort:
      sortColumnKey && sortOrder
        ? { key: sortColumnKey, order: mapSorterOrder(sortOrder) }
        : !sortChanged.current && defaultColumnKey && defaultColumnOrder
        ? { key: defaultColumnKey, order: mapSorterOrder(defaultColumnOrder) }
        : undefined,
    handleSortChange,
    getOrderForColumn,
  };
};

export default useTableSorting;
