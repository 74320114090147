import React, { useState, useEffect } from 'react';
import Loadable from 'react-loadable';

import { useStartTutorial, useFirebaseClaims } from 'utils/hooks';

const OnboardingTutorialProviderLazyComponent = Loadable({
  loader: () => import(/* webpackChunkName: "onboardingTutorial" */ './index'),
  loading() {
    return null;
  },
});

const OnboardingTutorialProviderLazy: React.FC<
  ComponentProps<typeof OnboardingTutorialProviderLazyComponent>
> = (props) => {
  const [shouldLoad, setShouldLoad] = useState(false);
  const { claims } = useFirebaseClaims();

  useStartTutorial(() => setShouldLoad(true));

  useEffect(() => {
    if (claims?.showOnboardingTutorial) {
      setShouldLoad(true);
    }
  }, [claims]);

  return shouldLoad ? (
    <OnboardingTutorialProviderLazyComponent {...props} />
  ) : (
    <>{props.children}</>
  );
};

export default OnboardingTutorialProviderLazy;
