import { useEffect } from 'react';

import usePrevious from './usePrevious';

const useOnChange = <T>(
  value: T,
  handleChange: (value: T, prev: T | undefined) => any,
  shouldHandleChange: (current: T, prev: T | undefined) => boolean = (current, prev) =>
    prev !== current,

  shouldHandleChangeWhenUndefined: boolean = true
) => {
  const previous = usePrevious(value);

  useEffect(() => {
    if (!shouldHandleChangeWhenUndefined && !previous) return;
    if (shouldHandleChange(value, previous)) {
      handleChange(value, previous);
    }
    // eslint-disable-next-line
  }, [value]);
};

export default useOnChange;
