import { useState, useCallback } from 'react';

const useUniqueState = <T>(initialState: T): [T, (newState: T) => void] => {
  const [state, internalSetState] = useState<T>(initialState);

  const setState = useCallback(
    (newState: T) => {
      if (state !== newState) {
        return internalSetState(newState);
      }
    },
    [state, internalSetState]
  );
  return [state, setState];
};

export default useUniqueState;
