import { useCallback, useState } from 'react';
import { FormInstance } from 'antd/lib/form';

type ChangeObject = {
  [key in string]: {
    initialValue: ValueType;
    currentValue: ValueType;
  };
};
type Options = {
  onRevertChanges?: () => void;
};

const useActivableForm = (form: FormInstance, options?: Options) => {
  const { onRevertChanges } = options || {};
  const [isFormActive, setFormActive] = useState(false);

  const deactivateForm = useCallback(() => {
    setFormActive(false);
  }, []);

  const activateForm = useCallback(() => {
    setFormActive(true);
  }, []);

  const revertChanges = useCallback(() => {
    deactivateForm();
    form.resetFields();
    onRevertChanges?.();
  }, [deactivateForm, form, onRevertChanges]);

  return {
    isFormActive,
    deactivateForm,
    activateForm,
    revertChanges,
  };
};

type ValueType = string | number | boolean;
export type ActivableFormComponentProps = {
  fieldChangeObject?: React.MutableRefObject<ChangeObject>;
  isFormActive?: boolean;
  onInputChange?: <TValue extends ValueType = ValueType>(field: string, value: TValue) => void;
  form: FormInstance;
  onInputsClick?: () => void;
};

export default useActivableForm;
