import { AdminUsers, StorymakerUsers } from './constants';

type RoleCheck = (roleInfo: UserRoleInfo) => boolean;

const ADMINISTRATOR_ROLES: UserRole[] = [AdminUsers.AccountAdmin];

export const isPrimary: RoleCheck = (roleInfo: UserRoleInfo) => roleInfo.isPrimary;

export const isManager: RoleCheck = (roleInfo: UserRoleInfo | UserRole) =>
  (typeof roleInfo === 'string' ? roleInfo : roleInfo.role) === AdminUsers.Manager;

export const isAdministrator = (roleInfo: UserRoleInfo | UserRole) =>
  isAdministratorRole(typeof roleInfo === 'string' ? roleInfo : roleInfo.role);

export const isAdministratorOrNonNowManager: RoleCheck = (roleInfo: UserRoleInfo | UserRole) => {
  const userRole = typeof roleInfo === 'string' ? roleInfo : roleInfo.role;
  return (
    isAdministratorRole(userRole) || (userRole !== AdminUsers.Manager && !isReviewerRole(userRole))
  );
};

export const isAdministratorRole = (role?: UserRole) => ADMINISTRATOR_ROLES.includes(role);

export const isReviewerRole = (role?: UserRole) => role === StorymakerUsers.Reviewer;

export const isAnyAdminRole = (role?: UserRole) => Object.values(AdminUsers).includes(role);

export const validateRoles = (checks: RoleCheck[], roleInfo: UserRoleInfo) =>
  checks.every((check) => check(roleInfo));

export const and = <T>(...args: T[]) => args.flat();

export const shouldRequireRobustPassword = (role?: UserRole | null) =>
  role === AdminUsers.ModeratorSecure ||
  role === AdminUsers.ModeratorSecure2 ||
  role === AdminUsers.AccountAdmin;

const exports = {
  isPrimary: [isPrimary],
  isManager: [isManager],
  isAdministrator: [isAdministrator],
  isAdministratorOrNonNowManager: [isAdministratorOrNonNowManager],
};

export default exports;
