import * as Sentry from '@sentry/browser';
import { Observable, Operation } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { GraphQLError } from 'graphql';

import { getOperationDescriptor } from 'utils/graphql';
import { getIdToken } from 'lib/firebase';

const OUTDATED_AUTH_TOKEN_ERROR = 'OUTDATED_AUTH_TOKEN_ERROR';

/**
 * Fetches native JavaScript Error from GraphQLError for logging purposes
 */
const fetchGraphQLError = (error: GraphQLError, operation: Operation) => {
  return (
    error.originalError ??
    new Error(`${error.name}: ${error.message} in ${getOperationDescriptor(operation)}`)
  );
};

// https://github.com/apollographql/apollo-link/issues/646#issuecomment-423279220
const promiseToObservable = <V, T extends Promise<V>>(promise: T) =>
  new Observable<V>((subscriber) => {
    promise.then(
      (value) => {
        if (subscriber.closed) return;
        subscriber.next(value);
        subscriber.complete();
      },
      (err) => subscriber.error(err)
    );
  });

export const errorLink = onError(({ operation, graphQLErrors, networkError, forward }) => {
  if (networkError) {
    Sentry.addBreadcrumb({
      type: 'error',
      category: 'graphqlNetworkError',
      message: getOperationDescriptor(
        operation,
        `GraphQL NetworkError: [${networkError.name}]: "${networkError.message}"; `
      ),
    });
  }

  if (graphQLErrors && graphQLErrors.length > 0) {
    // if token is outdated
    if (graphQLErrors.some((error) => error.extensions?.code === OUTDATED_AUTH_TOKEN_ERROR)) {
      return promiseToObservable(
        new Promise((resolve) => {
          // refetch the token:
          getIdToken(true).then(resolve);
        })
        // and retry the operation:
      ).flatMap(() => forward(operation));
    } else {
      graphQLErrors.forEach((error) =>
        Sentry.captureException(fetchGraphQLError(error, operation))
      );
    }
  }
});
