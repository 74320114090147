import React, { ReactNode, useMemo } from 'react';

import { NavigationConfig } from 'utils/navigation';
import { useFirebaseClaims } from 'utils/hooks';
import { AdminUsers, StorymakerUsers } from 'utils/constants';

import * as managerImport from './manager';
import * as accountAdminImport from './accountAdmin';
import * as reviewerImport from './reviewer';

export const manager = managerImport;
export const accountAdmin = accountAdminImport;
export const reviewer = reviewerImport;

export const NavigationConsumer: React.FC<{ children: (value: NavigationConfig) => ReactNode }> = ({
  children,
}) => {
  const { claims } = useFirebaseClaims();
  const role = claims?.role;

  return useMemo(() => {
    if (role === AdminUsers.AccountAdmin) {
      return (
        <accountAdmin.Provider>
          <accountAdmin.Context.Consumer>{children}</accountAdmin.Context.Consumer>
        </accountAdmin.Provider>
      );
    }

    if (role === StorymakerUsers.Reviewer) {
      return (
        <reviewer.Provider>
          <reviewer.Context.Consumer>{children}</reviewer.Context.Consumer>
        </reviewer.Provider>
      );
    }

    return (
      <manager.Provider>
        <manager.Context.Consumer>{children}</manager.Context.Consumer>
      </manager.Provider>
    );
  }, [role, children]);
};
