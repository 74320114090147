import { useCallback, useRef } from 'react';

type UseCountConfig = {
  count: number;
  trigger: () => void;
};

const useOnCount = ({ count, trigger }: UseCountConfig) => {
  const internalCount = useRef(0);

  return useCallback(() => {
    const newCount = internalCount.current + 1;
    if (newCount % count === 0) {
      trigger();
    }

    internalCount.current = newCount;
  }, [count, trigger]);
};

export default useOnCount;
