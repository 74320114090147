import styled, { css } from 'styled-components';
import { Checkbox as ANTDCheckbox } from 'antd';
import { themeColor } from 'utils/mixins';

export const checkboxStyle = css<{ $readOnly?: boolean }>`
  & .ant-checkbox .ant-checkbox-inner {
    border-color: ${themeColor('darkBlue')};
  }

  & .ant-checkbox-checked::after {
    border-color: ${themeColor('darkBlue')};
    border-radius: ${({ theme }) => theme.radius.xsm};
  }

  & .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${themeColor('darkBlue')};
    border-color: ${themeColor('darkBlue')};
  }

  & .ant-checkbox .ant-checkbox-inner {
    ${({ $readOnly }) => $readOnly && `opacity: 0.6;`}
  }

  & .ant-checkbox-inner {
    border-radius: ${({ theme }) => theme.radius.xsm};
  }

  && {
    font-size: ${({ theme }) => theme.fontSizes.md};
    font-weight: ${({ theme }) => theme.fontWeights.regular};
  }

  .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background-color: white;
    height: 2px;
    width: 8px;
  }

  .ant-checkbox-indeterminate .ant-checkbox-inner {
    background-color: ${themeColor('darkBlue')};
  }
`;

export const Checkbox = styled(ANTDCheckbox)<{ $readOnly?: boolean }>`
  ${checkboxStyle}
`;
