type NavigationBase = {
  key: string;
  icon: { hovered: string; normal: string; header?: string };
  sidebar: string;
  className?: string;
  header: string;
  conditions?: ((user: UserRoleInfo) => boolean)[];
};

export type NavigationPath = NavigationBase & {
  path: string;
  startsWith?: string[];
};

export type NavigationNested = NavigationBase & {
  items: NavigationConfig;
  tutorialOpenKey?: string;
};

export type NavigationConfig = Array<NavigationPath | NavigationNested>;

export const recursiveFindItem = (
  items: NavigationConfig,
  predicate: (item: NavigationPath | NavigationNested) => boolean
): NavigationConfig[0] | undefined => {
  for (const item of items) {
    if (predicate(item)) {
      return item;
    }

    const possiblyNested = item as unknown as NavigationNested;
    if (Array.isArray(possiblyNested.items)) {
      const nestedItem = recursiveFindItem(possiblyNested.items, predicate);

      if (nestedItem) {
        return nestedItem;
      }
    }
  }
};

/**
 * We use training section sidebarName as path and this function replaces spaces between words with dash and lowercases the path.
 */
export const normalizeTrainingSectionPath = (capitalPathWithSpaces: string) =>
  capitalPathWithSpaces.replace(' ', '-').toLocaleLowerCase();
