import React, { useCallback } from 'react';
import styled from 'styled-components';
import { TableRowSelection } from 'antd/lib/table/interface';
import { useQueryParams, DelimitedNumericArrayParam } from 'use-query-params';

import { centerFlex, center } from 'utils/mixins';

const Wrap = styled.div`
  ${centerFlex}
  ${center}
`;

const Transparent = styled.div`
  width: 55px;
  height: 55px;
  background-color: transparent;
  position: absolute;
  z-index: 2;
`;

const useTableSelection = (): [
  any[],
  TableRowSelection<any>['onChange'],
  {
    isAnySelected: boolean;
    renderCell: NonNullable<TableRowSelection<any>['renderCell']>;
    removeSelectionBeforeAction: <T extends Function>(callback: T) => (...args: any) => void;
    handleDeselectAll: () => void;
  }
] => {
  const [selectedItemsObj, setSelectedItemsInternal] = useQueryParams({
    selected: DelimitedNumericArrayParam,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const selectedItems = selectedItemsObj.selected || [];

  const setSelectedItems = useCallback(
    (arg: any[] | ((param: any[]) => any[])) => {
      setSelectedItemsInternal((selectedItemsObj) => {
        if (typeof arg === 'function') {
          // If we are eliminating the last item, we set selected items to undefined to clean the selectedIds from url params
          const newItems = arg(selectedItemsObj.selected || []);
          const newItemsSafe = newItems.length ? newItems : undefined;

          return { selected: newItemsSafe };
        }

        return { selected: arg.length ? arg : undefined };
      });
    },
    [setSelectedItemsInternal]
  );

  const renderCell = useCallback(
    (_checked, record, index, checkbox) => {
      const handleWrapClick = (event: React.MouseEvent<any>, record: any) => {
        event.stopPropagation();

        setSelectedItems((items) => {
          const included = items.includes(record.id);

          if (included) {
            return items.filter((item) => item !== record.id);
          }

          return [...items, record.id];
        });
      };

      return (
        <Wrap>
          <Transparent
            data-testid={`table-checkbox-${index}`}
            onClick={(event: React.MouseEvent<any>) => handleWrapClick(event, record)}
          />
          {checkbox}
        </Wrap>
      );
    },
    [setSelectedItems]
  );

  const removeSelectionBeforeAction = useCallback(
    <T extends Function>(callback: T) =>
      (...args: any[]) => {
        setSelectedItems([]);
        callback(...args);
      },
    [setSelectedItems]
  );

  const handleDeselectAll = useCallback(() => {
    if (selectedItems.length > 0) {
      setSelectedItems([]);
    }
  }, [selectedItems, setSelectedItems]);

  return [
    selectedItems,
    setSelectedItems,
    {
      renderCell,
      isAnySelected: selectedItems.length !== 0,
      removeSelectionBeforeAction,
      handleDeselectAll,
    },
  ];
};

export default useTableSelection;
