import visualControls from 'containers/OnboardingTutorialContext/controls';

import useOnboardingTutorial from './useOnboardingTutorial';

const useTutorialControls = () => {
  const tutorial = useOnboardingTutorial();

  return visualControls(tutorial?.step || 0);
};

export default useTutorialControls;
