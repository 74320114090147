import styled from 'styled-components';

export const Wrap = styled.div`
  display: flex;
  align-items: center;
  min-height: 100vh;
`;

export const InnerWrap = styled.div`
  margin-left: 150px;
`;
