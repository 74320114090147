import { Layout, Menu as AntdMenu } from 'antd';
import styled, { css } from 'styled-components';

import BaseDropdown from 'components/Dropdown';
import userIcon from 'assets/svg/ProfileDropdownIcons/user.svg';

export const Header = styled(Layout.Header)`
  &.ant-layout-header {
    padding: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }

  ${({ theme }) => css`
    &.ant-layout-header {
      background-color: ${theme.colors.darkBlue};
      border-left: 0.1px solid ${theme.colors.grey};
      height: ${theme.sizes.headerHeightPx}px;
    }
  `}
`;

// LEFT CORNER OF THE HEADER

export const Title = styled.div`
  margin-left: 28px;
  font-size: ${({ theme }) => theme.fontSizes.lg};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.white};
`;

export const TitleIcon = styled.img`
  margin-right: 11px;
`;

// RIGHT CORNER OF THE HEADER

export const UserInfoBlock = styled.div`
  display: flex;
  align-items: center;
`;

export const UserIcon = styled.img.attrs({
  src: userIcon,
  'data-testid': 'userIcon',
  alt: 'Currently signed in user icon as well as user menu trigger',
})`
  cursor: pointer;
`;

export const Dropdown = styled(BaseDropdown)``;

export const MenuTrigger = styled.div`
  padding-right: ${({ theme }) => theme.spacings.lg};
  display: flex;
  align-items: center;
  cursor: pointer;
`;
export const Menu = styled(AntdMenu)`
  &.ant-dropdown-menu {
    display: flex;
    flex-direction: column;
    align-items: 'flex-start';
    margin-right: ${({ theme }) => theme.spacings.lg};
    padding: 0px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
    border-radius: ${({ theme }) => theme.radius.sm};
  }
`;
