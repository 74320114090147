import { Config, IntegrationSourceTypes, FilmingGroupEmailTriggerSetting } from 'codegen';
import { AdminUsers, StorymakerUsers } from 'utils/constants';

export default {
  __typename: 'Config',
  stripeMaxCouponTries: 3,
  userRoles: [
    {
      __typename: 'UserRole',
      role: AdminUsers.Manager,
      roleName: 'NOW Manager',
      isOldCMSRole: false,
    },
    {
      __typename: 'UserRole',
      role: AdminUsers.Moderator2,
      roleName: 'Manager',
      isOldCMSRole: false,
    },
    {
      __typename: 'UserRole',
      role: 'Moderator',
      roleName: 'Manager 1.0',
      isOldCMSRole: true,
    },
    {
      __typename: 'UserRole',
      role: AdminUsers.AccountAdmin,
      roleName: 'Account Admin',
      isOldCMSRole: false,
    },
    {
      __typename: 'UserRole',
      role: 'Moderator - Secure',
      roleName: 'Secure Manager',
      isOldCMSRole: true,
    },
    {
      __typename: 'UserRole',
      role: AdminUsers.ModeratorSecure2,
      roleName: 'Secure Manager 2.0',
      isOldCMSRole: false,
    },
    {
      __typename: 'UserRole',
      role: 'Superadmin',
      roleName: 'Super Admin',
      isOldCMSRole: true,
    },
    {
      __typename: 'UserRole',
      role: StorymakerUsers.Reviewer,
      roleName: 'Reviewer',
      isOldCMSRole: false,
    },
  ],
  videoStatusTransitionMap: {
    __typename: 'VideoStatusTransitionMap',
    approved: ['do_not_use', 'not_approved'],
    created: ['do_not_use'],
    do_not_use: ['review_requested', 'rendered'],
    failed: [],
    preprocessing: [],
    rendered: ['approved', 'do_not_use', 'review_requested'],
    rendering: [],
    not_approved: ['approved'],
    review_requested: ['approved', 'not_approved', 'do_not_use'],
  },
  restrictedRoleStatusMap: {
    __typename: 'VideoRestrictedRoleStatusMap',
    storymaker: ['approved', 'not_approved'],
    reviewer: ['created', 'rendering', 'rendered', 'do_not_use'],
  },
  statusesOrder: [
    'review_requested',
    'approved',
    'not_approved',
    'rendered',
    'rendering',
    'preprocessing',
    'created',
    'failed',
    'do_not_use',
  ],
  humanizedVideoStatuses: {
    __typename: 'HumanizedVideoStatuses',
    do_not_use: 'do not use',
    preprocessing: 'building',
    rendering: 'building',
    rendered: 'complete',
    created: 'created',
    failed: 'failed',
    approved: 'approved',
    review_requested: 'review requested',
    not_approved: 'not approved',
  },
  integrationSources: ['realnex'],
  humanizedEmailTriggerSetting: {
    __typename: 'HumanizedEmailTriggerSetting',
    no_notifications: 'Never',
    notifications_upon_render: 'Always',
    notifications_after_approval: 'Only after approval',
  },
  subscriptionTypeManagerRole: {
    __typename: 'SubscriptionTypeManagerRole',
    Healthcare: 'Moderator - Secure 2.0',
    Quick: 'Moderator 2.0',
    Complete: 'Moderator 2.0',
    Other: 'Moderator 2.0',
    Storyvine_internal: 'Moderator 2.0',
    Now: 'Manager',
  },
  defaultLinkExpirationMap: {
    __typename: 'DefaultLinkExpirationMap',
    Healthcare: '7 days',
    Quick: '10 years',
    Complete: '10 years',
    Other: '10 years',
    Storyvine_internal: '10 years',
    Now: '10 years',
  },
  subscriptionTypeDefaultEmailTriggerSetting: {
    __typename: 'SubscriptionTypeDefaultEmailTriggerSetting',
    Healthcare: FilmingGroupEmailTriggerSetting.NoNotifications,
    Quick: FilmingGroupEmailTriggerSetting.NotificationsUponRender,
    Complete: FilmingGroupEmailTriggerSetting.NotificationsUponRender,
    Other: FilmingGroupEmailTriggerSetting.NotificationsUponRender,
    Storyvine_internal: FilmingGroupEmailTriggerSetting.NotificationsUponRender,
    Now: FilmingGroupEmailTriggerSetting.NotificationsAfterApproval,
  },
  humanizedVimeoUploadArgs: {
    __typename: 'HumanizedVimeoUploadArgs',
    view: {
      __typename: 'HumanizedVimeoViewArgs',
      anybody: 'Anyone',
      nobody: 'Only me',
      disable: 'Hide from Vimeo',
      password: 'Only people with a password',
      unlisted: 'Only people with the private link',
    },
    embed: {
      __typename: 'HumanizedVimeoEmbedArgs',
      public: 'Everywhere',
      private: 'Nowhere',
      whitelist: 'Specific domains',
    },
  },
} as Omit<Config, '__typename'> & {
  integrationSources: IntegrationSourceTypes[];
};
