import { currentUser, multiFactor } from 'lib/firebase';
import { useCallback, useState } from 'react';
import { useIdleTimer, workerTimers } from 'react-idle-timer';
import { useHistory } from 'react-router';
import { getEnvVar } from 'utils/env';
import { useFirebase, useOnChange, useWarning } from 'utils/hooks';

const EXPIRE_SESSION_WARNING_PROPS = {
  text: 'Your session is about to expire. You will be logged out soon unless you want to keep working.',
  cancelAfterOk: false,
  cancelText: 'LOG OUT',
  okText: 'KEEP WORKING',
};

export const EXPIRED_SESSION_STATE_NAME = 'expiredSession';

const LOGOUT_ACTION = 'LOGOUT_ACTION';

const useIdleTimeout = () => {
  const expireSessionWarning = useWarning();
  const { logout, user } = useFirebase();
  const history = useHistory();
  const role = user?.idTokenResult.claims.role;
  const isPasswordSecure = role === 'Account Admin' || role === 'Moderator - Secure 2.0';

  const [shouldContinue, setShouldContinue] = useState(false);
  const [shouldLogout, setShouldLogout] = useState(false);
  const [shouldReset, setShouldReset] = useState(false);
  const [shouldMessage, setShouldMessage] = useState(false);

  const onPrompt = useCallback(() => {
    if (!user || !isPasswordSecure) {
      setShouldReset(true);
      return;
    }

    expireSessionWarning.openWarning({
      ...EXPIRE_SESSION_WARNING_PROPS,
      onCancel: () => setShouldMessage(true),
      onOk: () => setShouldContinue(true),
    });
  }, [user, isPasswordSecure, expireSessionWarning]);

  const onActive = useCallback(() => {
    expireSessionWarning.closeWarning();
  }, [expireSessionWarning]);

  const onIdle = useCallback(() => {
    setShouldLogout(true);
  }, []);

  const onMessage = useCallback((action: string) => {
    if (action === LOGOUT_ACTION) {
      setShouldLogout(true);
    }
  }, []);

  const { activate, reset, message } = useIdleTimer({
    timeout:
      currentUser() && multiFactor().enrolledFactors.length > 0
        ? Number(getEnvVar('REACT_APP_TIMEOUT_TO_SESSION_EXPIRATION_WARNING_2FA')) || 28800000
        : Number(getEnvVar('TIMEOUT_TO_SESSION_EXPIRATION_WARNING')) || 1740000,
    promptTimeout: 1000 * 60,
    onPrompt,
    onIdle,
    onActive,
    onMessage,
    crossTab: true,
    timers: workerTimers,
  });

  const handleLogout = () => {
    history.push({ state: { [EXPIRED_SESSION_STATE_NAME]: true } });
    reset();
    logout().then();
    expireSessionWarning.closeWarning();
  };

  useOnChange(
    shouldContinue,
    () => {
      activate();
      setShouldContinue(false);
    },
    (current) => current
  );

  useOnChange(
    shouldLogout,
    () => {
      handleLogout();
      setShouldLogout(false);
    },
    (current) => current
  );

  useOnChange(
    shouldReset,
    () => {
      reset();
      setShouldReset(false);
    },
    (current) => current
  );

  useOnChange(
    shouldMessage,
    () => {
      message(LOGOUT_ACTION, true);
      setShouldMessage(false);
    },
    (current) => current
  );
};

export default useIdleTimeout;
