import styled from 'styled-components';

export const Wrap = styled.div`
  position: absolute;
  width: 100%;
  bottom: 67px;
  left: 0;
`;

export const Image = styled.img`
  width: 100%;
`;
