import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';

import { SIGNUP_STEP_ROUTES } from 'pages/SignUp/common';
import { INVITE_STEP_ROUTES } from 'pages/Invite/common';
import { useFirebaseClaims } from 'utils/hooks';
import { isSpecialFlowRoute } from 'containers/Router/utils';
import { unarray } from 'utils/array';

import { ALLOWED_ROUTES_FROM_INVITE_OR_SIGNUP_FLOWS } from './config';

type Props = {};

const DropOffRedirect: React.FC<Props> = ({ children }) => {
  const { claims } = useFirebaseClaims();
  const location = useLocation();

  if (ALLOWED_ROUTES_FROM_INVITE_OR_SIGNUP_FLOWS.includes(location.pathname)) {
    return <>{children}</>;
  }

  const signUpStep = claims?.signUpStep as keyof typeof SIGNUP_STEP_ROUTES;
  const acceptSecondManagerInviteStep =
    claims?.acceptSecondManagerInviteStep as keyof typeof INVITE_STEP_ROUTES;
  const selectedProduct = claims?.signUpProductId;

  // adding selected product into search params
  const searchExt = selectedProduct ? { search: `?product=${selectedProduct}` } : {};

  const [isSignUpRoute, isAcceptInviteRoute] = [
    isSpecialFlowRoute(SIGNUP_STEP_ROUTES, location.pathname),
    isSpecialFlowRoute(INVITE_STEP_ROUTES, location.pathname),
  ];

  // If user dropped off at some point during onboarding
  if (signUpStep) {
    const newRoute = SIGNUP_STEP_ROUTES[signUpStep];

    if (
      Array.isArray(newRoute)
        ? !newRoute.includes(location.pathname)
        : location.pathname !== newRoute
    ) {
      // Redirect to where user left off
      return (
        <Redirect
          to={{
            pathname: unarray(newRoute) as string,
            ...searchExt,
          }}
        />
      );
    }

    // If user tries to access next step without completing first step
  } else if (
    isSignUpRoute &&
    location.pathname !== SIGNUP_STEP_ROUTES[1] &&
    !acceptSecondManagerInviteStep
  ) {
    // Redirect to first step
    return (
      <Redirect
        to={{
          pathname: SIGNUP_STEP_ROUTES[1],
          ...searchExt,
        }}
      />
    );
  }

  // If user dropped off at some point during accepting invite phase
  if (acceptSecondManagerInviteStep) {
    const newRoute = INVITE_STEP_ROUTES[acceptSecondManagerInviteStep];

    if (location.pathname !== newRoute) {
      return (
        <Redirect
          to={{
            pathname: newRoute,
          }}
        />
      );
    }
    // If  user tries to access any of the accept invite steps without completing first step
  } else if (isAcceptInviteRoute) {
    return (
      <Redirect
        to={{
          pathname: '/login',
        }}
      />
    );
  }

  return <>{children}</>;
};

export default DropOffRedirect;
