import TagManager from 'react-gtm-module';

import { getEnvVar, tryGetEnvVar } from 'utils/env';

let initalized: { result: boolean };

const init = () => {
  if (!initalized) {
    const enabled = tryGetEnvVar('GOOGLE_TAG_MANAGER_ENABLED') === 'true';
    const gtmId = enabled && getEnvVar('GOOGLE_TAG_MANAGER_ID');

    gtmId &&
      TagManager.initialize({
        gtmId,
      });

    initalized = { result: !!gtmId && enabled };
  }

  return initalized.result;
};

export default init;
