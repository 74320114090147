class BaseError extends Error {
  constructor(message: string) {
    super(message);
    this.name = this.constructor.name;
  }
}

export class EnvironmentError extends BaseError {
  constructor(reason: string) {
    super(`Environment is misconfigured. ${reason}`);
  }
}

export class InvalidStateError extends BaseError {
  constructor(reason: string) {
    super(`Invalid state. ${reason}`);
  }
}

export class UnexpectedServerError extends BaseError {
  constructor(reason: string) {
    super(`Unexpected server error. ${reason}`);
  }
}

export class ExternalServiceError extends BaseError {
  constructor(reason: string) {
    super(`External server error. ${reason}`);
  }
}

export class RequestAbortedError extends Error {
  static TYPE = 'ABORTED';
  type = RequestAbortedError.TYPE;
  constructor(reason: string = 'Request aborted') {
    super(reason);
  }
}
