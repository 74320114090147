import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  * {
    animation-duration: 0s !important;
  }

  .ant-modal-mask {
    display: none;
  }

  .reactour__helper.reactour__helper--is-open {
    z-index: 99999999999
  }
`;
