import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5';

import * as GoogleTagManager from 'containers/GoogleTagManager';
import RouteTracker from 'containers/RouteTracker';
import FullPageLayout from 'components/Layout/FullPageLayout';
import RedirectAs404 from 'components/RedirectAs404';
import SignUpDropOffRedirect from 'components/SignUpPage/DropOffRedirect';
import roleChecks, { and } from 'utils/roles';
import { useIdleTimeout } from 'utils/hooks';

import PublicRoute from './PublicRoute';
import ProtectedRoute from './ProtectedRoute';
import * as S from './styles';
import Loading from 'components/Loading';
import { Page } from './routes';

/* Routes that are rendered under FullPageLayout component
should be configured in ./breadcrumbConfig.ts */

// We put exact because we want to redirect user to 404 in the case /login/someRandomPath
const ValidRoutes = React.memo(() => {
  return (
    <SignUpDropOffRedirect>
      <React.Suspense fallback={<Loading />}>
        <Switch>
          <Redirect exact from="/" to="/videos" />
          <Route exact path="/sso-login/:email" component={React.lazy(Page.Login)} />
          <PublicRoute exact path="/login" component={React.lazy(Page.LoginMultiStep)} />
          <PublicRoute exact path="/login-cms" component={React.lazy(Page.LoginUpgrade)} />
          <ProtectedRoute exact path="/verify-email" component={React.lazy(Page.VerifyEmail)} />
          <PublicRoute exact path="/2fa" component={React.lazy(Page.TwoFactorAuthentication)} />
          <PublicRoute
            exact
            path="/password-security-upgrade"
            component={React.lazy(Page.PasswordSecurityUpgrade)}
          />
          <Route exact path="/oauth-redirect" component={React.lazy(Page.OAuthDropOf)} />
          <Route
            exact
            path="/signup/create-account"
            component={React.lazy(Page.SignupCreateAccount)}
          />
          <Route
            exact
            path="/signup/payment-checkout"
            component={React.lazy(Page.SignUpPaymentCheckout)}
          />
          <Route
            exact
            path="/signup/payment-checkout-success"
            component={React.lazy(Page.SignUpPaymentCheckout)}
          />
          <PublicRoute exact path="/invite" component={React.lazy(Page.InviteSetPassword)} />
          <PublicRoute exact path="/forgot-password" component={React.lazy(Page.SendResetEmail)} />

          <PublicRoute exact path="/new-password" component={React.lazy(Page.ChangePassword)} />
          <PublicRoute exact path="/auth-redirect" component={React.lazy(Page.AuthRedirect)} />
          <Route exact path="/error" component={React.lazy(Page.InternalError)} />
          <ProtectedRoute
            exact
            path="/2fa-unenroll"
            component={React.lazy(Page.TwoFactorAuthenticationUnenroll)}
          />
          <FullPageLayout>
            <Switch>
              <Route
                exact
                path="/invite/set-user-info"
                component={React.lazy(Page.InviteSetAdminUserInfo)}
              />
              <Route exact path="/setup/manager" component={React.lazy(Page.SetupManager)} />
              <Route
                exact
                path="/setup/organization"
                component={React.lazy(Page.SetupOrganization)}
              />
              <Route exact path="/setup/waiting" component={React.lazy(Page.SetupWaiting)} />
              <ProtectedRoute
                exact
                path="/invalid-subscription"
                component={React.lazy(Page.InvalidSubscription)}
              />
              <ProtectedRoute exact path="/videos" component={React.lazy(Page.Videos)} />
              <ProtectedRoute exact path="/storymakers" component={React.lazy(Page.StoryMakers)} />

              <ProtectedRoute exact path="/reviewers" component={React.lazy(Page.Reviewers)} />
              <ProtectedRoute
                exact
                path="/filming-groups"
                component={React.lazy(Page.FilmingGroups)}
              />
              <ProtectedRoute
                exact
                path="/template/:id"
                component={React.lazy(Page.TemplateDetail)}
              />
              <ProtectedRoute
                exact
                path="/settings/brand"
                component={React.lazy(Page.SettingsBrand)}
              />
              <ProtectedRoute
                exact
                path="/settings/subscription"
                roleChecks={and(roleChecks.isPrimary, roleChecks.isManager)}
                component={React.lazy(Page.SettingsSubscription)}
              />
              <ProtectedRoute
                exact
                path="/organizations"
                component={React.lazy(Page.Organizations)}
                roleChecks={roleChecks.isAdministrator}
              />
              <ProtectedRoute
                exact
                path="/organizations/:id"
                component={React.lazy(Page.OrganizationDetailDetails)}
                roleChecks={roleChecks.isAdministrator}
              />
              <ProtectedRoute
                exact
                path="/organizations/:id/filming-groups"
                component={React.lazy(Page.OrganizationDetailFilmingGroups)}
                roleChecks={roleChecks.isAdministrator}
              />
              <ProtectedRoute
                exact
                path="/organizations/:id/filming-groups/create"
                component={React.lazy(Page.OrganizationDetailFilmingGroupCreate)}
                roleChecks={roleChecks.isAdministrator}
              />
              <ProtectedRoute
                exact
                path="/(admins|managers)"
                component={React.lazy(Page.AdminUsers)}
                roleChecks={roleChecks.isAdministrator}
              />
              <ProtectedRoute
                exact
                path="/settings/organization"
                component={React.lazy(Page.SettingsOrganization)}
              />
              <ProtectedRoute
                exact
                path="/settings/managers"
                component={React.lazy(Page.SettingsAdminUsers)}
              />
              <ProtectedRoute
                exact
                path="/(admins|managers)/:id"
                roleChecks={roleChecks.isAdministrator}
                component={React.lazy(Page.AdminUserDetail)}
              />
              <ProtectedRoute
                exact
                path="/storymakers/:id"
                component={React.lazy(Page.StorymakerDetail)}
              />
              <ProtectedRoute
                exact
                path="/templates/:id"
                roleChecks={roleChecks.isAdministrator}
                component={React.lazy(Page.TemplateDetailAdminDetails)}
              />
              <ProtectedRoute
                exact
                path="/templates/:id/filming-guide"
                roleChecks={roleChecks.isAdministrator}
                component={React.lazy(Page.TemplateDetailAdminFilmingGuide)}
              />
              <ProtectedRoute
                exact
                path="/templates"
                roleChecks={roleChecks.isAdministrator}
                component={React.lazy(Page.Templates)}
              />
              <ProtectedRoute
                exact
                path="/expired-password"
                component={React.lazy(Page.ExpiredPassword)}
              />
              <ProtectedRoute
                exact
                path="/videos/create"
                component={React.lazy(Page.CreateVideo)}
                roleChecks={roleChecks.isAdministratorOrNonNowManager}
              />
              <ProtectedRoute exact path="/videos/:id" component={React.lazy(Page.VideoDetail)} />
              <ProtectedRoute path="/training/:name" component={React.lazy(Page.Training)} />
              <RedirectAs404 />
            </Switch>
          </FullPageLayout>
          <RedirectAs404 />
        </Switch>
      </React.Suspense>
    </SignUpDropOffRedirect>
  );
});

const Router = () => {
  GoogleTagManager.init();
  useIdleTimeout();

  return (
    <S.Wrap>
      <RouteTracker />
      <QueryParamProvider adapter={ReactRouter5Adapter}>
        <Switch>
          <Route
            render={(props) => {
              const state = props.location.state as { is404?: boolean };
              return state?.is404 ? Page.NotFound : <ValidRoutes />;
            }}
          />
        </Switch>
      </QueryParamProvider>
    </S.Wrap>
  );
};

export default Router;
