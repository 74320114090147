import React from 'react';
import { ButtonProps } from 'antd/lib/button';
import { FormInstance } from 'antd/lib/form';

import * as S from './styles';

type Props = ButtonProps & {
  formInstance?: FormInstance;
  customColor?: S.ButtonColorOptions;
};

const Button: React.FC<Props> = ({
  children,
  loading,
  formInstance,
  disabled,
  customColor,
  ...buttonProps
}) => {
  return (
    <S.Button
      loading={loading}
      htmlType="submit"
      $customColor={customColor}
      {...buttonProps}
      disabled={
        disabled ||
        !!loading ||
        formInstance?.getFieldsError().some((field) => field.errors.length > 0)
      }
    >
      {children}
    </S.Button>
  );
};

export default Button;
