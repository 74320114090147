import { VideoStatus } from 'codegen';

export const renderedStatuses = [
  VideoStatus.Rendered,
  VideoStatus.Approved,
  VideoStatus.DoNotUse,
  VideoStatus.NotApproved,
  VideoStatus.ReviewRequested,
];

export const buildingStatuses = [VideoStatus.Rendering, VideoStatus.Preprocessing];

export const reviewerUnchangableStatuses = [VideoStatus.DoNotUse, VideoStatus.Rendered];

export const isRenderedStatus = (status: VideoStatus) => renderedStatuses.includes(status);

export const isBuildingStatus = (status: VideoStatus) => buildingStatuses.includes(status);

export const isVideoRelease = (assetKey?: string | null) => 'svData_Video_Legal' === assetKey;
