import { useHistory } from 'react-router-dom';

import { StepRouteMap } from './constants';

const useRouterController = (step?: number) => {
  const history = useHistory();
  const pathname = history.location.pathname;

  let redirectRoute: string | undefined;
  if (step && pathname !== StepRouteMap[step as keyof typeof StepRouteMap]) {
    redirectRoute = StepRouteMap[step as keyof typeof StepRouteMap];
  }

  return redirectRoute;
};

export default useRouterController;
