import React, { useRef, useEffect } from 'react';
import ReactGA from 'react-ga';
import { useLocation } from 'react-router-dom';

import * as GoogleAnalytics from 'containers/GoogleAnalytics';
import mixpanel, { events } from 'lib/mixpanel';

type Props = {};

const RouteTracker: React.FC<Props> = () => {
  const location = useLocation();
  const previousLocation = useRef<typeof location>();

  const { pathname, search } = location;

  useEffect(() => {
    if (
      pathname !== previousLocation.current?.pathname ||
      search !== previousLocation.current?.search
    ) {
      const page = pathname + search;
      const { location } = window;

      if (GoogleAnalytics.init()) {
        ReactGA.set({
          page,
          location: `${location.origin}${page}`,
        });
        ReactGA.pageview(page);
      }
      mixpanel.track(events.PAGEVIEW, { Page: page, Location: `${location.origin}${page}` });
    }

    previousLocation.current = location;
  }, [pathname, search, location]);

  return null;
};

export default RouteTracker;
