import styled from 'styled-components';
import { Card as ANTDCard } from 'antd';

import Button from 'components/Button';
import trainingIllustrationPng from 'assets/png/trainingIllustration.png';
import crossIconSvg from 'assets/svg/crossIcon.svg';
import { bold, themeOpaqueColor } from 'utils/mixins';

export const Card = styled(ANTDCard)<{ $visible: boolean }>`
  &.ant-card {
    display: ${({ $visible }) => ($visible ? 'block' : 'none')};
    min-height: 320px;
    width: 280px;
    border-radius: ${({ theme }) => theme.radius.sm};
    box-shadow: 0px 4px 8px ${themeOpaqueColor('darkBlack', 0.15)};
  }
`;

export const CardCoverWrap = styled.div`
  position: relative;
  max-height: 104px;
`;

export const CardCoverImg = styled.img.attrs({
  src: trainingIllustrationPng,
  alt: 'Training Illustration Image',
})`
  width: 100%;
  height: 100%;
  user-select: none;
`;

export const CardCoverClose = styled.img.attrs({ src: crossIconSvg, alt: 'Close Training Icon' })`
  position: absolute;
  right: ${({ theme }) => theme.spacings.sm};
  top: ${({ theme }) => theme.spacings.sm};
  cursor: pointer;
`;

export const CardBody = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.sub`
  ${bold}
  text-transform: uppercase;
`;

export const Paragraph = styled.p`
  margin-top: ${({ theme }) => theme.spacings.md};
`;

export const StartButton = styled(Button).attrs({ type: 'primary' })`
  height: 40px;
  min-width: 182px;
  align-self: center;
`;

export const SkipButton = styled.div<{ $color: string }>`
  min-width: 182px;
  align-self: center;
  font-size: ${({ theme }) => theme.fontSizes.sm};
  line-height: ${({ theme }) => theme.lineHeights.sm};
  text-align: center;
  cursor: pointer;
  margin-top: ${({ theme }) => theme.spacings.sm};
  color: ${({ $color }) => $color};
`;
