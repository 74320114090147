import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  JSON: any;
  Upload: any;
  Void: any;
};

/** Error provided by Storyvine Ruby backend */
export type ApiError = {
  __typename?: 'APIError';
  fieldName: Scalars['String'];
  messages: Array<Scalars['String']>;
};

/** Union result for AcceptInvitationResult,GenericError  */
export type AcceptInvitationResponse = AcceptInvitationResult | GenericError;

export type AcceptInvitationResult = {
  __typename?: 'AcceptInvitationResult';
  email: Scalars['String'];
};

export type AddFirebaseUidToUser = {
  __typename?: 'AddFirebaseUidToUser';
  id: Scalars['Int'];
};

export type AddFirebaseUidToUserInput = {
  email: Scalars['String'];
};

export type AddFirebaseUidToUserMobile = {
  __typename?: 'AddFirebaseUidToUserMobile';
  customToken: Scalars['String'];
  id: Scalars['Int'];
};

export type AddFirebaseUidToUserMobileInput = {
  accessToken: Scalars['String'];
};

/** Union result for AddFirebaseUidToUser,GenericError  */
export type AddFirebaseUidToUserResult = AddFirebaseUidToUser | GenericError;

export type AddManagerInfoInput = {
  cellPhone?: InputMaybe<Scalars['String']>;
  directPhone?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  title: Scalars['String'];
};

/** Union result for SignUpData,RefetchTokenError,GenericError  */
export type AddManagerInfoResult = GenericError | RefetchTokenError | SignUpData;

/** Admin user of a Storyvine organizations */
export type AdminUser = AdminUserBase & Node & {
  __typename?: 'AdminUser';
  cellPhone?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  directPhone?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  disabledEmailNotifications?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  featureFlags?: Maybe<Array<FeatureFlag>>;
  firstName?: Maybe<Scalars['String']>;
  has2fa?: Maybe<Scalars['Boolean']>;
  hasAcceptedInvite?: Maybe<Scalars['Boolean']>;
  /** Unique identifier for the resource */
  id: Scalars['Int'];
  isMe: Scalars['Boolean'];
  isPrimary: Scalars['Boolean'];
  lastName?: Maybe<Scalars['String']>;
  lastSignInAt?: Maybe<Scalars['DateTime']>;
  organization?: Maybe<Organization>;
  role?: Maybe<Scalars['String']>;
  roleName?: Maybe<Scalars['String']>;
  roleVersion?: Maybe<Scalars['Int']>;
  signInCount?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AdminUserBase = {
  disabled?: Maybe<Scalars['Boolean']>;
  disabledEmailNotifications?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
};

export type AdminUserFilterInput = {
  adminUserId?: InputMaybe<Scalars['Int']>;
  organizationId?: InputMaybe<Array<Scalars['Int']>>;
};

export type AfterLoginAction = {
  __typename?: 'AfterLoginAction';
  id: Scalars['Int'];
};

/** Union result for AfterLoginAction,GenericError  */
export type AfterLoginUserActionResult = AfterLoginAction | GenericError;

export type AfterUserLoginActionInput = {
  anonymousFirebaseId?: InputMaybe<Scalars['String']>;
  client: ClientTypeEnum;
  emailOrToken: Scalars['String'];
  sso: Scalars['Boolean'];
};

export type AppUser = Reviewer | Storymaker;

export type AppUserFilterInput = {
  appUserId?: InputMaybe<Scalars['Int']>;
  filmingGroupId?: InputMaybe<Array<Scalars['Int']>>;
  onlyReviewers?: InputMaybe<Scalars['Boolean']>;
  organizationId?: InputMaybe<Array<Scalars['Int']>>;
};

export type AppUserInterface = {
  createdAt: Scalars['DateTime'];
  deleted: Scalars['Boolean'];
  displayName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  filmingGroups: Array<FilmingGroup>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  organizationIds: Array<Scalars['Int']>;
  organizations: Array<Organization>;
  suspended?: Maybe<Scalars['Boolean']>;
  userCustomProperties?: Maybe<CustomProperties>;
  userSignupProperties: Scalars['JSON'];
  videoCount: Scalars['Int'];
};

/** Available feature flags to the application */
export enum ApplicationFeatureFlags {
  CustomShareOptions = 'CUSTOM_SHARE_OPTIONS',
  Feedback = 'FEEDBACK',
  MobileAppNavigation = 'MOBILE_APP_NAVIGATION',
  MobileAppVideoHistory = 'MOBILE_APP_VIDEO_HISTORY',
  NewVideoStatuses = 'NEW_VIDEO_STATUSES',
  NextGenCsvUpload = 'NEXT_GEN_CSV_UPLOAD',
  Review = 'REVIEW',
  Sso = 'SSO',
  VideoShareHistory = 'VIDEO_SHARE_HISTORY'
}

/** Union result for CouponApplicationResult,RefetchTokenError  */
export type ApplyCouponCodeResult = CouponApplicationResult | RefetchTokenError;

export type AssetLink = {
  assetKey: Scalars['String'];
  external: Scalars['Boolean'];
  url: Scalars['String'];
};

/** Union result for FilmingGroup,GenericError  */
export type AssignTemplatesToFilmingGroupResult = FilmingGroup | GenericError;

export type BaseError = {
  code: Scalars['Int'];
  message: Scalars['String'];
};

/** Brand asset of the organization. It is served with organization id as id. */
export type BrandAsset = Node & {
  __typename?: 'BrandAsset';
  colorLogo?: Maybe<Scalars['String']>;
  darkColor?: Maybe<Scalars['String']>;
  endCard: EndCard;
  greyColor?: Maybe<Scalars['String']>;
  /** Unique identifier for the resource */
  id: Scalars['Int'];
  lightColor?: Maybe<Scalars['String']>;
  videoReleaseStatement?: Maybe<Scalars['String']>;
  whiteLogo?: Maybe<Scalars['String']>;
};

/** Union result for GenericError,OutdatedDataError  */
export type CancelAdminUserInvitationResult = GenericError | OutdatedDataError;

/** Storyvine NOW template's capture step */
export type CaptureStep = Node & {
  __typename?: 'CaptureStep';
  coachingBody?: Maybe<Scalars['String']>;
  coachingPrompt?: Maybe<Scalars['String']>;
  coachingTitle?: Maybe<Scalars['String']>;
  /** Unique identifier for the resource */
  id: Scalars['Int'];
  key?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
  overlay?: Maybe<Scalars['String']>;
  overlayV2?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
};

export type CaptureStepInput = {
  coachingBody: Scalars['String'];
  coachingTitle: Scalars['String'];
  id: Scalars['Int'];
};

/**
 *
 *     Storyvine NOW template's capture steps list
 *
 *     Capture steps lists work as variants on a single template.
 *     End user sees them as different templates with shared data
 *     the only difference between those variants of templates are capture steps
 *
 */
export type CaptureStepsList = Node & {
  __typename?: 'CaptureStepsList';
  captureSteps?: Maybe<Array<CaptureStep>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Unique identifier for the resource */
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  template?: Maybe<Template>;
  templateId?: Maybe<Scalars['Int']>;
};

export type CaptureStepsListInput = {
  /** Capture steps updated in this capture steps list */
  captureSteps: Array<CaptureStepInput>;
  id: Scalars['Int'];
  name: Scalars['String'];
};

export enum ClientTypeEnum {
  App = 'APP',
  Dashboard = 'DASHBOARD'
}

/** Union result for ValidationError,AdminUser  */
export type CompleteResetPasswordResult = AdminUser | ValidationError;

/** Required configuration for client applications to function properly and sync with the API. */
export type Config = {
  __typename?: 'Config';
  defaultLinkExpirationMap: DefaultLinkExpirationMap;
  humanizedEmailTriggerSetting: HumanizedEmailTriggerSetting;
  humanizedVideoStatuses: HumanizedVideoStatuses;
  humanizedVimeoUploadArgs: HumanizedVimeoUploadArgs;
  integrationSources: Array<Scalars['String']>;
  restrictedRoleStatusMap: VideoRestrictedRoleStatusMap;
  statusesOrder: Array<Scalars['String']>;
  stripeMaxCouponTries: Scalars['Int'];
  subscriptionTypeDefaultEmailTriggerSetting: SubscriptionTypeDefaultEmailTriggerSetting;
  subscriptionTypeManagerRole: SubscriptionTypeManagerRole;
  userRoles: Array<UserRole>;
  videoStatusTransitionMap: VideoStatusTransitionMap;
};

export type CouponApplicationResult = {
  __typename?: 'CouponApplicationResult';
  amountOff: Scalars['Float'];
  percentOff: Scalars['Float'];
};

export type CreateFeedbackInput = {
  /** The text of actual feedback */
  feedback: Scalars['String'];
  /** The id of video that this feedback is created for */
  packageId: Scalars['Int'];
};

/** Union result for VideoFeedback,GenericError  */
export type CreateFeedbackResult = GenericError | VideoFeedback;

export type CreateOrganizationInput = {
  facebook?: InputMaybe<Scalars['String']>;
  instagram?: InputMaybe<Scalars['String']>;
  organizationLegalName: Scalars['String'];
  organizationName: Scalars['String'];
  organizationPhone: Scalars['String'];
  organizationUrl?: InputMaybe<Scalars['String']>;
  salesforceAccountId?: InputMaybe<Scalars['String']>;
  subscriptionType: SubscriptionType;
  twitter?: InputMaybe<Scalars['String']>;
  twoFactorAuthEnabled?: InputMaybe<Scalars['Boolean']>;
  vimeo?: InputMaybe<Scalars['String']>;
  youtube?: InputMaybe<Scalars['String']>;
};

/** Union result for Organization,GenericError  */
export type CreateOrganizationResult = GenericError | Organization;

/** Union result for PresignedRequest,GenericError,ValidationError  */
export type CreatePresignedRequestForVideoAssetUploadResult = GenericError | PresignedRequest | ValidationError;

/** Union result for Video,ValidationError  */
export type CreateVideoResult = ValidationError | Video;

/** Currently signed in admin user */
export type CurrentAdminUser = AdminUserBase & Node & {
  __typename?: 'CurrentAdminUser';
  disabled?: Maybe<Scalars['Boolean']>;
  disabledEmailNotifications?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  /** Unique identifier for the resource */
  id: Scalars['Int'];
  isPrimary: Scalars['Boolean'];
  lastName?: Maybe<Scalars['String']>;
  /** Organization to which currently signed in admin user belongs to */
  organization?: Maybe<Organization>;
  organizationId?: Maybe<Scalars['Int']>;
};

/** Union result for CurrentAdminUser,SignUpData,GenericError  */
export type CurrentAdminUserResult = CurrentAdminUser | GenericError | SignUpData;

/** Union result for CurrentAdminUser,Storymaker,Reviewer,SignUpData,GenericError  */
export type CurrentUserResult = CurrentAdminUser | GenericError | Reviewer | SignUpData | Storymaker;

export type DefaultLinkExpirationMap = {
  __typename?: 'DefaultLinkExpirationMap';
  Complete: Scalars['String'];
  Healthcare: Scalars['String'];
  Now: Scalars['String'];
  Other: Scalars['String'];
  Quick: Scalars['String'];
  Storyvine_internal: Scalars['String'];
};

/** Union result for AdminUser,GenericError  */
export type DeleteAdminUserResult = AdminUser | GenericError;

/** Union result for Storymaker,GenericError  */
export type DeleteStorymakerResult = GenericError | Storymaker;

export type DeletedUser = {
  __typename?: 'DeletedUser';
  user: AppUser;
};

/** Union result for AdminUser,GenericError  */
export type DisableAdminUserResult = AdminUser | GenericError;

/** Union result for Storymaker,Reviewer,GenericError  */
export type DisableAppUserResult = GenericError | Reviewer | Storymaker;

/** Union result for Storymaker,GenericError  */
export type DisableStorymakerResult = GenericError | Storymaker;

export type EditStorymakerInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

/** Union result for GenericError,Storymaker  */
export type EditStorymakerResult = GenericError | Storymaker;

export type EditTemplateInput = {
  description?: InputMaybe<Scalars['String']>;
  isReviewRequired?: InputMaybe<Scalars['Boolean']>;
  isStorymakerAddedEmailsEnabled?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  previewPackageId?: InputMaybe<Scalars['Int']>;
  shouldSaveToCameraRoll?: InputMaybe<Scalars['Boolean']>;
};

/** Union result for Template,GenericError  */
export type EditTemplateResult = GenericError | Template;

/** Union result for AdminUser,GenericError  */
export type EnableAdminUserResult = AdminUser | GenericError;

/** Union result for Storymaker,Reviewer,GenericError  */
export type EnableAppUserResult = GenericError | Reviewer | Storymaker;

/** Union result for Storymaker,GenericError  */
export type EnableStorymakerResult = GenericError | Storymaker;

/** End card of the brand. It is served with organization id as id. */
export type EndCard = Node & {
  __typename?: 'EndCard';
  businessEmail?: Maybe<Scalars['String']>;
  businessPhone?: Maybe<Scalars['String']>;
  callToAction?: Maybe<Scalars['String']>;
  /** Unique identifier for the resource */
  id: Scalars['Int'];
  type?: Maybe<EndCardType>;
  value?: Maybe<Scalars['String']>;
};

/** Type of EndCard */
export enum EndCardType {
  HashTag = 'hashTag',
  Url = 'url'
}

export type ExportVideosCsvFilterInput = {
  filmingGroupId?: InputMaybe<Array<Scalars['Int']>>;
  organizationId?: InputMaybe<Array<Scalars['Int']>>;
  search?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Array<Scalars['String']>>;
  templateId?: InputMaybe<Array<Scalars['Int']>>;
  videoIds?: InputMaybe<Array<Scalars['Int']>>;
};

export type FeatureFlag = {
  __typename?: 'FeatureFlag';
  flag: Scalars['String'];
  id: Scalars['ID'];
  type: FeatureFlagType;
};

export type FeatureFlagGroup = {
  __typename?: 'FeatureFlagGroup';
  flags: Array<Scalars['String']>;
  group: Scalars['ID'];
};

/** Feature flag types */
export enum FeatureFlagType {
  Global = 'GLOBAL',
  User = 'USER'
}

export type FileExport = {
  __typename?: 'FileExport';
  createdAt: Scalars['DateTime'];
  exportStatus: FileExportStatus;
  id: Scalars['ID'];
  processedKeys: Scalars['Int'];
  progress: Scalars['Float'];
  totalKeys: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

export enum FileExportStatus {
  BatchDone = 'BATCH_DONE',
  Done = 'DONE',
  Processing = 'PROCESSING',
  Queued = 'QUEUED',
  Started = 'STARTED',
  WaitingToProcess = 'WAITING_TO_PROCESS'
}

/** This entitiy represents a Filming Group */
export type FilmingGroup = Node & {
  __typename?: 'FilmingGroup';
  activeSignUpFormUrl?: Maybe<Scalars['String']>;
  emailTriggerSetting?: Maybe<FilmingGroupEmailTriggerSetting>;
  facebookDescription?: Maybe<Scalars['String']>;
  facebookTitle?: Maybe<Scalars['String']>;
  /** Unique identifier for the resource */
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  organization?: Maybe<Organization>;
  organizationId?: Maybe<Scalars['Int']>;
  shareableDownload?: Maybe<Scalars['Boolean']>;
  shareableFacebook?: Maybe<Scalars['Boolean']>;
  shareableTwitter?: Maybe<Scalars['Boolean']>;
  /** Number of storymakers assigned to this filming group */
  storymakersCount: Scalars['Int'];
  templates: Array<Template>;
  twitterDescription?: Maybe<Scalars['String']>;
  /** Number of storymakers assigned to this filming group */
  videosCount: Scalars['Int'];
};

/** Privacy setting for notifying storymakers in the filming group after video gets rendered */
export enum FilmingGroupEmailTriggerSetting {
  NoNotifications = 'no_notifications',
  NotificationsAfterApproval = 'notifications_after_approval',
  NotificationsUponRender = 'notifications_upon_render'
}

export type FilmingGroupFilterInput = {
  filmingGroupId?: InputMaybe<Array<Scalars['Int']>>;
  organizationId?: InputMaybe<Array<Scalars['Int']>>;
};

export type FilmingGroupNotifiactionSettingsInput = {
  /** Defines when or whether the email notification containing video should be sent to storymaker */
  emailTriggerSetting?: InputMaybe<FilmingGroupEmailTriggerSetting>;
  /** Description appended to facebook post */
  facebookDescription?: InputMaybe<Scalars['String']>;
  /** Title appended to facebook post */
  facebookTitle?: InputMaybe<Scalars['String']>;
  /** Defines whether a download button should be available inside email notification */
  shareableDownload?: InputMaybe<Scalars['Boolean']>;
  /** Defines whether email notification should contain share to facebook button */
  shareableFacebook?: InputMaybe<Scalars['Boolean']>;
  /** Defines whether email notification should contain share to twitter button */
  shareableTwitter?: InputMaybe<Scalars['Boolean']>;
  /** Tweet message to which the video should be appended */
  twitterDescription?: InputMaybe<Scalars['String']>;
};

/** Union result for FilmingGroup,GenericError  */
export type FilmingGroupResult = FilmingGroup | GenericError;

export type FinishSignUpInput = {
  facebook?: InputMaybe<Scalars['String']>;
  instagram?: InputMaybe<Scalars['String']>;
  organizationLegalName: Scalars['String'];
  organizationName: Scalars['String'];
  organizationPhone: Scalars['String'];
  organizationUrl?: InputMaybe<Scalars['String']>;
  twitter?: InputMaybe<Scalars['String']>;
  twoFactorAuthEnabled?: InputMaybe<Scalars['Boolean']>;
  vimeo?: InputMaybe<Scalars['String']>;
  youtube?: InputMaybe<Scalars['String']>;
};

/** Union result for SignUpData,RefetchTokenError,GenericError  */
export type FinishSignUpResult = GenericError | RefetchTokenError | SignUpData;

/** Union result for Transcription,GenericError  */
export type GenerateVideoTranscriptionResult = GenericError | Transcription;

export type GenericError = BaseError & {
  __typename?: 'GenericError';
  code: Scalars['Int'];
  message: Scalars['String'];
};

/** Union result for GenericError,AdminUser  */
export type GetUserFromInvitationTokenResult = AdminUser | GenericError;

export type HumanizedEmailTriggerSetting = {
  __typename?: 'HumanizedEmailTriggerSetting';
  no_notifications: Scalars['String'];
  notifications_after_approval: Scalars['String'];
  notifications_upon_render: Scalars['String'];
};

export type HumanizedVideoStatuses = {
  __typename?: 'HumanizedVideoStatuses';
  approved: Scalars['String'];
  created: Scalars['String'];
  do_not_use: Scalars['String'];
  failed: Scalars['String'];
  not_approved: Scalars['String'];
  preprocessing: Scalars['String'];
  rendered: Scalars['String'];
  rendering: Scalars['String'];
  review_requested: Scalars['String'];
};

export type HumanizedVimeoEmbedArgs = {
  __typename?: 'HumanizedVimeoEmbedArgs';
  private: Scalars['String'];
  public: Scalars['String'];
  whitelist: Scalars['String'];
};

export type HumanizedVimeoUploadArgs = {
  __typename?: 'HumanizedVimeoUploadArgs';
  embed: HumanizedVimeoEmbedArgs;
  view: HumanizedVimeoViewArgs;
};

export type HumanizedVimeoViewArgs = {
  __typename?: 'HumanizedVimeoViewArgs';
  anybody: Scalars['String'];
  disable: Scalars['String'];
  nobody: Scalars['String'];
  password: Scalars['String'];
  unlisted: Scalars['String'];
};

/** Storyvine NOW image's asset */
export type ImageAsset = NodeWithStringId & {
  __typename?: 'ImageAsset';
  captureStep?: Maybe<CaptureStep>;
  /** Unique identifier for the resource */
  id: Scalars['String'];
  isOriginal?: Maybe<Scalars['Boolean']>;
  /** @deprecated Use captureStep field instead */
  key?: Maybe<Scalars['String']>;
  /** @deprecated Use captureStep field instead */
  label?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

/** Integration Source types. */
export enum IntegrationSourceTypes {
  Realnex = 'realnex'
}

/** Union result for GenericError,AdminUser  */
export type InviteAdminUserResult = AdminUser | GenericError;

export type InviteStorymakerAndReviewersInput = {
  customProperties: Scalars['JSON'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  suspended?: InputMaybe<Scalars['Boolean']>;
};

export type InviteStorymakerInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

/** Union result for Storymaker,GenericError  */
export type InviteStorymakerResult = GenericError | Storymaker;

/** Union result for Storymaker,Reviewer,APIError,GenericError  */
export type InviteStorymakersWithReviewersResult = ApiError | GenericError | Reviewer | Storymaker;

export type LegalLinksUpdateInput = {
  privacyPolicy?: InputMaybe<Scalars['String']>;
  termsOfService?: InputMaybe<Scalars['String']>;
};

export type LoginAction = {
  __typename?: 'LoginAction';
  action: LoginActionEnum;
};

export enum LoginActionEnum {
  Anonymous = 'ANONYMOUS',
  Password = 'PASSWORD',
  PasswordUpgradeSecure = 'PASSWORD_UPGRADE_SECURE',
  Sso = 'SSO'
}

export type LoginToken = {
  __typename?: 'LoginToken';
  token: Scalars['String'];
};

/** Union result for VideoFeedback,GenericError  */
export type MarkFeedbacksAsViewedResult = GenericError | VideoFeedback;

/** Union result for GenericError,Organization  */
export type MigrateOrganizationResult = GenericError | Organization;

export type Mutation = {
  __typename?: 'Mutation';
  acceptInvitation: AcceptInvitationResponse;
  /**
   * Adds to the firebaseUid to the DB
   * @deprecated Use afterUserLoginAction instead
   */
  addFirebaseUidToUser?: Maybe<AddFirebaseUidToUserResult>;
  /**
   * Adds to the firebaseUid to the DB for mobile. Workaround because bug of the bug in the react-native-firebase https://github.com/invertase/react-native-firebase/issues/4731
   * @deprecated Use userLoginTokenAction instead
   */
  addFirebaseUidToUserMobile?: Maybe<AddFirebaseUidToUserMobileResult>;
  /** **Step 3** of Sign Up flow, includes storing basic information about the manager */
  addManagerInfo: AddManagerInfoResult;
  afterLoginUserAction: AfterLoginUserActionResult;
  /** Before submitting **Step 2** of Sign Up flow this mutation can be used for coupon code verification */
  applyCouponCode: ApplyCouponCodeResult;
  assignTemplatesToFilmingGroup: AssignTemplatesToFilmingGroupResult;
  /** Generates URL for user to add vimeo credentials */
  buildVimeoAuthorizationEndpoint?: Maybe<Scalars['String']>;
  /**
   *
   *   Cancels admin user's invitation.
   *   - If the cancellation is successful, returns null.
   *
   */
  cancelAdminUserInvitation?: Maybe<CancelAdminUserInvitationResult>;
  changePassword?: Maybe<ValidationError>;
  completeResetPassword?: Maybe<CompleteResetPasswordResult>;
  createFeedback: CreateFeedbackResult;
  createFilmingGroup?: Maybe<FilmingGroup>;
  createOrganization: CreateOrganizationResult;
  createPresignedRequestForVideoAssetUpload: CreatePresignedRequestForVideoAssetUploadResult;
  createVideo: CreateVideoResult;
  /**
   * Deletes user from admin_users table, and from firebase (if record exists).
   *     - Returns deleted AdminUser object if successful or GenericError if not.
   */
  deleteAdminUser?: Maybe<DeleteAdminUserResult>;
  deleteStorymaker: DeleteStorymakerResult;
  deleteVideos?: Maybe<GenericError>;
  /** Disables AdminUser's email notifications */
  disableAdminUserNotifications: DisableAdminUserResult;
  disableAppUser?: Maybe<DisableAppUserResult>;
  /** @deprecated Use disableAppUser instead */
  disableStorymaker?: Maybe<DisableStorymakerResult>;
  editStorymaker?: Maybe<EditStorymakerResult>;
  editTemplate: EditTemplateResult;
  /** Enables AdminUser's email notifications */
  enableAdminUserNotifications: EnableAdminUserResult;
  enableAppUser: EnableAppUserResult;
  /** @deprecated Use enableAppUser instead */
  enableStorymaker: EnableStorymakerResult;
  exportPackagesAndAssets: ExportPackagesAndAssetsResult;
  /**
   * Sends video assets to currently signed in Moderator's email address.
   *     - If videoIds is null, it sends assets of all the videos for the organization
   *     - Else, it sends selected videos' assets
   *
   */
  exportVideoAssets?: Maybe<GenericError>;
  exportVideosCsv?: Maybe<GenericError>;
  /** **Step 4** of Sign Up flow, launches a duplicate organization job, user should then wait for the organization to be setup */
  finishSignUp: FinishSignUpResult;
  /** Sets showOnboardingTutorial firebase custom claim to null */
  finishTutorial: Scalars['Boolean'];
  generateTranscript: GenerateVideoTranscriptionResult;
  /** Sets hideMobileOnboarding firebase custom claim to true */
  hideMobileOnboarding: Scalars['Boolean'];
  inviteAccountAdminUser: InviteAccountAdminUserResult;
  inviteAdminUser?: Maybe<InviteAdminUserResult>;
  inviteStorymakers?: Maybe<Array<InviteStorymakerResult>>;
  inviteStorymakersWithReviewers?: Maybe<Array<InviteStorymakersWithReviewersResult>>;
  loginUpgrade?: Maybe<GenericError>;
  markFeedbacksAsViewed: MarkFeedbacksAsViewedResult;
  migrateOrganization: MigrateOrganizationResult;
  pushVideoToYoutube?: Maybe<GenericError>;
  rebuildVideo: RebuildVideoResult;
  removeVimeoCredentials: Organization;
  removeYoutubeCredentials: Organization;
  requestAccountDelete?: Maybe<GenericError>;
  /** Sends an OTP to the user's email */
  requestOTP: RequestOtpResult;
  resendAdminUserInvitation?: Maybe<ResendAdminUserInvitationResult>;
  resendInviteStorymakerEmail?: Maybe<GenericError>;
  resendVideoReadyEmail?: Maybe<GenericError>;
  /** Sets showOnboardingTutorial firebase custom claim to true */
  restartTutorial: Scalars['Boolean'];
  restoreOriginalAsset: RestoreOriginalAssetResult;
  saveVimeoCredentials: SaveVimeoCredentialsResult;
  saveYoutubeCredentials: SaveYoutubeCredentialsResult;
  /**
   *
   *     Sends reset password email if registered in the system.
   *     Doesn't notify about existence of an email within the system for security reasons.
   */
  sendResetPasswordEmail?: Maybe<Scalars['Void']>;
  /** Sets a manager role to primary (Managers-only) */
  setPrimaryManager: Array<AdminUser>;
  shareToVimeo?: Maybe<GenericError>;
  shareVideo: ShareVideoResult;
  /** **Step 1** of Sign up flow, collecting a basic information about user as well as creating entity for later use during authentication. */
  signUpManager?: Maybe<SignUpManagerResult>;
  /** **Step 2** of Sign Up flow, includes subscription purchase, user must be authenticated at this point */
  storeSubscription?: Maybe<StoreSubscriptionResult>;
  /** Toggles an adminUsers disabled status */
  toggleAdminUserDisabledState: ToggleAdminUserDisabledResult;
  /** Toggle free filming groups for the current logged in user */
  toggleFreeVideoFilmingGroup: ToggleFreeVideoTemplateResult;
  unassignTemplatesFromFilmingGroup: UnassignTemplatesFromFilmingGroupResult;
  updateAdditionalEmailRecipients?: Maybe<UpdateAdditionalEmailRecipientsResult>;
  /** Updates secondary administrators' information. Needs primary administrator role. */
  updateAdminUser?: Maybe<UpdateAdminUserResult>;
  updateBrandColors?: Maybe<UpdateBrandColorsResult>;
  updateCaptureStepsList: UpdateCaptureStepsListResult;
  updateEndCardInfo: EndCard;
  updateFilmingGroup?: Maybe<FilmingGroup>;
  updateLegalLinks: Organization;
  /**
   * This mutation is being used for 2 purposes,
   *     1) Update currently logged in admin user's information.
   *     2) Complete accepting invite process of admin users.
   *
   */
  updateMe: UpdateMeResult;
  updateOrganization: Organization;
  updateStorymakers: Array<UpdateStorymakerResult>;
  updateSubscriptionType: UpdateSubscriptionTypeResult;
  updateTextCaptures: Array<UpdateTextCapturesResult>;
  updateTranscript: UpdateVideoTranscriptionResult;
  updateUserCustomPropertiesAndSignupData: UpdateUserCustomPropertiesAndSignupDataResult;
  updateUserSignupProperties: UpdateSignupUserCustomPropertiesResult;
  /**
   * Saves given changed links in given links to assets of the video.
   *   - External: Link is hosted on different source or hosted in our bucket but for other video.
   *   If a link is external, it will be downloaded and saved to our bucket again. Finally, it will be saved to assets of the video.
   */
  updateVideoAssets: Array<UpdateVideoAssetsResult>;
  updateVideoRelease: Organization;
  updateVideoStatus: UpdateVideoStatusResult;
  updateVideoTextAssets: UpdateVideoTextAssetsResult;
  /**
   * Uploads given file to the store and returns an access url
   * @deprecated
   *     This mutation should be avoided due to 30secs request timeout limitation on Heroku infrastructure.
   *     Use combination of createPresignedRequestForVideoAssetUpload & uploadVideoAssetSuccess
   *
   */
  uploadAsset: UploadAssetResult;
  uploadBrandLogos: BrandAsset;
  /** Adds new or update old story makers (reviewers) to the database and create firebase record if one is not existing. */
  uploadStoryMakers?: Maybe<UploadStoryMakersResult>;
  uploadVideoAssetSuccess?: Maybe<UploadVideoAssetSuccessResult>;
  upsertUserCustomProperties: UpsertUserCustomPropertiesResult;
  userDelete: UserDeleteResult;
  /** Returns an action enum that the frontend should take */
  userLoginAction: UserLoginAction;
  userLoginTokenAction: UserLoginTokenActionResult;
  userUpgradePasswordAndRole?: Maybe<UserUpgradePasswordAndRoleResult>;
  /** Verifies the user's email using an OTP */
  verifyOTP: VerifyOtpResult;
};


export type MutationAcceptInvitationArgs = {
  confirmationToken: Scalars['String'];
  isSSOUser?: Scalars['Boolean'];
  password?: InputMaybe<Scalars['String']>;
};


export type MutationAddFirebaseUidToUserArgs = {
  input: AddFirebaseUidToUserInput;
};


export type MutationAddFirebaseUidToUserMobileArgs = {
  input: AddFirebaseUidToUserMobileInput;
};


export type MutationAddManagerInfoArgs = {
  input: AddManagerInfoInput;
};


export type MutationAfterLoginUserActionArgs = {
  input: AfterUserLoginActionInput;
};


export type MutationApplyCouponCodeArgs = {
  couponCode: Scalars['String'];
  productId: Scalars['String'];
};


export type MutationAssignTemplatesToFilmingGroupArgs = {
  filmingGroupId: Scalars['Int'];
  templateIds: Array<Scalars['Int']>;
};


export type MutationBuildVimeoAuthorizationEndpointArgs = {
  redirectUrl: Scalars['String'];
  state: Scalars['String'];
};


export type MutationCancelAdminUserInvitationArgs = {
  adminUserId: Scalars['Int'];
};


export type MutationChangePasswordArgs = {
  newPassword: Scalars['String'];
  oldPassword: Scalars['String'];
};


export type MutationCompleteResetPasswordArgs = {
  email: Scalars['String'];
  newPassword: Scalars['String'];
  oobCode: Scalars['String'];
};


export type MutationCreateFeedbackArgs = {
  feedback: CreateFeedbackInput;
};


export type MutationCreateFilmingGroupArgs = {
  input: UpsertFilmingGroupInput;
  organizationId: Scalars['Int'];
};


export type MutationCreateOrganizationArgs = {
  input: CreateOrganizationInput;
};


export type MutationCreatePresignedRequestForVideoAssetUploadArgs = {
  assetKey: Scalars['String'];
  fileName: Scalars['String'];
  videoId: Scalars['Int'];
};


export type MutationCreateVideoArgs = {
  filmingGroupId: Scalars['Int'];
  storymakerId: Scalars['Int'];
  templateId: Scalars['Int'];
};


export type MutationDeleteAdminUserArgs = {
  adminUserId: Scalars['Int'];
};


export type MutationDeleteStorymakerArgs = {
  storymakerId: Scalars['Int'];
};


export type MutationDeleteVideosArgs = {
  videoIds: Array<Scalars['Int']>;
};


export type MutationDisableAdminUserNotificationsArgs = {
  managerId: Scalars['Int'];
};


export type MutationDisableAppUserArgs = {
  storymakerId: Scalars['Int'];
};


export type MutationDisableStorymakerArgs = {
  storymakerId: Scalars['Int'];
};


export type MutationEditStorymakerArgs = {
  input: EditStorymakerInput;
  storymakerId: Scalars['Int'];
};


export type MutationEditTemplateArgs = {
  input: EditTemplateInput;
  templateId: Scalars['Int'];
};


export type MutationEnableAdminUserNotificationsArgs = {
  managerId: Scalars['Int'];
};


export type MutationEnableAppUserArgs = {
  storymakerId: Scalars['Int'];
};


export type MutationEnableStorymakerArgs = {
  storymakerId: Scalars['Int'];
};


export type MutationExportPackagesAndAssetsArgs = {
  filter?: InputMaybe<ExportVideosCsvFilterInput>;
};


export type MutationExportVideoAssetsArgs = {
  videoIds?: InputMaybe<Array<Scalars['Int']>>;
};


export type MutationExportVideosCsvArgs = {
  filter?: InputMaybe<ExportVideosCsvFilterInput>;
};


export type MutationFinishSignUpArgs = {
  input: FinishSignUpInput;
};


export type MutationGenerateTranscriptArgs = {
  videoId: Scalars['Int'];
};


export type MutationInviteAccountAdminUserArgs = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  urlPrefix: Scalars['String'];
};


export type MutationInviteAdminUserArgs = {
  email: Scalars['String'];
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['Int']>;
  ssoLogin?: InputMaybe<Scalars['Boolean']>;
  urlPrefix: Scalars['String'];
};


export type MutationInviteStorymakersArgs = {
  filmingGroupIds: Array<Scalars['Int']>;
  organizationId?: InputMaybe<Scalars['Int']>;
  sendNotificationEmail?: InputMaybe<Scalars['Boolean']>;
  storymakers: Array<InviteStorymakerInput>;
};


export type MutationInviteStorymakersWithReviewersArgs = {
  appUsers: Array<InviteStorymakerAndReviewersInput>;
  connectReviewers?: InputMaybe<Scalars['Boolean']>;
  connectionWithReviewerExpressedBy?: InputMaybe<Scalars['String']>;
  employeeIdentifiedBy?: InputMaybe<Scalars['String']>;
  featureFlags?: InputMaybe<Array<ApplicationFeatureFlags>>;
  filmingGroupIds: Array<Scalars['Int']>;
  organizationId?: InputMaybe<Scalars['Int']>;
  sendNotificationEmail?: InputMaybe<Scalars['Boolean']>;
};


export type MutationLoginUpgradeArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationMarkFeedbacksAsViewedArgs = {
  feedbackIds: Array<Scalars['String']>;
  packageId: Scalars['Int'];
};


export type MutationMigrateOrganizationArgs = {
  organizationId: Scalars['Int'];
};


export type MutationPushVideoToYoutubeArgs = {
  channelId: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  privacySetting: YoutubePrivacySetting;
  title: Scalars['String'];
  videoId: Scalars['Int'];
};


export type MutationRebuildVideoArgs = {
  videoId: Scalars['Int'];
};


export type MutationRemoveVimeoCredentialsArgs = {
  organizationId?: InputMaybe<Scalars['Int']>;
};


export type MutationRequestOtpArgs = {
  email: Scalars['String'];
};


export type MutationResendAdminUserInvitationArgs = {
  adminUserId: Scalars['Int'];
  urlPrefix: Scalars['String'];
};


export type MutationResendInviteStorymakerEmailArgs = {
  storymakerIds: Array<Scalars['Int']>;
};


export type MutationResendVideoReadyEmailArgs = {
  recipients: ResendVideoReadyEmailRecipientType;
  videoId: Scalars['Int'];
};


export type MutationRestoreOriginalAssetArgs = {
  assetKey: Scalars['String'];
  videoId: Scalars['Int'];
};


export type MutationSaveVimeoCredentialsArgs = {
  code: Scalars['String'];
  organizationId?: InputMaybe<Scalars['Int']>;
  redirectUrl: Scalars['String'];
};


export type MutationSaveYoutubeCredentialsArgs = {
  authCode: Scalars['String'];
};


export type MutationSendResetPasswordEmailArgs = {
  email: Scalars['String'];
};


export type MutationSetPrimaryManagerArgs = {
  managerId: Scalars['Int'];
};


export type MutationShareToVimeoArgs = {
  input: ShareToVimeoInput;
};


export type MutationShareVideoArgs = {
  videoShare: ShareVideoInput;
};


export type MutationSignUpManagerArgs = {
  input: SignUpManagerInput;
  integrationSource?: InputMaybe<IntegrationSourceTypes>;
  selectedProduct: Scalars['String'];
};


export type MutationStoreSubscriptionArgs = {
  input: StoreSubscriptionInput;
  selectedProduct: Scalars['String'];
};


export type MutationToggleAdminUserDisabledStateArgs = {
  managerId: Scalars['Int'];
};


export type MutationUnassignTemplatesFromFilmingGroupArgs = {
  filmingGroupId: Scalars['Int'];
  templateIds: Array<Scalars['Int']>;
};


export type MutationUpdateAdditionalEmailRecipientsArgs = {
  additionalEmailRecipients: Array<Scalars['String']>;
};


export type MutationUpdateAdminUserArgs = {
  adminUserId: Scalars['Int'];
  input: UpdateAdminUserInput;
};


export type MutationUpdateBrandColorsArgs = {
  darkColor: Scalars['String'];
  greyColor: Scalars['String'];
  lightColor: Scalars['String'];
};


export type MutationUpdateCaptureStepsListArgs = {
  input: CaptureStepsListInput;
};


export type MutationUpdateEndCardInfoArgs = {
  businessEmail?: InputMaybe<Scalars['String']>;
  businessPhone?: InputMaybe<Scalars['String']>;
  callToAction: Scalars['String'];
  type: EndCardType;
  value: Scalars['String'];
};


export type MutationUpdateFilmingGroupArgs = {
  filmingGroupId: Scalars['Int'];
  input: UpsertFilmingGroupInput;
};


export type MutationUpdateLegalLinksArgs = {
  input: LegalLinksUpdateInput;
  organizationId?: InputMaybe<Scalars['Int']>;
};


export type MutationUpdateMeArgs = {
  input: UpdateAdminUserInput;
};


export type MutationUpdateOrganizationArgs = {
  input: OrganizationUpdateInput;
  organizationId?: InputMaybe<Scalars['Int']>;
};


export type MutationUpdateStorymakersArgs = {
  filmingGroupIds: Array<Scalars['Int']>;
  organizationId?: InputMaybe<Scalars['Int']>;
  storymakerIds: Array<Scalars['Int']>;
};


export type MutationUpdateSubscriptionTypeArgs = {
  organizationId: Scalars['Int'];
  subscriptionType: Scalars['String'];
  videoLinkExpiration?: InputMaybe<VideoLinkExpirationInput>;
};


export type MutationUpdateTextCapturesArgs = {
  inputs: Array<TextCaptureInput>;
};


export type MutationUpdateTranscriptArgs = {
  data: Array<TranscriptionPartInput>;
  videoId: Scalars['Int'];
};


export type MutationUpdateUserCustomPropertiesAndSignupDataArgs = {
  appUserId: Scalars['Int'];
  properties?: InputMaybe<Scalars['JSON']>;
  signupData?: InputMaybe<Scalars['JSON']>;
};


export type MutationUpdateUserSignupPropertiesArgs = {
  appUserId: Scalars['Int'];
  signupData: Scalars['JSON'];
};


export type MutationUpdateVideoAssetsArgs = {
  links: Array<AssetLink>;
  videoId: Scalars['Int'];
};


export type MutationUpdateVideoReleaseArgs = {
  input: VideoReleaseUpdateInput;
  organizationId?: InputMaybe<Scalars['Int']>;
};


export type MutationUpdateVideoStatusArgs = {
  status: Scalars['String'];
  videoId: Scalars['Int'];
};


export type MutationUpdateVideoTextAssetsArgs = {
  data: Array<UpdateVideoTextAssetsInput>;
  videoId: Scalars['Int'];
};


export type MutationUploadAssetArgs = {
  assetKey: Scalars['String'];
  file: Scalars['Upload'];
  videoId: Scalars['Int'];
};


export type MutationUploadBrandLogosArgs = {
  colorLogo?: InputMaybe<Scalars['Upload']>;
  whiteLogo?: InputMaybe<Scalars['Upload']>;
};


export type MutationUploadStoryMakersArgs = {
  storyMakers: Array<StoryMakerNewInput>;
};


export type MutationUploadVideoAssetSuccessArgs = {
  assetKey: Scalars['String'];
  assetUrl: Scalars['String'];
  videoId: Scalars['Int'];
};


export type MutationUpsertUserCustomPropertiesArgs = {
  appUserId: Scalars['Int'];
  properties: Scalars['JSON'];
};


export type MutationUserDeleteArgs = {
  userId?: InputMaybe<Scalars['Int']>;
};


export type MutationUserLoginActionArgs = {
  input: UserLoginActionInput;
};


export type MutationUserLoginTokenActionArgs = {
  email: Scalars['String'];
  password?: InputMaybe<Scalars['String']>;
  ssoProvider?: InputMaybe<SsoProviders>;
  token?: InputMaybe<Scalars['String']>;
};


export type MutationUserUpgradePasswordAndRoleArgs = {
  newPassword: Scalars['String'];
  oldPassword: Scalars['String'];
  userEmail: Scalars['String'];
};


export type MutationVerifyOtpArgs = {
  code: Scalars['String'];
  email: Scalars['String'];
};

export type Node = {
  /** Unique identifier for the resource */
  id: Scalars['Int'];
};

export type NodeWithStringId = {
  /** Unique identifier for the resource */
  id: Scalars['String'];
};

export type OauthInfo = Node & {
  __typename?: 'OauthInfo';
  /** Unique identifier for the resource */
  id: Scalars['Int'];
  vimeoAccount?: Maybe<VimeoAccount>;
  youtubeChannels?: Maybe<Array<YoutubeChannel>>;
};

/** Storyvine NOW organization */
export type Organization = Node & {
  __typename?: 'Organization';
  additionalEmailRecipients?: Maybe<Array<Scalars['String']>>;
  adminUsers: Array<AdminUser>;
  brandAsset?: Maybe<BrandAsset>;
  /** Unique identifier for the resource */
  id: Scalars['Int'];
  legalName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nowDashboardFlag?: Maybe<Scalars['Boolean']>;
  oauthInfo?: Maybe<OauthInfo>;
  phoneNumber?: Maybe<Scalars['String']>;
  privacyPolicy?: Maybe<Scalars['String']>;
  shareOptions?: Maybe<Array<ShareOption>>;
  socialHandles?: Maybe<SocialHandles>;
  storymakerCount?: Maybe<Scalars['Int']>;
  subscription?: Maybe<Subscription>;
  /**
   *
   *         Enumeration type field describing which type of product is organization subscribed to.
   *         To display this value replace all `_` (underscore) characters with ` ` (space) character
   *
   */
  subscriptionType?: Maybe<SubscriptionType>;
  termsOfService?: Maybe<Scalars['String']>;
  twoFactorAuthEnabled?: Maybe<Scalars['Boolean']>;
  videoCount?: Maybe<Scalars['Int']>;
  videoLinkExpiration?: Maybe<Scalars['JSON']>;
  videoReleasePromptBegin?: Maybe<Scalars['String']>;
  videoReleasePromptEnd?: Maybe<Scalars['String']>;
};

export type OrganizationFilterInput = {
  organizationId?: InputMaybe<Array<Scalars['Int']>>;
  /** Filter organizations based on whether they were transitioned to CMS 2.0 already */
  upgraded?: InputMaybe<Scalars['Boolean']>;
};

export type OrganizationUpdateInput = {
  facebook?: InputMaybe<Scalars['String']>;
  instagram?: InputMaybe<Scalars['String']>;
  organizationLegalName: Scalars['String'];
  organizationName: Scalars['String'];
  organizationPhone: Scalars['String'];
  twitter?: InputMaybe<Scalars['String']>;
  twoFactorAuthEnabled?: InputMaybe<Scalars['Boolean']>;
  vimeo?: InputMaybe<Scalars['String']>;
  youtube?: InputMaybe<Scalars['String']>;
};

/** This error indicates that user may be using outdated data to make a request and user may want to refetch given entity kinds. */
export type OutdatedDataError = BaseError & {
  __typename?: 'OutdatedDataError';
  code: Scalars['Int'];
  entities: Array<Scalars['String']>;
  message: Scalars['String'];
};

export type PaginatedAdminUser = {
  __typename?: 'PaginatedAdminUser';
  data: Array<AdminUser>;
  pagination: PaginationResult;
};

export type PaginatedAppUser = {
  __typename?: 'PaginatedAppUser';
  data: Array<AppUser>;
  pagination: PaginationResult;
};

export type PaginatedOrganization = {
  __typename?: 'PaginatedOrganization';
  data: Array<Organization>;
  pagination: PaginationResult;
};

export type PaginatedStorymaker = {
  __typename?: 'PaginatedStorymaker';
  data: Array<Storymaker>;
  pagination: PaginationResult;
};

export type PaginatedTemplate = {
  __typename?: 'PaginatedTemplate';
  data: Array<Template>;
  pagination: PaginationResult;
};

export type PaginatedVideo = {
  __typename?: 'PaginatedVideo';
  data: Array<Video>;
  pagination: PaginationResult;
};

export type PaginationInput = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type PaginationResult = {
  __typename?: 'PaginationResult';
  currentPage: Scalars['Int'];
  from: Scalars['Int'];
  maxPage: Scalars['Int'];
  perPage: Scalars['Int'];
  to: Scalars['Int'];
  total: Scalars['Int'];
};

/** Active subscription plan. */
export type Plan = {
  __typename?: 'Plan';
  /** Price amount of the plan in dollars. */
  amount: Scalars['Int'];
  /** Price amount of the plan in cents. */
  amountInCents: Scalars['Int'];
  /** Currency of the active plan. */
  currency: Scalars['String'];
  /** Unique identifier for the object. */
  id: Scalars['String'];
  interval: StripeIntervalType;
};

/**
 *
 *     Should be sent as HTTP POST request to `url` with `fields` parsed into FormData (https://developer.mozilla.org/en-US/docs/Web/API/FormData)
 *
 */
export type PresignedRequest = {
  __typename?: 'PresignedRequest';
  /** stringified JSON of presigned fields map */
  fields: Scalars['String'];
  url: Scalars['String'];
};

/** Filming Kit pricing offered by Storyvine NOW */
export type Price = {
  __typename?: 'Price';
  /** The amount to be charged. */
  amount?: Maybe<Scalars['Float']>;
  /** Three-letter [ISO currency code](https://www.iso.org/iso-4217-currency-codes.html), in lowercase. Must be a [supported currency](https://stripe.com/docs/currencies). */
  currency: Scalars['String'];
  id: Scalars['String'];
  /** The frequency at which a subscription is billed. One of `day`, `week`, `month` or `year`. */
  interval?: Maybe<StripeIntervalType>;
};

/** Filming Kit offered by Storyvine NOW */
export type Product = {
  __typename?: 'Product';
  /** Whether the product is currently available for purchase. */
  active: Scalars['Boolean'];
  /** Checklist of what's included in the Kit */
  checklist: Array<Scalars['String']>;
  /** Time at which the object was created. Measured in seconds since the Unix epoch. */
  created: Scalars['Int'];
  /** Standardized API datetime format for when the object was created. */
  createdAt: Scalars['DateTime'];
  /** The optional product's description, meant to be displayable to the customer. Use this field to optionally store a long form explanation of the product being sold for your own rendering purposes. */
  description?: Maybe<Scalars['String']>;
  /** Unique identifier for the object. */
  id: Scalars['String'];
  /** A list of up to 8 URLs of images for this product, meant to be displayable to the customer. */
  images: Array<Scalars['String']>;
  /** Has the value `true` if the object exists in live mode or the value `false` if the object exists in test mode. */
  livemode: Scalars['Boolean'];
  /** Stringified JSON of accessible metadata included in the product */
  metadata: Scalars['String'];
  /** The product's name, meant to be displayable to the customer. Whenever this product is sold via a subscription, name will show up on associated invoice line item descriptions. */
  name: Scalars['String'];
  prices: Array<Price>;
  /**
   *
   *           Amount of days for trial period until customer is charged.
   *           If null, customer is charged immediately after purchase
   *
   */
  trialDays?: Maybe<Scalars['Int']>;
  /** Time at which the object was last updated. Measured in seconds since the Unix epoch. */
  updated: Scalars['Int'];
  /** Standardized API datetime format for when the object was last updated. */
  updatedAt: Scalars['DateTime'];
};

export type ProductFilterInput = {
  productId?: InputMaybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  /** Admin users existing within the storyvine ecosystem or just your organization */
  adminUsers: PaginatedAdminUser;
  appUsers: PaginatedAppUser;
  applicationConfig: Config;
  /**
   * Returns either:
   *   - currently signed in user
   *   - data of a signup progress for current admin user's session
   *   - errors (401, 404)
   */
  currentUser?: Maybe<CurrentUserResult>;
  featureFlagGroups: Array<FeatureFlagGroup>;
  filmingGroup: FilmingGroupResult;
  filmingGroups: Array<FilmingGroup>;
  getUserFromInvitationToken: GetUserFromInvitationTokenResult;
  globalFeatureFlags: Array<FeatureFlag>;
  /**
   * Returns either:
   *   - currently signed in admin user's data
   *   - data of a sign up progress for current admin user's session
   *   - errors (401, 404)
   * @deprecated use "currentUser" query instead
   */
  me?: Maybe<CurrentAdminUserResult>;
  organizations: PaginatedOrganization;
  products?: Maybe<Array<Product>>;
  /** Gets idToken which can be used as bearer token. This service is only for local dev purposes */
  serviceGetToken: ServiceToken;
  /** @deprecated Use appUsers instead */
  storymakers: PaginatedStorymaker;
  /** Generates short living stripe customer portal redirect url from where users can manage their subscriptions */
  stripeCustomerPortalUrl?: Maybe<Scalars['String']>;
  templates: PaginatedTemplate;
  trainingSections: Array<TrainingSection>;
  trainings: Array<TrainingSection>;
  /**
   * Returns an action enum that the frontend should take
   * @deprecated Use userLoginAction MUTATION instead
   */
  userLoginAction: UserLoginActionResult;
  /**
   * Validates email, if the user is allowed to use SSO or have to use email and password to log in the app
   * @deprecated Use LoginAction instead
   */
  userPreLoginValidate?: Maybe<UserPreLoginValidateResult>;
  videoHistoryPreviewByStatus: VideoHistoryPreviewsByStatus;
  videos: PaginatedVideo;
  youtubeChannels?: Maybe<Array<YoutubeChannel>>;
};


export type QueryAdminUsersArgs = {
  filter?: InputMaybe<AdminUserFilterInput>;
  pagination?: InputMaybe<PaginationInput>;
  search?: InputMaybe<Scalars['String']>;
};


export type QueryAppUsersArgs = {
  filter?: InputMaybe<AppUserFilterInput>;
  pagination?: InputMaybe<PaginationInput>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<SortInput>;
};


export type QueryFilmingGroupArgs = {
  filmingGroupId: Scalars['String'];
};


export type QueryFilmingGroupsArgs = {
  filter?: InputMaybe<FilmingGroupFilterInput>;
};


export type QueryGetUserFromInvitationTokenArgs = {
  confirmationToken: Scalars['String'];
};


export type QueryOrganizationsArgs = {
  filter?: InputMaybe<OrganizationFilterInput>;
  pagination?: InputMaybe<PaginationInput>;
  search?: InputMaybe<Scalars['String']>;
};


export type QueryProductsArgs = {
  filter?: InputMaybe<ProductFilterInput>;
};


export type QueryServiceGetTokenArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type QueryStorymakersArgs = {
  filter?: InputMaybe<StorymakerFilterInput>;
  pagination?: InputMaybe<PaginationInput>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<SortInput>;
};


export type QueryStripeCustomerPortalUrlArgs = {
  returnUrl: Scalars['String'];
};


export type QueryTemplatesArgs = {
  filter?: InputMaybe<TemplateFilterInput>;
  pagination?: InputMaybe<PaginationInput>;
  search?: InputMaybe<Scalars['String']>;
};


export type QueryTrainingsArgs = {
  sectionId: Scalars['String'];
};


export type QueryUserLoginActionArgs = {
  input: UserLoginActionInput;
};


export type QueryUserPreLoginValidateArgs = {
  input: UserPreLoginValidateInput;
};


export type QueryVideoHistoryPreviewByStatusArgs = {
  previews?: InputMaybe<Scalars['Int']>;
};


export type QueryVideosArgs = {
  filter?: InputMaybe<VideoFilterInput>;
  pagination?: InputMaybe<PaginationInput>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<SortInput>;
};

/** Union result for Video,GenericError  */
export type RebuildVideoResult = GenericError | Video;

/** This error indicates that user should fetch a new up to date JWT token from firebase */
export type RefetchTokenError = BaseError & {
  __typename?: 'RefetchTokenError';
  code: Scalars['Int'];
  message: Scalars['String'];
};

/** Union result for OutdatedDataError,GenericError  */
export type ResendAdminUserInvitationResult = GenericError | OutdatedDataError;

/** Possible recipient types of Video Ready Email. */
export enum ResendVideoReadyEmailRecipientType {
  AdminUsers = 'adminUsers',
  All = 'all',
  Storymaker = 'storymaker'
}

/** Union result for VideoAsset,ImageAsset,GenericError,ValidationError  */
export type RestoreOriginalAssetResult = GenericError | ImageAsset | ValidationError | VideoAsset;

export type Reviewer = AppUserInterface & Node & {
  __typename?: 'Reviewer';
  createdAt: Scalars['DateTime'];
  deleted: Scalars['Boolean'];
  displayName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  filmingGroups: Array<FilmingGroup>;
  firstName?: Maybe<Scalars['String']>;
  /** Unique identifier for the resource */
  id: Scalars['Int'];
  isReviewer: Scalars['Boolean'];
  lastName?: Maybe<Scalars['String']>;
  organizationIds: Array<Scalars['Int']>;
  organizations: Array<Organization>;
  storymakers?: Maybe<Array<Storymaker>>;
  suspended?: Maybe<Scalars['Boolean']>;
  userCustomProperties?: Maybe<CustomProperties>;
  userSignupProperties: Scalars['JSON'];
  videoCount: Scalars['Int'];
};

export enum SsoProviders {
  Microsoft = 'MICROSOFT'
}

/** Union result for Organization,GenericError  */
export type SaveVimeoCredentialsResult = GenericError | Organization;

/** Union result for Organization,GenericError  */
export type SaveYoutubeCredentialsResult = GenericError | Organization;

/** Gets idToken which can be used as bearer token. This service is only for local dev purposes */
export type ServiceToken = {
  __typename?: 'ServiceToken';
  token: Scalars['String'];
};

export type ShareOption = {
  __typename?: 'ShareOption';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  opensAfterShare?: Maybe<Scalars['String']>;
  shareType: ShareOptionType;
};

export enum ShareOptionType {
  AssetProxyLink = 'ASSET_PROXY_LINK',
  Email = 'EMAIL',
  Facebook = 'FACEBOOK',
  OsNativeShare = 'OS_NATIVE_SHARE',
  Youtube = 'YOUTUBE'
}

export type ShareToVimeoInput = {
  description?: InputMaybe<Scalars['String']>;
  isVideoDownloadable?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  privacyEmbedSetting?: InputMaybe<VimeoPrivacyEmbedSettings>;
  privacyViewSetting?: InputMaybe<VimeoPrivacyViewSettings>;
  videoId: Scalars['Int'];
};

export type ShareVideoInput = {
  /** The video id that is share */
  packageId: Scalars['Int'];
  /** The share option that was used */
  shareOptionId: Scalars['String'];
};

/** Union result for VideoShareEntry,GenericError  */
export type ShareVideoResult = GenericError | VideoShareEntry;

/** Information stored during still ongoing sign up progress */
export type SignUpData = Node & {
  __typename?: 'SignUpData';
  done?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  /** Unique identifier for the resource */
  id: Scalars['Int'];
  lastName?: Maybe<Scalars['String']>;
  organizationName?: Maybe<Scalars['String']>;
  organizationPhone?: Maybe<Scalars['String']>;
  selectedProduct?: Maybe<Scalars['String']>;
  signUpStep?: Maybe<Scalars['Int']>;
  subscribed?: Maybe<Scalars['Boolean']>;
};

export type SignUpManagerInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  howDidYouHearAboutUs?: InputMaybe<Scalars['String']>;
  lastName: Scalars['String'];
  organizationName: Scalars['String'];
  organizationPhone: Scalars['String'];
  password: Scalars['String'];
};

/** Union result for SignUpData,GenericError,ValidationError  */
export type SignUpManagerResult = GenericError | SignUpData | ValidationError;

export type SocialHandles = {
  __typename?: 'SocialHandles';
  facebook?: Maybe<Scalars['String']>;
  instagram?: Maybe<Scalars['String']>;
  twitter?: Maybe<Scalars['String']>;
  vimeo?: Maybe<Scalars['String']>;
  youtube?: Maybe<Scalars['String']>;
};

/** Input describing in what order and based on which paramter should the results be sorted */
export type SortInput = {
  key?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<SortOrder>;
};

/** Order in which results should be sorted: `asc` | `desc` */
export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc'
}

export type StoreSubscriptionInput = {
  couponCode?: InputMaybe<Scalars['String']>;
  paymentMethodId: Scalars['String'];
  selectedPriceId: Scalars['String'];
};

/** Union result for SignUpData,RefetchTokenError,GenericError,ValidationError  */
export type StoreSubscriptionResult = GenericError | RefetchTokenError | SignUpData | ValidationError;

export type StoryMakerNewInput = {
  email: Scalars['String'];
  firstName?: InputMaybe<Scalars['String']>;
  isReviewer: Scalars['Boolean'];
  lastName?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['Int']>;
};

export type Storymaker = AppUserInterface & Node & {
  __typename?: 'Storymaker';
  createdAt: Scalars['DateTime'];
  deleted: Scalars['Boolean'];
  displayName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  featureFlags?: Maybe<Array<FeatureFlag>>;
  filmingGroups: Array<FilmingGroup>;
  firstName?: Maybe<Scalars['String']>;
  /** Unique identifier for the resource */
  id: Scalars['Int'];
  lastName?: Maybe<Scalars['String']>;
  organizationIds: Array<Scalars['Int']>;
  organizations: Array<Organization>;
  reviewers?: Maybe<Array<Reviewer>>;
  suspended?: Maybe<Scalars['Boolean']>;
  userCustomProperties?: Maybe<CustomProperties>;
  userSignupProperties: Scalars['JSON'];
  videoCount: Scalars['Int'];
};

export type StorymakerFilterInput = {
  filmingGroupId?: InputMaybe<Array<Scalars['Int']>>;
  organizationId?: InputMaybe<Array<Scalars['Int']>>;
  storymakerId?: InputMaybe<Scalars['Int']>;
};

/** Stripe subscription interval types. */
export enum StripeIntervalType {
  Day = 'day',
  Month = 'month',
  Week = 'week',
  Year = 'year'
}

/** Stripe subscription statuses. */
export enum StripeSubscriptionStatus {
  Active = 'active',
  Canceled = 'canceled',
  Incomplete = 'incomplete',
  IncompleteExpired = 'incomplete_expired',
  PastDue = 'past_due',
  Trialing = 'trialing',
  Unpaid = 'unpaid'
}

/** Subscription owned by the organization */
export type Subscription = {
  __typename?: 'Subscription';
  /** The time when the current subscription ends. Measured in seconds since the Unix epoch. */
  current_period_end: Scalars['Int'];
  /** The time when the current subscription ends. */
  nextBillingDate: Scalars['DateTime'];
  /** Active subscription plan. */
  plan: Plan;
  /** The product bought by the organization. */
  product: Product;
  status: StripeSubscriptionStatus;
};

/** Type of the subscription set by Storyvine */
export enum SubscriptionType {
  Complete = 'Complete',
  Healthcare = 'Healthcare',
  Now = 'Now',
  Other = 'Other',
  Quick = 'Quick',
  StoryvineInternal = 'Storyvine_internal'
}

/** Which email trigger setting should new filming groups default to based on subscription type */
export type SubscriptionTypeDefaultEmailTriggerSetting = {
  __typename?: 'SubscriptionTypeDefaultEmailTriggerSetting';
  Complete: Scalars['String'];
  Healthcare: Scalars['String'];
  Now: Scalars['String'];
  Other: Scalars['String'];
  Quick: Scalars['String'];
  Storyvine_internal: Scalars['String'];
};

export type SubscriptionTypeManagerRole = {
  __typename?: 'SubscriptionTypeManagerRole';
  Complete: Scalars['String'];
  Healthcare: Scalars['String'];
  Now: Scalars['String'];
  Other: Scalars['String'];
  Quick: Scalars['String'];
  Storyvine_internal: Scalars['String'];
};

/** Storyvine NOW template */
export type Template = Node & {
  __typename?: 'Template';
  captureStepListId?: Maybe<Scalars['Int']>;
  captureSteps: Array<CaptureStep>;
  captureStepsLists: Array<CaptureStepsList>;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  /** Unique identifier for the resource */
  id: Scalars['Int'];
  isReviewRequired?: Maybe<Scalars['Boolean']>;
  isStorymakerAddedEmailsEnabled?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  organizations?: Maybe<Array<Organization>>;
  originalName?: Maybe<Scalars['String']>;
  previewPackageId?: Maybe<Scalars['Int']>;
  previewVideo?: Maybe<Video>;
  shareOption?: Maybe<Array<ShareOption>>;
  shouldSaveToCameraRoll?: Maybe<Scalars['Boolean']>;
  textCaptures: Array<TextCapture>;
};


/** Storyvine NOW template */
export type TemplateCaptureStepsArgs = {
  captureStepListId?: InputMaybe<Scalars['Int']>;
};


/** Storyvine NOW template */
export type TemplateCaptureStepsListsArgs = {
  captureStepListId?: InputMaybe<Scalars['Int']>;
};

export type TemplateFilterInput = {
  filmingGroupId?: InputMaybe<Array<Scalars['Int']>>;
  templateId?: InputMaybe<Scalars['Int']>;
};

/** Storyvine NOW video's text asset */
export type TextAsset = NodeWithStringId & {
  __typename?: 'TextAsset';
  /** Unique identifier for the resource */
  id: Scalars['String'];
  textCapture?: Maybe<TextCapture>;
  value?: Maybe<Scalars['String']>;
};

/**
 * Storyvine NOW template's text capture:
 *     - entered by storymaker before starting filming capture steps
 *
 */
export type TextCapture = Node & {
  __typename?: 'TextCapture';
  characterLimit?: Maybe<Scalars['Int']>;
  /** Unique identifier for the resource */
  id: Scalars['Int'];
  isEditable?: Maybe<Scalars['Boolean']>;
  key?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  options?: Maybe<Array<Scalars['String']>>;
  position?: Maybe<Scalars['Int']>;
  required?: Maybe<Scalars['Boolean']>;
  screen?: Maybe<Scalars['Int']>;
};

export type TextCaptureInput = {
  id: Scalars['Int'];
  label: Scalars['String'];
  required?: InputMaybe<Scalars['Boolean']>;
};

/** Union result for FilmingGroup,GenericError  */
export type ToggleFreeVideoTemplateResult = FilmingGroup | GenericError;

export type Training = {
  __typename?: 'Training';
  content?: Maybe<Scalars['String']>;
  highlighted?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  title?: Maybe<Scalars['String']>;
};

export type TrainingCategory = {
  __typename?: 'TrainingCategory';
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  trainings: Array<Training>;
};

export type TrainingSection = {
  __typename?: 'TrainingSection';
  categories?: Maybe<Array<TrainingCategory>>;
  id: Scalars['String'];
  sidebarHoverIconUrl?: Maybe<Scalars['String']>;
  sidebarIconUrl?: Maybe<Scalars['String']>;
  sidebarName?: Maybe<Scalars['String']>;
};

export type Transcription = {
  __typename?: 'Transcription';
  id: Scalars['ID'];
  parts: Array<TranscriptionPart>;
  status: TranscriptionStatus;
  subtitlesUrl?: Maybe<Scalars['String']>;
};

export type TranscriptionPart = {
  __typename?: 'TranscriptionPart';
  from: Scalars['Int'];
  id: Scalars['ID'];
  text: Scalars['String'];
  to: Scalars['Int'];
};

export type TranscriptionPartInput = {
  from: Scalars['Int'];
  id: Scalars['ID'];
  text: Scalars['String'];
  to: Scalars['Int'];
};

export enum TranscriptionStatus {
  Cancelling = 'CANCELLING',
  Completed = 'COMPLETED',
  InProgress = 'IN_PROGRESS',
  None = 'NONE',
  NotStarted = 'NOT_STARTED',
  Postponed = 'POSTPONED'
}

/** Union result for FilmingGroup,GenericError  */
export type UnassignTemplatesFromFilmingGroupResult = FilmingGroup | GenericError;

/** Union result for GenericError,Organization  */
export type UpdateAdditionalEmailRecipientsResult = GenericError | Organization;

export type UpdateAdminUserInput = {
  cellPhone?: InputMaybe<Scalars['String']>;
  directPhone?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  title: Scalars['String'];
};

/** Union result for AdminUser,GenericError  */
export type UpdateAdminUserResult = AdminUser | GenericError;

/** Union result for ValidationError,BrandAsset  */
export type UpdateBrandColorsResult = BrandAsset | ValidationError;

/** Union result for CaptureStepsList,GenericError  */
export type UpdateCaptureStepsListResult = CaptureStepsList | GenericError;

/** Union result for AdminUser,GenericError  */
export type UpdateMeResult = AdminUser | GenericError;

/** Union result for Storymaker,GenericError  */
export type UpdateStorymakerResult = GenericError | Storymaker;

/** Union result for GenericError,Organization  */
export type UpdateSubscriptionTypeResult = GenericError | Organization;

/** Union result for TextCapture,GenericError  */
export type UpdateTextCapturesResult = GenericError | TextCapture;

/** Union result for Video,GenericError,APIError  */
export type UpdateVideoAssetsResult = ApiError | GenericError | Video;

/** Union result for Video,GenericError  */
export type UpdateVideoStatusResult = GenericError | Video;

export type UpdateVideoTextAssetsInput = {
  key: Scalars['String'];
  value: Scalars['String'];
};

/** Union result for Video,GenericError  */
export type UpdateVideoTextAssetsResult = GenericError | Video;

/** Union result for Transcription,GenericError  */
export type UpdateVideoTranscriptionResult = GenericError | Transcription;

export type UploadAssetResponse = {
  __typename?: 'UploadAssetResponse';
  url: Scalars['String'];
};

/** Union result for GenericError,UploadAssetResponse,ValidationError  */
export type UploadAssetResult = GenericError | UploadAssetResponse | ValidationError;

export type UploadStoryMakers = {
  __typename?: 'UploadStoryMakers';
  isSuccessful: Scalars['Boolean'];
};

/** Union result for UploadStoryMakers,GenericError  */
export type UploadStoryMakersResult = GenericError | UploadStoryMakers;

/** Union result for GenericError,ValidationError  */
export type UploadVideoAssetSuccessResult = GenericError | ValidationError;

export type UpsertFilmingGroupInput = {
  name: Scalars['String'];
  /** Email notifications settings definition for this filming group */
  notificationSettings: FilmingGroupNotifiactionSettingsInput;
};

export type User = AdminUser | CurrentAdminUser | Reviewer | Storymaker;

/** Union result for DeletedUser,GenericError  */
export type UserDeleteResult = DeletedUser | GenericError;

/** Union result for LoginAction,GenericError  */
export type UserLoginAction = GenericError | LoginAction;

export type UserLoginActionInput = {
  client: ClientTypeEnum;
  email: Scalars['String'];
};

/** Union result for LoginAction,GenericError  */
export type UserLoginActionResult = GenericError | LoginAction;

/** Union result for LoginToken,GenericError  */
export type UserLoginTokenActionResult = GenericError | LoginToken;

export type UserPreLoginValidate = {
  __typename?: 'UserPreLoginValidate';
  id: Scalars['Int'];
};

export type UserPreLoginValidateInput = {
  email: Scalars['String'];
};

/** Union result for UserPreLoginValidate,GenericError  */
export type UserPreLoginValidateResult = GenericError | UserPreLoginValidate;

/** Which email trigger setting should new filming groups default to based on subscription type */
export type UserRole = {
  __typename?: 'UserRole';
  isOldCMSRole: Scalars['Boolean'];
  role: Scalars['String'];
  roleName: Scalars['String'];
};

export type ValidationError = BaseError & {
  __typename?: 'ValidationError';
  code: Scalars['Int'];
  fields: Array<Scalars['String']>;
  message: Scalars['String'];
};

export type VerifyOtp = {
  __typename?: 'VerifyOTP';
  verified: Scalars['Boolean'];
};

export type Video = Node & {
  __typename?: 'Video';
  appUserId?: Maybe<Scalars['Int']>;
  captureStepListId?: Maybe<Scalars['Int']>;
  captureStepsList?: Maybe<CaptureStepsList>;
  createdAt: Scalars['DateTime'];
  feedbacks: Array<VideoFeedback>;
  /** A filming group the video was recorded in */
  filmingGroup?: Maybe<FilmingGroup>;
  hasNewFeedback: Scalars['Boolean'];
  /** Unique identifier for the resource */
  id: Scalars['Int'];
  /** Image assets used in the video */
  imageAssets: Array<ImageAsset>;
  lastSharedAt?: Maybe<Scalars['DateTime']>;
  /** An organization the video was filmed in */
  organization?: Maybe<Organization>;
  organizationId?: Maybe<Scalars['Int']>;
  /** The downloadable preview URL of the video */
  previewURL?: Maybe<Scalars['String']>;
  /** Undownloadable preview URL of the video. If the video is not in the rendered status, it gives the old preview. */
  previewURLInline?: Maybe<Scalars['String']>;
  renderedVideo?: Maybe<Scalars['String']>;
  shareOption: Array<ShareOption>;
  shared: Scalars['Boolean'];
  /** @deprecated Use "status" instead */
  state?: Maybe<Scalars['String']>;
  /** Equals to `state` field */
  status?: Maybe<Scalars['String']>;
  /** The storymaker who recorded the video */
  storymaker?: Maybe<Storymaker>;
  subOrganizationId?: Maybe<Scalars['Int']>;
  /** The template that was used for creating the video */
  template: Template;
  templateId?: Maybe<Scalars['Int']>;
  /** The text assets inputted by storymaker while filming a video */
  textAssets: Array<TextAsset>;
  /** The downloadable thumbnail URL of the video */
  thumbnailURL?: Maybe<Scalars['String']>;
  /** Undownloadable thumbnail URL of the video. If the video is not in rendered status, it gives the old thumbnail. */
  thumbnailURLInline?: Maybe<Scalars['String']>;
  /** The transcription of video */
  transcription?: Maybe<Transcription>;
  updatedAt: Scalars['DateTime'];
  /** Assets used in the video */
  videoAssets: Array<VideoAsset>;
  videoSharingHistory: Array<VideoShareEntry>;
};

/** Storyvine NOW video's asset */
export type VideoAsset = NodeWithStringId & {
  __typename?: 'VideoAsset';
  captureStep?: Maybe<CaptureStep>;
  duration?: Maybe<Scalars['Int']>;
  /** Unique identifier for the resource */
  id: Scalars['String'];
  isOriginal?: Maybe<Scalars['Boolean']>;
  /** @deprecated Use captureStep field instead */
  key?: Maybe<Scalars['String']>;
  /** @deprecated Use captureStep field instead */
  label?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type VideoFeedback = {
  __typename?: 'VideoFeedback';
  adminUserId?: Maybe<Scalars['Int']>;
  appUserId?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  creator?: Maybe<User>;
  feedback: Scalars['String'];
  id: Scalars['ID'];
  isViewed?: Maybe<Scalars['Boolean']>;
  packageId: Scalars['Int'];
  packageStatus: Scalars['String'];
};

export type VideoFilterInput = {
  filmingGroupId?: InputMaybe<Array<Scalars['Int']>>;
  organizationId?: InputMaybe<Array<Scalars['Int']>>;
  state?: InputMaybe<Array<Scalars['String']>>;
  templateId?: InputMaybe<Array<Scalars['Int']>>;
  videoId?: InputMaybe<Scalars['Int']>;
};

export type VideoHistoryPreview = {
  __typename?: 'VideoHistoryPreview';
  history: Array<Video>;
  totalVideosInStatus: Scalars['Int'];
};

export type VideoHistoryPreviewsByStatus = {
  __typename?: 'VideoHistoryPreviewsByStatus';
  approved?: Maybe<VideoHistoryPreview>;
  building?: Maybe<VideoHistoryPreview>;
  do_not_use?: Maybe<VideoHistoryPreview>;
  not_approved?: Maybe<VideoHistoryPreview>;
  rendered?: Maybe<VideoHistoryPreview>;
  review_requested?: Maybe<VideoHistoryPreview>;
  shared?: Maybe<VideoHistoryPreview>;
};

export type VideoLinkExpirationInput = {
  duration: Scalars['Int'];
  isCustom?: InputMaybe<Scalars['Boolean']>;
  unit: Scalars['String'];
};

export type VideoReleaseUpdateInput = {
  videoReleasePromptBegin?: InputMaybe<Scalars['String']>;
  videoReleasePromptEnd?: InputMaybe<Scalars['String']>;
};

export type VideoRestrictedRoleStatusMap = {
  __typename?: 'VideoRestrictedRoleStatusMap';
  reviewer: Array<Scalars['String']>;
  storymaker: Array<Scalars['String']>;
};

export type VideoShareEntry = {
  __typename?: 'VideoShareEntry';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  meta: Scalars['JSON'];
  packageId: Scalars['Int'];
  proxyLinks?: Maybe<Scalars['JSON']>;
  shareOption: ShareOption;
  shareOptionId: Scalars['String'];
  shareType: ShareOptionType;
};

/** Available video statuses. */
export enum VideoStatus {
  Approved = 'approved',
  Created = 'created',
  DoNotUse = 'do_not_use',
  Failed = 'failed',
  NotApproved = 'not_approved',
  Preprocessing = 'preprocessing',
  Rendered = 'rendered',
  Rendering = 'rendering',
  ReviewRequested = 'review_requested'
}

export type VideoStatusTransitionMap = {
  __typename?: 'VideoStatusTransitionMap';
  approved: Array<Scalars['String']>;
  created: Array<Scalars['String']>;
  do_not_use: Array<Scalars['String']>;
  failed: Array<Scalars['String']>;
  not_approved: Array<Scalars['String']>;
  preprocessing: Array<Scalars['String']>;
  rendered: Array<Scalars['String']>;
  rendering: Array<Scalars['String']>;
  review_requested: Array<Scalars['String']>;
};

/** Vimeo Account Information */
export type VimeoAccount = {
  __typename?: 'VimeoAccount';
  canSetDownloadableSetting: Scalars['Boolean'];
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  /** Shows available privacy embed settings of the vimeo account based on the subscription type */
  privacyEmbedSettings: Array<VimeoPrivacyEmbedSettings>;
  /** Shows available privacy view settings of the vimeo account based on the subscription type */
  privacyViewSettings: Array<VimeoPrivacyViewSettings>;
};

/**
 * Embed setting options of Vimeo
 *     public: Enable people to embed this video on any site - humanized name: everywhere
 *     private: Do not allow anyone to embed this video - humanized name: nowhere
 *     whitelist: (requires paid subscription in Vimeo): Only specified domains can embed the video - humanized name: Specific domains
 *
 */
export enum VimeoPrivacyEmbedSettings {
  Private = 'private',
  Public = 'public',
  Whitelist = 'whitelist'
}

/**
 * Privacy setting options of Vimeo
 *     anybody:  Allow anyone to see this video. - humanized: anyone
 *     nobody: Make this video visible to you and select team members (Admins and Contributors), and no one else - humanized: only me
 *     disable: (requires paid subscription in Vimeo): This video can be embedded on other sites but can’t be viewed on Vimeo (Your video cannot appear in channels and groups but can still be added to showcases and portfolios.) - humanized: hide from Vimeo
 *     password (requires paid subscription in Vimeo): Protect this video with a password (Note: you can share password-protected videos with non-Vimeo members). - humanized: only people with a password
 *     unlisted (requires paid subscription in Vimeo): Make video visible only to people who have the link. (Your video cannot appear in channels or groups, and any credits you’ve added will be removed.) - humanized: only people with the private link
 *
 */
export enum VimeoPrivacyViewSettings {
  Anybody = 'anybody',
  Disable = 'disable',
  Nobody = 'nobody',
  Password = 'password',
  Unlisted = 'unlisted'
}

/** YouTube Channel Information */
export type YoutubeChannel = {
  __typename?: 'YoutubeChannel';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** Privacy setting option of YouTube */
export enum YoutubePrivacySetting {
  Private = 'private',
  Public = 'public',
  Unlisted = 'unlisted'
}

/** Union result for AddFirebaseUidToUserMobile,GenericError  */
export type AddFirebaseUidToUserMobileResult = AddFirebaseUidToUserMobile | GenericError;

export type CustomProperties = {
  __typename?: 'customProperties';
  createdAt?: Maybe<Scalars['DateTime']>;
  properties?: Maybe<Scalars['JSON']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** Union result for FileExport,GenericError  */
export type ExportPackagesAndAssetsResult = FileExport | GenericError;

/** Union result for AdminUser,GenericError  */
export type InviteAccountAdminUserResult = AdminUser | GenericError;

/** Union result for VerifyOTP,GenericError  */
export type RequestOtpResult = GenericError | VerifyOtp;

/** Union result for AdminUser,GenericError  */
export type ToggleAdminUserDisabledResult = AdminUser | GenericError;

/** Union result for Reviewer,Storymaker,GenericError  */
export type UpdateSignupUserCustomPropertiesResult = GenericError | Reviewer | Storymaker;

/** Union result for Reviewer,Storymaker,GenericError  */
export type UpdateUserCustomPropertiesAndSignupDataResult = GenericError | Reviewer | Storymaker;

/** Union result for Reviewer,Storymaker,GenericError  */
export type UpsertUserCustomPropertiesResult = GenericError | Reviewer | Storymaker;

/** Union result for ValidationError,GenericError  */
export type UserUpgradePasswordAndRoleResult = GenericError | ValidationError;

/** Union result for VerifyOTP,GenericError  */
export type VerifyOtpResult = GenericError | VerifyOtp;

export type AddFirebaseUidToUserMutationVariables = Exact<{
  input: AddFirebaseUidToUserInput;
}>;


export type AddFirebaseUidToUserMutation = { __typename?: 'Mutation', addFirebaseUidToUser?: { __typename?: 'AddFirebaseUidToUser', id: number } | { __typename?: 'GenericError', message: string } | null };

export type LoginActionMutationVariables = Exact<{
  input: UserLoginActionInput;
}>;


export type LoginActionMutation = { __typename?: 'Mutation', userLoginAction: { __typename?: 'GenericError', code: number, message: string } | { __typename?: 'LoginAction', action: LoginActionEnum } };

export type AfterLoginActionMutationVariables = Exact<{
  input: AfterUserLoginActionInput;
}>;


export type AfterLoginActionMutation = { __typename?: 'Mutation', afterLoginUserAction: { __typename?: 'AfterLoginAction', id: number } | { __typename?: 'GenericError', message: string } };

export type PaginationFragmentFragment = { __typename?: 'PaginationResult', currentPage: number, total: number };

export type OrganizationTableInfoFragment = { __typename?: 'Organization', id: number, name?: string | null, subscriptionType?: SubscriptionType | null, videoCount?: number | null, storymakerCount?: number | null, nowDashboardFlag?: boolean | null };

export type OrganizationDetailInfoFragment = { __typename?: 'Organization', id: number, name?: string | null, legalName?: string | null, phoneNumber?: string | null, privacyPolicy?: string | null, termsOfService?: string | null, videoReleasePromptEnd?: string | null, videoReleasePromptBegin?: string | null, additionalEmailRecipients?: Array<string> | null, subscriptionType?: SubscriptionType | null, videoLinkExpiration?: any | null, nowDashboardFlag?: boolean | null, twoFactorAuthEnabled?: boolean | null, adminUsers: Array<{ __typename?: 'AdminUser', id: number, email?: string | null, isMe: boolean, isPrimary: boolean, disabledEmailNotifications?: boolean | null }>, socialHandles?: { __typename?: 'SocialHandles', instagram?: string | null, facebook?: string | null, twitter?: string | null, youtube?: string | null, vimeo?: string | null } | null };

export type AdminUserDetailInfoFragment = { __typename?: 'AdminUser', id: number, email?: string | null, firstName?: string | null, lastName?: string | null, roleName?: string | null, roleVersion?: number | null, role?: string | null, createdAt?: any | null, updatedAt?: any | null, hasAcceptedInvite?: boolean | null, disabled?: boolean | null, isPrimary: boolean, signInCount?: number | null, lastSignInAt?: any | null };

export type CustomPropertiesFragmentFragment = { __typename?: 'customProperties', createdAt?: any | null, properties?: any | null, updatedAt?: any | null };

export type StorymakerDetailInfoFragment = { __typename?: 'Storymaker', id: number, email?: string | null, firstName?: string | null, lastName?: string | null, suspended?: boolean | null, createdAt: any, videoCount: number, userSignupProperties: any, userCustomProperties?: { __typename?: 'customProperties', createdAt?: any | null, properties?: any | null, updatedAt?: any | null } | null };

export type ReviewerDetailInfoFragment = { __typename?: 'Reviewer', id: number, email?: string | null, firstName?: string | null, lastName?: string | null, suspended?: boolean | null, createdAt: any, videoCount: number, userSignupProperties: any, userCustomProperties?: { __typename?: 'customProperties', createdAt?: any | null, properties?: any | null, updatedAt?: any | null } | null };

export type TextCaptureLabelFragmentFragment = { __typename?: 'TextCapture', id: number, label?: string | null, required?: boolean | null };

export type CaptureStepFragmentFragment = { __typename?: 'CaptureStep', id: number, coachingBody?: string | null, coachingTitle?: string | null, coachingPrompt?: string | null };

export type TranscriptionPartFragmentFragment = { __typename?: 'Transcription', id: string, status: TranscriptionStatus, subtitlesUrl?: string | null, parts: Array<{ __typename?: 'TranscriptionPart', id: string, from: number, to: number, text: string }> };

export type VideoPropsFragment = { __typename?: 'Video', id: number, previewURL?: string | null, thumbnailURL?: string | null, thumbnailURLInline?: string | null, state?: string | null, shared: boolean, createdAt: any, updatedAt: any, captureStepsList?: { __typename?: 'CaptureStepsList', id: number, name?: string | null } | null, storymaker?: { __typename?: 'Storymaker', id: number, email?: string | null, firstName?: string | null, lastName?: string | null } | null, template: { __typename?: 'Template', id: number, name?: string | null }, filmingGroup?: { __typename?: 'FilmingGroup', id: number, name?: string | null, emailTriggerSetting?: FilmingGroupEmailTriggerSetting | null } | null, organization?: { __typename?: 'Organization', id: number, name?: string | null } | null, videoSharingHistory: Array<{ __typename?: 'VideoShareEntry', createdAt: any, meta: any, proxyLinks?: any | null, shareType: ShareOptionType }>, feedbacks: Array<{ __typename?: 'VideoFeedback', id: string }> };

export type FilmingGroupsFragmentFragment = { __typename?: 'FilmingGroup', id: number, name?: string | null, emailTriggerSetting?: FilmingGroupEmailTriggerSetting | null, shareableFacebook?: boolean | null, shareableTwitter?: boolean | null, shareableDownload?: boolean | null, facebookTitle?: string | null, facebookDescription?: string | null, twitterDescription?: string | null, storymakersCount: number, videosCount: number, activeSignUpFormUrl?: string | null, templates: Array<{ __typename?: 'Template', id: number, name?: string | null, previewVideo?: { __typename?: 'Video', id: number, previewURL?: string | null, thumbnailURLInline?: string | null } | null }> };

export type OauthInfoYoutubeFragmentFragment = { __typename?: 'OauthInfo', id: number, youtubeChannels?: Array<{ __typename?: 'YoutubeChannel', id?: string | null, name?: string | null }> | null };

export type OauthInfoVimeoFragmentFragment = { __typename?: 'OauthInfo', id: number, vimeoAccount?: { __typename?: 'VimeoAccount', id?: string | null, name?: string | null, canSetDownloadableSetting: boolean, privacyViewSettings: Array<VimeoPrivacyViewSettings>, privacyEmbedSettings: Array<VimeoPrivacyEmbedSettings> } | null };

export type OauthInfoFragmentFragment = { __typename?: 'OauthInfo', id: number, youtubeChannels?: Array<{ __typename?: 'YoutubeChannel', id?: string | null, name?: string | null }> | null, vimeoAccount?: { __typename?: 'VimeoAccount', id?: string | null, name?: string | null, canSetDownloadableSetting: boolean, privacyViewSettings: Array<VimeoPrivacyViewSettings>, privacyEmbedSettings: Array<VimeoPrivacyEmbedSettings> } | null };

export type AcceptInvitationMutationVariables = Exact<{
  confirmationToken: Scalars['String'];
  isSSOUser?: Scalars['Boolean'];
  password?: InputMaybe<Scalars['String']>;
}>;


export type AcceptInvitationMutation = { __typename?: 'Mutation', acceptInvitation: { __typename?: 'AcceptInvitationResult', email: string } | { __typename?: 'GenericError', message: string } };

export type SignUpManagerMutationVariables = Exact<{
  managerInput: SignUpManagerInput;
  productId: Scalars['String'];
  integrationSource?: InputMaybe<IntegrationSourceTypes>;
}>;


export type SignUpManagerMutation = { __typename?: 'Mutation', signUpManager?: { __typename?: 'GenericError', message: string } | { __typename?: 'SignUpData', id: number, firstName?: string | null, lastName?: string | null, email?: string | null, organizationName?: string | null, organizationPhone?: string | null } | { __typename?: 'ValidationError', fields: Array<string>, message: string } | null };

export type RebuildVideoMutationVariables = Exact<{
  videoId: Scalars['Int'];
}>;


export type RebuildVideoMutation = { __typename?: 'Mutation', rebuildVideo: { __typename?: 'GenericError', message: string } | { __typename?: 'Video', id: number, state?: string | null } };

export type UpdateVideoTextAssetsMutationVariables = Exact<{
  videoId: Scalars['Int'];
  data: Array<UpdateVideoTextAssetsInput> | UpdateVideoTextAssetsInput;
}>;


export type UpdateVideoTextAssetsMutation = { __typename?: 'Mutation', updateVideoTextAssets: { __typename?: 'GenericError', message: string } | { __typename?: 'Video', id: number, textAssets: Array<{ __typename?: 'TextAsset', id: string, value?: string | null }> } };

export type InviteStorymakersMutationVariables = Exact<{
  storymakers: Array<InviteStorymakerInput> | InviteStorymakerInput;
  filmingGroupIds: Array<Scalars['Int']> | Scalars['Int'];
  organizationId?: InputMaybe<Scalars['Int']>;
  sendNotificationEmail?: InputMaybe<Scalars['Boolean']>;
}>;


export type InviteStorymakersMutation = { __typename?: 'Mutation', inviteStorymakers?: Array<{ __typename?: 'GenericError', code: number, message: string } | { __typename?: 'Storymaker', id: number, firstName?: string | null, lastName?: string | null, email?: string | null }> | null };

export type InviteStorymakersWithReviwersMutationVariables = Exact<{
  storymakers: Array<InviteStorymakerAndReviewersInput> | InviteStorymakerAndReviewersInput;
  filmingGroupIds: Array<Scalars['Int']> | Scalars['Int'];
  organizationId?: InputMaybe<Scalars['Int']>;
  connectReviewers?: InputMaybe<Scalars['Boolean']>;
  employeeIdentifiedBy?: InputMaybe<Scalars['String']>;
  connectionWithReviewerExpressedBy?: InputMaybe<Scalars['String']>;
  sendNotificationEmail?: InputMaybe<Scalars['Boolean']>;
  featureFlags?: InputMaybe<Array<ApplicationFeatureFlags> | ApplicationFeatureFlags>;
}>;


export type InviteStorymakersWithReviwersMutation = { __typename?: 'Mutation', inviteStorymakersWithReviewers?: Array<{ __typename?: 'APIError', fieldName: string, messages: Array<string> } | { __typename?: 'GenericError', code: number, message: string } | { __typename?: 'Reviewer', id: number, firstName?: string | null, lastName?: string | null, email?: string | null } | { __typename?: 'Storymaker', id: number, firstName?: string | null, lastName?: string | null, email?: string | null }> | null };

export type UploadVideoAssetMutationVariables = Exact<{
  videoId: Scalars['Int'];
  assetKey: Scalars['String'];
  file: Scalars['Upload'];
}>;


export type UploadVideoAssetMutation = { __typename?: 'Mutation', uploadAsset: { __typename: 'GenericError', code: number, message: string } | { __typename: 'UploadAssetResponse', url: string } | { __typename: 'ValidationError', code: number, message: string } };

export type CreatePresignedRequestForVideoAssetUploadMutationVariables = Exact<{
  videoId: Scalars['Int'];
  assetKey: Scalars['String'];
  fileName: Scalars['String'];
}>;


export type CreatePresignedRequestForVideoAssetUploadMutation = { __typename?: 'Mutation', createPresignedRequestForVideoAssetUpload: { __typename: 'GenericError', message: string } | { __typename: 'PresignedRequest', url: string, fields: string } | { __typename: 'ValidationError', message: string } };

export type UploadVideoAssetSuccessMutationVariables = Exact<{
  videoId: Scalars['Int'];
  assetKey: Scalars['String'];
  assetUrl: Scalars['String'];
}>;


export type UploadVideoAssetSuccessMutation = { __typename?: 'Mutation', uploadVideoAssetSuccess?: { __typename: 'GenericError', message: string } | { __typename: 'ValidationError', message: string } | null };

export type UpdateVideoAssetsMutationVariables = Exact<{
  videoId: Scalars['Int'];
  links: Array<AssetLink> | AssetLink;
}>;


export type UpdateVideoAssetsMutation = { __typename?: 'Mutation', updateVideoAssets: Array<{ __typename: 'APIError', fieldName: string, messages: Array<string> } | { __typename: 'GenericError', message: string } | { __typename: 'Video', id: number, imageAssets: Array<{ __typename?: 'ImageAsset', id: string, url?: string | null, isOriginal?: boolean | null }>, videoAssets: Array<{ __typename?: 'VideoAsset', id: string, url?: string | null, duration?: number | null, isOriginal?: boolean | null }> }> };

export type RestoreOriginalMutationVariables = Exact<{
  videoId: Scalars['Int'];
  assetKey: Scalars['String'];
}>;


export type RestoreOriginalMutation = { __typename?: 'Mutation', restoreOriginalAsset: { __typename: 'GenericError', message: string } | { __typename: 'ImageAsset', id: string, url?: string | null, isOriginal?: boolean | null } | { __typename: 'ValidationError', message: string } | { __typename: 'VideoAsset', id: string, url?: string | null, isOriginal?: boolean | null, duration?: number | null } };

export type StoreSubscriptionMutationVariables = Exact<{
  input: StoreSubscriptionInput;
  selectedProduct: Scalars['String'];
}>;


export type StoreSubscriptionMutation = { __typename?: 'Mutation', storeSubscription?: { __typename?: 'GenericError', code: number, message: string } | { __typename?: 'RefetchTokenError', code: number, message: string } | { __typename?: 'SignUpData', id: number, subscribed?: boolean | null } | { __typename?: 'ValidationError', code: number, message: string } | null };

export type AddManagerInfoMutationVariables = Exact<{
  input: AddManagerInfoInput;
}>;


export type AddManagerInfoMutation = { __typename?: 'Mutation', addManagerInfo: { __typename?: 'GenericError', code: number, message: string } | { __typename?: 'RefetchTokenError', code: number, message: string } | { __typename?: 'SignUpData', id: number, firstName?: string | null, lastName?: string | null } };

export type FinishSignUpMutationVariables = Exact<{
  input: FinishSignUpInput;
}>;


export type FinishSignUpMutation = { __typename?: 'Mutation', finishSignUp: { __typename: 'GenericError', code: number, message: string } | { __typename: 'RefetchTokenError', code: number, message: string } | { __typename: 'SignUpData', id: number, done?: boolean | null } };

export type SaveYoutubeCredentialsMutationVariables = Exact<{
  authCode: Scalars['String'];
}>;


export type SaveYoutubeCredentialsMutation = { __typename?: 'Mutation', saveYoutubeCredentials: { __typename?: 'GenericError', code: number, message: string } | { __typename?: 'Organization', id: number, oauthInfo?: { __typename?: 'OauthInfo', id: number, youtubeChannels?: Array<{ __typename?: 'YoutubeChannel', id?: string | null, name?: string | null }> | null } | null } };

export type BuildVimeoAuthorizationEndpointMutationVariables = Exact<{
  redirectUrl: Scalars['String'];
  state: Scalars['String'];
}>;


export type BuildVimeoAuthorizationEndpointMutation = { __typename?: 'Mutation', buildVimeoAuthorizationEndpoint?: string | null };

export type SaveVimeoCredentialsMutationVariables = Exact<{
  code: Scalars['String'];
  redirectUrl: Scalars['String'];
}>;


export type SaveVimeoCredentialsMutation = { __typename?: 'Mutation', saveVimeoCredentials: { __typename?: 'GenericError', code: number, message: string } | { __typename?: 'Organization', id: number, oauthInfo?: { __typename?: 'OauthInfo', id: number, vimeoAccount?: { __typename?: 'VimeoAccount', id?: string | null, name?: string | null, canSetDownloadableSetting: boolean, privacyViewSettings: Array<VimeoPrivacyViewSettings>, privacyEmbedSettings: Array<VimeoPrivacyEmbedSettings> } | null } | null } };

export type RemoveVimeoCredentialsMutationVariables = Exact<{ [key: string]: never; }>;


export type RemoveVimeoCredentialsMutation = { __typename?: 'Mutation', removeVimeoCredentials: { __typename?: 'Organization', id: number, oauthInfo?: { __typename?: 'OauthInfo', id: number, vimeoAccount?: { __typename?: 'VimeoAccount', id?: string | null, name?: string | null, canSetDownloadableSetting: boolean, privacyViewSettings: Array<VimeoPrivacyViewSettings>, privacyEmbedSettings: Array<VimeoPrivacyEmbedSettings> } | null } | null } };

export type PushVideoToYoutubeMutationVariables = Exact<{
  channelId: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  privacySetting: YoutubePrivacySetting;
  title: Scalars['String'];
  videoId: Scalars['Int'];
}>;


export type PushVideoToYoutubeMutation = { __typename?: 'Mutation', pushVideoToYoutube?: { __typename?: 'GenericError', code: number, message: string } | null };

export type UpdateBrandColorsMutationVariables = Exact<{
  lightColor: Scalars['String'];
  darkColor: Scalars['String'];
  greyColor: Scalars['String'];
}>;


export type UpdateBrandColorsMutation = { __typename?: 'Mutation', updateBrandColors?: { __typename?: 'BrandAsset', id: number, darkColor?: string | null, lightColor?: string | null, greyColor?: string | null } | { __typename?: 'ValidationError', message: string } | null };

export type UploadBrandLogosMutationVariables = Exact<{
  colorLogo?: InputMaybe<Scalars['Upload']>;
  whiteLogo?: InputMaybe<Scalars['Upload']>;
}>;


export type UploadBrandLogosMutation = { __typename?: 'Mutation', uploadBrandLogos: { __typename?: 'BrandAsset', id: number, colorLogo?: string | null, whiteLogo?: string | null } };

export type UpdateEndCardInfoMutationVariables = Exact<{
  type: EndCardType;
  value: Scalars['String'];
  callToAction: Scalars['String'];
  businessEmail?: InputMaybe<Scalars['String']>;
  businessPhone?: InputMaybe<Scalars['String']>;
}>;


export type UpdateEndCardInfoMutation = { __typename?: 'Mutation', updateEndCardInfo: { __typename?: 'EndCard', id: number, callToAction?: string | null, value?: string | null, type?: EndCardType | null, businessPhone?: string | null, businessEmail?: string | null } };

export type RemoveYoutubeCredentialsMutationVariables = Exact<{ [key: string]: never; }>;


export type RemoveYoutubeCredentialsMutation = { __typename?: 'Mutation', removeYoutubeCredentials: { __typename?: 'Organization', id: number, oauthInfo?: { __typename?: 'OauthInfo', id: number, youtubeChannels?: Array<{ __typename?: 'YoutubeChannel', id?: string | null, name?: string | null }> | null } | null } };

export type UpdateOrganizationMutationVariables = Exact<{
  input: OrganizationUpdateInput;
  organizationId?: InputMaybe<Scalars['Int']>;
}>;


export type UpdateOrganizationMutation = { __typename?: 'Mutation', updateOrganization: { __typename?: 'Organization', id: number, name?: string | null, legalName?: string | null, phoneNumber?: string | null, twoFactorAuthEnabled?: boolean | null, socialHandles?: { __typename?: 'SocialHandles', instagram?: string | null, facebook?: string | null, twitter?: string | null, youtube?: string | null, vimeo?: string | null } | null } };

export type UpdateMeMutationVariables = Exact<{
  input: UpdateAdminUserInput;
}>;


export type UpdateMeMutation = { __typename?: 'Mutation', updateMe: { __typename?: 'AdminUser', id: number, firstName?: string | null, lastName?: string | null, email?: string | null, cellPhone?: string | null, directPhone?: string | null, title?: string | null, isPrimary: boolean } | { __typename?: 'GenericError', code: number, message: string } };

export type UpdateAdminUserMutationVariables = Exact<{
  adminUserId: Scalars['Int'];
  input: UpdateAdminUserInput;
}>;


export type UpdateAdminUserMutation = { __typename?: 'Mutation', updateAdminUser?: { __typename?: 'AdminUser', id: number, firstName?: string | null, lastName?: string | null, email?: string | null, cellPhone?: string | null, directPhone?: string | null, title?: string | null, isPrimary: boolean } | { __typename?: 'GenericError', code: number, message: string } | null };

export type UpdateStorymakersMutationVariables = Exact<{
  storymakerIds: Array<Scalars['Int']> | Scalars['Int'];
  organizationId?: InputMaybe<Scalars['Int']>;
  filmingGroupIds: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type UpdateStorymakersMutation = { __typename?: 'Mutation', updateStorymakers: Array<{ __typename: 'GenericError', message: string } | { __typename: 'Storymaker', id: number, filmingGroups: Array<{ __typename?: 'FilmingGroup', id: number, name?: string | null }> }> };

export type EnableAppUserMutationVariables = Exact<{
  storymakerId: Scalars['Int'];
}>;


export type EnableAppUserMutation = { __typename?: 'Mutation', enableAppUser: { __typename: 'GenericError', message: string } | { __typename: 'Reviewer', id: number, suspended?: boolean | null } | { __typename: 'Storymaker', id: number, suspended?: boolean | null } };

export type DisableAppUserMutationVariables = Exact<{
  storymakerId: Scalars['Int'];
}>;


export type DisableAppUserMutation = { __typename?: 'Mutation', disableAppUser?: { __typename: 'GenericError', message: string } | { __typename: 'Reviewer', id: number, suspended?: boolean | null } | { __typename: 'Storymaker', id: number, suspended?: boolean | null } | null };

export type ManagerInviteAdminUserMutationVariables = Exact<{
  email: Scalars['String'];
  urlPrefix: Scalars['String'];
}>;


export type ManagerInviteAdminUserMutation = { __typename?: 'Mutation', inviteAdminUser?: { __typename?: 'AdminUser', id: number, email?: string | null, hasAcceptedInvite?: boolean | null, firstName?: string | null, lastName?: string | null, cellPhone?: string | null, directPhone?: string | null, title?: string | null, isMe: boolean, isPrimary: boolean, role?: string | null } | { __typename?: 'GenericError', message: string } | null };

export type AdminInviteAdminUserMutationVariables = Exact<{
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  urlPrefix: Scalars['String'];
  organizationId: Scalars['Int'];
  ssoLogin?: InputMaybe<Scalars['Boolean']>;
}>;


export type AdminInviteAdminUserMutation = { __typename?: 'Mutation', inviteAdminUser?: { __typename?: 'AdminUser', id: number, email?: string | null, firstName?: string | null, lastName?: string | null, roleName?: string | null, roleVersion?: number | null, role?: string | null, createdAt?: any | null, updatedAt?: any | null, hasAcceptedInvite?: boolean | null, disabled?: boolean | null, isPrimary: boolean, signInCount?: number | null, lastSignInAt?: any | null } | { __typename?: 'GenericError', message: string } | null };

export type InviteAccountAdminUserMutationVariables = Exact<{
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  urlPrefix: Scalars['String'];
}>;


export type InviteAccountAdminUserMutation = { __typename?: 'Mutation', inviteAccountAdminUser: { __typename?: 'AdminUser', id: number, email?: string | null, firstName?: string | null, lastName?: string | null, roleName?: string | null, roleVersion?: number | null, role?: string | null, createdAt?: any | null, updatedAt?: any | null, hasAcceptedInvite?: boolean | null, disabled?: boolean | null, isPrimary: boolean, signInCount?: number | null, lastSignInAt?: any | null } | { __typename?: 'GenericError', message: string } };

export type UpdateVideoStatusMutationVariables = Exact<{
  videoId: Scalars['Int'];
  status: Scalars['String'];
}>;


export type UpdateVideoStatusMutation = { __typename?: 'Mutation', updateVideoStatus: { __typename: 'GenericError', message: string } | { __typename: 'Video', id: number, state?: string | null } };

export type ResendAdminUserInvitationMutationVariables = Exact<{
  adminUserId: Scalars['Int'];
  urlPrefix: Scalars['String'];
}>;


export type ResendAdminUserInvitationMutation = { __typename?: 'Mutation', resendAdminUserInvitation?: { __typename?: 'GenericError', message: string } | { __typename?: 'OutdatedDataError', message: string, entities: Array<string> } | null };

export type CancelAdminUserInvitationMutationVariables = Exact<{
  adminUserId: Scalars['Int'];
}>;


export type CancelAdminUserInvitationMutation = { __typename?: 'Mutation', cancelAdminUserInvitation?: { __typename?: 'GenericError', message: string } | { __typename?: 'OutdatedDataError', message: string, entities: Array<string> } | null };

export type UpdateLegalLinksMutationVariables = Exact<{
  input: LegalLinksUpdateInput;
  organizationId?: InputMaybe<Scalars['Int']>;
}>;


export type UpdateLegalLinksMutation = { __typename?: 'Mutation', updateLegalLinks: { __typename?: 'Organization', id: number, privacyPolicy?: string | null, termsOfService?: string | null } };

export type UpdateVideoReleaseMutationVariables = Exact<{
  input: VideoReleaseUpdateInput;
  organizationId?: InputMaybe<Scalars['Int']>;
}>;


export type UpdateVideoReleaseMutation = { __typename?: 'Mutation', updateVideoRelease: { __typename?: 'Organization', id: number, videoReleasePromptBegin?: string | null, videoReleasePromptEnd?: string | null } };

export type SetPrimaryManagerMutationVariables = Exact<{
  managerId: Scalars['Int'];
}>;


export type SetPrimaryManagerMutation = { __typename?: 'Mutation', setPrimaryManager: Array<{ __typename?: 'AdminUser', id: number, isPrimary: boolean }> };

export type DeleteAdminUserMutationVariables = Exact<{
  adminUserId: Scalars['Int'];
}>;


export type DeleteAdminUserMutation = { __typename?: 'Mutation', deleteAdminUser?: { __typename?: 'AdminUser', id: number } | { __typename?: 'GenericError', message: string } | null };

export type FinishTutorialMutationVariables = Exact<{ [key: string]: never; }>;


export type FinishTutorialMutation = { __typename?: 'Mutation', finishTutorial: boolean };

export type RestartTutorialMutationVariables = Exact<{ [key: string]: never; }>;


export type RestartTutorialMutation = { __typename?: 'Mutation', restartTutorial: boolean };

export type DeleteVideosMutationVariables = Exact<{
  videoIds: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type DeleteVideosMutation = { __typename?: 'Mutation', deleteVideos?: { __typename?: 'GenericError', message: string } | null };

export type ApplyCouponCodeMutationVariables = Exact<{
  couponCode: Scalars['String'];
  productId: Scalars['String'];
}>;


export type ApplyCouponCodeMutation = { __typename?: 'Mutation', applyCouponCode: { __typename: 'CouponApplicationResult', amountOff: number, percentOff: number } | { __typename: 'RefetchTokenError' } };

export type CreateVideoMutationVariables = Exact<{
  filmingGroupId: Scalars['Int'];
  templateId: Scalars['Int'];
  storymakerId: Scalars['Int'];
}>;


export type CreateVideoMutation = { __typename?: 'Mutation', createVideo: { __typename: 'ValidationError', message: string } | { __typename: 'Video', id: number } };

export type ExportVideoAssetsMutationVariables = Exact<{
  videoIds?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
}>;


export type ExportVideoAssetsMutation = { __typename?: 'Mutation', exportVideoAssets?: { __typename?: 'GenericError', message: string } | null };

export type LoginUpgradeMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginUpgradeMutation = { __typename?: 'Mutation', loginUpgrade?: { __typename?: 'GenericError', code: number, message: string } | null };

export type SendResetPasswordEmailMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type SendResetPasswordEmailMutation = { __typename?: 'Mutation', sendResetPasswordEmail?: any | null };

export type CompleteResetPasswordMutationVariables = Exact<{
  email: Scalars['String'];
  oobCode: Scalars['String'];
  newPassword: Scalars['String'];
}>;


export type CompleteResetPasswordMutation = { __typename?: 'Mutation', completeResetPassword?: { __typename?: 'AdminUser', email?: string | null } | { __typename?: 'ValidationError', message: string } | null };

export type ResendVideoReadyEmailMutationVariables = Exact<{
  videoId: Scalars['Int'];
  recipients: ResendVideoReadyEmailRecipientType;
}>;


export type ResendVideoReadyEmailMutation = { __typename?: 'Mutation', resendVideoReadyEmail?: { __typename?: 'GenericError', code: number, message: string } | null };

export type ExportVideosCsvMutationVariables = Exact<{
  filter?: InputMaybe<ExportVideosCsvFilterInput>;
}>;


export type ExportVideosCsvMutation = { __typename?: 'Mutation', exportVideosCsv?: { __typename?: 'GenericError', message: string } | null };

export type ExportPackagesAndAssetsMutationVariables = Exact<{
  filter?: InputMaybe<ExportVideosCsvFilterInput>;
}>;


export type ExportPackagesAndAssetsMutation = { __typename?: 'Mutation', exportPackagesAndAssets: { __typename?: 'FileExport', totalKeys: number } | { __typename?: 'GenericError', message: string } };

export type ResendInviteStorymakerEmailMutationVariables = Exact<{
  storymakerIds: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type ResendInviteStorymakerEmailMutation = { __typename?: 'Mutation', resendInviteStorymakerEmail?: { __typename?: 'GenericError', message: string } | null };

export type UpdateAdditionalEmailRecipientsMutationVariables = Exact<{
  additionalEmailRecipients: Array<Scalars['String']> | Scalars['String'];
}>;


export type UpdateAdditionalEmailRecipientsMutation = { __typename?: 'Mutation', updateAdditionalEmailRecipients?: { __typename?: 'GenericError', message: string } | { __typename?: 'Organization', id: number, additionalEmailRecipients?: Array<string> | null } | null };

export type EnableAdminUserEmailNotificationsMutationVariables = Exact<{
  managerId: Scalars['Int'];
}>;


export type EnableAdminUserEmailNotificationsMutation = { __typename?: 'Mutation', enableAdminUserNotifications: { __typename?: 'AdminUser', id: number, disabledEmailNotifications?: boolean | null } | { __typename?: 'GenericError', message: string } };

export type DisableAdminUserEmailNotificationsMutationVariables = Exact<{
  managerId: Scalars['Int'];
}>;


export type DisableAdminUserEmailNotificationsMutation = { __typename?: 'Mutation', disableAdminUserNotifications: { __typename?: 'AdminUser', id: number, disabledEmailNotifications?: boolean | null } | { __typename?: 'GenericError', message: string } };

export type EditStorymakerMutationVariables = Exact<{
  storymakerId: Scalars['Int'];
  input: EditStorymakerInput;
}>;


export type EditStorymakerMutation = { __typename?: 'Mutation', editStorymaker?: { __typename?: 'GenericError', message: string } | { __typename?: 'Storymaker', id: number, email?: string | null, firstName?: string | null, lastName?: string | null } | null };

export type UpdateUserCustomPropertiesAndSignupDataMutationVariables = Exact<{
  appUserId: Scalars['Int'];
  properties?: InputMaybe<Scalars['JSON']>;
  signupData?: InputMaybe<Scalars['JSON']>;
}>;


export type UpdateUserCustomPropertiesAndSignupDataMutation = { __typename?: 'Mutation', updateUserCustomPropertiesAndSignupData: { __typename?: 'GenericError', message: string } | { __typename?: 'Reviewer', userSignupProperties: any, userCustomProperties?: { __typename?: 'customProperties', createdAt?: any | null, properties?: any | null, updatedAt?: any | null } | null } | { __typename?: 'Storymaker', userSignupProperties: any, userCustomProperties?: { __typename?: 'customProperties', createdAt?: any | null, properties?: any | null, updatedAt?: any | null } | null } };

export type DeleteStorymakerMutationVariables = Exact<{
  storymakerId: Scalars['Int'];
}>;


export type DeleteStorymakerMutation = { __typename?: 'Mutation', deleteStorymaker: { __typename?: 'GenericError', message: string } | { __typename?: 'Storymaker', id: number, deleted: boolean } };

export type UpdateTextCapturesMutationVariables = Exact<{
  inputs: Array<TextCaptureInput> | TextCaptureInput;
}>;


export type UpdateTextCapturesMutation = { __typename?: 'Mutation', updateTextCaptures: Array<{ __typename?: 'GenericError', message: string } | { __typename?: 'TextCapture', id: number, label?: string | null, required?: boolean | null }> };

export type UpdateCaptureStepsListMutationVariables = Exact<{
  input: CaptureStepsListInput;
}>;


export type UpdateCaptureStepsListMutation = { __typename?: 'Mutation', updateCaptureStepsList: { __typename?: 'CaptureStepsList', id: number, name?: string | null, captureSteps?: Array<{ __typename?: 'CaptureStep', id: number, coachingBody?: string | null, coachingTitle?: string | null, coachingPrompt?: string | null }> | null } | { __typename: 'GenericError', message: string } };

export type EditTemplateMutationVariables = Exact<{
  input: EditTemplateInput;
  templateId: Scalars['Int'];
}>;


export type EditTemplateMutation = { __typename?: 'Mutation', editTemplate: { __typename?: 'GenericError', message: string } | { __typename?: 'Template', id: number, name?: string | null, description?: string | null, shouldSaveToCameraRoll?: boolean | null, isStorymakerAddedEmailsEnabled?: boolean | null, isReviewRequired?: boolean | null } };

export type CreateOrganizationMutationVariables = Exact<{
  input: CreateOrganizationInput;
}>;


export type CreateOrganizationMutation = { __typename?: 'Mutation', createOrganization: { __typename?: 'GenericError', message: string } | { __typename?: 'Organization', id: number, name?: string | null, subscriptionType?: SubscriptionType | null, videoCount?: number | null, storymakerCount?: number | null, nowDashboardFlag?: boolean | null } };

export type MigrateOrganizationMutationVariables = Exact<{
  organizationId: Scalars['Int'];
}>;


export type MigrateOrganizationMutation = { __typename?: 'Mutation', migrateOrganization: { __typename?: 'GenericError', message: string } | { __typename?: 'Organization', id: number, nowDashboardFlag?: boolean | null } };

export type AssignTemplatesToFilmingGroupMutationVariables = Exact<{
  filmingGroupId: Scalars['Int'];
  templateIds: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type AssignTemplatesToFilmingGroupMutation = { __typename?: 'Mutation', assignTemplatesToFilmingGroup: { __typename?: 'FilmingGroup', id: number, templates: Array<{ __typename?: 'Template', id: number, name?: string | null, previewVideo?: { __typename?: 'Video', id: number, previewURL?: string | null, thumbnailURLInline?: string | null } | null }> } | { __typename?: 'GenericError', message: string } };

export type UnassignTemplatesFromFilmingGroupMutationVariables = Exact<{
  filmingGroupId: Scalars['Int'];
  templateIds: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type UnassignTemplatesFromFilmingGroupMutation = { __typename?: 'Mutation', unassignTemplatesFromFilmingGroup: { __typename?: 'FilmingGroup', id: number, templates: Array<{ __typename?: 'Template', id: number, name?: string | null, previewVideo?: { __typename?: 'Video', id: number, previewURL?: string | null, thumbnailURLInline?: string | null } | null }> } | { __typename?: 'GenericError', message: string } };

export type CreateFilmingGroupMutationVariables = Exact<{
  organizationId: Scalars['Int'];
  input: UpsertFilmingGroupInput;
}>;


export type CreateFilmingGroupMutation = { __typename?: 'Mutation', createFilmingGroup?: { __typename?: 'FilmingGroup', id: number, name?: string | null, emailTriggerSetting?: FilmingGroupEmailTriggerSetting | null, shareableFacebook?: boolean | null, shareableTwitter?: boolean | null, shareableDownload?: boolean | null, facebookTitle?: string | null, facebookDescription?: string | null, twitterDescription?: string | null, storymakersCount: number, videosCount: number, activeSignUpFormUrl?: string | null, templates: Array<{ __typename?: 'Template', id: number, name?: string | null, previewVideo?: { __typename?: 'Video', id: number, previewURL?: string | null, thumbnailURLInline?: string | null } | null }> } | null };

export type UpdateFilmingGroupMutationVariables = Exact<{
  filmingGroupId: Scalars['Int'];
  input: UpsertFilmingGroupInput;
}>;


export type UpdateFilmingGroupMutation = { __typename?: 'Mutation', updateFilmingGroup?: { __typename?: 'FilmingGroup', id: number, name?: string | null, emailTriggerSetting?: FilmingGroupEmailTriggerSetting | null, shareableFacebook?: boolean | null, shareableTwitter?: boolean | null, shareableDownload?: boolean | null, facebookTitle?: string | null, facebookDescription?: string | null, twitterDescription?: string | null, storymakersCount: number, videosCount: number, activeSignUpFormUrl?: string | null, templates: Array<{ __typename?: 'Template', id: number, name?: string | null, previewVideo?: { __typename?: 'Video', id: number, previewURL?: string | null, thumbnailURLInline?: string | null } | null }> } | null };

export type ShareToVimeoMutationVariables = Exact<{
  input: ShareToVimeoInput;
}>;


export type ShareToVimeoMutation = { __typename?: 'Mutation', shareToVimeo?: { __typename?: 'GenericError', message: string } | null };

export type ChangePasswordMutationVariables = Exact<{
  oldPassword: Scalars['String'];
  newPassword: Scalars['String'];
}>;


export type ChangePasswordMutation = { __typename?: 'Mutation', changePassword?: { __typename?: 'ValidationError', message: string } | null };

export type CreateFeedbackMutationVariables = Exact<{
  input: CreateFeedbackInput;
}>;


export type CreateFeedbackMutation = { __typename?: 'Mutation', createFeedback: { __typename?: 'GenericError', message: string } | { __typename?: 'VideoFeedback', id: string, packageId: number, packageStatus: string, feedback: string, appUserId?: number | null, adminUserId?: number | null, createdAt: any, creator?: { __typename: 'AdminUser' } | { __typename: 'CurrentAdminUser' } | { __typename: 'Reviewer' } | { __typename: 'Storymaker' } | null } };

export type UpdateSubscriptionTypeMutationVariables = Exact<{
  organizationId: Scalars['Int'];
  subscriptionType: Scalars['String'];
  videoLinkExpiration?: InputMaybe<VideoLinkExpirationInput>;
}>;


export type UpdateSubscriptionTypeMutation = { __typename?: 'Mutation', updateSubscriptionType: { __typename?: 'GenericError', message: string } | { __typename?: 'Organization', subscriptionType?: SubscriptionType | null, videoLinkExpiration?: any | null } };

export type UserLoginTokenActionMutationVariables = Exact<{
  email: Scalars['String'];
  password?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
  ssoProvider?: InputMaybe<SsoProviders>;
}>;


export type UserLoginTokenActionMutation = { __typename?: 'Mutation', userLoginTokenAction: { __typename?: 'GenericError', message: string } | { __typename?: 'LoginToken', token: string } };

export type ToggleAdminUserDisabledStateMutationVariables = Exact<{
  managerId: Scalars['Int'];
}>;


export type ToggleAdminUserDisabledStateMutation = { __typename?: 'Mutation', toggleAdminUserDisabledState: { __typename: 'AdminUser', id: number, disabled?: boolean | null } | { __typename: 'GenericError', message: string } };

export type UserUpgradePasswordAndRolesMutationVariables = Exact<{
  userEmail: Scalars['String'];
  oldPassword: Scalars['String'];
  newPassword: Scalars['String'];
}>;


export type UserUpgradePasswordAndRolesMutation = { __typename?: 'Mutation', userUpgradePasswordAndRole?: { __typename?: 'GenericError', message: string, code: number } | { __typename?: 'ValidationError', message: string } | null };

export type GenerateTranscriptMutationVariables = Exact<{
  videoId: Scalars['Int'];
}>;


export type GenerateTranscriptMutation = { __typename?: 'Mutation', generateTranscript: { __typename?: 'GenericError', message: string } | { __typename?: 'Transcription', id: string, status: TranscriptionStatus, subtitlesUrl?: string | null, parts: Array<{ __typename?: 'TranscriptionPart', id: string, from: number, to: number, text: string }> } };

export type UpdateTranscriptMutationVariables = Exact<{
  videoId: Scalars['Int'];
  data: Array<TranscriptionPartInput> | TranscriptionPartInput;
}>;


export type UpdateTranscriptMutation = { __typename?: 'Mutation', updateTranscript: { __typename?: 'GenericError', message: string } | { __typename?: 'Transcription', id: string, status: TranscriptionStatus, subtitlesUrl?: string | null, parts: Array<{ __typename?: 'TranscriptionPart', id: string, from: number, to: number, text: string }> } };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'Query', me?: { __typename?: 'CurrentAdminUser', id: number, email?: string | null, firstName?: string | null, lastName?: string | null, isPrimary: boolean, organization?: { __typename?: 'Organization', id: number, name?: string | null, twoFactorAuthEnabled?: boolean | null, oauthInfo?: { __typename?: 'OauthInfo', id: number, youtubeChannels?: Array<{ __typename?: 'YoutubeChannel', id?: string | null, name?: string | null }> | null, vimeoAccount?: { __typename?: 'VimeoAccount', id?: string | null, name?: string | null, canSetDownloadableSetting: boolean, privacyViewSettings: Array<VimeoPrivacyViewSettings>, privacyEmbedSettings: Array<VimeoPrivacyEmbedSettings> } | null } | null, subscription?: { __typename?: 'Subscription', status: StripeSubscriptionStatus, nextBillingDate: any, plan: { __typename?: 'Plan', amount: number, currency: string }, product: { __typename?: 'Product', name: string, images: Array<string>, checklist: Array<string> } } | null } | null } | { __typename?: 'GenericError', message: string } | { __typename?: 'SignUpData', id: number, firstName?: string | null, lastName?: string | null, email?: string | null, organizationName?: string | null, organizationPhone?: string | null } | null };

export type StorymakersQueryVariables = Exact<{
  pagination?: InputMaybe<PaginationInput>;
  filter?: InputMaybe<AppUserFilterInput>;
  sort?: InputMaybe<SortInput>;
  search?: InputMaybe<Scalars['String']>;
}>;


export type StorymakersQuery = { __typename?: 'Query', appUsers: { __typename?: 'PaginatedAppUser', data: Array<{ __typename?: 'Reviewer', id: number, email?: string | null, firstName?: string | null, lastName?: string | null, suspended?: boolean | null, createdAt: any, videoCount: number, userSignupProperties: any, organizations: Array<{ __typename?: 'Organization', id: number, name?: string | null }>, filmingGroups: Array<{ __typename?: 'FilmingGroup', id: number, name?: string | null, organizationId?: number | null }>, userCustomProperties?: { __typename?: 'customProperties', createdAt?: any | null, properties?: any | null, updatedAt?: any | null } | null } | { __typename?: 'Storymaker', id: number, email?: string | null, firstName?: string | null, lastName?: string | null, suspended?: boolean | null, createdAt: any, videoCount: number, userSignupProperties: any, organizations: Array<{ __typename?: 'Organization', id: number, name?: string | null }>, filmingGroups: Array<{ __typename?: 'FilmingGroup', id: number, name?: string | null, organizationId?: number | null }>, userCustomProperties?: { __typename?: 'customProperties', createdAt?: any | null, properties?: any | null, updatedAt?: any | null } | null }>, pagination: { __typename?: 'PaginationResult', currentPage: number, total: number } } };

export type DeprecatedStorymakersQueryVariables = Exact<{
  pagination?: InputMaybe<PaginationInput>;
  filter?: InputMaybe<StorymakerFilterInput>;
  sort?: InputMaybe<SortInput>;
  search?: InputMaybe<Scalars['String']>;
}>;


export type DeprecatedStorymakersQuery = { __typename?: 'Query', storymakers: { __typename?: 'PaginatedStorymaker', data: Array<{ __typename?: 'Storymaker', id: number, email?: string | null, firstName?: string | null, lastName?: string | null, suspended?: boolean | null, createdAt: any, videoCount: number, userSignupProperties: any, organizations: Array<{ __typename?: 'Organization', id: number, name?: string | null }>, filmingGroups: Array<{ __typename?: 'FilmingGroup', id: number, name?: string | null, organizationId?: number | null }>, userCustomProperties?: { __typename?: 'customProperties', createdAt?: any | null, properties?: any | null, updatedAt?: any | null } | null }>, pagination: { __typename?: 'PaginationResult', currentPage: number, total: number } } };

export type ReviewersQueryVariables = Exact<{
  pagination?: InputMaybe<PaginationInput>;
  filter?: InputMaybe<AppUserFilterInput>;
  sort?: InputMaybe<SortInput>;
  search?: InputMaybe<Scalars['String']>;
}>;


export type ReviewersQuery = { __typename?: 'Query', appUsers: { __typename?: 'PaginatedAppUser', data: Array<{ __typename?: 'Reviewer', id: number, email?: string | null, firstName?: string | null, lastName?: string | null, suspended?: boolean | null, createdAt: any, videoCount: number, userSignupProperties: any, storymakers?: Array<{ __typename?: 'Storymaker', id: number, email?: string | null, firstName?: string | null, lastName?: string | null, suspended?: boolean | null, createdAt: any, videoCount: number, userSignupProperties: any, filmingGroups: Array<{ __typename?: 'FilmingGroup', id: number, name?: string | null, organizationId?: number | null }>, organizations: Array<{ __typename?: 'Organization', id: number, name?: string | null }>, userCustomProperties?: { __typename?: 'customProperties', createdAt?: any | null, properties?: any | null, updatedAt?: any | null } | null }> | null, organizations: Array<{ __typename?: 'Organization', id: number, name?: string | null }>, filmingGroups: Array<{ __typename?: 'FilmingGroup', id: number, name?: string | null, organizationId?: number | null }>, userCustomProperties?: { __typename?: 'customProperties', createdAt?: any | null, properties?: any | null, updatedAt?: any | null } | null } | { __typename?: 'Storymaker', id: number, organizations: Array<{ __typename?: 'Organization', id: number, name?: string | null }>, filmingGroups: Array<{ __typename?: 'FilmingGroup', id: number, name?: string | null, organizationId?: number | null }> }>, pagination: { __typename?: 'PaginationResult', currentPage: number, total: number } } };

export type StorymakerNamesQueryVariables = Exact<{
  filter?: InputMaybe<AppUserFilterInput>;
}>;


export type StorymakerNamesQuery = { __typename?: 'Query', appUsers: { __typename?: 'PaginatedAppUser', data: Array<{ __typename?: 'Reviewer', id: number, firstName?: string | null, lastName?: string | null } | { __typename?: 'Storymaker', id: number, firstName?: string | null, lastName?: string | null }> } };

export type DeprecatedStorymakerNamesQueryVariables = Exact<{
  filter?: InputMaybe<StorymakerFilterInput>;
}>;


export type DeprecatedStorymakerNamesQuery = { __typename?: 'Query', storymakers: { __typename?: 'PaginatedStorymaker', data: Array<{ __typename?: 'Storymaker', id: number, firstName?: string | null, lastName?: string | null }> } };

export type VideosQueryVariables = Exact<{
  offset?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<VideoFilterInput>;
  sort?: InputMaybe<SortInput>;
}>;


export type VideosQuery = { __typename?: 'Query', videos: { __typename?: 'PaginatedVideo', data: Array<{ __typename?: 'Video', id: number, previewURL?: string | null, thumbnailURL?: string | null, thumbnailURLInline?: string | null, state?: string | null, shared: boolean, createdAt: any, updatedAt: any, transcription?: { __typename?: 'Transcription', id: string, status: TranscriptionStatus, subtitlesUrl?: string | null, parts: Array<{ __typename?: 'TranscriptionPart', id: string, from: number, to: number, text: string }> } | null, textAssets: Array<{ __typename?: 'TextAsset', id: string, value?: string | null }>, captureStepsList?: { __typename?: 'CaptureStepsList', id: number, name?: string | null } | null, storymaker?: { __typename?: 'Storymaker', id: number, email?: string | null, firstName?: string | null, lastName?: string | null } | null, template: { __typename?: 'Template', id: number, name?: string | null }, filmingGroup?: { __typename?: 'FilmingGroup', id: number, name?: string | null, emailTriggerSetting?: FilmingGroupEmailTriggerSetting | null } | null, organization?: { __typename?: 'Organization', id: number, name?: string | null } | null, videoSharingHistory: Array<{ __typename?: 'VideoShareEntry', createdAt: any, meta: any, proxyLinks?: any | null, shareType: ShareOptionType }>, feedbacks: Array<{ __typename?: 'VideoFeedback', id: string }> }>, pagination: { __typename?: 'PaginationResult', currentPage: number, total: number } } };

export type VideoDetailQueryVariables = Exact<{
  videoId?: InputMaybe<Scalars['Int']>;
}>;


export type VideoDetailQuery = { __typename?: 'Query', videos: { __typename?: 'PaginatedVideo', data: Array<{ __typename?: 'Video', previewURLInline?: string | null, id: number, previewURL?: string | null, thumbnailURL?: string | null, thumbnailURLInline?: string | null, state?: string | null, shared: boolean, createdAt: any, updatedAt: any, transcription?: { __typename?: 'Transcription', id: string, status: TranscriptionStatus, subtitlesUrl?: string | null, parts: Array<{ __typename?: 'TranscriptionPart', id: string, from: number, to: number, text: string }> } | null, captureStepsList?: { __typename?: 'CaptureStepsList', id: number, name?: string | null } | null, storymaker?: { __typename?: 'Storymaker', id: number, email?: string | null, firstName?: string | null, lastName?: string | null } | null, template: { __typename?: 'Template', id: number, name?: string | null }, filmingGroup?: { __typename?: 'FilmingGroup', id: number, name?: string | null, emailTriggerSetting?: FilmingGroupEmailTriggerSetting | null } | null, organization?: { __typename?: 'Organization', id: number, name?: string | null } | null, videoSharingHistory: Array<{ __typename?: 'VideoShareEntry', createdAt: any, meta: any, proxyLinks?: any | null, shareType: ShareOptionType }>, feedbacks: Array<{ __typename?: 'VideoFeedback', id: string }> }> } };

export type VideoTextAssetsQueryVariables = Exact<{
  videoId: Scalars['Int'];
}>;


export type VideoTextAssetsQuery = { __typename?: 'Query', videos: { __typename?: 'PaginatedVideo', data: Array<{ __typename?: 'Video', id: number, textAssets: Array<{ __typename?: 'TextAsset', id: string, value?: string | null, textCapture?: { __typename?: 'TextCapture', key?: string | null, characterLimit?: number | null, options?: Array<string> | null, isEditable?: boolean | null, id: number, label?: string | null, required?: boolean | null } | null }> }> } };

export type VideoCapturedAssetsQueryVariables = Exact<{
  videoId: Scalars['Int'];
}>;


export type VideoCapturedAssetsQuery = { __typename?: 'Query', videos: { __typename?: 'PaginatedVideo', data: Array<{ __typename?: 'Video', id: number, videoAssets: Array<{ __typename?: 'VideoAsset', id: string, key?: string | null, label?: string | null, url?: string | null, duration?: number | null, isOriginal?: boolean | null }>, imageAssets: Array<{ __typename?: 'ImageAsset', id: string, key?: string | null, label?: string | null, url?: string | null, isOriginal?: boolean | null }> }> } };

export type VideoFeedbackHistoryQueryVariables = Exact<{
  videoId?: InputMaybe<Scalars['Int']>;
}>;


export type VideoFeedbackHistoryQuery = { __typename?: 'Query', videos: { __typename?: 'PaginatedVideo', data: Array<{ __typename?: 'Video', feedbacks: Array<{ __typename?: 'VideoFeedback', id: string, packageStatus: string, feedback: string, createdAt: any }> }> } };

export type FilmingGroupsQueryVariables = Exact<{
  filter?: InputMaybe<FilmingGroupFilterInput>;
}>;


export type FilmingGroupsQuery = { __typename?: 'Query', filmingGroups: Array<{ __typename?: 'FilmingGroup', id: number, name?: string | null, emailTriggerSetting?: FilmingGroupEmailTriggerSetting | null, shareableFacebook?: boolean | null, shareableTwitter?: boolean | null, shareableDownload?: boolean | null, facebookTitle?: string | null, facebookDescription?: string | null, twitterDescription?: string | null, storymakersCount: number, videosCount: number, activeSignUpFormUrl?: string | null, templates: Array<{ __typename?: 'Template', id: number, name?: string | null, previewVideo?: { __typename?: 'Video', id: number, previewURL?: string | null, thumbnailURLInline?: string | null } | null }> }> };

export type FilmingGroupsWithTemplatesQueryVariables = Exact<{
  filter?: InputMaybe<FilmingGroupFilterInput>;
}>;


export type FilmingGroupsWithTemplatesQuery = { __typename?: 'Query', filmingGroups: Array<{ __typename?: 'FilmingGroup', id: number, name?: string | null, templates: Array<{ __typename?: 'Template', id: number, name?: string | null }> }> };

export type FilmingGroupNamesQueryVariables = Exact<{
  filter?: InputMaybe<FilmingGroupFilterInput>;
}>;


export type FilmingGroupNamesQuery = { __typename?: 'Query', filmingGroups: Array<{ __typename?: 'FilmingGroup', id: number, name?: string | null, organizationId?: number | null, templates: Array<{ __typename?: 'Template', id: number }> }> };

export type ProductQueryVariables = Exact<{
  productId: Scalars['String'];
}>;


export type ProductQuery = { __typename?: 'Query', products?: Array<{ __typename?: 'Product', id: string, name: string, images: Array<string>, checklist: Array<string>, trialDays?: number | null, prices: Array<{ __typename?: 'Price', id: string, interval?: StripeIntervalType | null, currency: string, amount?: number | null }> }> | null };

export type YoutubeChannelsQueryVariables = Exact<{ [key: string]: never; }>;


export type YoutubeChannelsQuery = { __typename?: 'Query', youtubeChannels?: Array<{ __typename?: 'YoutubeChannel', id?: string | null, name?: string | null }> | null };

export type StripeCustomerPortalUrlQueryVariables = Exact<{
  returnUrl: Scalars['String'];
}>;


export type StripeCustomerPortalUrlQuery = { __typename?: 'Query', stripeCustomerPortalUrl?: string | null };

export type BrandColorsQueryVariables = Exact<{ [key: string]: never; }>;


export type BrandColorsQuery = { __typename?: 'Query', me?: { __typename?: 'CurrentAdminUser', id: number, organization?: { __typename?: 'Organization', id: number, brandAsset?: { __typename?: 'BrandAsset', id: number, darkColor?: string | null, lightColor?: string | null, greyColor?: string | null } | null } | null } | { __typename?: 'GenericError' } | { __typename?: 'SignUpData' } | null };

export type BrandLogosQueryVariables = Exact<{ [key: string]: never; }>;


export type BrandLogosQuery = { __typename?: 'Query', me?: { __typename?: 'CurrentAdminUser', id: number, organization?: { __typename?: 'Organization', id: number, brandAsset?: { __typename?: 'BrandAsset', id: number, whiteLogo?: string | null, colorLogo?: string | null } | null } | null } | { __typename?: 'GenericError' } | { __typename?: 'SignUpData' } | null };

export type BrandEndCardQueryVariables = Exact<{ [key: string]: never; }>;


export type BrandEndCardQuery = { __typename?: 'Query', me?: { __typename?: 'CurrentAdminUser', id: number, organization?: { __typename?: 'Organization', id: number, brandAsset?: { __typename?: 'BrandAsset', id: number, endCard: { __typename?: 'EndCard', id: number, callToAction?: string | null, businessEmail?: string | null, businessPhone?: string | null, type?: EndCardType | null, value?: string | null } } | null } | null } | { __typename?: 'GenericError' } | { __typename?: 'SignUpData' } | null };

export type OrganizationInformationQueryVariables = Exact<{ [key: string]: never; }>;


export type OrganizationInformationQuery = { __typename?: 'Query', me?: { __typename?: 'CurrentAdminUser', id: number, organization?: { __typename?: 'Organization', id: number, name?: string | null, legalName?: string | null, phoneNumber?: string | null, privacyPolicy?: string | null, termsOfService?: string | null, videoReleasePromptEnd?: string | null, videoReleasePromptBegin?: string | null, additionalEmailRecipients?: Array<string> | null, subscriptionType?: SubscriptionType | null, videoLinkExpiration?: any | null, nowDashboardFlag?: boolean | null, twoFactorAuthEnabled?: boolean | null, adminUsers: Array<{ __typename?: 'AdminUser', id: number, email?: string | null, isMe: boolean, isPrimary: boolean, disabledEmailNotifications?: boolean | null }>, socialHandles?: { __typename?: 'SocialHandles', instagram?: string | null, facebook?: string | null, twitter?: string | null, youtube?: string | null, vimeo?: string | null } | null } | null } | { __typename?: 'GenericError' } | { __typename?: 'SignUpData' } | null };

export type AdminUsersInformationQueryVariables = Exact<{ [key: string]: never; }>;


export type AdminUsersInformationQuery = { __typename?: 'Query', me?: { __typename?: 'CurrentAdminUser', id: number, isPrimary: boolean, organization?: { __typename?: 'Organization', id: number, adminUsers: Array<{ __typename?: 'AdminUser', id: number, email?: string | null, firstName?: string | null, lastName?: string | null, cellPhone?: string | null, directPhone?: string | null, title?: string | null, isMe: boolean, role?: string | null, isPrimary: boolean, hasAcceptedInvite?: boolean | null }> } | null } | { __typename?: 'GenericError' } | { __typename?: 'SignUpData' } | null };

export type ApplicationConfigQueryVariables = Exact<{ [key: string]: never; }>;


export type ApplicationConfigQuery = { __typename?: 'Query', applicationConfig: { __typename?: 'Config', stripeMaxCouponTries: number, statusesOrder: Array<string>, integrationSources: Array<string>, userRoles: Array<{ __typename?: 'UserRole', role: string, roleName: string, isOldCMSRole: boolean }>, videoStatusTransitionMap: { __typename?: 'VideoStatusTransitionMap', approved: Array<string>, created: Array<string>, do_not_use: Array<string>, failed: Array<string>, preprocessing: Array<string>, rendered: Array<string>, rendering: Array<string>, review_requested: Array<string>, not_approved: Array<string> }, restrictedRoleStatusMap: { __typename?: 'VideoRestrictedRoleStatusMap', reviewer: Array<string>, storymaker: Array<string> }, humanizedEmailTriggerSetting: { __typename?: 'HumanizedEmailTriggerSetting', no_notifications: string, notifications_after_approval: string, notifications_upon_render: string }, subscriptionTypeDefaultEmailTriggerSetting: { __typename?: 'SubscriptionTypeDefaultEmailTriggerSetting', Complete: string, Healthcare: string, Now: string, Other: string, Quick: string, Storyvine_internal: string }, subscriptionTypeManagerRole: { __typename?: 'SubscriptionTypeManagerRole', Complete: string, Healthcare: string, Now: string, Other: string, Quick: string, Storyvine_internal: string }, defaultLinkExpirationMap: { __typename?: 'DefaultLinkExpirationMap', Complete: string, Healthcare: string, Now: string, Other: string, Quick: string, Storyvine_internal: string }, humanizedVimeoUploadArgs: { __typename?: 'HumanizedVimeoUploadArgs', view: { __typename?: 'HumanizedVimeoViewArgs', anybody: string, nobody: string, disable: string, password: string, unlisted: string }, embed: { __typename?: 'HumanizedVimeoEmbedArgs', public: string, private: string, whitelist: string } } } };

export type TrainingsQueryVariables = Exact<{
  sectionId: Scalars['String'];
}>;


export type TrainingsQuery = { __typename?: 'Query', trainings: Array<{ __typename?: 'TrainingSection', categories?: Array<{ __typename?: 'TrainingCategory', id: string, name?: string | null, trainings: Array<{ __typename?: 'Training', id: string, title?: string | null, content?: string | null, highlighted?: boolean | null }> }> | null }> };

export type TrainingSectionsQueryVariables = Exact<{ [key: string]: never; }>;


export type TrainingSectionsQuery = { __typename?: 'Query', trainingSections: Array<{ __typename?: 'TrainingSection', id: string, sidebarName?: string | null, sidebarIconUrl?: string | null, sidebarHoverIconUrl?: string | null }> };

export type TemplateNamesQueryVariables = Exact<{
  filter?: InputMaybe<TemplateFilterInput>;
  pagination?: InputMaybe<PaginationInput>;
}>;


export type TemplateNamesQuery = { __typename?: 'Query', templates: { __typename?: 'PaginatedTemplate', data: Array<{ __typename?: 'Template', id: number, name?: string | null, organizations?: Array<{ __typename?: 'Organization', id: number }> | null }> } };

export type TemplatesQueryVariables = Exact<{
  filter?: InputMaybe<TemplateFilterInput>;
  pagination?: InputMaybe<PaginationInput>;
  search?: InputMaybe<Scalars['String']>;
}>;


export type TemplatesQuery = { __typename?: 'Query', templates: { __typename?: 'PaginatedTemplate', data: Array<{ __typename?: 'Template', id: number, name?: string | null, description?: string | null, createdAt?: any | null, organizations?: Array<{ __typename?: 'Organization', id: number, name?: string | null }> | null, captureStepsLists: Array<{ __typename?: 'CaptureStepsList', id: number, name?: string | null, captureSteps?: Array<{ __typename?: 'CaptureStep', id: number, coachingBody?: string | null, coachingTitle?: string | null }> | null }>, previewVideo?: { __typename?: 'Video', id: number, previewURL?: string | null, thumbnailURL?: string | null } | null }>, pagination: { __typename?: 'PaginationResult', currentPage: number, total: number } } };

export type TemplatesAdminDetailQueryVariables = Exact<{
  filter?: InputMaybe<TemplateFilterInput>;
  pagination?: InputMaybe<PaginationInput>;
}>;


export type TemplatesAdminDetailQuery = { __typename?: 'Query', templates: { __typename?: 'PaginatedTemplate', data: Array<{ __typename?: 'Template', id: number, name?: string | null, description?: string | null, shouldSaveToCameraRoll?: boolean | null, isStorymakerAddedEmailsEnabled?: boolean | null, isReviewRequired?: boolean | null, organizations?: Array<{ __typename?: 'Organization', id: number, name?: string | null }> | null, previewVideo?: { __typename?: 'Video', id: number, previewURL?: string | null, thumbnailURL?: string | null } | null, captureStepsLists: Array<{ __typename?: 'CaptureStepsList', id: number, name?: string | null, createdAt?: any | null, captureSteps?: Array<{ __typename?: 'CaptureStep', id: number, coachingBody?: string | null, coachingTitle?: string | null, coachingPrompt?: string | null }> | null }>, textCaptures: Array<{ __typename?: 'TextCapture', characterLimit?: number | null, options?: Array<string> | null, isEditable?: boolean | null, id: number, label?: string | null, required?: boolean | null }> }> } };

export type FilmingGroupSignUpFormLinksQueryVariables = Exact<{
  filter?: InputMaybe<FilmingGroupFilterInput>;
}>;


export type FilmingGroupSignUpFormLinksQuery = { __typename?: 'Query', filmingGroups: Array<{ __typename?: 'FilmingGroup', id: number, name?: string | null, activeSignUpFormUrl?: string | null }> };

export type OrganizationsQueryVariables = Exact<{
  filter?: InputMaybe<OrganizationFilterInput>;
  pagination?: InputMaybe<PaginationInput>;
  search?: InputMaybe<Scalars['String']>;
}>;


export type OrganizationsQuery = { __typename?: 'Query', organizations: { __typename?: 'PaginatedOrganization', data: Array<{ __typename?: 'Organization', id: number, name?: string | null, subscriptionType?: SubscriptionType | null, videoCount?: number | null, storymakerCount?: number | null, nowDashboardFlag?: boolean | null }>, pagination: { __typename?: 'PaginationResult', currentPage: number, total: number } } };

export type OrganizationDetailQueryVariables = Exact<{
  filter?: InputMaybe<OrganizationFilterInput>;
}>;


export type OrganizationDetailQuery = { __typename?: 'Query', organizations: { __typename?: 'PaginatedOrganization', data: Array<{ __typename?: 'Organization', id: number, name?: string | null, legalName?: string | null, phoneNumber?: string | null, privacyPolicy?: string | null, termsOfService?: string | null, videoReleasePromptEnd?: string | null, videoReleasePromptBegin?: string | null, additionalEmailRecipients?: Array<string> | null, subscriptionType?: SubscriptionType | null, videoLinkExpiration?: any | null, nowDashboardFlag?: boolean | null, twoFactorAuthEnabled?: boolean | null, adminUsers: Array<{ __typename?: 'AdminUser', id: number, email?: string | null, isMe: boolean, isPrimary: boolean, disabledEmailNotifications?: boolean | null }>, socialHandles?: { __typename?: 'SocialHandles', instagram?: string | null, facebook?: string | null, twitter?: string | null, youtube?: string | null, vimeo?: string | null } | null }>, pagination: { __typename?: 'PaginationResult', currentPage: number, total: number } } };

export type OrganizationSubscriptionTypeQueryVariables = Exact<{
  filter?: InputMaybe<OrganizationFilterInput>;
}>;


export type OrganizationSubscriptionTypeQuery = { __typename?: 'Query', organizations: { __typename?: 'PaginatedOrganization', data: Array<{ __typename?: 'Organization', id: number, subscriptionType?: SubscriptionType | null }> } };

export type OrganizationNamesAndSubscriptionTypesQueryVariables = Exact<{
  filter?: InputMaybe<OrganizationFilterInput>;
  pagination?: InputMaybe<PaginationInput>;
  search?: InputMaybe<Scalars['String']>;
}>;


export type OrganizationNamesAndSubscriptionTypesQuery = { __typename?: 'Query', organizations: { __typename?: 'PaginatedOrganization', data: Array<{ __typename?: 'Organization', id: number, name?: string | null, subscriptionType?: SubscriptionType | null }>, pagination: { __typename?: 'PaginationResult', currentPage: number, total: number } } };

export type AdminUsersQueryVariables = Exact<{
  offset?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  filter?: InputMaybe<AdminUserFilterInput>;
  search?: InputMaybe<Scalars['String']>;
}>;


export type AdminUsersQuery = { __typename?: 'Query', adminUsers: { __typename?: 'PaginatedAdminUser', data: Array<{ __typename?: 'AdminUser', isMe: boolean, id: number, email?: string | null, firstName?: string | null, lastName?: string | null, roleName?: string | null, roleVersion?: number | null, role?: string | null, createdAt?: any | null, updatedAt?: any | null, hasAcceptedInvite?: boolean | null, disabled?: boolean | null, isPrimary: boolean, signInCount?: number | null, lastSignInAt?: any | null, organization?: { __typename?: 'Organization', id: number, name?: string | null } | null }>, pagination: { __typename?: 'PaginationResult', currentPage: number, total: number } } };

export type AdminUserDetailQueryVariables = Exact<{
  filter?: InputMaybe<AdminUserFilterInput>;
}>;


export type AdminUserDetailQuery = { __typename?: 'Query', adminUsers: { __typename?: 'PaginatedAdminUser', data: Array<{ __typename?: 'AdminUser', isMe: boolean, isPrimary: boolean, title?: string | null, cellPhone?: string | null, directPhone?: string | null, has2fa?: boolean | null, id: number, email?: string | null, firstName?: string | null, lastName?: string | null, roleName?: string | null, roleVersion?: number | null, role?: string | null, createdAt?: any | null, updatedAt?: any | null, hasAcceptedInvite?: boolean | null, disabled?: boolean | null, signInCount?: number | null, lastSignInAt?: any | null, organization?: { __typename?: 'Organization', id: number, name?: string | null, subscriptionType?: SubscriptionType | null } | null }>, pagination: { __typename?: 'PaginationResult', currentPage: number, total: number } } };

export type StorymakerDetailQueryVariables = Exact<{
  filter?: InputMaybe<AppUserFilterInput>;
}>;


export type StorymakerDetailQuery = { __typename?: 'Query', appUsers: { __typename?: 'PaginatedAppUser', data: Array<{ __typename?: 'Reviewer', id: number, email?: string | null, firstName?: string | null, lastName?: string | null, suspended?: boolean | null, createdAt: any, videoCount: number, userSignupProperties: any, organizations: Array<{ __typename?: 'Organization', id: number, name?: string | null, subscriptionType?: SubscriptionType | null }>, filmingGroups: Array<{ __typename?: 'FilmingGroup', id: number, name?: string | null, organizationId?: number | null }>, userCustomProperties?: { __typename?: 'customProperties', createdAt?: any | null, properties?: any | null, updatedAt?: any | null } | null } | { __typename?: 'Storymaker', id: number, email?: string | null, firstName?: string | null, lastName?: string | null, suspended?: boolean | null, createdAt: any, videoCount: number, userSignupProperties: any, organizations: Array<{ __typename?: 'Organization', id: number, name?: string | null, subscriptionType?: SubscriptionType | null }>, filmingGroups: Array<{ __typename?: 'FilmingGroup', id: number, name?: string | null, organizationId?: number | null }>, userCustomProperties?: { __typename?: 'customProperties', createdAt?: any | null, properties?: any | null, updatedAt?: any | null } | null }>, pagination: { __typename?: 'PaginationResult', currentPage: number, total: number } } };

export type DeprecatedStorymakerDetailQueryVariables = Exact<{
  filter?: InputMaybe<StorymakerFilterInput>;
}>;


export type DeprecatedStorymakerDetailQuery = { __typename?: 'Query', storymakers: { __typename?: 'PaginatedStorymaker', data: Array<{ __typename?: 'Storymaker', id: number, email?: string | null, firstName?: string | null, lastName?: string | null, suspended?: boolean | null, createdAt: any, videoCount: number, userSignupProperties: any, organizations: Array<{ __typename?: 'Organization', id: number, name?: string | null, subscriptionType?: SubscriptionType | null }>, filmingGroups: Array<{ __typename?: 'FilmingGroup', id: number, name?: string | null, organizationId?: number | null }>, userCustomProperties?: { __typename?: 'customProperties', createdAt?: any | null, properties?: any | null, updatedAt?: any | null } | null }>, pagination: { __typename?: 'PaginationResult', currentPage: number, total: number } } };

export type ApplicationGlobalFeatureFlagsQueryVariables = Exact<{ [key: string]: never; }>;


export type ApplicationGlobalFeatureFlagsQuery = { __typename?: 'Query', globalFeatureFlags: Array<{ __typename?: 'FeatureFlag', id: string, flag: string, type: FeatureFlagType }> };

export type FeatureFlagGroupsQueryVariables = Exact<{ [key: string]: never; }>;


export type FeatureFlagGroupsQuery = { __typename?: 'Query', featureFlagGroups: Array<{ __typename?: 'FeatureFlagGroup', group: string, flags: Array<string> }> };

export type GetUserFromInvitationTokenQueryVariables = Exact<{
  confirmationToken: Scalars['String'];
}>;


export type GetUserFromInvitationTokenQuery = { __typename?: 'Query', getUserFromInvitationToken: { __typename?: 'AdminUser', email?: string | null } | { __typename?: 'GenericError', message: string } };

export const PaginationFragmentFragmentDoc = gql`
    fragment paginationFragment on PaginationResult {
  currentPage
  total
}
    `;
export const OrganizationTableInfoFragmentDoc = gql`
    fragment organizationTableInfo on Organization {
  id
  name
  subscriptionType
  videoCount
  storymakerCount
  nowDashboardFlag
}
    `;
export const OrganizationDetailInfoFragmentDoc = gql`
    fragment organizationDetailInfo on Organization {
  id
  name
  legalName
  phoneNumber
  privacyPolicy
  termsOfService
  videoReleasePromptEnd
  videoReleasePromptBegin
  additionalEmailRecipients
  subscriptionType
  videoLinkExpiration
  nowDashboardFlag
  twoFactorAuthEnabled
  adminUsers {
    id
    email
    isMe
    isPrimary
    disabledEmailNotifications
  }
  socialHandles {
    instagram
    facebook
    twitter
    youtube
    vimeo
  }
}
    `;
export const AdminUserDetailInfoFragmentDoc = gql`
    fragment adminUserDetailInfo on AdminUser {
  id
  email
  firstName
  lastName
  roleName
  roleVersion
  role
  createdAt
  updatedAt
  hasAcceptedInvite
  disabled
  isPrimary
  roleVersion
  signInCount
  lastSignInAt
}
    `;
export const CustomPropertiesFragmentFragmentDoc = gql`
    fragment customPropertiesFragment on customProperties {
  createdAt
  properties
  updatedAt
}
    `;
export const StorymakerDetailInfoFragmentDoc = gql`
    fragment storymakerDetailInfo on Storymaker {
  id
  email
  firstName
  lastName
  suspended
  createdAt
  videoCount
  userSignupProperties
  userCustomProperties {
    ...customPropertiesFragment
  }
}
    ${CustomPropertiesFragmentFragmentDoc}`;
export const ReviewerDetailInfoFragmentDoc = gql`
    fragment reviewerDetailInfo on Reviewer {
  id
  email
  firstName
  lastName
  suspended
  createdAt
  videoCount
  userSignupProperties
  userCustomProperties {
    ...customPropertiesFragment
  }
}
    ${CustomPropertiesFragmentFragmentDoc}`;
export const TextCaptureLabelFragmentFragmentDoc = gql`
    fragment textCaptureLabelFragment on TextCapture {
  id
  label
  required
}
    `;
export const CaptureStepFragmentFragmentDoc = gql`
    fragment captureStepFragment on CaptureStep {
  id
  coachingBody
  coachingTitle
  coachingPrompt
}
    `;
export const TranscriptionPartFragmentFragmentDoc = gql`
    fragment transcriptionPartFragment on Transcription {
  id
  parts {
    id
    from
    to
    text
  }
  status
  subtitlesUrl
}
    `;
export const VideoPropsFragmentDoc = gql`
    fragment VideoProps on Video {
  id
  previewURL
  thumbnailURL
  thumbnailURLInline
  captureStepsList {
    id
    name
  }
  storymaker {
    id
    email
    firstName
    lastName
  }
  state
  template {
    id
    name
  }
  filmingGroup {
    id
    name
    emailTriggerSetting
  }
  organization {
    id
    name
  }
  shared
  videoSharingHistory {
    createdAt
    meta
    proxyLinks
    shareType
  }
  feedbacks {
    id
  }
  createdAt
  updatedAt
}
    `;
export const FilmingGroupsFragmentFragmentDoc = gql`
    fragment filmingGroupsFragment on FilmingGroup {
  id
  name
  emailTriggerSetting
  shareableFacebook
  shareableTwitter
  shareableDownload
  facebookTitle
  facebookDescription
  twitterDescription
  storymakersCount
  videosCount
  activeSignUpFormUrl
  templates {
    id
    name
    previewVideo {
      id
      previewURL
      thumbnailURLInline
    }
  }
}
    `;
export const OauthInfoYoutubeFragmentFragmentDoc = gql`
    fragment oauthInfoYoutubeFragment on OauthInfo {
  id
  youtubeChannels {
    id
    name
  }
}
    `;
export const OauthInfoVimeoFragmentFragmentDoc = gql`
    fragment oauthInfoVimeoFragment on OauthInfo {
  id
  vimeoAccount {
    id
    name
    canSetDownloadableSetting
    privacyViewSettings
    privacyEmbedSettings
  }
}
    `;
export const OauthInfoFragmentFragmentDoc = gql`
    fragment oauthInfoFragment on OauthInfo {
  ...oauthInfoYoutubeFragment
  ...oauthInfoVimeoFragment
}
    ${OauthInfoYoutubeFragmentFragmentDoc}
${OauthInfoVimeoFragmentFragmentDoc}`;
export const AddFirebaseUidToUserDocument = gql`
    mutation AddFirebaseUidToUser($input: AddFirebaseUidToUserInput!) {
  addFirebaseUidToUser(input: $input) {
    ... on AddFirebaseUidToUser {
      id
    }
    ... on GenericError {
      message
    }
  }
}
    `;
export type AddFirebaseUidToUserMutationFn = Apollo.MutationFunction<AddFirebaseUidToUserMutation, AddFirebaseUidToUserMutationVariables>;

/**
 * __useAddFirebaseUidToUserMutation__
 *
 * To run a mutation, you first call `useAddFirebaseUidToUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddFirebaseUidToUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addFirebaseUidToUserMutation, { data, loading, error }] = useAddFirebaseUidToUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddFirebaseUidToUserMutation(baseOptions?: Apollo.MutationHookOptions<AddFirebaseUidToUserMutation, AddFirebaseUidToUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddFirebaseUidToUserMutation, AddFirebaseUidToUserMutationVariables>(AddFirebaseUidToUserDocument, options);
      }
export type AddFirebaseUidToUserMutationHookResult = ReturnType<typeof useAddFirebaseUidToUserMutation>;
export type AddFirebaseUidToUserMutationResult = Apollo.MutationResult<AddFirebaseUidToUserMutation>;
export type AddFirebaseUidToUserMutationOptions = Apollo.BaseMutationOptions<AddFirebaseUidToUserMutation, AddFirebaseUidToUserMutationVariables>;
export const LoginActionDocument = gql`
    mutation LoginAction($input: UserLoginActionInput!) {
  userLoginAction(input: $input) {
    ... on LoginAction {
      action
    }
    ... on GenericError {
      code
      message
    }
  }
}
    `;
export type LoginActionMutationFn = Apollo.MutationFunction<LoginActionMutation, LoginActionMutationVariables>;

/**
 * __useLoginActionMutation__
 *
 * To run a mutation, you first call `useLoginActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginActionMutation, { data, loading, error }] = useLoginActionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginActionMutation(baseOptions?: Apollo.MutationHookOptions<LoginActionMutation, LoginActionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginActionMutation, LoginActionMutationVariables>(LoginActionDocument, options);
      }
export type LoginActionMutationHookResult = ReturnType<typeof useLoginActionMutation>;
export type LoginActionMutationResult = Apollo.MutationResult<LoginActionMutation>;
export type LoginActionMutationOptions = Apollo.BaseMutationOptions<LoginActionMutation, LoginActionMutationVariables>;
export const AfterLoginActionDocument = gql`
    mutation AfterLoginAction($input: AfterUserLoginActionInput!) {
  afterLoginUserAction(input: $input) {
    ... on AfterLoginAction {
      id
    }
    ... on GenericError {
      message
    }
  }
}
    `;
export type AfterLoginActionMutationFn = Apollo.MutationFunction<AfterLoginActionMutation, AfterLoginActionMutationVariables>;

/**
 * __useAfterLoginActionMutation__
 *
 * To run a mutation, you first call `useAfterLoginActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAfterLoginActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [afterLoginActionMutation, { data, loading, error }] = useAfterLoginActionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAfterLoginActionMutation(baseOptions?: Apollo.MutationHookOptions<AfterLoginActionMutation, AfterLoginActionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AfterLoginActionMutation, AfterLoginActionMutationVariables>(AfterLoginActionDocument, options);
      }
export type AfterLoginActionMutationHookResult = ReturnType<typeof useAfterLoginActionMutation>;
export type AfterLoginActionMutationResult = Apollo.MutationResult<AfterLoginActionMutation>;
export type AfterLoginActionMutationOptions = Apollo.BaseMutationOptions<AfterLoginActionMutation, AfterLoginActionMutationVariables>;
export const AcceptInvitationDocument = gql`
    mutation AcceptInvitation($confirmationToken: String!, $isSSOUser: Boolean! = false, $password: String) {
  acceptInvitation(
    confirmationToken: $confirmationToken
    isSSOUser: $isSSOUser
    password: $password
  ) {
    ... on AcceptInvitationResult {
      email
    }
    ... on GenericError {
      message
    }
  }
}
    `;
export type AcceptInvitationMutationFn = Apollo.MutationFunction<AcceptInvitationMutation, AcceptInvitationMutationVariables>;

/**
 * __useAcceptInvitationMutation__
 *
 * To run a mutation, you first call `useAcceptInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptInvitationMutation, { data, loading, error }] = useAcceptInvitationMutation({
 *   variables: {
 *      confirmationToken: // value for 'confirmationToken'
 *      isSSOUser: // value for 'isSSOUser'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useAcceptInvitationMutation(baseOptions?: Apollo.MutationHookOptions<AcceptInvitationMutation, AcceptInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AcceptInvitationMutation, AcceptInvitationMutationVariables>(AcceptInvitationDocument, options);
      }
export type AcceptInvitationMutationHookResult = ReturnType<typeof useAcceptInvitationMutation>;
export type AcceptInvitationMutationResult = Apollo.MutationResult<AcceptInvitationMutation>;
export type AcceptInvitationMutationOptions = Apollo.BaseMutationOptions<AcceptInvitationMutation, AcceptInvitationMutationVariables>;
export const SignUpManagerDocument = gql`
    mutation SignUpManager($managerInput: SignUpManagerInput!, $productId: String!, $integrationSource: IntegrationSourceTypes) {
  signUpManager(
    input: $managerInput
    selectedProduct: $productId
    integrationSource: $integrationSource
  ) {
    ... on ValidationError {
      fields
      message
    }
    ... on GenericError {
      message
    }
    ... on SignUpData {
      id
      firstName
      lastName
      email
      organizationName
      organizationPhone
    }
  }
}
    `;
export type SignUpManagerMutationFn = Apollo.MutationFunction<SignUpManagerMutation, SignUpManagerMutationVariables>;

/**
 * __useSignUpManagerMutation__
 *
 * To run a mutation, you first call `useSignUpManagerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpManagerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpManagerMutation, { data, loading, error }] = useSignUpManagerMutation({
 *   variables: {
 *      managerInput: // value for 'managerInput'
 *      productId: // value for 'productId'
 *      integrationSource: // value for 'integrationSource'
 *   },
 * });
 */
export function useSignUpManagerMutation(baseOptions?: Apollo.MutationHookOptions<SignUpManagerMutation, SignUpManagerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignUpManagerMutation, SignUpManagerMutationVariables>(SignUpManagerDocument, options);
      }
export type SignUpManagerMutationHookResult = ReturnType<typeof useSignUpManagerMutation>;
export type SignUpManagerMutationResult = Apollo.MutationResult<SignUpManagerMutation>;
export type SignUpManagerMutationOptions = Apollo.BaseMutationOptions<SignUpManagerMutation, SignUpManagerMutationVariables>;
export const RebuildVideoDocument = gql`
    mutation RebuildVideo($videoId: Int!) {
  rebuildVideo(videoId: $videoId) {
    ... on Video {
      id
      state
    }
    ... on GenericError {
      message
    }
  }
}
    `;
export type RebuildVideoMutationFn = Apollo.MutationFunction<RebuildVideoMutation, RebuildVideoMutationVariables>;

/**
 * __useRebuildVideoMutation__
 *
 * To run a mutation, you first call `useRebuildVideoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRebuildVideoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rebuildVideoMutation, { data, loading, error }] = useRebuildVideoMutation({
 *   variables: {
 *      videoId: // value for 'videoId'
 *   },
 * });
 */
export function useRebuildVideoMutation(baseOptions?: Apollo.MutationHookOptions<RebuildVideoMutation, RebuildVideoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RebuildVideoMutation, RebuildVideoMutationVariables>(RebuildVideoDocument, options);
      }
export type RebuildVideoMutationHookResult = ReturnType<typeof useRebuildVideoMutation>;
export type RebuildVideoMutationResult = Apollo.MutationResult<RebuildVideoMutation>;
export type RebuildVideoMutationOptions = Apollo.BaseMutationOptions<RebuildVideoMutation, RebuildVideoMutationVariables>;
export const UpdateVideoTextAssetsDocument = gql`
    mutation UpdateVideoTextAssets($videoId: Int!, $data: [UpdateVideoTextAssetsInput!]!) {
  updateVideoTextAssets(videoId: $videoId, data: $data) {
    ... on Video {
      id
      textAssets {
        id
        value
      }
    }
    ... on GenericError {
      message
    }
  }
}
    `;
export type UpdateVideoTextAssetsMutationFn = Apollo.MutationFunction<UpdateVideoTextAssetsMutation, UpdateVideoTextAssetsMutationVariables>;

/**
 * __useUpdateVideoTextAssetsMutation__
 *
 * To run a mutation, you first call `useUpdateVideoTextAssetsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVideoTextAssetsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVideoTextAssetsMutation, { data, loading, error }] = useUpdateVideoTextAssetsMutation({
 *   variables: {
 *      videoId: // value for 'videoId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateVideoTextAssetsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateVideoTextAssetsMutation, UpdateVideoTextAssetsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateVideoTextAssetsMutation, UpdateVideoTextAssetsMutationVariables>(UpdateVideoTextAssetsDocument, options);
      }
export type UpdateVideoTextAssetsMutationHookResult = ReturnType<typeof useUpdateVideoTextAssetsMutation>;
export type UpdateVideoTextAssetsMutationResult = Apollo.MutationResult<UpdateVideoTextAssetsMutation>;
export type UpdateVideoTextAssetsMutationOptions = Apollo.BaseMutationOptions<UpdateVideoTextAssetsMutation, UpdateVideoTextAssetsMutationVariables>;
export const InviteStorymakersDocument = gql`
    mutation InviteStorymakers($storymakers: [InviteStorymakerInput!]!, $filmingGroupIds: [Int!]!, $organizationId: Int, $sendNotificationEmail: Boolean) {
  inviteStorymakers(
    storymakers: $storymakers
    filmingGroupIds: $filmingGroupIds
    organizationId: $organizationId
    sendNotificationEmail: $sendNotificationEmail
  ) {
    ... on Storymaker {
      id
      firstName
      lastName
      email
    }
    ... on GenericError {
      code
      message
    }
  }
}
    `;
export type InviteStorymakersMutationFn = Apollo.MutationFunction<InviteStorymakersMutation, InviteStorymakersMutationVariables>;

/**
 * __useInviteStorymakersMutation__
 *
 * To run a mutation, you first call `useInviteStorymakersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteStorymakersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteStorymakersMutation, { data, loading, error }] = useInviteStorymakersMutation({
 *   variables: {
 *      storymakers: // value for 'storymakers'
 *      filmingGroupIds: // value for 'filmingGroupIds'
 *      organizationId: // value for 'organizationId'
 *      sendNotificationEmail: // value for 'sendNotificationEmail'
 *   },
 * });
 */
export function useInviteStorymakersMutation(baseOptions?: Apollo.MutationHookOptions<InviteStorymakersMutation, InviteStorymakersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InviteStorymakersMutation, InviteStorymakersMutationVariables>(InviteStorymakersDocument, options);
      }
export type InviteStorymakersMutationHookResult = ReturnType<typeof useInviteStorymakersMutation>;
export type InviteStorymakersMutationResult = Apollo.MutationResult<InviteStorymakersMutation>;
export type InviteStorymakersMutationOptions = Apollo.BaseMutationOptions<InviteStorymakersMutation, InviteStorymakersMutationVariables>;
export const InviteStorymakersWithReviwersDocument = gql`
    mutation InviteStorymakersWithReviwers($storymakers: [InviteStorymakerAndReviewersInput!]!, $filmingGroupIds: [Int!]!, $organizationId: Int, $connectReviewers: Boolean, $employeeIdentifiedBy: String, $connectionWithReviewerExpressedBy: String, $sendNotificationEmail: Boolean, $featureFlags: [ApplicationFeatureFlags!]) {
  inviteStorymakersWithReviewers(
    appUsers: $storymakers
    filmingGroupIds: $filmingGroupIds
    organizationId: $organizationId
    connectReviewers: $connectReviewers
    employeeIdentifiedBy: $employeeIdentifiedBy
    connectionWithReviewerExpressedBy: $connectionWithReviewerExpressedBy
    sendNotificationEmail: $sendNotificationEmail
    featureFlags: $featureFlags
  ) {
    ... on Storymaker {
      id
      firstName
      lastName
      email
    }
    ... on Reviewer {
      id
      firstName
      lastName
      email
    }
    ... on APIError {
      fieldName
      messages
    }
    ... on GenericError {
      code
      message
    }
  }
}
    `;
export type InviteStorymakersWithReviwersMutationFn = Apollo.MutationFunction<InviteStorymakersWithReviwersMutation, InviteStorymakersWithReviwersMutationVariables>;

/**
 * __useInviteStorymakersWithReviwersMutation__
 *
 * To run a mutation, you first call `useInviteStorymakersWithReviwersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteStorymakersWithReviwersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteStorymakersWithReviwersMutation, { data, loading, error }] = useInviteStorymakersWithReviwersMutation({
 *   variables: {
 *      storymakers: // value for 'storymakers'
 *      filmingGroupIds: // value for 'filmingGroupIds'
 *      organizationId: // value for 'organizationId'
 *      connectReviewers: // value for 'connectReviewers'
 *      employeeIdentifiedBy: // value for 'employeeIdentifiedBy'
 *      connectionWithReviewerExpressedBy: // value for 'connectionWithReviewerExpressedBy'
 *      sendNotificationEmail: // value for 'sendNotificationEmail'
 *      featureFlags: // value for 'featureFlags'
 *   },
 * });
 */
export function useInviteStorymakersWithReviwersMutation(baseOptions?: Apollo.MutationHookOptions<InviteStorymakersWithReviwersMutation, InviteStorymakersWithReviwersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InviteStorymakersWithReviwersMutation, InviteStorymakersWithReviwersMutationVariables>(InviteStorymakersWithReviwersDocument, options);
      }
export type InviteStorymakersWithReviwersMutationHookResult = ReturnType<typeof useInviteStorymakersWithReviwersMutation>;
export type InviteStorymakersWithReviwersMutationResult = Apollo.MutationResult<InviteStorymakersWithReviwersMutation>;
export type InviteStorymakersWithReviwersMutationOptions = Apollo.BaseMutationOptions<InviteStorymakersWithReviwersMutation, InviteStorymakersWithReviwersMutationVariables>;
export const UploadVideoAssetDocument = gql`
    mutation UploadVideoAsset($videoId: Int!, $assetKey: String!, $file: Upload!) {
  uploadAsset(videoId: $videoId, assetKey: $assetKey, file: $file) {
    __typename
    ... on GenericError {
      code
      message
    }
    ... on ValidationError {
      code
      message
    }
    ... on UploadAssetResponse {
      url
    }
  }
}
    `;
export type UploadVideoAssetMutationFn = Apollo.MutationFunction<UploadVideoAssetMutation, UploadVideoAssetMutationVariables>;

/**
 * __useUploadVideoAssetMutation__
 *
 * To run a mutation, you first call `useUploadVideoAssetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadVideoAssetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadVideoAssetMutation, { data, loading, error }] = useUploadVideoAssetMutation({
 *   variables: {
 *      videoId: // value for 'videoId'
 *      assetKey: // value for 'assetKey'
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUploadVideoAssetMutation(baseOptions?: Apollo.MutationHookOptions<UploadVideoAssetMutation, UploadVideoAssetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadVideoAssetMutation, UploadVideoAssetMutationVariables>(UploadVideoAssetDocument, options);
      }
export type UploadVideoAssetMutationHookResult = ReturnType<typeof useUploadVideoAssetMutation>;
export type UploadVideoAssetMutationResult = Apollo.MutationResult<UploadVideoAssetMutation>;
export type UploadVideoAssetMutationOptions = Apollo.BaseMutationOptions<UploadVideoAssetMutation, UploadVideoAssetMutationVariables>;
export const CreatePresignedRequestForVideoAssetUploadDocument = gql`
    mutation CreatePresignedRequestForVideoAssetUpload($videoId: Int!, $assetKey: String!, $fileName: String!) {
  createPresignedRequestForVideoAssetUpload(
    videoId: $videoId
    assetKey: $assetKey
    fileName: $fileName
  ) {
    __typename
    ... on GenericError {
      message
    }
    ... on ValidationError {
      message
    }
    ... on PresignedRequest {
      url
      fields
    }
  }
}
    `;
export type CreatePresignedRequestForVideoAssetUploadMutationFn = Apollo.MutationFunction<CreatePresignedRequestForVideoAssetUploadMutation, CreatePresignedRequestForVideoAssetUploadMutationVariables>;

/**
 * __useCreatePresignedRequestForVideoAssetUploadMutation__
 *
 * To run a mutation, you first call `useCreatePresignedRequestForVideoAssetUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePresignedRequestForVideoAssetUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPresignedRequestForVideoAssetUploadMutation, { data, loading, error }] = useCreatePresignedRequestForVideoAssetUploadMutation({
 *   variables: {
 *      videoId: // value for 'videoId'
 *      assetKey: // value for 'assetKey'
 *      fileName: // value for 'fileName'
 *   },
 * });
 */
export function useCreatePresignedRequestForVideoAssetUploadMutation(baseOptions?: Apollo.MutationHookOptions<CreatePresignedRequestForVideoAssetUploadMutation, CreatePresignedRequestForVideoAssetUploadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePresignedRequestForVideoAssetUploadMutation, CreatePresignedRequestForVideoAssetUploadMutationVariables>(CreatePresignedRequestForVideoAssetUploadDocument, options);
      }
export type CreatePresignedRequestForVideoAssetUploadMutationHookResult = ReturnType<typeof useCreatePresignedRequestForVideoAssetUploadMutation>;
export type CreatePresignedRequestForVideoAssetUploadMutationResult = Apollo.MutationResult<CreatePresignedRequestForVideoAssetUploadMutation>;
export type CreatePresignedRequestForVideoAssetUploadMutationOptions = Apollo.BaseMutationOptions<CreatePresignedRequestForVideoAssetUploadMutation, CreatePresignedRequestForVideoAssetUploadMutationVariables>;
export const UploadVideoAssetSuccessDocument = gql`
    mutation UploadVideoAssetSuccess($videoId: Int!, $assetKey: String!, $assetUrl: String!) {
  uploadVideoAssetSuccess(
    videoId: $videoId
    assetKey: $assetKey
    assetUrl: $assetUrl
  ) {
    __typename
    ... on GenericError {
      message
    }
    ... on ValidationError {
      message
    }
  }
}
    `;
export type UploadVideoAssetSuccessMutationFn = Apollo.MutationFunction<UploadVideoAssetSuccessMutation, UploadVideoAssetSuccessMutationVariables>;

/**
 * __useUploadVideoAssetSuccessMutation__
 *
 * To run a mutation, you first call `useUploadVideoAssetSuccessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadVideoAssetSuccessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadVideoAssetSuccessMutation, { data, loading, error }] = useUploadVideoAssetSuccessMutation({
 *   variables: {
 *      videoId: // value for 'videoId'
 *      assetKey: // value for 'assetKey'
 *      assetUrl: // value for 'assetUrl'
 *   },
 * });
 */
export function useUploadVideoAssetSuccessMutation(baseOptions?: Apollo.MutationHookOptions<UploadVideoAssetSuccessMutation, UploadVideoAssetSuccessMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadVideoAssetSuccessMutation, UploadVideoAssetSuccessMutationVariables>(UploadVideoAssetSuccessDocument, options);
      }
export type UploadVideoAssetSuccessMutationHookResult = ReturnType<typeof useUploadVideoAssetSuccessMutation>;
export type UploadVideoAssetSuccessMutationResult = Apollo.MutationResult<UploadVideoAssetSuccessMutation>;
export type UploadVideoAssetSuccessMutationOptions = Apollo.BaseMutationOptions<UploadVideoAssetSuccessMutation, UploadVideoAssetSuccessMutationVariables>;
export const UpdateVideoAssetsDocument = gql`
    mutation UpdateVideoAssets($videoId: Int!, $links: [AssetLink!]!) {
  updateVideoAssets(videoId: $videoId, links: $links) {
    __typename
    ... on Video {
      id
      imageAssets {
        id
        url
        isOriginal
      }
      videoAssets {
        id
        url
        duration
        isOriginal
      }
    }
    ... on GenericError {
      message
    }
    ... on APIError {
      fieldName
      messages
    }
  }
}
    `;
export type UpdateVideoAssetsMutationFn = Apollo.MutationFunction<UpdateVideoAssetsMutation, UpdateVideoAssetsMutationVariables>;

/**
 * __useUpdateVideoAssetsMutation__
 *
 * To run a mutation, you first call `useUpdateVideoAssetsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVideoAssetsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVideoAssetsMutation, { data, loading, error }] = useUpdateVideoAssetsMutation({
 *   variables: {
 *      videoId: // value for 'videoId'
 *      links: // value for 'links'
 *   },
 * });
 */
export function useUpdateVideoAssetsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateVideoAssetsMutation, UpdateVideoAssetsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateVideoAssetsMutation, UpdateVideoAssetsMutationVariables>(UpdateVideoAssetsDocument, options);
      }
export type UpdateVideoAssetsMutationHookResult = ReturnType<typeof useUpdateVideoAssetsMutation>;
export type UpdateVideoAssetsMutationResult = Apollo.MutationResult<UpdateVideoAssetsMutation>;
export type UpdateVideoAssetsMutationOptions = Apollo.BaseMutationOptions<UpdateVideoAssetsMutation, UpdateVideoAssetsMutationVariables>;
export const RestoreOriginalDocument = gql`
    mutation RestoreOriginal($videoId: Int!, $assetKey: String!) {
  restoreOriginalAsset(videoId: $videoId, assetKey: $assetKey) {
    __typename
    ... on VideoAsset {
      id
      url
      isOriginal
      duration
    }
    ... on ImageAsset {
      id
      url
      isOriginal
    }
    ... on GenericError {
      message
    }
    ... on ValidationError {
      message
    }
  }
}
    `;
export type RestoreOriginalMutationFn = Apollo.MutationFunction<RestoreOriginalMutation, RestoreOriginalMutationVariables>;

/**
 * __useRestoreOriginalMutation__
 *
 * To run a mutation, you first call `useRestoreOriginalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreOriginalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreOriginalMutation, { data, loading, error }] = useRestoreOriginalMutation({
 *   variables: {
 *      videoId: // value for 'videoId'
 *      assetKey: // value for 'assetKey'
 *   },
 * });
 */
export function useRestoreOriginalMutation(baseOptions?: Apollo.MutationHookOptions<RestoreOriginalMutation, RestoreOriginalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RestoreOriginalMutation, RestoreOriginalMutationVariables>(RestoreOriginalDocument, options);
      }
export type RestoreOriginalMutationHookResult = ReturnType<typeof useRestoreOriginalMutation>;
export type RestoreOriginalMutationResult = Apollo.MutationResult<RestoreOriginalMutation>;
export type RestoreOriginalMutationOptions = Apollo.BaseMutationOptions<RestoreOriginalMutation, RestoreOriginalMutationVariables>;
export const StoreSubscriptionDocument = gql`
    mutation StoreSubscription($input: StoreSubscriptionInput!, $selectedProduct: String!) {
  storeSubscription(input: $input, selectedProduct: $selectedProduct) {
    ... on SignUpData {
      id
      subscribed
    }
    ... on BaseError {
      code
      message
    }
    ... on RefetchTokenError {
      code
      message
    }
  }
}
    `;
export type StoreSubscriptionMutationFn = Apollo.MutationFunction<StoreSubscriptionMutation, StoreSubscriptionMutationVariables>;

/**
 * __useStoreSubscriptionMutation__
 *
 * To run a mutation, you first call `useStoreSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStoreSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [storeSubscriptionMutation, { data, loading, error }] = useStoreSubscriptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *      selectedProduct: // value for 'selectedProduct'
 *   },
 * });
 */
export function useStoreSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<StoreSubscriptionMutation, StoreSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StoreSubscriptionMutation, StoreSubscriptionMutationVariables>(StoreSubscriptionDocument, options);
      }
export type StoreSubscriptionMutationHookResult = ReturnType<typeof useStoreSubscriptionMutation>;
export type StoreSubscriptionMutationResult = Apollo.MutationResult<StoreSubscriptionMutation>;
export type StoreSubscriptionMutationOptions = Apollo.BaseMutationOptions<StoreSubscriptionMutation, StoreSubscriptionMutationVariables>;
export const AddManagerInfoDocument = gql`
    mutation AddManagerInfo($input: AddManagerInfoInput!) {
  addManagerInfo(input: $input) {
    ... on SignUpData {
      id
      firstName
      lastName
    }
    ... on BaseError {
      code
      message
    }
    ... on RefetchTokenError {
      code
      message
    }
  }
}
    `;
export type AddManagerInfoMutationFn = Apollo.MutationFunction<AddManagerInfoMutation, AddManagerInfoMutationVariables>;

/**
 * __useAddManagerInfoMutation__
 *
 * To run a mutation, you first call `useAddManagerInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddManagerInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addManagerInfoMutation, { data, loading, error }] = useAddManagerInfoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddManagerInfoMutation(baseOptions?: Apollo.MutationHookOptions<AddManagerInfoMutation, AddManagerInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddManagerInfoMutation, AddManagerInfoMutationVariables>(AddManagerInfoDocument, options);
      }
export type AddManagerInfoMutationHookResult = ReturnType<typeof useAddManagerInfoMutation>;
export type AddManagerInfoMutationResult = Apollo.MutationResult<AddManagerInfoMutation>;
export type AddManagerInfoMutationOptions = Apollo.BaseMutationOptions<AddManagerInfoMutation, AddManagerInfoMutationVariables>;
export const FinishSignUpDocument = gql`
    mutation FinishSignUp($input: FinishSignUpInput!) {
  finishSignUp(input: $input) {
    __typename
    ... on SignUpData {
      id
      done
    }
    ... on BaseError {
      code
      message
    }
    ... on RefetchTokenError {
      code
      message
    }
  }
}
    `;
export type FinishSignUpMutationFn = Apollo.MutationFunction<FinishSignUpMutation, FinishSignUpMutationVariables>;

/**
 * __useFinishSignUpMutation__
 *
 * To run a mutation, you first call `useFinishSignUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinishSignUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finishSignUpMutation, { data, loading, error }] = useFinishSignUpMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFinishSignUpMutation(baseOptions?: Apollo.MutationHookOptions<FinishSignUpMutation, FinishSignUpMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FinishSignUpMutation, FinishSignUpMutationVariables>(FinishSignUpDocument, options);
      }
export type FinishSignUpMutationHookResult = ReturnType<typeof useFinishSignUpMutation>;
export type FinishSignUpMutationResult = Apollo.MutationResult<FinishSignUpMutation>;
export type FinishSignUpMutationOptions = Apollo.BaseMutationOptions<FinishSignUpMutation, FinishSignUpMutationVariables>;
export const SaveYoutubeCredentialsDocument = gql`
    mutation SaveYoutubeCredentials($authCode: String!) {
  saveYoutubeCredentials(authCode: $authCode) {
    ... on Organization {
      id
      oauthInfo {
        ...oauthInfoYoutubeFragment
      }
    }
    ... on GenericError {
      code
      message
    }
  }
}
    ${OauthInfoYoutubeFragmentFragmentDoc}`;
export type SaveYoutubeCredentialsMutationFn = Apollo.MutationFunction<SaveYoutubeCredentialsMutation, SaveYoutubeCredentialsMutationVariables>;

/**
 * __useSaveYoutubeCredentialsMutation__
 *
 * To run a mutation, you first call `useSaveYoutubeCredentialsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveYoutubeCredentialsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveYoutubeCredentialsMutation, { data, loading, error }] = useSaveYoutubeCredentialsMutation({
 *   variables: {
 *      authCode: // value for 'authCode'
 *   },
 * });
 */
export function useSaveYoutubeCredentialsMutation(baseOptions?: Apollo.MutationHookOptions<SaveYoutubeCredentialsMutation, SaveYoutubeCredentialsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveYoutubeCredentialsMutation, SaveYoutubeCredentialsMutationVariables>(SaveYoutubeCredentialsDocument, options);
      }
export type SaveYoutubeCredentialsMutationHookResult = ReturnType<typeof useSaveYoutubeCredentialsMutation>;
export type SaveYoutubeCredentialsMutationResult = Apollo.MutationResult<SaveYoutubeCredentialsMutation>;
export type SaveYoutubeCredentialsMutationOptions = Apollo.BaseMutationOptions<SaveYoutubeCredentialsMutation, SaveYoutubeCredentialsMutationVariables>;
export const BuildVimeoAuthorizationEndpointDocument = gql`
    mutation BuildVimeoAuthorizationEndpoint($redirectUrl: String!, $state: String!) {
  buildVimeoAuthorizationEndpoint(redirectUrl: $redirectUrl, state: $state)
}
    `;
export type BuildVimeoAuthorizationEndpointMutationFn = Apollo.MutationFunction<BuildVimeoAuthorizationEndpointMutation, BuildVimeoAuthorizationEndpointMutationVariables>;

/**
 * __useBuildVimeoAuthorizationEndpointMutation__
 *
 * To run a mutation, you first call `useBuildVimeoAuthorizationEndpointMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBuildVimeoAuthorizationEndpointMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [buildVimeoAuthorizationEndpointMutation, { data, loading, error }] = useBuildVimeoAuthorizationEndpointMutation({
 *   variables: {
 *      redirectUrl: // value for 'redirectUrl'
 *      state: // value for 'state'
 *   },
 * });
 */
export function useBuildVimeoAuthorizationEndpointMutation(baseOptions?: Apollo.MutationHookOptions<BuildVimeoAuthorizationEndpointMutation, BuildVimeoAuthorizationEndpointMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BuildVimeoAuthorizationEndpointMutation, BuildVimeoAuthorizationEndpointMutationVariables>(BuildVimeoAuthorizationEndpointDocument, options);
      }
export type BuildVimeoAuthorizationEndpointMutationHookResult = ReturnType<typeof useBuildVimeoAuthorizationEndpointMutation>;
export type BuildVimeoAuthorizationEndpointMutationResult = Apollo.MutationResult<BuildVimeoAuthorizationEndpointMutation>;
export type BuildVimeoAuthorizationEndpointMutationOptions = Apollo.BaseMutationOptions<BuildVimeoAuthorizationEndpointMutation, BuildVimeoAuthorizationEndpointMutationVariables>;
export const SaveVimeoCredentialsDocument = gql`
    mutation SaveVimeoCredentials($code: String!, $redirectUrl: String!) {
  saveVimeoCredentials(code: $code, redirectUrl: $redirectUrl) {
    ... on Organization {
      id
      oauthInfo {
        ...oauthInfoVimeoFragment
      }
    }
    ... on GenericError {
      code
      message
    }
  }
}
    ${OauthInfoVimeoFragmentFragmentDoc}`;
export type SaveVimeoCredentialsMutationFn = Apollo.MutationFunction<SaveVimeoCredentialsMutation, SaveVimeoCredentialsMutationVariables>;

/**
 * __useSaveVimeoCredentialsMutation__
 *
 * To run a mutation, you first call `useSaveVimeoCredentialsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveVimeoCredentialsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveVimeoCredentialsMutation, { data, loading, error }] = useSaveVimeoCredentialsMutation({
 *   variables: {
 *      code: // value for 'code'
 *      redirectUrl: // value for 'redirectUrl'
 *   },
 * });
 */
export function useSaveVimeoCredentialsMutation(baseOptions?: Apollo.MutationHookOptions<SaveVimeoCredentialsMutation, SaveVimeoCredentialsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveVimeoCredentialsMutation, SaveVimeoCredentialsMutationVariables>(SaveVimeoCredentialsDocument, options);
      }
export type SaveVimeoCredentialsMutationHookResult = ReturnType<typeof useSaveVimeoCredentialsMutation>;
export type SaveVimeoCredentialsMutationResult = Apollo.MutationResult<SaveVimeoCredentialsMutation>;
export type SaveVimeoCredentialsMutationOptions = Apollo.BaseMutationOptions<SaveVimeoCredentialsMutation, SaveVimeoCredentialsMutationVariables>;
export const RemoveVimeoCredentialsDocument = gql`
    mutation RemoveVimeoCredentials {
  removeVimeoCredentials {
    id
    oauthInfo {
      ...oauthInfoVimeoFragment
    }
  }
}
    ${OauthInfoVimeoFragmentFragmentDoc}`;
export type RemoveVimeoCredentialsMutationFn = Apollo.MutationFunction<RemoveVimeoCredentialsMutation, RemoveVimeoCredentialsMutationVariables>;

/**
 * __useRemoveVimeoCredentialsMutation__
 *
 * To run a mutation, you first call `useRemoveVimeoCredentialsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveVimeoCredentialsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeVimeoCredentialsMutation, { data, loading, error }] = useRemoveVimeoCredentialsMutation({
 *   variables: {
 *   },
 * });
 */
export function useRemoveVimeoCredentialsMutation(baseOptions?: Apollo.MutationHookOptions<RemoveVimeoCredentialsMutation, RemoveVimeoCredentialsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveVimeoCredentialsMutation, RemoveVimeoCredentialsMutationVariables>(RemoveVimeoCredentialsDocument, options);
      }
export type RemoveVimeoCredentialsMutationHookResult = ReturnType<typeof useRemoveVimeoCredentialsMutation>;
export type RemoveVimeoCredentialsMutationResult = Apollo.MutationResult<RemoveVimeoCredentialsMutation>;
export type RemoveVimeoCredentialsMutationOptions = Apollo.BaseMutationOptions<RemoveVimeoCredentialsMutation, RemoveVimeoCredentialsMutationVariables>;
export const PushVideoToYoutubeDocument = gql`
    mutation PushVideoToYoutube($channelId: String!, $description: String, $privacySetting: YoutubePrivacySetting!, $title: String!, $videoId: Int!) {
  pushVideoToYoutube(
    channelId: $channelId
    description: $description
    privacySetting: $privacySetting
    title: $title
    videoId: $videoId
  ) {
    ... on GenericError {
      code
      message
    }
  }
}
    `;
export type PushVideoToYoutubeMutationFn = Apollo.MutationFunction<PushVideoToYoutubeMutation, PushVideoToYoutubeMutationVariables>;

/**
 * __usePushVideoToYoutubeMutation__
 *
 * To run a mutation, you first call `usePushVideoToYoutubeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePushVideoToYoutubeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pushVideoToYoutubeMutation, { data, loading, error }] = usePushVideoToYoutubeMutation({
 *   variables: {
 *      channelId: // value for 'channelId'
 *      description: // value for 'description'
 *      privacySetting: // value for 'privacySetting'
 *      title: // value for 'title'
 *      videoId: // value for 'videoId'
 *   },
 * });
 */
export function usePushVideoToYoutubeMutation(baseOptions?: Apollo.MutationHookOptions<PushVideoToYoutubeMutation, PushVideoToYoutubeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PushVideoToYoutubeMutation, PushVideoToYoutubeMutationVariables>(PushVideoToYoutubeDocument, options);
      }
export type PushVideoToYoutubeMutationHookResult = ReturnType<typeof usePushVideoToYoutubeMutation>;
export type PushVideoToYoutubeMutationResult = Apollo.MutationResult<PushVideoToYoutubeMutation>;
export type PushVideoToYoutubeMutationOptions = Apollo.BaseMutationOptions<PushVideoToYoutubeMutation, PushVideoToYoutubeMutationVariables>;
export const UpdateBrandColorsDocument = gql`
    mutation UpdateBrandColors($lightColor: String!, $darkColor: String!, $greyColor: String!) {
  updateBrandColors(
    lightColor: $lightColor
    darkColor: $darkColor
    greyColor: $greyColor
  ) {
    ... on ValidationError {
      message
    }
    ... on BrandAsset {
      id
      darkColor
      lightColor
      greyColor
    }
  }
}
    `;
export type UpdateBrandColorsMutationFn = Apollo.MutationFunction<UpdateBrandColorsMutation, UpdateBrandColorsMutationVariables>;

/**
 * __useUpdateBrandColorsMutation__
 *
 * To run a mutation, you first call `useUpdateBrandColorsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBrandColorsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBrandColorsMutation, { data, loading, error }] = useUpdateBrandColorsMutation({
 *   variables: {
 *      lightColor: // value for 'lightColor'
 *      darkColor: // value for 'darkColor'
 *      greyColor: // value for 'greyColor'
 *   },
 * });
 */
export function useUpdateBrandColorsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBrandColorsMutation, UpdateBrandColorsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBrandColorsMutation, UpdateBrandColorsMutationVariables>(UpdateBrandColorsDocument, options);
      }
export type UpdateBrandColorsMutationHookResult = ReturnType<typeof useUpdateBrandColorsMutation>;
export type UpdateBrandColorsMutationResult = Apollo.MutationResult<UpdateBrandColorsMutation>;
export type UpdateBrandColorsMutationOptions = Apollo.BaseMutationOptions<UpdateBrandColorsMutation, UpdateBrandColorsMutationVariables>;
export const UploadBrandLogosDocument = gql`
    mutation UploadBrandLogos($colorLogo: Upload, $whiteLogo: Upload) {
  uploadBrandLogos(colorLogo: $colorLogo, whiteLogo: $whiteLogo) {
    id
    colorLogo
    whiteLogo
  }
}
    `;
export type UploadBrandLogosMutationFn = Apollo.MutationFunction<UploadBrandLogosMutation, UploadBrandLogosMutationVariables>;

/**
 * __useUploadBrandLogosMutation__
 *
 * To run a mutation, you first call `useUploadBrandLogosMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadBrandLogosMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadBrandLogosMutation, { data, loading, error }] = useUploadBrandLogosMutation({
 *   variables: {
 *      colorLogo: // value for 'colorLogo'
 *      whiteLogo: // value for 'whiteLogo'
 *   },
 * });
 */
export function useUploadBrandLogosMutation(baseOptions?: Apollo.MutationHookOptions<UploadBrandLogosMutation, UploadBrandLogosMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadBrandLogosMutation, UploadBrandLogosMutationVariables>(UploadBrandLogosDocument, options);
      }
export type UploadBrandLogosMutationHookResult = ReturnType<typeof useUploadBrandLogosMutation>;
export type UploadBrandLogosMutationResult = Apollo.MutationResult<UploadBrandLogosMutation>;
export type UploadBrandLogosMutationOptions = Apollo.BaseMutationOptions<UploadBrandLogosMutation, UploadBrandLogosMutationVariables>;
export const UpdateEndCardInfoDocument = gql`
    mutation UpdateEndCardInfo($type: EndCardType!, $value: String!, $callToAction: String!, $businessEmail: String, $businessPhone: String) {
  updateEndCardInfo(
    type: $type
    value: $value
    callToAction: $callToAction
    businessEmail: $businessEmail
    businessPhone: $businessPhone
  ) {
    id
    callToAction
    value
    type
    businessPhone
    businessEmail
  }
}
    `;
export type UpdateEndCardInfoMutationFn = Apollo.MutationFunction<UpdateEndCardInfoMutation, UpdateEndCardInfoMutationVariables>;

/**
 * __useUpdateEndCardInfoMutation__
 *
 * To run a mutation, you first call `useUpdateEndCardInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEndCardInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEndCardInfoMutation, { data, loading, error }] = useUpdateEndCardInfoMutation({
 *   variables: {
 *      type: // value for 'type'
 *      value: // value for 'value'
 *      callToAction: // value for 'callToAction'
 *      businessEmail: // value for 'businessEmail'
 *      businessPhone: // value for 'businessPhone'
 *   },
 * });
 */
export function useUpdateEndCardInfoMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEndCardInfoMutation, UpdateEndCardInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEndCardInfoMutation, UpdateEndCardInfoMutationVariables>(UpdateEndCardInfoDocument, options);
      }
export type UpdateEndCardInfoMutationHookResult = ReturnType<typeof useUpdateEndCardInfoMutation>;
export type UpdateEndCardInfoMutationResult = Apollo.MutationResult<UpdateEndCardInfoMutation>;
export type UpdateEndCardInfoMutationOptions = Apollo.BaseMutationOptions<UpdateEndCardInfoMutation, UpdateEndCardInfoMutationVariables>;
export const RemoveYoutubeCredentialsDocument = gql`
    mutation RemoveYoutubeCredentials {
  removeYoutubeCredentials {
    id
    oauthInfo {
      id
      youtubeChannels {
        id
        name
      }
    }
  }
}
    `;
export type RemoveYoutubeCredentialsMutationFn = Apollo.MutationFunction<RemoveYoutubeCredentialsMutation, RemoveYoutubeCredentialsMutationVariables>;

/**
 * __useRemoveYoutubeCredentialsMutation__
 *
 * To run a mutation, you first call `useRemoveYoutubeCredentialsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveYoutubeCredentialsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeYoutubeCredentialsMutation, { data, loading, error }] = useRemoveYoutubeCredentialsMutation({
 *   variables: {
 *   },
 * });
 */
export function useRemoveYoutubeCredentialsMutation(baseOptions?: Apollo.MutationHookOptions<RemoveYoutubeCredentialsMutation, RemoveYoutubeCredentialsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveYoutubeCredentialsMutation, RemoveYoutubeCredentialsMutationVariables>(RemoveYoutubeCredentialsDocument, options);
      }
export type RemoveYoutubeCredentialsMutationHookResult = ReturnType<typeof useRemoveYoutubeCredentialsMutation>;
export type RemoveYoutubeCredentialsMutationResult = Apollo.MutationResult<RemoveYoutubeCredentialsMutation>;
export type RemoveYoutubeCredentialsMutationOptions = Apollo.BaseMutationOptions<RemoveYoutubeCredentialsMutation, RemoveYoutubeCredentialsMutationVariables>;
export const UpdateOrganizationDocument = gql`
    mutation UpdateOrganization($input: OrganizationUpdateInput!, $organizationId: Int) {
  updateOrganization(input: $input, organizationId: $organizationId) {
    id
    name
    legalName
    phoneNumber
    twoFactorAuthEnabled
    socialHandles {
      instagram
      facebook
      twitter
      youtube
      vimeo
    }
  }
}
    `;
export type UpdateOrganizationMutationFn = Apollo.MutationFunction<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>;

/**
 * __useUpdateOrganizationMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationMutation, { data, loading, error }] = useUpdateOrganizationMutation({
 *   variables: {
 *      input: // value for 'input'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useUpdateOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>(UpdateOrganizationDocument, options);
      }
export type UpdateOrganizationMutationHookResult = ReturnType<typeof useUpdateOrganizationMutation>;
export type UpdateOrganizationMutationResult = Apollo.MutationResult<UpdateOrganizationMutation>;
export type UpdateOrganizationMutationOptions = Apollo.BaseMutationOptions<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>;
export const UpdateMeDocument = gql`
    mutation UpdateMe($input: UpdateAdminUserInput!) {
  updateMe(input: $input) {
    ... on GenericError {
      code
      message
    }
    ... on AdminUser {
      id
      firstName
      lastName
      email
      cellPhone
      directPhone
      title
      isPrimary
    }
  }
}
    `;
export type UpdateMeMutationFn = Apollo.MutationFunction<UpdateMeMutation, UpdateMeMutationVariables>;

/**
 * __useUpdateMeMutation__
 *
 * To run a mutation, you first call `useUpdateMeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMeMutation, { data, loading, error }] = useUpdateMeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMeMutation, UpdateMeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMeMutation, UpdateMeMutationVariables>(UpdateMeDocument, options);
      }
export type UpdateMeMutationHookResult = ReturnType<typeof useUpdateMeMutation>;
export type UpdateMeMutationResult = Apollo.MutationResult<UpdateMeMutation>;
export type UpdateMeMutationOptions = Apollo.BaseMutationOptions<UpdateMeMutation, UpdateMeMutationVariables>;
export const UpdateAdminUserDocument = gql`
    mutation UpdateAdminUser($adminUserId: Int!, $input: UpdateAdminUserInput!) {
  updateAdminUser(adminUserId: $adminUserId, input: $input) {
    ... on GenericError {
      code
      message
    }
    ... on AdminUser {
      id
      firstName
      lastName
      email
      cellPhone
      directPhone
      title
      isPrimary
    }
  }
}
    `;
export type UpdateAdminUserMutationFn = Apollo.MutationFunction<UpdateAdminUserMutation, UpdateAdminUserMutationVariables>;

/**
 * __useUpdateAdminUserMutation__
 *
 * To run a mutation, you first call `useUpdateAdminUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAdminUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAdminUserMutation, { data, loading, error }] = useUpdateAdminUserMutation({
 *   variables: {
 *      adminUserId: // value for 'adminUserId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAdminUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAdminUserMutation, UpdateAdminUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAdminUserMutation, UpdateAdminUserMutationVariables>(UpdateAdminUserDocument, options);
      }
export type UpdateAdminUserMutationHookResult = ReturnType<typeof useUpdateAdminUserMutation>;
export type UpdateAdminUserMutationResult = Apollo.MutationResult<UpdateAdminUserMutation>;
export type UpdateAdminUserMutationOptions = Apollo.BaseMutationOptions<UpdateAdminUserMutation, UpdateAdminUserMutationVariables>;
export const UpdateStorymakersDocument = gql`
    mutation UpdateStorymakers($storymakerIds: [Int!]!, $organizationId: Int, $filmingGroupIds: [Int!]!) {
  updateStorymakers(
    storymakerIds: $storymakerIds
    organizationId: $organizationId
    filmingGroupIds: $filmingGroupIds
  ) {
    __typename
    ... on GenericError {
      message
    }
    ... on Storymaker {
      id
      filmingGroups {
        id
        name
      }
    }
  }
}
    `;
export type UpdateStorymakersMutationFn = Apollo.MutationFunction<UpdateStorymakersMutation, UpdateStorymakersMutationVariables>;

/**
 * __useUpdateStorymakersMutation__
 *
 * To run a mutation, you first call `useUpdateStorymakersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStorymakersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStorymakersMutation, { data, loading, error }] = useUpdateStorymakersMutation({
 *   variables: {
 *      storymakerIds: // value for 'storymakerIds'
 *      organizationId: // value for 'organizationId'
 *      filmingGroupIds: // value for 'filmingGroupIds'
 *   },
 * });
 */
export function useUpdateStorymakersMutation(baseOptions?: Apollo.MutationHookOptions<UpdateStorymakersMutation, UpdateStorymakersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateStorymakersMutation, UpdateStorymakersMutationVariables>(UpdateStorymakersDocument, options);
      }
export type UpdateStorymakersMutationHookResult = ReturnType<typeof useUpdateStorymakersMutation>;
export type UpdateStorymakersMutationResult = Apollo.MutationResult<UpdateStorymakersMutation>;
export type UpdateStorymakersMutationOptions = Apollo.BaseMutationOptions<UpdateStorymakersMutation, UpdateStorymakersMutationVariables>;
export const EnableAppUserDocument = gql`
    mutation EnableAppUser($storymakerId: Int!) {
  enableAppUser(storymakerId: $storymakerId) {
    __typename
    ... on Storymaker {
      id
      suspended
    }
    ... on Reviewer {
      id
      suspended
    }
    ... on GenericError {
      message
    }
  }
}
    `;
export type EnableAppUserMutationFn = Apollo.MutationFunction<EnableAppUserMutation, EnableAppUserMutationVariables>;

/**
 * __useEnableAppUserMutation__
 *
 * To run a mutation, you first call `useEnableAppUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnableAppUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enableAppUserMutation, { data, loading, error }] = useEnableAppUserMutation({
 *   variables: {
 *      storymakerId: // value for 'storymakerId'
 *   },
 * });
 */
export function useEnableAppUserMutation(baseOptions?: Apollo.MutationHookOptions<EnableAppUserMutation, EnableAppUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EnableAppUserMutation, EnableAppUserMutationVariables>(EnableAppUserDocument, options);
      }
export type EnableAppUserMutationHookResult = ReturnType<typeof useEnableAppUserMutation>;
export type EnableAppUserMutationResult = Apollo.MutationResult<EnableAppUserMutation>;
export type EnableAppUserMutationOptions = Apollo.BaseMutationOptions<EnableAppUserMutation, EnableAppUserMutationVariables>;
export const DisableAppUserDocument = gql`
    mutation DisableAppUser($storymakerId: Int!) {
  disableAppUser(storymakerId: $storymakerId) {
    __typename
    ... on Storymaker {
      id
      suspended
    }
    ... on Reviewer {
      id
      suspended
    }
    ... on GenericError {
      message
    }
  }
}
    `;
export type DisableAppUserMutationFn = Apollo.MutationFunction<DisableAppUserMutation, DisableAppUserMutationVariables>;

/**
 * __useDisableAppUserMutation__
 *
 * To run a mutation, you first call `useDisableAppUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisableAppUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disableAppUserMutation, { data, loading, error }] = useDisableAppUserMutation({
 *   variables: {
 *      storymakerId: // value for 'storymakerId'
 *   },
 * });
 */
export function useDisableAppUserMutation(baseOptions?: Apollo.MutationHookOptions<DisableAppUserMutation, DisableAppUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DisableAppUserMutation, DisableAppUserMutationVariables>(DisableAppUserDocument, options);
      }
export type DisableAppUserMutationHookResult = ReturnType<typeof useDisableAppUserMutation>;
export type DisableAppUserMutationResult = Apollo.MutationResult<DisableAppUserMutation>;
export type DisableAppUserMutationOptions = Apollo.BaseMutationOptions<DisableAppUserMutation, DisableAppUserMutationVariables>;
export const ManagerInviteAdminUserDocument = gql`
    mutation ManagerInviteAdminUser($email: String!, $urlPrefix: String!) {
  inviteAdminUser(email: $email, urlPrefix: $urlPrefix) {
    ... on GenericError {
      message
    }
    ... on AdminUser {
      id
      email
      hasAcceptedInvite
      firstName
      lastName
      cellPhone
      directPhone
      title
      isMe
      isPrimary
      role
    }
  }
}
    `;
export type ManagerInviteAdminUserMutationFn = Apollo.MutationFunction<ManagerInviteAdminUserMutation, ManagerInviteAdminUserMutationVariables>;

/**
 * __useManagerInviteAdminUserMutation__
 *
 * To run a mutation, you first call `useManagerInviteAdminUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useManagerInviteAdminUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [managerInviteAdminUserMutation, { data, loading, error }] = useManagerInviteAdminUserMutation({
 *   variables: {
 *      email: // value for 'email'
 *      urlPrefix: // value for 'urlPrefix'
 *   },
 * });
 */
export function useManagerInviteAdminUserMutation(baseOptions?: Apollo.MutationHookOptions<ManagerInviteAdminUserMutation, ManagerInviteAdminUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ManagerInviteAdminUserMutation, ManagerInviteAdminUserMutationVariables>(ManagerInviteAdminUserDocument, options);
      }
export type ManagerInviteAdminUserMutationHookResult = ReturnType<typeof useManagerInviteAdminUserMutation>;
export type ManagerInviteAdminUserMutationResult = Apollo.MutationResult<ManagerInviteAdminUserMutation>;
export type ManagerInviteAdminUserMutationOptions = Apollo.BaseMutationOptions<ManagerInviteAdminUserMutation, ManagerInviteAdminUserMutationVariables>;
export const AdminInviteAdminUserDocument = gql`
    mutation AdminInviteAdminUser($firstName: String!, $lastName: String!, $email: String!, $urlPrefix: String!, $organizationId: Int!, $ssoLogin: Boolean = false) {
  inviteAdminUser(
    email: $email
    urlPrefix: $urlPrefix
    firstName: $firstName
    lastName: $lastName
    organizationId: $organizationId
    ssoLogin: $ssoLogin
  ) {
    ... on GenericError {
      message
    }
    ... on AdminUser {
      ...adminUserDetailInfo
    }
  }
}
    ${AdminUserDetailInfoFragmentDoc}`;
export type AdminInviteAdminUserMutationFn = Apollo.MutationFunction<AdminInviteAdminUserMutation, AdminInviteAdminUserMutationVariables>;

/**
 * __useAdminInviteAdminUserMutation__
 *
 * To run a mutation, you first call `useAdminInviteAdminUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminInviteAdminUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminInviteAdminUserMutation, { data, loading, error }] = useAdminInviteAdminUserMutation({
 *   variables: {
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      email: // value for 'email'
 *      urlPrefix: // value for 'urlPrefix'
 *      organizationId: // value for 'organizationId'
 *      ssoLogin: // value for 'ssoLogin'
 *   },
 * });
 */
export function useAdminInviteAdminUserMutation(baseOptions?: Apollo.MutationHookOptions<AdminInviteAdminUserMutation, AdminInviteAdminUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminInviteAdminUserMutation, AdminInviteAdminUserMutationVariables>(AdminInviteAdminUserDocument, options);
      }
export type AdminInviteAdminUserMutationHookResult = ReturnType<typeof useAdminInviteAdminUserMutation>;
export type AdminInviteAdminUserMutationResult = Apollo.MutationResult<AdminInviteAdminUserMutation>;
export type AdminInviteAdminUserMutationOptions = Apollo.BaseMutationOptions<AdminInviteAdminUserMutation, AdminInviteAdminUserMutationVariables>;
export const InviteAccountAdminUserDocument = gql`
    mutation InviteAccountAdminUser($firstName: String!, $lastName: String!, $email: String!, $urlPrefix: String!) {
  inviteAccountAdminUser(
    email: $email
    urlPrefix: $urlPrefix
    firstName: $firstName
    lastName: $lastName
  ) {
    ... on GenericError {
      message
    }
    ... on AdminUser {
      ...adminUserDetailInfo
    }
  }
}
    ${AdminUserDetailInfoFragmentDoc}`;
export type InviteAccountAdminUserMutationFn = Apollo.MutationFunction<InviteAccountAdminUserMutation, InviteAccountAdminUserMutationVariables>;

/**
 * __useInviteAccountAdminUserMutation__
 *
 * To run a mutation, you first call `useInviteAccountAdminUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteAccountAdminUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteAccountAdminUserMutation, { data, loading, error }] = useInviteAccountAdminUserMutation({
 *   variables: {
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      email: // value for 'email'
 *      urlPrefix: // value for 'urlPrefix'
 *   },
 * });
 */
export function useInviteAccountAdminUserMutation(baseOptions?: Apollo.MutationHookOptions<InviteAccountAdminUserMutation, InviteAccountAdminUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InviteAccountAdminUserMutation, InviteAccountAdminUserMutationVariables>(InviteAccountAdminUserDocument, options);
      }
export type InviteAccountAdminUserMutationHookResult = ReturnType<typeof useInviteAccountAdminUserMutation>;
export type InviteAccountAdminUserMutationResult = Apollo.MutationResult<InviteAccountAdminUserMutation>;
export type InviteAccountAdminUserMutationOptions = Apollo.BaseMutationOptions<InviteAccountAdminUserMutation, InviteAccountAdminUserMutationVariables>;
export const UpdateVideoStatusDocument = gql`
    mutation UpdateVideoStatus($videoId: Int!, $status: String!) {
  updateVideoStatus(videoId: $videoId, status: $status) {
    __typename
    ... on Video {
      id
      state
    }
    ... on GenericError {
      message
    }
  }
}
    `;
export type UpdateVideoStatusMutationFn = Apollo.MutationFunction<UpdateVideoStatusMutation, UpdateVideoStatusMutationVariables>;

/**
 * __useUpdateVideoStatusMutation__
 *
 * To run a mutation, you first call `useUpdateVideoStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVideoStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVideoStatusMutation, { data, loading, error }] = useUpdateVideoStatusMutation({
 *   variables: {
 *      videoId: // value for 'videoId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdateVideoStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateVideoStatusMutation, UpdateVideoStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateVideoStatusMutation, UpdateVideoStatusMutationVariables>(UpdateVideoStatusDocument, options);
      }
export type UpdateVideoStatusMutationHookResult = ReturnType<typeof useUpdateVideoStatusMutation>;
export type UpdateVideoStatusMutationResult = Apollo.MutationResult<UpdateVideoStatusMutation>;
export type UpdateVideoStatusMutationOptions = Apollo.BaseMutationOptions<UpdateVideoStatusMutation, UpdateVideoStatusMutationVariables>;
export const ResendAdminUserInvitationDocument = gql`
    mutation ResendAdminUserInvitation($adminUserId: Int!, $urlPrefix: String!) {
  resendAdminUserInvitation(adminUserId: $adminUserId, urlPrefix: $urlPrefix) {
    ... on GenericError {
      message
    }
    ... on OutdatedDataError {
      message
      entities
    }
  }
}
    `;
export type ResendAdminUserInvitationMutationFn = Apollo.MutationFunction<ResendAdminUserInvitationMutation, ResendAdminUserInvitationMutationVariables>;

/**
 * __useResendAdminUserInvitationMutation__
 *
 * To run a mutation, you first call `useResendAdminUserInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendAdminUserInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendAdminUserInvitationMutation, { data, loading, error }] = useResendAdminUserInvitationMutation({
 *   variables: {
 *      adminUserId: // value for 'adminUserId'
 *      urlPrefix: // value for 'urlPrefix'
 *   },
 * });
 */
export function useResendAdminUserInvitationMutation(baseOptions?: Apollo.MutationHookOptions<ResendAdminUserInvitationMutation, ResendAdminUserInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResendAdminUserInvitationMutation, ResendAdminUserInvitationMutationVariables>(ResendAdminUserInvitationDocument, options);
      }
export type ResendAdminUserInvitationMutationHookResult = ReturnType<typeof useResendAdminUserInvitationMutation>;
export type ResendAdminUserInvitationMutationResult = Apollo.MutationResult<ResendAdminUserInvitationMutation>;
export type ResendAdminUserInvitationMutationOptions = Apollo.BaseMutationOptions<ResendAdminUserInvitationMutation, ResendAdminUserInvitationMutationVariables>;
export const CancelAdminUserInvitationDocument = gql`
    mutation CancelAdminUserInvitation($adminUserId: Int!) {
  cancelAdminUserInvitation(adminUserId: $adminUserId) {
    ... on GenericError {
      message
    }
    ... on OutdatedDataError {
      message
      entities
    }
  }
}
    `;
export type CancelAdminUserInvitationMutationFn = Apollo.MutationFunction<CancelAdminUserInvitationMutation, CancelAdminUserInvitationMutationVariables>;

/**
 * __useCancelAdminUserInvitationMutation__
 *
 * To run a mutation, you first call `useCancelAdminUserInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelAdminUserInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelAdminUserInvitationMutation, { data, loading, error }] = useCancelAdminUserInvitationMutation({
 *   variables: {
 *      adminUserId: // value for 'adminUserId'
 *   },
 * });
 */
export function useCancelAdminUserInvitationMutation(baseOptions?: Apollo.MutationHookOptions<CancelAdminUserInvitationMutation, CancelAdminUserInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelAdminUserInvitationMutation, CancelAdminUserInvitationMutationVariables>(CancelAdminUserInvitationDocument, options);
      }
export type CancelAdminUserInvitationMutationHookResult = ReturnType<typeof useCancelAdminUserInvitationMutation>;
export type CancelAdminUserInvitationMutationResult = Apollo.MutationResult<CancelAdminUserInvitationMutation>;
export type CancelAdminUserInvitationMutationOptions = Apollo.BaseMutationOptions<CancelAdminUserInvitationMutation, CancelAdminUserInvitationMutationVariables>;
export const UpdateLegalLinksDocument = gql`
    mutation UpdateLegalLinks($input: LegalLinksUpdateInput!, $organizationId: Int) {
  updateLegalLinks(input: $input, organizationId: $organizationId) {
    id
    privacyPolicy
    termsOfService
  }
}
    `;
export type UpdateLegalLinksMutationFn = Apollo.MutationFunction<UpdateLegalLinksMutation, UpdateLegalLinksMutationVariables>;

/**
 * __useUpdateLegalLinksMutation__
 *
 * To run a mutation, you first call `useUpdateLegalLinksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLegalLinksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLegalLinksMutation, { data, loading, error }] = useUpdateLegalLinksMutation({
 *   variables: {
 *      input: // value for 'input'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useUpdateLegalLinksMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLegalLinksMutation, UpdateLegalLinksMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLegalLinksMutation, UpdateLegalLinksMutationVariables>(UpdateLegalLinksDocument, options);
      }
export type UpdateLegalLinksMutationHookResult = ReturnType<typeof useUpdateLegalLinksMutation>;
export type UpdateLegalLinksMutationResult = Apollo.MutationResult<UpdateLegalLinksMutation>;
export type UpdateLegalLinksMutationOptions = Apollo.BaseMutationOptions<UpdateLegalLinksMutation, UpdateLegalLinksMutationVariables>;
export const UpdateVideoReleaseDocument = gql`
    mutation UpdateVideoRelease($input: VideoReleaseUpdateInput!, $organizationId: Int) {
  updateVideoRelease(input: $input, organizationId: $organizationId) {
    id
    videoReleasePromptBegin
    videoReleasePromptEnd
  }
}
    `;
export type UpdateVideoReleaseMutationFn = Apollo.MutationFunction<UpdateVideoReleaseMutation, UpdateVideoReleaseMutationVariables>;

/**
 * __useUpdateVideoReleaseMutation__
 *
 * To run a mutation, you first call `useUpdateVideoReleaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVideoReleaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVideoReleaseMutation, { data, loading, error }] = useUpdateVideoReleaseMutation({
 *   variables: {
 *      input: // value for 'input'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useUpdateVideoReleaseMutation(baseOptions?: Apollo.MutationHookOptions<UpdateVideoReleaseMutation, UpdateVideoReleaseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateVideoReleaseMutation, UpdateVideoReleaseMutationVariables>(UpdateVideoReleaseDocument, options);
      }
export type UpdateVideoReleaseMutationHookResult = ReturnType<typeof useUpdateVideoReleaseMutation>;
export type UpdateVideoReleaseMutationResult = Apollo.MutationResult<UpdateVideoReleaseMutation>;
export type UpdateVideoReleaseMutationOptions = Apollo.BaseMutationOptions<UpdateVideoReleaseMutation, UpdateVideoReleaseMutationVariables>;
export const SetPrimaryManagerDocument = gql`
    mutation SetPrimaryManager($managerId: Int!) {
  setPrimaryManager(managerId: $managerId) {
    id
    isPrimary
  }
}
    `;
export type SetPrimaryManagerMutationFn = Apollo.MutationFunction<SetPrimaryManagerMutation, SetPrimaryManagerMutationVariables>;

/**
 * __useSetPrimaryManagerMutation__
 *
 * To run a mutation, you first call `useSetPrimaryManagerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPrimaryManagerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPrimaryManagerMutation, { data, loading, error }] = useSetPrimaryManagerMutation({
 *   variables: {
 *      managerId: // value for 'managerId'
 *   },
 * });
 */
export function useSetPrimaryManagerMutation(baseOptions?: Apollo.MutationHookOptions<SetPrimaryManagerMutation, SetPrimaryManagerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetPrimaryManagerMutation, SetPrimaryManagerMutationVariables>(SetPrimaryManagerDocument, options);
      }
export type SetPrimaryManagerMutationHookResult = ReturnType<typeof useSetPrimaryManagerMutation>;
export type SetPrimaryManagerMutationResult = Apollo.MutationResult<SetPrimaryManagerMutation>;
export type SetPrimaryManagerMutationOptions = Apollo.BaseMutationOptions<SetPrimaryManagerMutation, SetPrimaryManagerMutationVariables>;
export const DeleteAdminUserDocument = gql`
    mutation DeleteAdminUser($adminUserId: Int!) {
  deleteAdminUser(adminUserId: $adminUserId) {
    ... on AdminUser {
      id
    }
    ... on GenericError {
      message
    }
  }
}
    `;
export type DeleteAdminUserMutationFn = Apollo.MutationFunction<DeleteAdminUserMutation, DeleteAdminUserMutationVariables>;

/**
 * __useDeleteAdminUserMutation__
 *
 * To run a mutation, you first call `useDeleteAdminUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAdminUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAdminUserMutation, { data, loading, error }] = useDeleteAdminUserMutation({
 *   variables: {
 *      adminUserId: // value for 'adminUserId'
 *   },
 * });
 */
export function useDeleteAdminUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAdminUserMutation, DeleteAdminUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAdminUserMutation, DeleteAdminUserMutationVariables>(DeleteAdminUserDocument, options);
      }
export type DeleteAdminUserMutationHookResult = ReturnType<typeof useDeleteAdminUserMutation>;
export type DeleteAdminUserMutationResult = Apollo.MutationResult<DeleteAdminUserMutation>;
export type DeleteAdminUserMutationOptions = Apollo.BaseMutationOptions<DeleteAdminUserMutation, DeleteAdminUserMutationVariables>;
export const FinishTutorialDocument = gql`
    mutation FinishTutorial {
  finishTutorial
}
    `;
export type FinishTutorialMutationFn = Apollo.MutationFunction<FinishTutorialMutation, FinishTutorialMutationVariables>;

/**
 * __useFinishTutorialMutation__
 *
 * To run a mutation, you first call `useFinishTutorialMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinishTutorialMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finishTutorialMutation, { data, loading, error }] = useFinishTutorialMutation({
 *   variables: {
 *   },
 * });
 */
export function useFinishTutorialMutation(baseOptions?: Apollo.MutationHookOptions<FinishTutorialMutation, FinishTutorialMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FinishTutorialMutation, FinishTutorialMutationVariables>(FinishTutorialDocument, options);
      }
export type FinishTutorialMutationHookResult = ReturnType<typeof useFinishTutorialMutation>;
export type FinishTutorialMutationResult = Apollo.MutationResult<FinishTutorialMutation>;
export type FinishTutorialMutationOptions = Apollo.BaseMutationOptions<FinishTutorialMutation, FinishTutorialMutationVariables>;
export const RestartTutorialDocument = gql`
    mutation RestartTutorial {
  restartTutorial
}
    `;
export type RestartTutorialMutationFn = Apollo.MutationFunction<RestartTutorialMutation, RestartTutorialMutationVariables>;

/**
 * __useRestartTutorialMutation__
 *
 * To run a mutation, you first call `useRestartTutorialMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestartTutorialMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restartTutorialMutation, { data, loading, error }] = useRestartTutorialMutation({
 *   variables: {
 *   },
 * });
 */
export function useRestartTutorialMutation(baseOptions?: Apollo.MutationHookOptions<RestartTutorialMutation, RestartTutorialMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RestartTutorialMutation, RestartTutorialMutationVariables>(RestartTutorialDocument, options);
      }
export type RestartTutorialMutationHookResult = ReturnType<typeof useRestartTutorialMutation>;
export type RestartTutorialMutationResult = Apollo.MutationResult<RestartTutorialMutation>;
export type RestartTutorialMutationOptions = Apollo.BaseMutationOptions<RestartTutorialMutation, RestartTutorialMutationVariables>;
export const DeleteVideosDocument = gql`
    mutation DeleteVideos($videoIds: [Int!]!) {
  deleteVideos(videoIds: $videoIds) {
    ... on GenericError {
      message
    }
  }
}
    `;
export type DeleteVideosMutationFn = Apollo.MutationFunction<DeleteVideosMutation, DeleteVideosMutationVariables>;

/**
 * __useDeleteVideosMutation__
 *
 * To run a mutation, you first call `useDeleteVideosMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteVideosMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteVideosMutation, { data, loading, error }] = useDeleteVideosMutation({
 *   variables: {
 *      videoIds: // value for 'videoIds'
 *   },
 * });
 */
export function useDeleteVideosMutation(baseOptions?: Apollo.MutationHookOptions<DeleteVideosMutation, DeleteVideosMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteVideosMutation, DeleteVideosMutationVariables>(DeleteVideosDocument, options);
      }
export type DeleteVideosMutationHookResult = ReturnType<typeof useDeleteVideosMutation>;
export type DeleteVideosMutationResult = Apollo.MutationResult<DeleteVideosMutation>;
export type DeleteVideosMutationOptions = Apollo.BaseMutationOptions<DeleteVideosMutation, DeleteVideosMutationVariables>;
export const ApplyCouponCodeDocument = gql`
    mutation ApplyCouponCode($couponCode: String!, $productId: String!) {
  applyCouponCode(couponCode: $couponCode, productId: $productId) {
    __typename
    ... on CouponApplicationResult {
      amountOff
      percentOff
    }
  }
}
    `;
export type ApplyCouponCodeMutationFn = Apollo.MutationFunction<ApplyCouponCodeMutation, ApplyCouponCodeMutationVariables>;

/**
 * __useApplyCouponCodeMutation__
 *
 * To run a mutation, you first call `useApplyCouponCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApplyCouponCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [applyCouponCodeMutation, { data, loading, error }] = useApplyCouponCodeMutation({
 *   variables: {
 *      couponCode: // value for 'couponCode'
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useApplyCouponCodeMutation(baseOptions?: Apollo.MutationHookOptions<ApplyCouponCodeMutation, ApplyCouponCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApplyCouponCodeMutation, ApplyCouponCodeMutationVariables>(ApplyCouponCodeDocument, options);
      }
export type ApplyCouponCodeMutationHookResult = ReturnType<typeof useApplyCouponCodeMutation>;
export type ApplyCouponCodeMutationResult = Apollo.MutationResult<ApplyCouponCodeMutation>;
export type ApplyCouponCodeMutationOptions = Apollo.BaseMutationOptions<ApplyCouponCodeMutation, ApplyCouponCodeMutationVariables>;
export const CreateVideoDocument = gql`
    mutation CreateVideo($filmingGroupId: Int!, $templateId: Int!, $storymakerId: Int!) {
  createVideo(
    filmingGroupId: $filmingGroupId
    templateId: $templateId
    storymakerId: $storymakerId
  ) {
    __typename
    ... on Video {
      id
    }
    ... on ValidationError {
      message
    }
  }
}
    `;
export type CreateVideoMutationFn = Apollo.MutationFunction<CreateVideoMutation, CreateVideoMutationVariables>;

/**
 * __useCreateVideoMutation__
 *
 * To run a mutation, you first call `useCreateVideoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateVideoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVideoMutation, { data, loading, error }] = useCreateVideoMutation({
 *   variables: {
 *      filmingGroupId: // value for 'filmingGroupId'
 *      templateId: // value for 'templateId'
 *      storymakerId: // value for 'storymakerId'
 *   },
 * });
 */
export function useCreateVideoMutation(baseOptions?: Apollo.MutationHookOptions<CreateVideoMutation, CreateVideoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateVideoMutation, CreateVideoMutationVariables>(CreateVideoDocument, options);
      }
export type CreateVideoMutationHookResult = ReturnType<typeof useCreateVideoMutation>;
export type CreateVideoMutationResult = Apollo.MutationResult<CreateVideoMutation>;
export type CreateVideoMutationOptions = Apollo.BaseMutationOptions<CreateVideoMutation, CreateVideoMutationVariables>;
export const ExportVideoAssetsDocument = gql`
    mutation ExportVideoAssets($videoIds: [Int!]) {
  exportVideoAssets(videoIds: $videoIds) {
    ... on GenericError {
      message
    }
  }
}
    `;
export type ExportVideoAssetsMutationFn = Apollo.MutationFunction<ExportVideoAssetsMutation, ExportVideoAssetsMutationVariables>;

/**
 * __useExportVideoAssetsMutation__
 *
 * To run a mutation, you first call `useExportVideoAssetsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportVideoAssetsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportVideoAssetsMutation, { data, loading, error }] = useExportVideoAssetsMutation({
 *   variables: {
 *      videoIds: // value for 'videoIds'
 *   },
 * });
 */
export function useExportVideoAssetsMutation(baseOptions?: Apollo.MutationHookOptions<ExportVideoAssetsMutation, ExportVideoAssetsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExportVideoAssetsMutation, ExportVideoAssetsMutationVariables>(ExportVideoAssetsDocument, options);
      }
export type ExportVideoAssetsMutationHookResult = ReturnType<typeof useExportVideoAssetsMutation>;
export type ExportVideoAssetsMutationResult = Apollo.MutationResult<ExportVideoAssetsMutation>;
export type ExportVideoAssetsMutationOptions = Apollo.BaseMutationOptions<ExportVideoAssetsMutation, ExportVideoAssetsMutationVariables>;
export const LoginUpgradeDocument = gql`
    mutation LoginUpgrade($email: String!, $password: String!) {
  loginUpgrade(email: $email, password: $password) {
    code
    message
  }
}
    `;
export type LoginUpgradeMutationFn = Apollo.MutationFunction<LoginUpgradeMutation, LoginUpgradeMutationVariables>;

/**
 * __useLoginUpgradeMutation__
 *
 * To run a mutation, you first call `useLoginUpgradeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginUpgradeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginUpgradeMutation, { data, loading, error }] = useLoginUpgradeMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginUpgradeMutation(baseOptions?: Apollo.MutationHookOptions<LoginUpgradeMutation, LoginUpgradeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginUpgradeMutation, LoginUpgradeMutationVariables>(LoginUpgradeDocument, options);
      }
export type LoginUpgradeMutationHookResult = ReturnType<typeof useLoginUpgradeMutation>;
export type LoginUpgradeMutationResult = Apollo.MutationResult<LoginUpgradeMutation>;
export type LoginUpgradeMutationOptions = Apollo.BaseMutationOptions<LoginUpgradeMutation, LoginUpgradeMutationVariables>;
export const SendResetPasswordEmailDocument = gql`
    mutation SendResetPasswordEmail($email: String!) {
  sendResetPasswordEmail(email: $email)
}
    `;
export type SendResetPasswordEmailMutationFn = Apollo.MutationFunction<SendResetPasswordEmailMutation, SendResetPasswordEmailMutationVariables>;

/**
 * __useSendResetPasswordEmailMutation__
 *
 * To run a mutation, you first call `useSendResetPasswordEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendResetPasswordEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendResetPasswordEmailMutation, { data, loading, error }] = useSendResetPasswordEmailMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useSendResetPasswordEmailMutation(baseOptions?: Apollo.MutationHookOptions<SendResetPasswordEmailMutation, SendResetPasswordEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendResetPasswordEmailMutation, SendResetPasswordEmailMutationVariables>(SendResetPasswordEmailDocument, options);
      }
export type SendResetPasswordEmailMutationHookResult = ReturnType<typeof useSendResetPasswordEmailMutation>;
export type SendResetPasswordEmailMutationResult = Apollo.MutationResult<SendResetPasswordEmailMutation>;
export type SendResetPasswordEmailMutationOptions = Apollo.BaseMutationOptions<SendResetPasswordEmailMutation, SendResetPasswordEmailMutationVariables>;
export const CompleteResetPasswordDocument = gql`
    mutation CompleteResetPassword($email: String!, $oobCode: String!, $newPassword: String!) {
  completeResetPassword(
    email: $email
    oobCode: $oobCode
    newPassword: $newPassword
  ) {
    ... on AdminUser {
      email
    }
    ... on ValidationError {
      message
    }
  }
}
    `;
export type CompleteResetPasswordMutationFn = Apollo.MutationFunction<CompleteResetPasswordMutation, CompleteResetPasswordMutationVariables>;

/**
 * __useCompleteResetPasswordMutation__
 *
 * To run a mutation, you first call `useCompleteResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeResetPasswordMutation, { data, loading, error }] = useCompleteResetPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *      oobCode: // value for 'oobCode'
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function useCompleteResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<CompleteResetPasswordMutation, CompleteResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompleteResetPasswordMutation, CompleteResetPasswordMutationVariables>(CompleteResetPasswordDocument, options);
      }
export type CompleteResetPasswordMutationHookResult = ReturnType<typeof useCompleteResetPasswordMutation>;
export type CompleteResetPasswordMutationResult = Apollo.MutationResult<CompleteResetPasswordMutation>;
export type CompleteResetPasswordMutationOptions = Apollo.BaseMutationOptions<CompleteResetPasswordMutation, CompleteResetPasswordMutationVariables>;
export const ResendVideoReadyEmailDocument = gql`
    mutation ResendVideoReadyEmail($videoId: Int!, $recipients: ResendVideoReadyEmailRecipientType!) {
  resendVideoReadyEmail(videoId: $videoId, recipients: $recipients) {
    code
    message
  }
}
    `;
export type ResendVideoReadyEmailMutationFn = Apollo.MutationFunction<ResendVideoReadyEmailMutation, ResendVideoReadyEmailMutationVariables>;

/**
 * __useResendVideoReadyEmailMutation__
 *
 * To run a mutation, you first call `useResendVideoReadyEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendVideoReadyEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendVideoReadyEmailMutation, { data, loading, error }] = useResendVideoReadyEmailMutation({
 *   variables: {
 *      videoId: // value for 'videoId'
 *      recipients: // value for 'recipients'
 *   },
 * });
 */
export function useResendVideoReadyEmailMutation(baseOptions?: Apollo.MutationHookOptions<ResendVideoReadyEmailMutation, ResendVideoReadyEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResendVideoReadyEmailMutation, ResendVideoReadyEmailMutationVariables>(ResendVideoReadyEmailDocument, options);
      }
export type ResendVideoReadyEmailMutationHookResult = ReturnType<typeof useResendVideoReadyEmailMutation>;
export type ResendVideoReadyEmailMutationResult = Apollo.MutationResult<ResendVideoReadyEmailMutation>;
export type ResendVideoReadyEmailMutationOptions = Apollo.BaseMutationOptions<ResendVideoReadyEmailMutation, ResendVideoReadyEmailMutationVariables>;
export const ExportVideosCsvDocument = gql`
    mutation ExportVideosCsv($filter: ExportVideosCsvFilterInput) {
  exportVideosCsv(filter: $filter) {
    ... on GenericError {
      message
    }
  }
}
    `;
export type ExportVideosCsvMutationFn = Apollo.MutationFunction<ExportVideosCsvMutation, ExportVideosCsvMutationVariables>;

/**
 * __useExportVideosCsvMutation__
 *
 * To run a mutation, you first call `useExportVideosCsvMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportVideosCsvMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportVideosCsvMutation, { data, loading, error }] = useExportVideosCsvMutation({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useExportVideosCsvMutation(baseOptions?: Apollo.MutationHookOptions<ExportVideosCsvMutation, ExportVideosCsvMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExportVideosCsvMutation, ExportVideosCsvMutationVariables>(ExportVideosCsvDocument, options);
      }
export type ExportVideosCsvMutationHookResult = ReturnType<typeof useExportVideosCsvMutation>;
export type ExportVideosCsvMutationResult = Apollo.MutationResult<ExportVideosCsvMutation>;
export type ExportVideosCsvMutationOptions = Apollo.BaseMutationOptions<ExportVideosCsvMutation, ExportVideosCsvMutationVariables>;
export const ExportPackagesAndAssetsDocument = gql`
    mutation ExportPackagesAndAssets($filter: ExportVideosCsvFilterInput) {
  exportPackagesAndAssets(filter: $filter) {
    ... on FileExport {
      totalKeys
    }
    ... on GenericError {
      message
    }
  }
}
    `;
export type ExportPackagesAndAssetsMutationFn = Apollo.MutationFunction<ExportPackagesAndAssetsMutation, ExportPackagesAndAssetsMutationVariables>;

/**
 * __useExportPackagesAndAssetsMutation__
 *
 * To run a mutation, you first call `useExportPackagesAndAssetsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportPackagesAndAssetsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportPackagesAndAssetsMutation, { data, loading, error }] = useExportPackagesAndAssetsMutation({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useExportPackagesAndAssetsMutation(baseOptions?: Apollo.MutationHookOptions<ExportPackagesAndAssetsMutation, ExportPackagesAndAssetsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExportPackagesAndAssetsMutation, ExportPackagesAndAssetsMutationVariables>(ExportPackagesAndAssetsDocument, options);
      }
export type ExportPackagesAndAssetsMutationHookResult = ReturnType<typeof useExportPackagesAndAssetsMutation>;
export type ExportPackagesAndAssetsMutationResult = Apollo.MutationResult<ExportPackagesAndAssetsMutation>;
export type ExportPackagesAndAssetsMutationOptions = Apollo.BaseMutationOptions<ExportPackagesAndAssetsMutation, ExportPackagesAndAssetsMutationVariables>;
export const ResendInviteStorymakerEmailDocument = gql`
    mutation ResendInviteStorymakerEmail($storymakerIds: [Int!]!) {
  resendInviteStorymakerEmail(storymakerIds: $storymakerIds) {
    ... on GenericError {
      message
    }
  }
}
    `;
export type ResendInviteStorymakerEmailMutationFn = Apollo.MutationFunction<ResendInviteStorymakerEmailMutation, ResendInviteStorymakerEmailMutationVariables>;

/**
 * __useResendInviteStorymakerEmailMutation__
 *
 * To run a mutation, you first call `useResendInviteStorymakerEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendInviteStorymakerEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendInviteStorymakerEmailMutation, { data, loading, error }] = useResendInviteStorymakerEmailMutation({
 *   variables: {
 *      storymakerIds: // value for 'storymakerIds'
 *   },
 * });
 */
export function useResendInviteStorymakerEmailMutation(baseOptions?: Apollo.MutationHookOptions<ResendInviteStorymakerEmailMutation, ResendInviteStorymakerEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResendInviteStorymakerEmailMutation, ResendInviteStorymakerEmailMutationVariables>(ResendInviteStorymakerEmailDocument, options);
      }
export type ResendInviteStorymakerEmailMutationHookResult = ReturnType<typeof useResendInviteStorymakerEmailMutation>;
export type ResendInviteStorymakerEmailMutationResult = Apollo.MutationResult<ResendInviteStorymakerEmailMutation>;
export type ResendInviteStorymakerEmailMutationOptions = Apollo.BaseMutationOptions<ResendInviteStorymakerEmailMutation, ResendInviteStorymakerEmailMutationVariables>;
export const UpdateAdditionalEmailRecipientsDocument = gql`
    mutation UpdateAdditionalEmailRecipients($additionalEmailRecipients: [String!]!) {
  updateAdditionalEmailRecipients(
    additionalEmailRecipients: $additionalEmailRecipients
  ) {
    ... on GenericError {
      message
    }
    ... on Organization {
      id
      additionalEmailRecipients
    }
  }
}
    `;
export type UpdateAdditionalEmailRecipientsMutationFn = Apollo.MutationFunction<UpdateAdditionalEmailRecipientsMutation, UpdateAdditionalEmailRecipientsMutationVariables>;

/**
 * __useUpdateAdditionalEmailRecipientsMutation__
 *
 * To run a mutation, you first call `useUpdateAdditionalEmailRecipientsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAdditionalEmailRecipientsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAdditionalEmailRecipientsMutation, { data, loading, error }] = useUpdateAdditionalEmailRecipientsMutation({
 *   variables: {
 *      additionalEmailRecipients: // value for 'additionalEmailRecipients'
 *   },
 * });
 */
export function useUpdateAdditionalEmailRecipientsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAdditionalEmailRecipientsMutation, UpdateAdditionalEmailRecipientsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAdditionalEmailRecipientsMutation, UpdateAdditionalEmailRecipientsMutationVariables>(UpdateAdditionalEmailRecipientsDocument, options);
      }
export type UpdateAdditionalEmailRecipientsMutationHookResult = ReturnType<typeof useUpdateAdditionalEmailRecipientsMutation>;
export type UpdateAdditionalEmailRecipientsMutationResult = Apollo.MutationResult<UpdateAdditionalEmailRecipientsMutation>;
export type UpdateAdditionalEmailRecipientsMutationOptions = Apollo.BaseMutationOptions<UpdateAdditionalEmailRecipientsMutation, UpdateAdditionalEmailRecipientsMutationVariables>;
export const EnableAdminUserEmailNotificationsDocument = gql`
    mutation EnableAdminUserEmailNotifications($managerId: Int!) {
  enableAdminUserNotifications(managerId: $managerId) {
    ... on AdminUser {
      id
      disabledEmailNotifications
    }
    ... on GenericError {
      message
    }
  }
}
    `;
export type EnableAdminUserEmailNotificationsMutationFn = Apollo.MutationFunction<EnableAdminUserEmailNotificationsMutation, EnableAdminUserEmailNotificationsMutationVariables>;

/**
 * __useEnableAdminUserEmailNotificationsMutation__
 *
 * To run a mutation, you first call `useEnableAdminUserEmailNotificationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnableAdminUserEmailNotificationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enableAdminUserEmailNotificationsMutation, { data, loading, error }] = useEnableAdminUserEmailNotificationsMutation({
 *   variables: {
 *      managerId: // value for 'managerId'
 *   },
 * });
 */
export function useEnableAdminUserEmailNotificationsMutation(baseOptions?: Apollo.MutationHookOptions<EnableAdminUserEmailNotificationsMutation, EnableAdminUserEmailNotificationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EnableAdminUserEmailNotificationsMutation, EnableAdminUserEmailNotificationsMutationVariables>(EnableAdminUserEmailNotificationsDocument, options);
      }
export type EnableAdminUserEmailNotificationsMutationHookResult = ReturnType<typeof useEnableAdminUserEmailNotificationsMutation>;
export type EnableAdminUserEmailNotificationsMutationResult = Apollo.MutationResult<EnableAdminUserEmailNotificationsMutation>;
export type EnableAdminUserEmailNotificationsMutationOptions = Apollo.BaseMutationOptions<EnableAdminUserEmailNotificationsMutation, EnableAdminUserEmailNotificationsMutationVariables>;
export const DisableAdminUserEmailNotificationsDocument = gql`
    mutation DisableAdminUserEmailNotifications($managerId: Int!) {
  disableAdminUserNotifications(managerId: $managerId) {
    ... on AdminUser {
      id
      disabledEmailNotifications
    }
    ... on GenericError {
      message
    }
  }
}
    `;
export type DisableAdminUserEmailNotificationsMutationFn = Apollo.MutationFunction<DisableAdminUserEmailNotificationsMutation, DisableAdminUserEmailNotificationsMutationVariables>;

/**
 * __useDisableAdminUserEmailNotificationsMutation__
 *
 * To run a mutation, you first call `useDisableAdminUserEmailNotificationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisableAdminUserEmailNotificationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disableAdminUserEmailNotificationsMutation, { data, loading, error }] = useDisableAdminUserEmailNotificationsMutation({
 *   variables: {
 *      managerId: // value for 'managerId'
 *   },
 * });
 */
export function useDisableAdminUserEmailNotificationsMutation(baseOptions?: Apollo.MutationHookOptions<DisableAdminUserEmailNotificationsMutation, DisableAdminUserEmailNotificationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DisableAdminUserEmailNotificationsMutation, DisableAdminUserEmailNotificationsMutationVariables>(DisableAdminUserEmailNotificationsDocument, options);
      }
export type DisableAdminUserEmailNotificationsMutationHookResult = ReturnType<typeof useDisableAdminUserEmailNotificationsMutation>;
export type DisableAdminUserEmailNotificationsMutationResult = Apollo.MutationResult<DisableAdminUserEmailNotificationsMutation>;
export type DisableAdminUserEmailNotificationsMutationOptions = Apollo.BaseMutationOptions<DisableAdminUserEmailNotificationsMutation, DisableAdminUserEmailNotificationsMutationVariables>;
export const EditStorymakerDocument = gql`
    mutation EditStorymaker($storymakerId: Int!, $input: EditStorymakerInput!) {
  editStorymaker(storymakerId: $storymakerId, input: $input) {
    ... on Storymaker {
      id
      email
      firstName
      lastName
    }
    ... on GenericError {
      message
    }
  }
}
    `;
export type EditStorymakerMutationFn = Apollo.MutationFunction<EditStorymakerMutation, EditStorymakerMutationVariables>;

/**
 * __useEditStorymakerMutation__
 *
 * To run a mutation, you first call `useEditStorymakerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditStorymakerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editStorymakerMutation, { data, loading, error }] = useEditStorymakerMutation({
 *   variables: {
 *      storymakerId: // value for 'storymakerId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditStorymakerMutation(baseOptions?: Apollo.MutationHookOptions<EditStorymakerMutation, EditStorymakerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditStorymakerMutation, EditStorymakerMutationVariables>(EditStorymakerDocument, options);
      }
export type EditStorymakerMutationHookResult = ReturnType<typeof useEditStorymakerMutation>;
export type EditStorymakerMutationResult = Apollo.MutationResult<EditStorymakerMutation>;
export type EditStorymakerMutationOptions = Apollo.BaseMutationOptions<EditStorymakerMutation, EditStorymakerMutationVariables>;
export const UpdateUserCustomPropertiesAndSignupDataDocument = gql`
    mutation UpdateUserCustomPropertiesAndSignupData($appUserId: Int!, $properties: JSON, $signupData: JSON) {
  updateUserCustomPropertiesAndSignupData(
    appUserId: $appUserId
    properties: $properties
    signupData: $signupData
  ) {
    ... on Storymaker {
      userCustomProperties {
        ...customPropertiesFragment
      }
      userSignupProperties
    }
    ... on Reviewer {
      userCustomProperties {
        ...customPropertiesFragment
      }
      userSignupProperties
    }
    ... on GenericError {
      message
    }
  }
}
    ${CustomPropertiesFragmentFragmentDoc}`;
export type UpdateUserCustomPropertiesAndSignupDataMutationFn = Apollo.MutationFunction<UpdateUserCustomPropertiesAndSignupDataMutation, UpdateUserCustomPropertiesAndSignupDataMutationVariables>;

/**
 * __useUpdateUserCustomPropertiesAndSignupDataMutation__
 *
 * To run a mutation, you first call `useUpdateUserCustomPropertiesAndSignupDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserCustomPropertiesAndSignupDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserCustomPropertiesAndSignupDataMutation, { data, loading, error }] = useUpdateUserCustomPropertiesAndSignupDataMutation({
 *   variables: {
 *      appUserId: // value for 'appUserId'
 *      properties: // value for 'properties'
 *      signupData: // value for 'signupData'
 *   },
 * });
 */
export function useUpdateUserCustomPropertiesAndSignupDataMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserCustomPropertiesAndSignupDataMutation, UpdateUserCustomPropertiesAndSignupDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserCustomPropertiesAndSignupDataMutation, UpdateUserCustomPropertiesAndSignupDataMutationVariables>(UpdateUserCustomPropertiesAndSignupDataDocument, options);
      }
export type UpdateUserCustomPropertiesAndSignupDataMutationHookResult = ReturnType<typeof useUpdateUserCustomPropertiesAndSignupDataMutation>;
export type UpdateUserCustomPropertiesAndSignupDataMutationResult = Apollo.MutationResult<UpdateUserCustomPropertiesAndSignupDataMutation>;
export type UpdateUserCustomPropertiesAndSignupDataMutationOptions = Apollo.BaseMutationOptions<UpdateUserCustomPropertiesAndSignupDataMutation, UpdateUserCustomPropertiesAndSignupDataMutationVariables>;
export const DeleteStorymakerDocument = gql`
    mutation DeleteStorymaker($storymakerId: Int!) {
  deleteStorymaker(storymakerId: $storymakerId) {
    ... on Storymaker {
      id
      deleted
    }
    ... on GenericError {
      message
    }
  }
}
    `;
export type DeleteStorymakerMutationFn = Apollo.MutationFunction<DeleteStorymakerMutation, DeleteStorymakerMutationVariables>;

/**
 * __useDeleteStorymakerMutation__
 *
 * To run a mutation, you first call `useDeleteStorymakerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStorymakerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStorymakerMutation, { data, loading, error }] = useDeleteStorymakerMutation({
 *   variables: {
 *      storymakerId: // value for 'storymakerId'
 *   },
 * });
 */
export function useDeleteStorymakerMutation(baseOptions?: Apollo.MutationHookOptions<DeleteStorymakerMutation, DeleteStorymakerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteStorymakerMutation, DeleteStorymakerMutationVariables>(DeleteStorymakerDocument, options);
      }
export type DeleteStorymakerMutationHookResult = ReturnType<typeof useDeleteStorymakerMutation>;
export type DeleteStorymakerMutationResult = Apollo.MutationResult<DeleteStorymakerMutation>;
export type DeleteStorymakerMutationOptions = Apollo.BaseMutationOptions<DeleteStorymakerMutation, DeleteStorymakerMutationVariables>;
export const UpdateTextCapturesDocument = gql`
    mutation UpdateTextCaptures($inputs: [TextCaptureInput!]!) {
  updateTextCaptures(inputs: $inputs) {
    ... on TextCapture {
      ...textCaptureLabelFragment
    }
    ... on GenericError {
      message
    }
  }
}
    ${TextCaptureLabelFragmentFragmentDoc}`;
export type UpdateTextCapturesMutationFn = Apollo.MutationFunction<UpdateTextCapturesMutation, UpdateTextCapturesMutationVariables>;

/**
 * __useUpdateTextCapturesMutation__
 *
 * To run a mutation, you first call `useUpdateTextCapturesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTextCapturesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTextCapturesMutation, { data, loading, error }] = useUpdateTextCapturesMutation({
 *   variables: {
 *      inputs: // value for 'inputs'
 *   },
 * });
 */
export function useUpdateTextCapturesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTextCapturesMutation, UpdateTextCapturesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTextCapturesMutation, UpdateTextCapturesMutationVariables>(UpdateTextCapturesDocument, options);
      }
export type UpdateTextCapturesMutationHookResult = ReturnType<typeof useUpdateTextCapturesMutation>;
export type UpdateTextCapturesMutationResult = Apollo.MutationResult<UpdateTextCapturesMutation>;
export type UpdateTextCapturesMutationOptions = Apollo.BaseMutationOptions<UpdateTextCapturesMutation, UpdateTextCapturesMutationVariables>;
export const UpdateCaptureStepsListDocument = gql`
    mutation UpdateCaptureStepsList($input: CaptureStepsListInput!) {
  updateCaptureStepsList(input: $input) {
    ... on CaptureStepsList {
      id
      name
      captureSteps {
        ...captureStepFragment
      }
    }
    ... on GenericError {
      __typename
      message
    }
  }
}
    ${CaptureStepFragmentFragmentDoc}`;
export type UpdateCaptureStepsListMutationFn = Apollo.MutationFunction<UpdateCaptureStepsListMutation, UpdateCaptureStepsListMutationVariables>;

/**
 * __useUpdateCaptureStepsListMutation__
 *
 * To run a mutation, you first call `useUpdateCaptureStepsListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCaptureStepsListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCaptureStepsListMutation, { data, loading, error }] = useUpdateCaptureStepsListMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCaptureStepsListMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCaptureStepsListMutation, UpdateCaptureStepsListMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCaptureStepsListMutation, UpdateCaptureStepsListMutationVariables>(UpdateCaptureStepsListDocument, options);
      }
export type UpdateCaptureStepsListMutationHookResult = ReturnType<typeof useUpdateCaptureStepsListMutation>;
export type UpdateCaptureStepsListMutationResult = Apollo.MutationResult<UpdateCaptureStepsListMutation>;
export type UpdateCaptureStepsListMutationOptions = Apollo.BaseMutationOptions<UpdateCaptureStepsListMutation, UpdateCaptureStepsListMutationVariables>;
export const EditTemplateDocument = gql`
    mutation EditTemplate($input: EditTemplateInput!, $templateId: Int!) {
  editTemplate(input: $input, templateId: $templateId) {
    ... on Template {
      id
      name
      description
      shouldSaveToCameraRoll
      isStorymakerAddedEmailsEnabled
      isReviewRequired
    }
    ... on GenericError {
      message
    }
  }
}
    `;
export type EditTemplateMutationFn = Apollo.MutationFunction<EditTemplateMutation, EditTemplateMutationVariables>;

/**
 * __useEditTemplateMutation__
 *
 * To run a mutation, you first call `useEditTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editTemplateMutation, { data, loading, error }] = useEditTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *      templateId: // value for 'templateId'
 *   },
 * });
 */
export function useEditTemplateMutation(baseOptions?: Apollo.MutationHookOptions<EditTemplateMutation, EditTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditTemplateMutation, EditTemplateMutationVariables>(EditTemplateDocument, options);
      }
export type EditTemplateMutationHookResult = ReturnType<typeof useEditTemplateMutation>;
export type EditTemplateMutationResult = Apollo.MutationResult<EditTemplateMutation>;
export type EditTemplateMutationOptions = Apollo.BaseMutationOptions<EditTemplateMutation, EditTemplateMutationVariables>;
export const CreateOrganizationDocument = gql`
    mutation CreateOrganization($input: CreateOrganizationInput!) {
  createOrganization(input: $input) {
    ... on Organization {
      ...organizationTableInfo
    }
    ... on GenericError {
      message
    }
  }
}
    ${OrganizationTableInfoFragmentDoc}`;
export type CreateOrganizationMutationFn = Apollo.MutationFunction<CreateOrganizationMutation, CreateOrganizationMutationVariables>;

/**
 * __useCreateOrganizationMutation__
 *
 * To run a mutation, you first call `useCreateOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrganizationMutation, { data, loading, error }] = useCreateOrganizationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrganizationMutation, CreateOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrganizationMutation, CreateOrganizationMutationVariables>(CreateOrganizationDocument, options);
      }
export type CreateOrganizationMutationHookResult = ReturnType<typeof useCreateOrganizationMutation>;
export type CreateOrganizationMutationResult = Apollo.MutationResult<CreateOrganizationMutation>;
export type CreateOrganizationMutationOptions = Apollo.BaseMutationOptions<CreateOrganizationMutation, CreateOrganizationMutationVariables>;
export const MigrateOrganizationDocument = gql`
    mutation MigrateOrganization($organizationId: Int!) {
  migrateOrganization(organizationId: $organizationId) {
    ... on Organization {
      id
      nowDashboardFlag
    }
    ... on GenericError {
      message
    }
  }
}
    `;
export type MigrateOrganizationMutationFn = Apollo.MutationFunction<MigrateOrganizationMutation, MigrateOrganizationMutationVariables>;

/**
 * __useMigrateOrganizationMutation__
 *
 * To run a mutation, you first call `useMigrateOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMigrateOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [migrateOrganizationMutation, { data, loading, error }] = useMigrateOrganizationMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useMigrateOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<MigrateOrganizationMutation, MigrateOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MigrateOrganizationMutation, MigrateOrganizationMutationVariables>(MigrateOrganizationDocument, options);
      }
export type MigrateOrganizationMutationHookResult = ReturnType<typeof useMigrateOrganizationMutation>;
export type MigrateOrganizationMutationResult = Apollo.MutationResult<MigrateOrganizationMutation>;
export type MigrateOrganizationMutationOptions = Apollo.BaseMutationOptions<MigrateOrganizationMutation, MigrateOrganizationMutationVariables>;
export const AssignTemplatesToFilmingGroupDocument = gql`
    mutation AssignTemplatesToFilmingGroup($filmingGroupId: Int!, $templateIds: [Int!]!) {
  assignTemplatesToFilmingGroup(
    filmingGroupId: $filmingGroupId
    templateIds: $templateIds
  ) {
    ... on GenericError {
      message
    }
    ... on FilmingGroup {
      id
      templates {
        id
        name
        previewVideo {
          id
          previewURL
          thumbnailURLInline
        }
      }
    }
  }
}
    `;
export type AssignTemplatesToFilmingGroupMutationFn = Apollo.MutationFunction<AssignTemplatesToFilmingGroupMutation, AssignTemplatesToFilmingGroupMutationVariables>;

/**
 * __useAssignTemplatesToFilmingGroupMutation__
 *
 * To run a mutation, you first call `useAssignTemplatesToFilmingGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignTemplatesToFilmingGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignTemplatesToFilmingGroupMutation, { data, loading, error }] = useAssignTemplatesToFilmingGroupMutation({
 *   variables: {
 *      filmingGroupId: // value for 'filmingGroupId'
 *      templateIds: // value for 'templateIds'
 *   },
 * });
 */
export function useAssignTemplatesToFilmingGroupMutation(baseOptions?: Apollo.MutationHookOptions<AssignTemplatesToFilmingGroupMutation, AssignTemplatesToFilmingGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AssignTemplatesToFilmingGroupMutation, AssignTemplatesToFilmingGroupMutationVariables>(AssignTemplatesToFilmingGroupDocument, options);
      }
export type AssignTemplatesToFilmingGroupMutationHookResult = ReturnType<typeof useAssignTemplatesToFilmingGroupMutation>;
export type AssignTemplatesToFilmingGroupMutationResult = Apollo.MutationResult<AssignTemplatesToFilmingGroupMutation>;
export type AssignTemplatesToFilmingGroupMutationOptions = Apollo.BaseMutationOptions<AssignTemplatesToFilmingGroupMutation, AssignTemplatesToFilmingGroupMutationVariables>;
export const UnassignTemplatesFromFilmingGroupDocument = gql`
    mutation UnassignTemplatesFromFilmingGroup($filmingGroupId: Int!, $templateIds: [Int!]!) {
  unassignTemplatesFromFilmingGroup(
    filmingGroupId: $filmingGroupId
    templateIds: $templateIds
  ) {
    ... on GenericError {
      message
    }
    ... on FilmingGroup {
      id
      templates {
        id
        name
        previewVideo {
          id
          previewURL
          thumbnailURLInline
        }
      }
    }
  }
}
    `;
export type UnassignTemplatesFromFilmingGroupMutationFn = Apollo.MutationFunction<UnassignTemplatesFromFilmingGroupMutation, UnassignTemplatesFromFilmingGroupMutationVariables>;

/**
 * __useUnassignTemplatesFromFilmingGroupMutation__
 *
 * To run a mutation, you first call `useUnassignTemplatesFromFilmingGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnassignTemplatesFromFilmingGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unassignTemplatesFromFilmingGroupMutation, { data, loading, error }] = useUnassignTemplatesFromFilmingGroupMutation({
 *   variables: {
 *      filmingGroupId: // value for 'filmingGroupId'
 *      templateIds: // value for 'templateIds'
 *   },
 * });
 */
export function useUnassignTemplatesFromFilmingGroupMutation(baseOptions?: Apollo.MutationHookOptions<UnassignTemplatesFromFilmingGroupMutation, UnassignTemplatesFromFilmingGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnassignTemplatesFromFilmingGroupMutation, UnassignTemplatesFromFilmingGroupMutationVariables>(UnassignTemplatesFromFilmingGroupDocument, options);
      }
export type UnassignTemplatesFromFilmingGroupMutationHookResult = ReturnType<typeof useUnassignTemplatesFromFilmingGroupMutation>;
export type UnassignTemplatesFromFilmingGroupMutationResult = Apollo.MutationResult<UnassignTemplatesFromFilmingGroupMutation>;
export type UnassignTemplatesFromFilmingGroupMutationOptions = Apollo.BaseMutationOptions<UnassignTemplatesFromFilmingGroupMutation, UnassignTemplatesFromFilmingGroupMutationVariables>;
export const CreateFilmingGroupDocument = gql`
    mutation CreateFilmingGroup($organizationId: Int!, $input: UpsertFilmingGroupInput!) {
  createFilmingGroup(organizationId: $organizationId, input: $input) {
    ...filmingGroupsFragment
  }
}
    ${FilmingGroupsFragmentFragmentDoc}`;
export type CreateFilmingGroupMutationFn = Apollo.MutationFunction<CreateFilmingGroupMutation, CreateFilmingGroupMutationVariables>;

/**
 * __useCreateFilmingGroupMutation__
 *
 * To run a mutation, you first call `useCreateFilmingGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFilmingGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFilmingGroupMutation, { data, loading, error }] = useCreateFilmingGroupMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateFilmingGroupMutation(baseOptions?: Apollo.MutationHookOptions<CreateFilmingGroupMutation, CreateFilmingGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFilmingGroupMutation, CreateFilmingGroupMutationVariables>(CreateFilmingGroupDocument, options);
      }
export type CreateFilmingGroupMutationHookResult = ReturnType<typeof useCreateFilmingGroupMutation>;
export type CreateFilmingGroupMutationResult = Apollo.MutationResult<CreateFilmingGroupMutation>;
export type CreateFilmingGroupMutationOptions = Apollo.BaseMutationOptions<CreateFilmingGroupMutation, CreateFilmingGroupMutationVariables>;
export const UpdateFilmingGroupDocument = gql`
    mutation UpdateFilmingGroup($filmingGroupId: Int!, $input: UpsertFilmingGroupInput!) {
  updateFilmingGroup(filmingGroupId: $filmingGroupId, input: $input) {
    ...filmingGroupsFragment
  }
}
    ${FilmingGroupsFragmentFragmentDoc}`;
export type UpdateFilmingGroupMutationFn = Apollo.MutationFunction<UpdateFilmingGroupMutation, UpdateFilmingGroupMutationVariables>;

/**
 * __useUpdateFilmingGroupMutation__
 *
 * To run a mutation, you first call `useUpdateFilmingGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFilmingGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFilmingGroupMutation, { data, loading, error }] = useUpdateFilmingGroupMutation({
 *   variables: {
 *      filmingGroupId: // value for 'filmingGroupId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateFilmingGroupMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFilmingGroupMutation, UpdateFilmingGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFilmingGroupMutation, UpdateFilmingGroupMutationVariables>(UpdateFilmingGroupDocument, options);
      }
export type UpdateFilmingGroupMutationHookResult = ReturnType<typeof useUpdateFilmingGroupMutation>;
export type UpdateFilmingGroupMutationResult = Apollo.MutationResult<UpdateFilmingGroupMutation>;
export type UpdateFilmingGroupMutationOptions = Apollo.BaseMutationOptions<UpdateFilmingGroupMutation, UpdateFilmingGroupMutationVariables>;
export const ShareToVimeoDocument = gql`
    mutation ShareToVimeo($input: ShareToVimeoInput!) {
  shareToVimeo(input: $input) {
    ... on GenericError {
      message
    }
  }
}
    `;
export type ShareToVimeoMutationFn = Apollo.MutationFunction<ShareToVimeoMutation, ShareToVimeoMutationVariables>;

/**
 * __useShareToVimeoMutation__
 *
 * To run a mutation, you first call `useShareToVimeoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShareToVimeoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shareToVimeoMutation, { data, loading, error }] = useShareToVimeoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useShareToVimeoMutation(baseOptions?: Apollo.MutationHookOptions<ShareToVimeoMutation, ShareToVimeoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ShareToVimeoMutation, ShareToVimeoMutationVariables>(ShareToVimeoDocument, options);
      }
export type ShareToVimeoMutationHookResult = ReturnType<typeof useShareToVimeoMutation>;
export type ShareToVimeoMutationResult = Apollo.MutationResult<ShareToVimeoMutation>;
export type ShareToVimeoMutationOptions = Apollo.BaseMutationOptions<ShareToVimeoMutation, ShareToVimeoMutationVariables>;
export const ChangePasswordDocument = gql`
    mutation ChangePassword($oldPassword: String!, $newPassword: String!) {
  changePassword(oldPassword: $oldPassword, newPassword: $newPassword) {
    ... on ValidationError {
      message
    }
  }
}
    `;
export type ChangePasswordMutationFn = Apollo.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      oldPassword: // value for 'oldPassword'
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function useChangePasswordMutation(baseOptions?: Apollo.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument, options);
      }
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = Apollo.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<ChangePasswordMutation, ChangePasswordMutationVariables>;
export const CreateFeedbackDocument = gql`
    mutation CreateFeedback($input: CreateFeedbackInput!) {
  createFeedback(feedback: $input) {
    ... on VideoFeedback {
      id
      packageId
      packageStatus
      feedback
      appUserId
      adminUserId
      createdAt
      creator {
        __typename
      }
    }
    ... on GenericError {
      message
    }
  }
}
    `;
export type CreateFeedbackMutationFn = Apollo.MutationFunction<CreateFeedbackMutation, CreateFeedbackMutationVariables>;

/**
 * __useCreateFeedbackMutation__
 *
 * To run a mutation, you first call `useCreateFeedbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFeedbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFeedbackMutation, { data, loading, error }] = useCreateFeedbackMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateFeedbackMutation(baseOptions?: Apollo.MutationHookOptions<CreateFeedbackMutation, CreateFeedbackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFeedbackMutation, CreateFeedbackMutationVariables>(CreateFeedbackDocument, options);
      }
export type CreateFeedbackMutationHookResult = ReturnType<typeof useCreateFeedbackMutation>;
export type CreateFeedbackMutationResult = Apollo.MutationResult<CreateFeedbackMutation>;
export type CreateFeedbackMutationOptions = Apollo.BaseMutationOptions<CreateFeedbackMutation, CreateFeedbackMutationVariables>;
export const UpdateSubscriptionTypeDocument = gql`
    mutation UpdateSubscriptionType($organizationId: Int!, $subscriptionType: String!, $videoLinkExpiration: VideoLinkExpirationInput) {
  updateSubscriptionType(
    organizationId: $organizationId
    subscriptionType: $subscriptionType
    videoLinkExpiration: $videoLinkExpiration
  ) {
    ... on Organization {
      subscriptionType
      videoLinkExpiration
    }
    ... on GenericError {
      message
    }
  }
}
    `;
export type UpdateSubscriptionTypeMutationFn = Apollo.MutationFunction<UpdateSubscriptionTypeMutation, UpdateSubscriptionTypeMutationVariables>;

/**
 * __useUpdateSubscriptionTypeMutation__
 *
 * To run a mutation, you first call `useUpdateSubscriptionTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSubscriptionTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSubscriptionTypeMutation, { data, loading, error }] = useUpdateSubscriptionTypeMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      subscriptionType: // value for 'subscriptionType'
 *      videoLinkExpiration: // value for 'videoLinkExpiration'
 *   },
 * });
 */
export function useUpdateSubscriptionTypeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSubscriptionTypeMutation, UpdateSubscriptionTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSubscriptionTypeMutation, UpdateSubscriptionTypeMutationVariables>(UpdateSubscriptionTypeDocument, options);
      }
export type UpdateSubscriptionTypeMutationHookResult = ReturnType<typeof useUpdateSubscriptionTypeMutation>;
export type UpdateSubscriptionTypeMutationResult = Apollo.MutationResult<UpdateSubscriptionTypeMutation>;
export type UpdateSubscriptionTypeMutationOptions = Apollo.BaseMutationOptions<UpdateSubscriptionTypeMutation, UpdateSubscriptionTypeMutationVariables>;
export const UserLoginTokenActionDocument = gql`
    mutation userLoginTokenAction($email: String!, $password: String, $token: String, $ssoProvider: SSOProviders) {
  userLoginTokenAction(
    email: $email
    password: $password
    token: $token
    ssoProvider: $ssoProvider
  ) {
    ... on LoginToken {
      token
    }
    ... on GenericError {
      message
    }
  }
}
    `;
export type UserLoginTokenActionMutationFn = Apollo.MutationFunction<UserLoginTokenActionMutation, UserLoginTokenActionMutationVariables>;

/**
 * __useUserLoginTokenActionMutation__
 *
 * To run a mutation, you first call `useUserLoginTokenActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserLoginTokenActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userLoginTokenActionMutation, { data, loading, error }] = useUserLoginTokenActionMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      token: // value for 'token'
 *      ssoProvider: // value for 'ssoProvider'
 *   },
 * });
 */
export function useUserLoginTokenActionMutation(baseOptions?: Apollo.MutationHookOptions<UserLoginTokenActionMutation, UserLoginTokenActionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserLoginTokenActionMutation, UserLoginTokenActionMutationVariables>(UserLoginTokenActionDocument, options);
      }
export type UserLoginTokenActionMutationHookResult = ReturnType<typeof useUserLoginTokenActionMutation>;
export type UserLoginTokenActionMutationResult = Apollo.MutationResult<UserLoginTokenActionMutation>;
export type UserLoginTokenActionMutationOptions = Apollo.BaseMutationOptions<UserLoginTokenActionMutation, UserLoginTokenActionMutationVariables>;
export const ToggleAdminUserDisabledStateDocument = gql`
    mutation ToggleAdminUserDisabledState($managerId: Int!) {
  toggleAdminUserDisabledState(managerId: $managerId) {
    __typename
    ... on AdminUser {
      id
      disabled
    }
    ... on GenericError {
      message
    }
  }
}
    `;
export type ToggleAdminUserDisabledStateMutationFn = Apollo.MutationFunction<ToggleAdminUserDisabledStateMutation, ToggleAdminUserDisabledStateMutationVariables>;

/**
 * __useToggleAdminUserDisabledStateMutation__
 *
 * To run a mutation, you first call `useToggleAdminUserDisabledStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleAdminUserDisabledStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleAdminUserDisabledStateMutation, { data, loading, error }] = useToggleAdminUserDisabledStateMutation({
 *   variables: {
 *      managerId: // value for 'managerId'
 *   },
 * });
 */
export function useToggleAdminUserDisabledStateMutation(baseOptions?: Apollo.MutationHookOptions<ToggleAdminUserDisabledStateMutation, ToggleAdminUserDisabledStateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToggleAdminUserDisabledStateMutation, ToggleAdminUserDisabledStateMutationVariables>(ToggleAdminUserDisabledStateDocument, options);
      }
export type ToggleAdminUserDisabledStateMutationHookResult = ReturnType<typeof useToggleAdminUserDisabledStateMutation>;
export type ToggleAdminUserDisabledStateMutationResult = Apollo.MutationResult<ToggleAdminUserDisabledStateMutation>;
export type ToggleAdminUserDisabledStateMutationOptions = Apollo.BaseMutationOptions<ToggleAdminUserDisabledStateMutation, ToggleAdminUserDisabledStateMutationVariables>;
export const UserUpgradePasswordAndRolesDocument = gql`
    mutation userUpgradePasswordAndRoles($userEmail: String!, $oldPassword: String!, $newPassword: String!) {
  userUpgradePasswordAndRole(
    userEmail: $userEmail
    oldPassword: $oldPassword
    newPassword: $newPassword
  ) {
    ... on GenericError {
      message
      code
    }
    ... on ValidationError {
      message
    }
  }
}
    `;
export type UserUpgradePasswordAndRolesMutationFn = Apollo.MutationFunction<UserUpgradePasswordAndRolesMutation, UserUpgradePasswordAndRolesMutationVariables>;

/**
 * __useUserUpgradePasswordAndRolesMutation__
 *
 * To run a mutation, you first call `useUserUpgradePasswordAndRolesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserUpgradePasswordAndRolesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userUpgradePasswordAndRolesMutation, { data, loading, error }] = useUserUpgradePasswordAndRolesMutation({
 *   variables: {
 *      userEmail: // value for 'userEmail'
 *      oldPassword: // value for 'oldPassword'
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function useUserUpgradePasswordAndRolesMutation(baseOptions?: Apollo.MutationHookOptions<UserUpgradePasswordAndRolesMutation, UserUpgradePasswordAndRolesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserUpgradePasswordAndRolesMutation, UserUpgradePasswordAndRolesMutationVariables>(UserUpgradePasswordAndRolesDocument, options);
      }
export type UserUpgradePasswordAndRolesMutationHookResult = ReturnType<typeof useUserUpgradePasswordAndRolesMutation>;
export type UserUpgradePasswordAndRolesMutationResult = Apollo.MutationResult<UserUpgradePasswordAndRolesMutation>;
export type UserUpgradePasswordAndRolesMutationOptions = Apollo.BaseMutationOptions<UserUpgradePasswordAndRolesMutation, UserUpgradePasswordAndRolesMutationVariables>;
export const GenerateTranscriptDocument = gql`
    mutation GenerateTranscript($videoId: Int!) {
  generateTranscript(videoId: $videoId) {
    ... on Transcription {
      ...transcriptionPartFragment
    }
    ... on GenericError {
      message
    }
  }
}
    ${TranscriptionPartFragmentFragmentDoc}`;
export type GenerateTranscriptMutationFn = Apollo.MutationFunction<GenerateTranscriptMutation, GenerateTranscriptMutationVariables>;

/**
 * __useGenerateTranscriptMutation__
 *
 * To run a mutation, you first call `useGenerateTranscriptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateTranscriptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateTranscriptMutation, { data, loading, error }] = useGenerateTranscriptMutation({
 *   variables: {
 *      videoId: // value for 'videoId'
 *   },
 * });
 */
export function useGenerateTranscriptMutation(baseOptions?: Apollo.MutationHookOptions<GenerateTranscriptMutation, GenerateTranscriptMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateTranscriptMutation, GenerateTranscriptMutationVariables>(GenerateTranscriptDocument, options);
      }
export type GenerateTranscriptMutationHookResult = ReturnType<typeof useGenerateTranscriptMutation>;
export type GenerateTranscriptMutationResult = Apollo.MutationResult<GenerateTranscriptMutation>;
export type GenerateTranscriptMutationOptions = Apollo.BaseMutationOptions<GenerateTranscriptMutation, GenerateTranscriptMutationVariables>;
export const UpdateTranscriptDocument = gql`
    mutation UpdateTranscript($videoId: Int!, $data: [TranscriptionPartInput!]!) {
  updateTranscript(videoId: $videoId, data: $data) {
    ... on Transcription {
      ...transcriptionPartFragment
    }
    ... on GenericError {
      message
    }
  }
}
    ${TranscriptionPartFragmentFragmentDoc}`;
export type UpdateTranscriptMutationFn = Apollo.MutationFunction<UpdateTranscriptMutation, UpdateTranscriptMutationVariables>;

/**
 * __useUpdateTranscriptMutation__
 *
 * To run a mutation, you first call `useUpdateTranscriptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTranscriptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTranscriptMutation, { data, loading, error }] = useUpdateTranscriptMutation({
 *   variables: {
 *      videoId: // value for 'videoId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateTranscriptMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTranscriptMutation, UpdateTranscriptMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTranscriptMutation, UpdateTranscriptMutationVariables>(UpdateTranscriptDocument, options);
      }
export type UpdateTranscriptMutationHookResult = ReturnType<typeof useUpdateTranscriptMutation>;
export type UpdateTranscriptMutationResult = Apollo.MutationResult<UpdateTranscriptMutation>;
export type UpdateTranscriptMutationOptions = Apollo.BaseMutationOptions<UpdateTranscriptMutation, UpdateTranscriptMutationVariables>;
export const MeDocument = gql`
    query Me {
  me {
    ... on CurrentAdminUser {
      id
      email
      firstName
      lastName
      isPrimary
      organization {
        id
        name
        twoFactorAuthEnabled
        oauthInfo {
          ...oauthInfoFragment
        }
        subscription {
          status
          nextBillingDate
          plan {
            amount
            currency
          }
          product {
            name
            images
            checklist
          }
        }
      }
    }
    ... on SignUpData {
      id
      firstName
      lastName
      email
      organizationName
      organizationPhone
    }
    ... on GenericError {
      message
    }
  }
}
    ${OauthInfoFragmentFragmentDoc}`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const StorymakersDocument = gql`
    query Storymakers($pagination: PaginationInput, $filter: AppUserFilterInput, $sort: SortInput, $search: String) {
  appUsers(pagination: $pagination, filter: $filter, sort: $sort, search: $search) {
    data {
      ... on Node {
        id
      }
      ... on Storymaker {
        ...storymakerDetailInfo
      }
      ... on Reviewer {
        ...reviewerDetailInfo
      }
      ... on AppUserInterface {
        organizations {
          id
          name
        }
        filmingGroups {
          id
          name
          organizationId
        }
      }
    }
    pagination {
      ...paginationFragment
    }
  }
}
    ${StorymakerDetailInfoFragmentDoc}
${ReviewerDetailInfoFragmentDoc}
${PaginationFragmentFragmentDoc}`;

/**
 * __useStorymakersQuery__
 *
 * To run a query within a React component, call `useStorymakersQuery` and pass it any options that fit your needs.
 * When your component renders, `useStorymakersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStorymakersQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useStorymakersQuery(baseOptions?: Apollo.QueryHookOptions<StorymakersQuery, StorymakersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StorymakersQuery, StorymakersQueryVariables>(StorymakersDocument, options);
      }
export function useStorymakersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StorymakersQuery, StorymakersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StorymakersQuery, StorymakersQueryVariables>(StorymakersDocument, options);
        }
export type StorymakersQueryHookResult = ReturnType<typeof useStorymakersQuery>;
export type StorymakersLazyQueryHookResult = ReturnType<typeof useStorymakersLazyQuery>;
export type StorymakersQueryResult = Apollo.QueryResult<StorymakersQuery, StorymakersQueryVariables>;
export const DeprecatedStorymakersDocument = gql`
    query DeprecatedStorymakers($pagination: PaginationInput, $filter: StorymakerFilterInput, $sort: SortInput, $search: String) {
  storymakers(
    pagination: $pagination
    filter: $filter
    sort: $sort
    search: $search
  ) {
    data {
      ...storymakerDetailInfo
      organizations {
        id
        name
      }
      filmingGroups {
        id
        name
        organizationId
      }
    }
    pagination {
      ...paginationFragment
    }
  }
}
    ${StorymakerDetailInfoFragmentDoc}
${PaginationFragmentFragmentDoc}`;

/**
 * __useDeprecatedStorymakersQuery__
 *
 * To run a query within a React component, call `useDeprecatedStorymakersQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeprecatedStorymakersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeprecatedStorymakersQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useDeprecatedStorymakersQuery(baseOptions?: Apollo.QueryHookOptions<DeprecatedStorymakersQuery, DeprecatedStorymakersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeprecatedStorymakersQuery, DeprecatedStorymakersQueryVariables>(DeprecatedStorymakersDocument, options);
      }
export function useDeprecatedStorymakersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeprecatedStorymakersQuery, DeprecatedStorymakersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeprecatedStorymakersQuery, DeprecatedStorymakersQueryVariables>(DeprecatedStorymakersDocument, options);
        }
export type DeprecatedStorymakersQueryHookResult = ReturnType<typeof useDeprecatedStorymakersQuery>;
export type DeprecatedStorymakersLazyQueryHookResult = ReturnType<typeof useDeprecatedStorymakersLazyQuery>;
export type DeprecatedStorymakersQueryResult = Apollo.QueryResult<DeprecatedStorymakersQuery, DeprecatedStorymakersQueryVariables>;
export const ReviewersDocument = gql`
    query Reviewers($pagination: PaginationInput, $filter: AppUserFilterInput, $sort: SortInput, $search: String) {
  appUsers(pagination: $pagination, filter: $filter, sort: $sort, search: $search) {
    data {
      ... on Node {
        id
      }
      ... on Reviewer {
        ...reviewerDetailInfo
        storymakers {
          ...storymakerDetailInfo
          filmingGroups {
            id
            name
            organizationId
          }
          organizations {
            id
            name
          }
        }
      }
      ... on AppUserInterface {
        organizations {
          id
          name
        }
        filmingGroups {
          id
          name
          organizationId
        }
      }
    }
    pagination {
      ...paginationFragment
    }
  }
}
    ${ReviewerDetailInfoFragmentDoc}
${StorymakerDetailInfoFragmentDoc}
${PaginationFragmentFragmentDoc}`;

/**
 * __useReviewersQuery__
 *
 * To run a query within a React component, call `useReviewersQuery` and pass it any options that fit your needs.
 * When your component renders, `useReviewersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReviewersQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useReviewersQuery(baseOptions?: Apollo.QueryHookOptions<ReviewersQuery, ReviewersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReviewersQuery, ReviewersQueryVariables>(ReviewersDocument, options);
      }
export function useReviewersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReviewersQuery, ReviewersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReviewersQuery, ReviewersQueryVariables>(ReviewersDocument, options);
        }
export type ReviewersQueryHookResult = ReturnType<typeof useReviewersQuery>;
export type ReviewersLazyQueryHookResult = ReturnType<typeof useReviewersLazyQuery>;
export type ReviewersQueryResult = Apollo.QueryResult<ReviewersQuery, ReviewersQueryVariables>;
export const StorymakerNamesDocument = gql`
    query StorymakerNames($filter: AppUserFilterInput) {
  appUsers(filter: $filter) {
    data {
      ... on Node {
        id
      }
      ... on AppUserInterface {
        firstName
        lastName
      }
    }
  }
}
    `;

/**
 * __useStorymakerNamesQuery__
 *
 * To run a query within a React component, call `useStorymakerNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useStorymakerNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStorymakerNamesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useStorymakerNamesQuery(baseOptions?: Apollo.QueryHookOptions<StorymakerNamesQuery, StorymakerNamesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StorymakerNamesQuery, StorymakerNamesQueryVariables>(StorymakerNamesDocument, options);
      }
export function useStorymakerNamesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StorymakerNamesQuery, StorymakerNamesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StorymakerNamesQuery, StorymakerNamesQueryVariables>(StorymakerNamesDocument, options);
        }
export type StorymakerNamesQueryHookResult = ReturnType<typeof useStorymakerNamesQuery>;
export type StorymakerNamesLazyQueryHookResult = ReturnType<typeof useStorymakerNamesLazyQuery>;
export type StorymakerNamesQueryResult = Apollo.QueryResult<StorymakerNamesQuery, StorymakerNamesQueryVariables>;
export const DeprecatedStorymakerNamesDocument = gql`
    query DeprecatedStorymakerNames($filter: StorymakerFilterInput) {
  storymakers(filter: $filter) {
    data {
      id
      firstName
      lastName
    }
  }
}
    `;

/**
 * __useDeprecatedStorymakerNamesQuery__
 *
 * To run a query within a React component, call `useDeprecatedStorymakerNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeprecatedStorymakerNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeprecatedStorymakerNamesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useDeprecatedStorymakerNamesQuery(baseOptions?: Apollo.QueryHookOptions<DeprecatedStorymakerNamesQuery, DeprecatedStorymakerNamesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeprecatedStorymakerNamesQuery, DeprecatedStorymakerNamesQueryVariables>(DeprecatedStorymakerNamesDocument, options);
      }
export function useDeprecatedStorymakerNamesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeprecatedStorymakerNamesQuery, DeprecatedStorymakerNamesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeprecatedStorymakerNamesQuery, DeprecatedStorymakerNamesQueryVariables>(DeprecatedStorymakerNamesDocument, options);
        }
export type DeprecatedStorymakerNamesQueryHookResult = ReturnType<typeof useDeprecatedStorymakerNamesQuery>;
export type DeprecatedStorymakerNamesLazyQueryHookResult = ReturnType<typeof useDeprecatedStorymakerNamesLazyQuery>;
export type DeprecatedStorymakerNamesQueryResult = Apollo.QueryResult<DeprecatedStorymakerNamesQuery, DeprecatedStorymakerNamesQueryVariables>;
export const VideosDocument = gql`
    query Videos($offset: Int, $limit: Int, $search: String, $filter: VideoFilterInput, $sort: SortInput) {
  videos(
    pagination: {offset: $offset, limit: $limit}
    filter: $filter
    search: $search
    sort: $sort
  ) {
    data {
      ...VideoProps
      transcription {
        ...transcriptionPartFragment
      }
      textAssets {
        id
        value
      }
    }
    pagination {
      ...paginationFragment
    }
  }
}
    ${VideoPropsFragmentDoc}
${TranscriptionPartFragmentFragmentDoc}
${PaginationFragmentFragmentDoc}`;

/**
 * __useVideosQuery__
 *
 * To run a query within a React component, call `useVideosQuery` and pass it any options that fit your needs.
 * When your component renders, `useVideosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVideosQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      search: // value for 'search'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useVideosQuery(baseOptions?: Apollo.QueryHookOptions<VideosQuery, VideosQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VideosQuery, VideosQueryVariables>(VideosDocument, options);
      }
export function useVideosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VideosQuery, VideosQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VideosQuery, VideosQueryVariables>(VideosDocument, options);
        }
export type VideosQueryHookResult = ReturnType<typeof useVideosQuery>;
export type VideosLazyQueryHookResult = ReturnType<typeof useVideosLazyQuery>;
export type VideosQueryResult = Apollo.QueryResult<VideosQuery, VideosQueryVariables>;
export const VideoDetailDocument = gql`
    query VideoDetail($videoId: Int) {
  videos(filter: {videoId: $videoId}) {
    data {
      ...VideoProps
      transcription {
        ...transcriptionPartFragment
      }
      previewURLInline
    }
  }
}
    ${VideoPropsFragmentDoc}
${TranscriptionPartFragmentFragmentDoc}`;

/**
 * __useVideoDetailQuery__
 *
 * To run a query within a React component, call `useVideoDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useVideoDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVideoDetailQuery({
 *   variables: {
 *      videoId: // value for 'videoId'
 *   },
 * });
 */
export function useVideoDetailQuery(baseOptions?: Apollo.QueryHookOptions<VideoDetailQuery, VideoDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VideoDetailQuery, VideoDetailQueryVariables>(VideoDetailDocument, options);
      }
export function useVideoDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VideoDetailQuery, VideoDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VideoDetailQuery, VideoDetailQueryVariables>(VideoDetailDocument, options);
        }
export type VideoDetailQueryHookResult = ReturnType<typeof useVideoDetailQuery>;
export type VideoDetailLazyQueryHookResult = ReturnType<typeof useVideoDetailLazyQuery>;
export type VideoDetailQueryResult = Apollo.QueryResult<VideoDetailQuery, VideoDetailQueryVariables>;
export const VideoTextAssetsDocument = gql`
    query VideoTextAssets($videoId: Int!) {
  videos(filter: {videoId: $videoId}) {
    data {
      id
      textAssets {
        id
        value
        textCapture {
          ...textCaptureLabelFragment
          key
          characterLimit
          options
          isEditable
        }
      }
    }
  }
}
    ${TextCaptureLabelFragmentFragmentDoc}`;

/**
 * __useVideoTextAssetsQuery__
 *
 * To run a query within a React component, call `useVideoTextAssetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useVideoTextAssetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVideoTextAssetsQuery({
 *   variables: {
 *      videoId: // value for 'videoId'
 *   },
 * });
 */
export function useVideoTextAssetsQuery(baseOptions: Apollo.QueryHookOptions<VideoTextAssetsQuery, VideoTextAssetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VideoTextAssetsQuery, VideoTextAssetsQueryVariables>(VideoTextAssetsDocument, options);
      }
export function useVideoTextAssetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VideoTextAssetsQuery, VideoTextAssetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VideoTextAssetsQuery, VideoTextAssetsQueryVariables>(VideoTextAssetsDocument, options);
        }
export type VideoTextAssetsQueryHookResult = ReturnType<typeof useVideoTextAssetsQuery>;
export type VideoTextAssetsLazyQueryHookResult = ReturnType<typeof useVideoTextAssetsLazyQuery>;
export type VideoTextAssetsQueryResult = Apollo.QueryResult<VideoTextAssetsQuery, VideoTextAssetsQueryVariables>;
export const VideoCapturedAssetsDocument = gql`
    query VideoCapturedAssets($videoId: Int!) {
  videos(filter: {videoId: $videoId}) {
    data {
      id
      videoAssets {
        id
        key
        label
        url
        duration
        isOriginal
      }
      imageAssets {
        id
        key
        label
        url
        isOriginal
      }
    }
  }
}
    `;

/**
 * __useVideoCapturedAssetsQuery__
 *
 * To run a query within a React component, call `useVideoCapturedAssetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useVideoCapturedAssetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVideoCapturedAssetsQuery({
 *   variables: {
 *      videoId: // value for 'videoId'
 *   },
 * });
 */
export function useVideoCapturedAssetsQuery(baseOptions: Apollo.QueryHookOptions<VideoCapturedAssetsQuery, VideoCapturedAssetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VideoCapturedAssetsQuery, VideoCapturedAssetsQueryVariables>(VideoCapturedAssetsDocument, options);
      }
export function useVideoCapturedAssetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VideoCapturedAssetsQuery, VideoCapturedAssetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VideoCapturedAssetsQuery, VideoCapturedAssetsQueryVariables>(VideoCapturedAssetsDocument, options);
        }
export type VideoCapturedAssetsQueryHookResult = ReturnType<typeof useVideoCapturedAssetsQuery>;
export type VideoCapturedAssetsLazyQueryHookResult = ReturnType<typeof useVideoCapturedAssetsLazyQuery>;
export type VideoCapturedAssetsQueryResult = Apollo.QueryResult<VideoCapturedAssetsQuery, VideoCapturedAssetsQueryVariables>;
export const VideoFeedbackHistoryDocument = gql`
    query VideoFeedbackHistory($videoId: Int) {
  videos(filter: {videoId: $videoId}) {
    data {
      feedbacks {
        id
        packageStatus
        feedback
        createdAt
      }
    }
  }
}
    `;

/**
 * __useVideoFeedbackHistoryQuery__
 *
 * To run a query within a React component, call `useVideoFeedbackHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useVideoFeedbackHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVideoFeedbackHistoryQuery({
 *   variables: {
 *      videoId: // value for 'videoId'
 *   },
 * });
 */
export function useVideoFeedbackHistoryQuery(baseOptions?: Apollo.QueryHookOptions<VideoFeedbackHistoryQuery, VideoFeedbackHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VideoFeedbackHistoryQuery, VideoFeedbackHistoryQueryVariables>(VideoFeedbackHistoryDocument, options);
      }
export function useVideoFeedbackHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VideoFeedbackHistoryQuery, VideoFeedbackHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VideoFeedbackHistoryQuery, VideoFeedbackHistoryQueryVariables>(VideoFeedbackHistoryDocument, options);
        }
export type VideoFeedbackHistoryQueryHookResult = ReturnType<typeof useVideoFeedbackHistoryQuery>;
export type VideoFeedbackHistoryLazyQueryHookResult = ReturnType<typeof useVideoFeedbackHistoryLazyQuery>;
export type VideoFeedbackHistoryQueryResult = Apollo.QueryResult<VideoFeedbackHistoryQuery, VideoFeedbackHistoryQueryVariables>;
export const FilmingGroupsDocument = gql`
    query FilmingGroups($filter: FilmingGroupFilterInput) {
  filmingGroups(filter: $filter) {
    ...filmingGroupsFragment
  }
}
    ${FilmingGroupsFragmentFragmentDoc}`;

/**
 * __useFilmingGroupsQuery__
 *
 * To run a query within a React component, call `useFilmingGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFilmingGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFilmingGroupsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useFilmingGroupsQuery(baseOptions?: Apollo.QueryHookOptions<FilmingGroupsQuery, FilmingGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FilmingGroupsQuery, FilmingGroupsQueryVariables>(FilmingGroupsDocument, options);
      }
export function useFilmingGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FilmingGroupsQuery, FilmingGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FilmingGroupsQuery, FilmingGroupsQueryVariables>(FilmingGroupsDocument, options);
        }
export type FilmingGroupsQueryHookResult = ReturnType<typeof useFilmingGroupsQuery>;
export type FilmingGroupsLazyQueryHookResult = ReturnType<typeof useFilmingGroupsLazyQuery>;
export type FilmingGroupsQueryResult = Apollo.QueryResult<FilmingGroupsQuery, FilmingGroupsQueryVariables>;
export const FilmingGroupsWithTemplatesDocument = gql`
    query FilmingGroupsWithTemplates($filter: FilmingGroupFilterInput) {
  filmingGroups(filter: $filter) {
    id
    name
    templates {
      id
      name
    }
  }
}
    `;

/**
 * __useFilmingGroupsWithTemplatesQuery__
 *
 * To run a query within a React component, call `useFilmingGroupsWithTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFilmingGroupsWithTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFilmingGroupsWithTemplatesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useFilmingGroupsWithTemplatesQuery(baseOptions?: Apollo.QueryHookOptions<FilmingGroupsWithTemplatesQuery, FilmingGroupsWithTemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FilmingGroupsWithTemplatesQuery, FilmingGroupsWithTemplatesQueryVariables>(FilmingGroupsWithTemplatesDocument, options);
      }
export function useFilmingGroupsWithTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FilmingGroupsWithTemplatesQuery, FilmingGroupsWithTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FilmingGroupsWithTemplatesQuery, FilmingGroupsWithTemplatesQueryVariables>(FilmingGroupsWithTemplatesDocument, options);
        }
export type FilmingGroupsWithTemplatesQueryHookResult = ReturnType<typeof useFilmingGroupsWithTemplatesQuery>;
export type FilmingGroupsWithTemplatesLazyQueryHookResult = ReturnType<typeof useFilmingGroupsWithTemplatesLazyQuery>;
export type FilmingGroupsWithTemplatesQueryResult = Apollo.QueryResult<FilmingGroupsWithTemplatesQuery, FilmingGroupsWithTemplatesQueryVariables>;
export const FilmingGroupNamesDocument = gql`
    query FilmingGroupNames($filter: FilmingGroupFilterInput) {
  filmingGroups(filter: $filter) {
    id
    name
    organizationId
    templates {
      id
    }
  }
}
    `;

/**
 * __useFilmingGroupNamesQuery__
 *
 * To run a query within a React component, call `useFilmingGroupNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFilmingGroupNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFilmingGroupNamesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useFilmingGroupNamesQuery(baseOptions?: Apollo.QueryHookOptions<FilmingGroupNamesQuery, FilmingGroupNamesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FilmingGroupNamesQuery, FilmingGroupNamesQueryVariables>(FilmingGroupNamesDocument, options);
      }
export function useFilmingGroupNamesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FilmingGroupNamesQuery, FilmingGroupNamesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FilmingGroupNamesQuery, FilmingGroupNamesQueryVariables>(FilmingGroupNamesDocument, options);
        }
export type FilmingGroupNamesQueryHookResult = ReturnType<typeof useFilmingGroupNamesQuery>;
export type FilmingGroupNamesLazyQueryHookResult = ReturnType<typeof useFilmingGroupNamesLazyQuery>;
export type FilmingGroupNamesQueryResult = Apollo.QueryResult<FilmingGroupNamesQuery, FilmingGroupNamesQueryVariables>;
export const ProductDocument = gql`
    query Product($productId: String!) {
  products(filter: {productId: $productId}) {
    id
    name
    images
    prices {
      id
      interval
      currency
      amount
    }
    checklist
    trialDays
  }
}
    `;

/**
 * __useProductQuery__
 *
 * To run a query within a React component, call `useProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductQuery({
 *   variables: {
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useProductQuery(baseOptions: Apollo.QueryHookOptions<ProductQuery, ProductQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductQuery, ProductQueryVariables>(ProductDocument, options);
      }
export function useProductLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductQuery, ProductQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductQuery, ProductQueryVariables>(ProductDocument, options);
        }
export type ProductQueryHookResult = ReturnType<typeof useProductQuery>;
export type ProductLazyQueryHookResult = ReturnType<typeof useProductLazyQuery>;
export type ProductQueryResult = Apollo.QueryResult<ProductQuery, ProductQueryVariables>;
export const YoutubeChannelsDocument = gql`
    query YoutubeChannels {
  youtubeChannels {
    id
    name
  }
}
    `;

/**
 * __useYoutubeChannelsQuery__
 *
 * To run a query within a React component, call `useYoutubeChannelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useYoutubeChannelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useYoutubeChannelsQuery({
 *   variables: {
 *   },
 * });
 */
export function useYoutubeChannelsQuery(baseOptions?: Apollo.QueryHookOptions<YoutubeChannelsQuery, YoutubeChannelsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<YoutubeChannelsQuery, YoutubeChannelsQueryVariables>(YoutubeChannelsDocument, options);
      }
export function useYoutubeChannelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<YoutubeChannelsQuery, YoutubeChannelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<YoutubeChannelsQuery, YoutubeChannelsQueryVariables>(YoutubeChannelsDocument, options);
        }
export type YoutubeChannelsQueryHookResult = ReturnType<typeof useYoutubeChannelsQuery>;
export type YoutubeChannelsLazyQueryHookResult = ReturnType<typeof useYoutubeChannelsLazyQuery>;
export type YoutubeChannelsQueryResult = Apollo.QueryResult<YoutubeChannelsQuery, YoutubeChannelsQueryVariables>;
export const StripeCustomerPortalUrlDocument = gql`
    query StripeCustomerPortalUrl($returnUrl: String!) {
  stripeCustomerPortalUrl(returnUrl: $returnUrl)
}
    `;

/**
 * __useStripeCustomerPortalUrlQuery__
 *
 * To run a query within a React component, call `useStripeCustomerPortalUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useStripeCustomerPortalUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStripeCustomerPortalUrlQuery({
 *   variables: {
 *      returnUrl: // value for 'returnUrl'
 *   },
 * });
 */
export function useStripeCustomerPortalUrlQuery(baseOptions: Apollo.QueryHookOptions<StripeCustomerPortalUrlQuery, StripeCustomerPortalUrlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StripeCustomerPortalUrlQuery, StripeCustomerPortalUrlQueryVariables>(StripeCustomerPortalUrlDocument, options);
      }
export function useStripeCustomerPortalUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StripeCustomerPortalUrlQuery, StripeCustomerPortalUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StripeCustomerPortalUrlQuery, StripeCustomerPortalUrlQueryVariables>(StripeCustomerPortalUrlDocument, options);
        }
export type StripeCustomerPortalUrlQueryHookResult = ReturnType<typeof useStripeCustomerPortalUrlQuery>;
export type StripeCustomerPortalUrlLazyQueryHookResult = ReturnType<typeof useStripeCustomerPortalUrlLazyQuery>;
export type StripeCustomerPortalUrlQueryResult = Apollo.QueryResult<StripeCustomerPortalUrlQuery, StripeCustomerPortalUrlQueryVariables>;
export const BrandColorsDocument = gql`
    query BrandColors {
  me {
    ... on CurrentAdminUser {
      id
      organization {
        id
        brandAsset {
          id
          darkColor
          lightColor
          greyColor
        }
      }
    }
  }
}
    `;

/**
 * __useBrandColorsQuery__
 *
 * To run a query within a React component, call `useBrandColorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrandColorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrandColorsQuery({
 *   variables: {
 *   },
 * });
 */
export function useBrandColorsQuery(baseOptions?: Apollo.QueryHookOptions<BrandColorsQuery, BrandColorsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BrandColorsQuery, BrandColorsQueryVariables>(BrandColorsDocument, options);
      }
export function useBrandColorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BrandColorsQuery, BrandColorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BrandColorsQuery, BrandColorsQueryVariables>(BrandColorsDocument, options);
        }
export type BrandColorsQueryHookResult = ReturnType<typeof useBrandColorsQuery>;
export type BrandColorsLazyQueryHookResult = ReturnType<typeof useBrandColorsLazyQuery>;
export type BrandColorsQueryResult = Apollo.QueryResult<BrandColorsQuery, BrandColorsQueryVariables>;
export const BrandLogosDocument = gql`
    query BrandLogos {
  me {
    ... on CurrentAdminUser {
      id
      organization {
        id
        brandAsset {
          id
          whiteLogo
          colorLogo
        }
      }
    }
  }
}
    `;

/**
 * __useBrandLogosQuery__
 *
 * To run a query within a React component, call `useBrandLogosQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrandLogosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrandLogosQuery({
 *   variables: {
 *   },
 * });
 */
export function useBrandLogosQuery(baseOptions?: Apollo.QueryHookOptions<BrandLogosQuery, BrandLogosQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BrandLogosQuery, BrandLogosQueryVariables>(BrandLogosDocument, options);
      }
export function useBrandLogosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BrandLogosQuery, BrandLogosQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BrandLogosQuery, BrandLogosQueryVariables>(BrandLogosDocument, options);
        }
export type BrandLogosQueryHookResult = ReturnType<typeof useBrandLogosQuery>;
export type BrandLogosLazyQueryHookResult = ReturnType<typeof useBrandLogosLazyQuery>;
export type BrandLogosQueryResult = Apollo.QueryResult<BrandLogosQuery, BrandLogosQueryVariables>;
export const BrandEndCardDocument = gql`
    query BrandEndCard {
  me {
    ... on CurrentAdminUser {
      id
      organization {
        id
        brandAsset {
          id
          endCard {
            id
            callToAction
            businessEmail
            businessPhone
            type
            value
          }
        }
      }
    }
  }
}
    `;

/**
 * __useBrandEndCardQuery__
 *
 * To run a query within a React component, call `useBrandEndCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrandEndCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrandEndCardQuery({
 *   variables: {
 *   },
 * });
 */
export function useBrandEndCardQuery(baseOptions?: Apollo.QueryHookOptions<BrandEndCardQuery, BrandEndCardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BrandEndCardQuery, BrandEndCardQueryVariables>(BrandEndCardDocument, options);
      }
export function useBrandEndCardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BrandEndCardQuery, BrandEndCardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BrandEndCardQuery, BrandEndCardQueryVariables>(BrandEndCardDocument, options);
        }
export type BrandEndCardQueryHookResult = ReturnType<typeof useBrandEndCardQuery>;
export type BrandEndCardLazyQueryHookResult = ReturnType<typeof useBrandEndCardLazyQuery>;
export type BrandEndCardQueryResult = Apollo.QueryResult<BrandEndCardQuery, BrandEndCardQueryVariables>;
export const OrganizationInformationDocument = gql`
    query OrganizationInformation {
  me {
    ... on CurrentAdminUser {
      id
      organization {
        ...organizationDetailInfo
      }
    }
  }
}
    ${OrganizationDetailInfoFragmentDoc}`;

/**
 * __useOrganizationInformationQuery__
 *
 * To run a query within a React component, call `useOrganizationInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationInformationQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrganizationInformationQuery(baseOptions?: Apollo.QueryHookOptions<OrganizationInformationQuery, OrganizationInformationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganizationInformationQuery, OrganizationInformationQueryVariables>(OrganizationInformationDocument, options);
      }
export function useOrganizationInformationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganizationInformationQuery, OrganizationInformationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganizationInformationQuery, OrganizationInformationQueryVariables>(OrganizationInformationDocument, options);
        }
export type OrganizationInformationQueryHookResult = ReturnType<typeof useOrganizationInformationQuery>;
export type OrganizationInformationLazyQueryHookResult = ReturnType<typeof useOrganizationInformationLazyQuery>;
export type OrganizationInformationQueryResult = Apollo.QueryResult<OrganizationInformationQuery, OrganizationInformationQueryVariables>;
export const AdminUsersInformationDocument = gql`
    query AdminUsersInformation {
  me {
    ... on CurrentAdminUser {
      id
      isPrimary
      organization {
        id
        adminUsers {
          id
          email
          firstName
          lastName
          cellPhone
          directPhone
          title
          isMe
          role
          isPrimary
          hasAcceptedInvite
        }
      }
    }
  }
}
    `;

/**
 * __useAdminUsersInformationQuery__
 *
 * To run a query within a React component, call `useAdminUsersInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminUsersInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminUsersInformationQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdminUsersInformationQuery(baseOptions?: Apollo.QueryHookOptions<AdminUsersInformationQuery, AdminUsersInformationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminUsersInformationQuery, AdminUsersInformationQueryVariables>(AdminUsersInformationDocument, options);
      }
export function useAdminUsersInformationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminUsersInformationQuery, AdminUsersInformationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminUsersInformationQuery, AdminUsersInformationQueryVariables>(AdminUsersInformationDocument, options);
        }
export type AdminUsersInformationQueryHookResult = ReturnType<typeof useAdminUsersInformationQuery>;
export type AdminUsersInformationLazyQueryHookResult = ReturnType<typeof useAdminUsersInformationLazyQuery>;
export type AdminUsersInformationQueryResult = Apollo.QueryResult<AdminUsersInformationQuery, AdminUsersInformationQueryVariables>;
export const ApplicationConfigDocument = gql`
    query ApplicationConfig {
  applicationConfig {
    stripeMaxCouponTries
    statusesOrder
    integrationSources
    userRoles {
      role
      roleName
      isOldCMSRole
    }
    videoStatusTransitionMap {
      approved
      created
      do_not_use
      failed
      preprocessing
      rendered
      rendering
      review_requested
      not_approved
    }
    restrictedRoleStatusMap {
      reviewer
      storymaker
    }
    humanizedEmailTriggerSetting {
      no_notifications
      notifications_after_approval
      notifications_upon_render
    }
    subscriptionTypeDefaultEmailTriggerSetting {
      Complete
      Healthcare
      Now
      Other
      Quick
      Storyvine_internal
    }
    subscriptionTypeManagerRole {
      Complete
      Healthcare
      Now
      Other
      Quick
      Storyvine_internal
    }
    defaultLinkExpirationMap {
      Complete
      Healthcare
      Now
      Other
      Quick
      Storyvine_internal
    }
    humanizedVimeoUploadArgs {
      view {
        anybody
        nobody
        disable
        password
        unlisted
      }
      embed {
        public
        private
        whitelist
      }
    }
  }
}
    `;

/**
 * __useApplicationConfigQuery__
 *
 * To run a query within a React component, call `useApplicationConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useApplicationConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApplicationConfigQuery({
 *   variables: {
 *   },
 * });
 */
export function useApplicationConfigQuery(baseOptions?: Apollo.QueryHookOptions<ApplicationConfigQuery, ApplicationConfigQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApplicationConfigQuery, ApplicationConfigQueryVariables>(ApplicationConfigDocument, options);
      }
export function useApplicationConfigLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApplicationConfigQuery, ApplicationConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApplicationConfigQuery, ApplicationConfigQueryVariables>(ApplicationConfigDocument, options);
        }
export type ApplicationConfigQueryHookResult = ReturnType<typeof useApplicationConfigQuery>;
export type ApplicationConfigLazyQueryHookResult = ReturnType<typeof useApplicationConfigLazyQuery>;
export type ApplicationConfigQueryResult = Apollo.QueryResult<ApplicationConfigQuery, ApplicationConfigQueryVariables>;
export const TrainingsDocument = gql`
    query Trainings($sectionId: String!) {
  trainings(sectionId: $sectionId) {
    categories {
      id
      name
      trainings {
        id
        title
        content
        highlighted
      }
    }
  }
}
    `;

/**
 * __useTrainingsQuery__
 *
 * To run a query within a React component, call `useTrainingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrainingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrainingsQuery({
 *   variables: {
 *      sectionId: // value for 'sectionId'
 *   },
 * });
 */
export function useTrainingsQuery(baseOptions: Apollo.QueryHookOptions<TrainingsQuery, TrainingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TrainingsQuery, TrainingsQueryVariables>(TrainingsDocument, options);
      }
export function useTrainingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TrainingsQuery, TrainingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TrainingsQuery, TrainingsQueryVariables>(TrainingsDocument, options);
        }
export type TrainingsQueryHookResult = ReturnType<typeof useTrainingsQuery>;
export type TrainingsLazyQueryHookResult = ReturnType<typeof useTrainingsLazyQuery>;
export type TrainingsQueryResult = Apollo.QueryResult<TrainingsQuery, TrainingsQueryVariables>;
export const TrainingSectionsDocument = gql`
    query TrainingSections {
  trainingSections {
    id
    sidebarName
    sidebarIconUrl
    sidebarHoverIconUrl
  }
}
    `;

/**
 * __useTrainingSectionsQuery__
 *
 * To run a query within a React component, call `useTrainingSectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrainingSectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrainingSectionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useTrainingSectionsQuery(baseOptions?: Apollo.QueryHookOptions<TrainingSectionsQuery, TrainingSectionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TrainingSectionsQuery, TrainingSectionsQueryVariables>(TrainingSectionsDocument, options);
      }
export function useTrainingSectionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TrainingSectionsQuery, TrainingSectionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TrainingSectionsQuery, TrainingSectionsQueryVariables>(TrainingSectionsDocument, options);
        }
export type TrainingSectionsQueryHookResult = ReturnType<typeof useTrainingSectionsQuery>;
export type TrainingSectionsLazyQueryHookResult = ReturnType<typeof useTrainingSectionsLazyQuery>;
export type TrainingSectionsQueryResult = Apollo.QueryResult<TrainingSectionsQuery, TrainingSectionsQueryVariables>;
export const TemplateNamesDocument = gql`
    query TemplateNames($filter: TemplateFilterInput, $pagination: PaginationInput) {
  templates(filter: $filter, pagination: $pagination) {
    data {
      id
      name
      organizations {
        id
      }
    }
  }
}
    `;

/**
 * __useTemplateNamesQuery__
 *
 * To run a query within a React component, call `useTemplateNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemplateNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemplateNamesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useTemplateNamesQuery(baseOptions?: Apollo.QueryHookOptions<TemplateNamesQuery, TemplateNamesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TemplateNamesQuery, TemplateNamesQueryVariables>(TemplateNamesDocument, options);
      }
export function useTemplateNamesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TemplateNamesQuery, TemplateNamesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TemplateNamesQuery, TemplateNamesQueryVariables>(TemplateNamesDocument, options);
        }
export type TemplateNamesQueryHookResult = ReturnType<typeof useTemplateNamesQuery>;
export type TemplateNamesLazyQueryHookResult = ReturnType<typeof useTemplateNamesLazyQuery>;
export type TemplateNamesQueryResult = Apollo.QueryResult<TemplateNamesQuery, TemplateNamesQueryVariables>;
export const TemplatesDocument = gql`
    query Templates($filter: TemplateFilterInput, $pagination: PaginationInput, $search: String) {
  templates(filter: $filter, pagination: $pagination, search: $search) {
    data {
      id
      name
      description
      createdAt
      organizations {
        id
        name
      }
      captureStepsLists {
        id
        name
        captureSteps {
          id
          coachingBody
          coachingTitle
        }
      }
      previewVideo {
        id
        previewURL
        thumbnailURL
      }
    }
    pagination {
      ...paginationFragment
    }
  }
}
    ${PaginationFragmentFragmentDoc}`;

/**
 * __useTemplatesQuery__
 *
 * To run a query within a React component, call `useTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemplatesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useTemplatesQuery(baseOptions?: Apollo.QueryHookOptions<TemplatesQuery, TemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TemplatesQuery, TemplatesQueryVariables>(TemplatesDocument, options);
      }
export function useTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TemplatesQuery, TemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TemplatesQuery, TemplatesQueryVariables>(TemplatesDocument, options);
        }
export type TemplatesQueryHookResult = ReturnType<typeof useTemplatesQuery>;
export type TemplatesLazyQueryHookResult = ReturnType<typeof useTemplatesLazyQuery>;
export type TemplatesQueryResult = Apollo.QueryResult<TemplatesQuery, TemplatesQueryVariables>;
export const TemplatesAdminDetailDocument = gql`
    query TemplatesAdminDetail($filter: TemplateFilterInput, $pagination: PaginationInput) {
  templates(filter: $filter, pagination: $pagination) {
    data {
      id
      name
      description
      shouldSaveToCameraRoll
      isStorymakerAddedEmailsEnabled
      isReviewRequired
      organizations {
        id
        name
      }
      previewVideo {
        id
        previewURL
        thumbnailURL
      }
      captureStepsLists {
        id
        name
        createdAt
        captureSteps {
          ...captureStepFragment
        }
      }
      textCaptures {
        ...textCaptureLabelFragment
        characterLimit
        options
        isEditable
      }
    }
  }
}
    ${CaptureStepFragmentFragmentDoc}
${TextCaptureLabelFragmentFragmentDoc}`;

/**
 * __useTemplatesAdminDetailQuery__
 *
 * To run a query within a React component, call `useTemplatesAdminDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemplatesAdminDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemplatesAdminDetailQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useTemplatesAdminDetailQuery(baseOptions?: Apollo.QueryHookOptions<TemplatesAdminDetailQuery, TemplatesAdminDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TemplatesAdminDetailQuery, TemplatesAdminDetailQueryVariables>(TemplatesAdminDetailDocument, options);
      }
export function useTemplatesAdminDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TemplatesAdminDetailQuery, TemplatesAdminDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TemplatesAdminDetailQuery, TemplatesAdminDetailQueryVariables>(TemplatesAdminDetailDocument, options);
        }
export type TemplatesAdminDetailQueryHookResult = ReturnType<typeof useTemplatesAdminDetailQuery>;
export type TemplatesAdminDetailLazyQueryHookResult = ReturnType<typeof useTemplatesAdminDetailLazyQuery>;
export type TemplatesAdminDetailQueryResult = Apollo.QueryResult<TemplatesAdminDetailQuery, TemplatesAdminDetailQueryVariables>;
export const FilmingGroupSignUpFormLinksDocument = gql`
    query FilmingGroupSignUpFormLinks($filter: FilmingGroupFilterInput) {
  filmingGroups(filter: $filter) {
    id
    name
    activeSignUpFormUrl
  }
}
    `;

/**
 * __useFilmingGroupSignUpFormLinksQuery__
 *
 * To run a query within a React component, call `useFilmingGroupSignUpFormLinksQuery` and pass it any options that fit your needs.
 * When your component renders, `useFilmingGroupSignUpFormLinksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFilmingGroupSignUpFormLinksQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useFilmingGroupSignUpFormLinksQuery(baseOptions?: Apollo.QueryHookOptions<FilmingGroupSignUpFormLinksQuery, FilmingGroupSignUpFormLinksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FilmingGroupSignUpFormLinksQuery, FilmingGroupSignUpFormLinksQueryVariables>(FilmingGroupSignUpFormLinksDocument, options);
      }
export function useFilmingGroupSignUpFormLinksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FilmingGroupSignUpFormLinksQuery, FilmingGroupSignUpFormLinksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FilmingGroupSignUpFormLinksQuery, FilmingGroupSignUpFormLinksQueryVariables>(FilmingGroupSignUpFormLinksDocument, options);
        }
export type FilmingGroupSignUpFormLinksQueryHookResult = ReturnType<typeof useFilmingGroupSignUpFormLinksQuery>;
export type FilmingGroupSignUpFormLinksLazyQueryHookResult = ReturnType<typeof useFilmingGroupSignUpFormLinksLazyQuery>;
export type FilmingGroupSignUpFormLinksQueryResult = Apollo.QueryResult<FilmingGroupSignUpFormLinksQuery, FilmingGroupSignUpFormLinksQueryVariables>;
export const OrganizationsDocument = gql`
    query Organizations($filter: OrganizationFilterInput, $pagination: PaginationInput, $search: String) {
  organizations(filter: $filter, pagination: $pagination, search: $search) {
    data {
      ...organizationTableInfo
    }
    pagination {
      ...paginationFragment
    }
  }
}
    ${OrganizationTableInfoFragmentDoc}
${PaginationFragmentFragmentDoc}`;

/**
 * __useOrganizationsQuery__
 *
 * To run a query within a React component, call `useOrganizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useOrganizationsQuery(baseOptions?: Apollo.QueryHookOptions<OrganizationsQuery, OrganizationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganizationsQuery, OrganizationsQueryVariables>(OrganizationsDocument, options);
      }
export function useOrganizationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganizationsQuery, OrganizationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganizationsQuery, OrganizationsQueryVariables>(OrganizationsDocument, options);
        }
export type OrganizationsQueryHookResult = ReturnType<typeof useOrganizationsQuery>;
export type OrganizationsLazyQueryHookResult = ReturnType<typeof useOrganizationsLazyQuery>;
export type OrganizationsQueryResult = Apollo.QueryResult<OrganizationsQuery, OrganizationsQueryVariables>;
export const OrganizationDetailDocument = gql`
    query OrganizationDetail($filter: OrganizationFilterInput) {
  organizations(filter: $filter) {
    data {
      ...organizationDetailInfo
    }
    pagination {
      ...paginationFragment
    }
  }
}
    ${OrganizationDetailInfoFragmentDoc}
${PaginationFragmentFragmentDoc}`;

/**
 * __useOrganizationDetailQuery__
 *
 * To run a query within a React component, call `useOrganizationDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationDetailQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useOrganizationDetailQuery(baseOptions?: Apollo.QueryHookOptions<OrganizationDetailQuery, OrganizationDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganizationDetailQuery, OrganizationDetailQueryVariables>(OrganizationDetailDocument, options);
      }
export function useOrganizationDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganizationDetailQuery, OrganizationDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganizationDetailQuery, OrganizationDetailQueryVariables>(OrganizationDetailDocument, options);
        }
export type OrganizationDetailQueryHookResult = ReturnType<typeof useOrganizationDetailQuery>;
export type OrganizationDetailLazyQueryHookResult = ReturnType<typeof useOrganizationDetailLazyQuery>;
export type OrganizationDetailQueryResult = Apollo.QueryResult<OrganizationDetailQuery, OrganizationDetailQueryVariables>;
export const OrganizationSubscriptionTypeDocument = gql`
    query OrganizationSubscriptionType($filter: OrganizationFilterInput) {
  organizations(filter: $filter) {
    data {
      id
      subscriptionType
    }
  }
}
    `;

/**
 * __useOrganizationSubscriptionTypeQuery__
 *
 * To run a query within a React component, call `useOrganizationSubscriptionTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationSubscriptionTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationSubscriptionTypeQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useOrganizationSubscriptionTypeQuery(baseOptions?: Apollo.QueryHookOptions<OrganizationSubscriptionTypeQuery, OrganizationSubscriptionTypeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganizationSubscriptionTypeQuery, OrganizationSubscriptionTypeQueryVariables>(OrganizationSubscriptionTypeDocument, options);
      }
export function useOrganizationSubscriptionTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganizationSubscriptionTypeQuery, OrganizationSubscriptionTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganizationSubscriptionTypeQuery, OrganizationSubscriptionTypeQueryVariables>(OrganizationSubscriptionTypeDocument, options);
        }
export type OrganizationSubscriptionTypeQueryHookResult = ReturnType<typeof useOrganizationSubscriptionTypeQuery>;
export type OrganizationSubscriptionTypeLazyQueryHookResult = ReturnType<typeof useOrganizationSubscriptionTypeLazyQuery>;
export type OrganizationSubscriptionTypeQueryResult = Apollo.QueryResult<OrganizationSubscriptionTypeQuery, OrganizationSubscriptionTypeQueryVariables>;
export const OrganizationNamesAndSubscriptionTypesDocument = gql`
    query OrganizationNamesAndSubscriptionTypes($filter: OrganizationFilterInput, $pagination: PaginationInput, $search: String) {
  organizations(filter: $filter, pagination: $pagination, search: $search) {
    data {
      id
      name
      subscriptionType
    }
    pagination {
      ...paginationFragment
    }
  }
}
    ${PaginationFragmentFragmentDoc}`;

/**
 * __useOrganizationNamesAndSubscriptionTypesQuery__
 *
 * To run a query within a React component, call `useOrganizationNamesAndSubscriptionTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationNamesAndSubscriptionTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationNamesAndSubscriptionTypesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useOrganizationNamesAndSubscriptionTypesQuery(baseOptions?: Apollo.QueryHookOptions<OrganizationNamesAndSubscriptionTypesQuery, OrganizationNamesAndSubscriptionTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganizationNamesAndSubscriptionTypesQuery, OrganizationNamesAndSubscriptionTypesQueryVariables>(OrganizationNamesAndSubscriptionTypesDocument, options);
      }
export function useOrganizationNamesAndSubscriptionTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganizationNamesAndSubscriptionTypesQuery, OrganizationNamesAndSubscriptionTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganizationNamesAndSubscriptionTypesQuery, OrganizationNamesAndSubscriptionTypesQueryVariables>(OrganizationNamesAndSubscriptionTypesDocument, options);
        }
export type OrganizationNamesAndSubscriptionTypesQueryHookResult = ReturnType<typeof useOrganizationNamesAndSubscriptionTypesQuery>;
export type OrganizationNamesAndSubscriptionTypesLazyQueryHookResult = ReturnType<typeof useOrganizationNamesAndSubscriptionTypesLazyQuery>;
export type OrganizationNamesAndSubscriptionTypesQueryResult = Apollo.QueryResult<OrganizationNamesAndSubscriptionTypesQuery, OrganizationNamesAndSubscriptionTypesQueryVariables>;
export const AdminUsersDocument = gql`
    query AdminUsers($offset: Int, $limit: Int, $filter: AdminUserFilterInput, $search: String) {
  adminUsers(
    pagination: {offset: $offset, limit: $limit}
    filter: $filter
    search: $search
  ) {
    data {
      ...adminUserDetailInfo
      isMe
      organization {
        id
        name
      }
    }
    pagination {
      ...paginationFragment
    }
  }
}
    ${AdminUserDetailInfoFragmentDoc}
${PaginationFragmentFragmentDoc}`;

/**
 * __useAdminUsersQuery__
 *
 * To run a query within a React component, call `useAdminUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminUsersQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      filter: // value for 'filter'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useAdminUsersQuery(baseOptions?: Apollo.QueryHookOptions<AdminUsersQuery, AdminUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminUsersQuery, AdminUsersQueryVariables>(AdminUsersDocument, options);
      }
export function useAdminUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminUsersQuery, AdminUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminUsersQuery, AdminUsersQueryVariables>(AdminUsersDocument, options);
        }
export type AdminUsersQueryHookResult = ReturnType<typeof useAdminUsersQuery>;
export type AdminUsersLazyQueryHookResult = ReturnType<typeof useAdminUsersLazyQuery>;
export type AdminUsersQueryResult = Apollo.QueryResult<AdminUsersQuery, AdminUsersQueryVariables>;
export const AdminUserDetailDocument = gql`
    query AdminUserDetail($filter: AdminUserFilterInput) {
  adminUsers(filter: $filter) {
    data {
      ...adminUserDetailInfo
      isMe
      isPrimary
      title
      cellPhone
      directPhone
      has2fa
      organization {
        id
        name
        subscriptionType
      }
    }
    pagination {
      ...paginationFragment
    }
  }
}
    ${AdminUserDetailInfoFragmentDoc}
${PaginationFragmentFragmentDoc}`;

/**
 * __useAdminUserDetailQuery__
 *
 * To run a query within a React component, call `useAdminUserDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminUserDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminUserDetailQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAdminUserDetailQuery(baseOptions?: Apollo.QueryHookOptions<AdminUserDetailQuery, AdminUserDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminUserDetailQuery, AdminUserDetailQueryVariables>(AdminUserDetailDocument, options);
      }
export function useAdminUserDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminUserDetailQuery, AdminUserDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminUserDetailQuery, AdminUserDetailQueryVariables>(AdminUserDetailDocument, options);
        }
export type AdminUserDetailQueryHookResult = ReturnType<typeof useAdminUserDetailQuery>;
export type AdminUserDetailLazyQueryHookResult = ReturnType<typeof useAdminUserDetailLazyQuery>;
export type AdminUserDetailQueryResult = Apollo.QueryResult<AdminUserDetailQuery, AdminUserDetailQueryVariables>;
export const StorymakerDetailDocument = gql`
    query StorymakerDetail($filter: AppUserFilterInput) {
  appUsers(filter: $filter) {
    data {
      ... on Node {
        id
      }
      ... on Storymaker {
        ...storymakerDetailInfo
      }
      ... on Reviewer {
        ...reviewerDetailInfo
      }
      ... on AppUserInterface {
        organizations {
          id
          name
          subscriptionType
        }
        filmingGroups {
          id
          name
          organizationId
        }
      }
    }
    pagination {
      ...paginationFragment
    }
  }
}
    ${StorymakerDetailInfoFragmentDoc}
${ReviewerDetailInfoFragmentDoc}
${PaginationFragmentFragmentDoc}`;

/**
 * __useStorymakerDetailQuery__
 *
 * To run a query within a React component, call `useStorymakerDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useStorymakerDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStorymakerDetailQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useStorymakerDetailQuery(baseOptions?: Apollo.QueryHookOptions<StorymakerDetailQuery, StorymakerDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StorymakerDetailQuery, StorymakerDetailQueryVariables>(StorymakerDetailDocument, options);
      }
export function useStorymakerDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StorymakerDetailQuery, StorymakerDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StorymakerDetailQuery, StorymakerDetailQueryVariables>(StorymakerDetailDocument, options);
        }
export type StorymakerDetailQueryHookResult = ReturnType<typeof useStorymakerDetailQuery>;
export type StorymakerDetailLazyQueryHookResult = ReturnType<typeof useStorymakerDetailLazyQuery>;
export type StorymakerDetailQueryResult = Apollo.QueryResult<StorymakerDetailQuery, StorymakerDetailQueryVariables>;
export const DeprecatedStorymakerDetailDocument = gql`
    query DeprecatedStorymakerDetail($filter: StorymakerFilterInput) {
  storymakers(filter: $filter) {
    data {
      ...storymakerDetailInfo
      organizations {
        id
        name
        subscriptionType
      }
      filmingGroups {
        id
        name
        organizationId
      }
    }
    pagination {
      ...paginationFragment
    }
  }
}
    ${StorymakerDetailInfoFragmentDoc}
${PaginationFragmentFragmentDoc}`;

/**
 * __useDeprecatedStorymakerDetailQuery__
 *
 * To run a query within a React component, call `useDeprecatedStorymakerDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeprecatedStorymakerDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeprecatedStorymakerDetailQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useDeprecatedStorymakerDetailQuery(baseOptions?: Apollo.QueryHookOptions<DeprecatedStorymakerDetailQuery, DeprecatedStorymakerDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeprecatedStorymakerDetailQuery, DeprecatedStorymakerDetailQueryVariables>(DeprecatedStorymakerDetailDocument, options);
      }
export function useDeprecatedStorymakerDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeprecatedStorymakerDetailQuery, DeprecatedStorymakerDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeprecatedStorymakerDetailQuery, DeprecatedStorymakerDetailQueryVariables>(DeprecatedStorymakerDetailDocument, options);
        }
export type DeprecatedStorymakerDetailQueryHookResult = ReturnType<typeof useDeprecatedStorymakerDetailQuery>;
export type DeprecatedStorymakerDetailLazyQueryHookResult = ReturnType<typeof useDeprecatedStorymakerDetailLazyQuery>;
export type DeprecatedStorymakerDetailQueryResult = Apollo.QueryResult<DeprecatedStorymakerDetailQuery, DeprecatedStorymakerDetailQueryVariables>;
export const ApplicationGlobalFeatureFlagsDocument = gql`
    query ApplicationGlobalFeatureFlags {
  globalFeatureFlags {
    id
    flag
    type
  }
}
    `;

/**
 * __useApplicationGlobalFeatureFlagsQuery__
 *
 * To run a query within a React component, call `useApplicationGlobalFeatureFlagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useApplicationGlobalFeatureFlagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApplicationGlobalFeatureFlagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useApplicationGlobalFeatureFlagsQuery(baseOptions?: Apollo.QueryHookOptions<ApplicationGlobalFeatureFlagsQuery, ApplicationGlobalFeatureFlagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApplicationGlobalFeatureFlagsQuery, ApplicationGlobalFeatureFlagsQueryVariables>(ApplicationGlobalFeatureFlagsDocument, options);
      }
export function useApplicationGlobalFeatureFlagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApplicationGlobalFeatureFlagsQuery, ApplicationGlobalFeatureFlagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApplicationGlobalFeatureFlagsQuery, ApplicationGlobalFeatureFlagsQueryVariables>(ApplicationGlobalFeatureFlagsDocument, options);
        }
export type ApplicationGlobalFeatureFlagsQueryHookResult = ReturnType<typeof useApplicationGlobalFeatureFlagsQuery>;
export type ApplicationGlobalFeatureFlagsLazyQueryHookResult = ReturnType<typeof useApplicationGlobalFeatureFlagsLazyQuery>;
export type ApplicationGlobalFeatureFlagsQueryResult = Apollo.QueryResult<ApplicationGlobalFeatureFlagsQuery, ApplicationGlobalFeatureFlagsQueryVariables>;
export const FeatureFlagGroupsDocument = gql`
    query FeatureFlagGroups {
  featureFlagGroups {
    group
    flags
  }
}
    `;

/**
 * __useFeatureFlagGroupsQuery__
 *
 * To run a query within a React component, call `useFeatureFlagGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeatureFlagGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeatureFlagGroupsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFeatureFlagGroupsQuery(baseOptions?: Apollo.QueryHookOptions<FeatureFlagGroupsQuery, FeatureFlagGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FeatureFlagGroupsQuery, FeatureFlagGroupsQueryVariables>(FeatureFlagGroupsDocument, options);
      }
export function useFeatureFlagGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FeatureFlagGroupsQuery, FeatureFlagGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FeatureFlagGroupsQuery, FeatureFlagGroupsQueryVariables>(FeatureFlagGroupsDocument, options);
        }
export type FeatureFlagGroupsQueryHookResult = ReturnType<typeof useFeatureFlagGroupsQuery>;
export type FeatureFlagGroupsLazyQueryHookResult = ReturnType<typeof useFeatureFlagGroupsLazyQuery>;
export type FeatureFlagGroupsQueryResult = Apollo.QueryResult<FeatureFlagGroupsQuery, FeatureFlagGroupsQueryVariables>;
export const GetUserFromInvitationTokenDocument = gql`
    query GetUserFromInvitationToken($confirmationToken: String!) {
  getUserFromInvitationToken(confirmationToken: $confirmationToken) {
    ... on AdminUser {
      email
    }
    ... on GenericError {
      message
    }
  }
}
    `;

/**
 * __useGetUserFromInvitationTokenQuery__
 *
 * To run a query within a React component, call `useGetUserFromInvitationTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserFromInvitationTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserFromInvitationTokenQuery({
 *   variables: {
 *      confirmationToken: // value for 'confirmationToken'
 *   },
 * });
 */
export function useGetUserFromInvitationTokenQuery(baseOptions: Apollo.QueryHookOptions<GetUserFromInvitationTokenQuery, GetUserFromInvitationTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserFromInvitationTokenQuery, GetUserFromInvitationTokenQueryVariables>(GetUserFromInvitationTokenDocument, options);
      }
export function useGetUserFromInvitationTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserFromInvitationTokenQuery, GetUserFromInvitationTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserFromInvitationTokenQuery, GetUserFromInvitationTokenQueryVariables>(GetUserFromInvitationTokenDocument, options);
        }
export type GetUserFromInvitationTokenQueryHookResult = ReturnType<typeof useGetUserFromInvitationTokenQuery>;
export type GetUserFromInvitationTokenLazyQueryHookResult = ReturnType<typeof useGetUserFromInvitationTokenLazyQuery>;
export type GetUserFromInvitationTokenQueryResult = Apollo.QueryResult<GetUserFromInvitationTokenQuery, GetUserFromInvitationTokenQueryVariables>;