const createProxyWithMethods = (target = {}) =>
  new Proxy(target, {
    get(_, propKey) {
      if (propKey in target) return target[propKey as keyof typeof target];

      return function (...args: any) {
        console.info(`Mixpanel: calling method ${String(propKey)} with arguments: `, ...args);
      };
    },
  });

export default createProxyWithMethods({ people: createProxyWithMethods() }) as any;
