import { getOperationDefinition } from '@apollo/client/utilities';
import { Operation } from '@apollo/client';
import { FieldNode } from 'graphql';

export const getOperationDescriptor = (operation: Operation, prefix = '') => {
  const opDefinition = getOperationDefinition(operation.query);

  return `${prefix}${opDefinition?.operation ?? 'unknown'} ${operation.operationName} > ${
    (opDefinition?.selectionSet.selections[0] as FieldNode)?.name?.value ?? 'unknown'
  }`;
};

export const batch = { context: { batch: true } };
