import React from 'react';
import { Link } from 'react-router-dom';

import * as S from './styles';

type Props = {
  fullName: string;
  linkTo?: string;
};

const UserName = ({ fullName, linkTo }: Props) => {
  const name = <S.UserName data-testid="userInfo-fullName">{fullName}</S.UserName>;

  if (!!linkTo) return <Link to={linkTo}>{name}</Link>;

  return name;
};

export default UserName;
