import { useCallback, useEffect, useState } from 'react';

import useOnChange from './useOnChange';

export type UseTableSearchConfig = {
  initialSearchValue?: string;
  onSearch?: (searchTerm: string) => void;
  clearSearchRef: React.MutableRefObject<(() => void) | undefined>;
};

const useTableSearch = ({ onSearch, initialSearchValue, clearSearchRef }: UseTableSearchConfig) => {
  const [search, setSearch] = useState('');
  const handleSearch = useCallback(
    (currentValue?: string) => onSearch?.(currentValue ?? search),
    [search, onSearch]
  );

  useOnChange(initialSearchValue, (newValue) => setSearch(newValue || ''));

  useEffect(() => {
    if (clearSearchRef && !clearSearchRef.current) {
      clearSearchRef.current = () => setSearch('');
    }
  }, [setSearch, clearSearchRef]);

  return {
    search,
    handleSearch,
    onChange: setSearch,
  };
};

export default useTableSearch;
