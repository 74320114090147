import { Mixpanel } from 'mixpanel-browser';
import { User } from 'firebase/auth';

const flowsFactory = (mixpanel: Mixpanel) =>
  new (class {
    trackUserClaims = (claims?: any | null) => {
      mixpanel.people.set({
        'User Role': claims?.role,
        'Show Onboarding Tutorial': claims?.showOnboardingTutorial,
        'Sign Up Step': claims?.signUpStep,
        Primary: claims?.isPrimary,
        'Subscription End': claims?.subscriptionEnd,
      });
    };

    authentication = (firebaseUser?: User | null, claims?: any | null) => {
      if (firebaseUser) {
        mixpanel.people.set({
          $avatar: firebaseUser.photoURL,
          $email: firebaseUser.email,
          $phone: firebaseUser.phoneNumber,
          $name: firebaseUser.displayName,
          $created: firebaseUser.metadata.creationTime,
          'Last Signed In': firebaseUser.metadata.lastSignInTime,
        });

        this.trackUserClaims(claims);

        mixpanel.identify(firebaseUser.uid);
      } else {
        mixpanel.reset();
      }
    };
  })();

export default flowsFactory;
