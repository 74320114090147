import ReactGA from 'react-ga';

import { getEnvVar, tryGetEnvVar } from 'utils/env';

let initalized: { result: boolean };

const init = () => {
  if (!initalized) {
    const enabled = tryGetEnvVar('GOOGLE_ANALYTICS_ENABLED') === 'true';
    const trackingCode = enabled && getEnvVar('GOOGLE_ANALYTICS_TRACKING_CODE');

    trackingCode &&
      ReactGA.initialize(trackingCode, {
        testMode: !enabled,
      });

    initalized = { result: !!trackingCode && enabled };
  }

  return initalized.result;
};

export default init;
