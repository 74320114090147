import { useCallback, useMemo } from 'react';
import { useApolloClient } from '@apollo/client';
import { getQueryDefinition } from '@apollo/client/utilities';
import { DocumentNode, SelectionNode, FieldNode, FragmentSpreadNode, Kind } from 'graphql';

import { PaginationResult } from 'codegen';

// CONSTANTS AND TYPES DEPENDING ON APOLLO
const QUERY_PREFIX = `$ROOT_QUERY.`;
type Cache = {
  ROOT_QUERY?: { [key: string]: any };
  [key: string]: any | Pagination;
};

//CONSTANTS AND TYPES DEPENDING ON OUR END
type Pagination = Pick<PaginationResult, 'currentPage' | 'total'>;

const isNameableNode = (node: SelectionNode): node is FieldNode | FragmentSpreadNode =>
  node.kind === Kind['FIELD'] || node.kind === Kind['FRAGMENT_SPREAD'];

// Returns a callback to find and remove given query and related pagination info in the cache and refetch latest query
const useRefetchPagination = (
  queryDocument: DocumentNode,
  refetch?: () => Promise<any>
): (() => void) => {
  const { cache } = useApolloClient();
  const extractedCache = cache.extract() as Cache;
  const queryDefinition = useMemo(
    () => getQueryDefinition(queryDocument).selectionSet.selections[0],
    [queryDocument]
  );

  const cleanPagesInCache = useCallback(() => {
    if (!queryDefinition || !isNameableNode(queryDefinition)) return;

    const queryName = queryDefinition.name.value;
    if (extractedCache.ROOT_QUERY) {
      for (const key in extractedCache.ROOT_QUERY) {
        if (key.startsWith(`${queryName}(`)) {
          const objId = `${QUERY_PREFIX}${key}`;
          const paginationObjId = extractedCache[objId]?.pagination?.id;

          paginationObjId && (cache as any).data.delete(paginationObjId);
          (cache as any).data.delete(`${QUERY_PREFIX}${key}`);
        }
      }
    }

    refetch?.();
  }, [cache, extractedCache, queryDefinition, refetch]);

  return cleanPagesInCache;
};

export default useRefetchPagination;
