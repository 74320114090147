import { pickBy, identity, isEqual, get, mapValues } from 'lodash';

export const replaceEmptyArraysWithUndefined = <T extends { [key: string]: Maybe<any[]> }>(
  object: T
) => {
  return mapValues(object, (value) => (value?.length ? value : undefined));
};

export const areObjectsWithArraysEqual = <
  T extends { [key: string]: Maybe<any[]> },
  K extends { [key: string]: Maybe<any[]> } = T
>(
  object1: T,
  object2?: K
) => {
  const internalEqual = (o1?: T | K, o2?: K | T) =>
    !Object.keys(o1 || {}).some((key) => !isEqual(get(o1, key), get(o2, key)));
  return internalEqual(object1, object2) && internalEqual(object2, object1);
};

export const getUndefinedIfNoKeys = <T extends object>(object: T) =>
  // pickBy(object, identity) = remove keys with undefined values
  // https://stephencharlesweiss.com/objects-undefined-values-and-lodash-pickby-identity/
  Object.keys(pickBy(object, identity)).length === 0 ? undefined : object;
