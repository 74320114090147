import styled, { css } from 'styled-components';

import phoneIconSVG from 'assets/svg/phoneIcon.svg';
import letterIconSVG from 'assets/svg/letterIcon.svg';
import { themeColor } from 'utils/mixins';

export const Wrap = styled.div<{ $welcome?: boolean }>`
  color: ${themeColor('white')};

  text-transform: uppercase;
  ${({ $welcome }) =>
    $welcome
      ? css`
          text-align: center;
          font-size: ${({ theme }) => theme.fontSizes.sm};
          line-height: ${({ theme }) => theme.lineHeights.sm};
          letter-spacing: 0.5px;
          opacity: 0.9;
        `
      : css`
          font-size: ${({ theme }) => theme.fontSizes.xsm};
          line-height: ${({ theme }) => theme.lineHeights.xsm};
          text-align: left;
          letter-spacing: 0.2px;
        `}
`;

export const Value = styled.div``;

export const Link = styled.a`
  display: block;
  margin-top: 2px;
  color: ${themeColor('white')};
`;

export const Small = styled.small`
  font-size: 0.6em;
`;

export const ContactWrap = styled.div`
  display: flex;
  align-items: center;
`;

const iconMarginRight = css`
  margin-right: 7px;
`;

export const PhoneIcon = styled.img.attrs({ src: phoneIconSVG })`
  ${iconMarginRight}
`;

export const LetterIcon = styled.img.attrs({ src: letterIconSVG })`
  ${iconMarginRight}
`;
