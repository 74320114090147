import React from 'react';
import { RouteChildrenProps } from 'react-router-dom';

import { useOrganizationsQuery } from 'codegen';

const OrganizationName: React.FC<RouteChildrenProps<{ id: string }>> = ({ match }) => {
  const organizationIdParam = match?.params.id;
  const organizationId = Number(organizationIdParam);
  const { data, loading } = useOrganizationsQuery({
    fetchPolicy: 'cache-only',
    variables: { filter: { organizationId: [organizationId] } },
    skip: isNaN(organizationId) || organizationIdParam == null,
  });

  return <span>{loading ? '...' : data?.organizations.data[0].name || organizationIdParam}</span>;
};

export default OrganizationName;
