import styled from 'styled-components';

import { themeOpaqueColor } from 'utils/mixins';

export const LegalDocsWrap = styled.div`
  & > a {
    margin-bottom: 3px;
  }
  & > a:last-child {
    margin-bottom: ${({ theme }) => theme.spacings.sm};
  }
`;

export const LegalDoc = styled.a`
  display: block;
  text-transform: none;
  color: ${themeOpaqueColor('white', 0.6)};
`;
