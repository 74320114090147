import Notification from 'components/Notification';
import {
  useUpdateAdminUserMutation,
  useUpdateMeMutation,
  MeDocument,
  CurrentAdminUser,
  UpdateAdminUserInput,
} from 'codegen';

const useUpdateAdminUser = () => {
  const [updateAdminUser, { loading: updateAdminUserLoading }] = useUpdateAdminUserMutation();
  const [updateMe, { loading: updateMeLoading }] = useUpdateMeMutation({
    update(cache, { data }) {
      if (data?.updateMe.__typename === 'AdminUser') {
        const meResult = cache.readQuery({ query: MeDocument }) as { me: CurrentAdminUser } | null;

        if (meResult) {
          cache.writeQuery({
            query: MeDocument,
            data: {
              me: {
                ...meResult.me,
                firstName: data.updateMe.firstName,
                lastName: data.updateMe.lastName,
              },
            },
          });
        }
      }
    },
  });

  const handleUpdateMe = async (input: UpdateAdminUserInput, onSuccess?: () => void) => {
    try {
      const result = await updateMe({
        variables: { input },
      });
      if (result.data?.updateMe?.__typename === 'GenericError') {
        throw new Error(result.data?.updateMe.message);
      }

      onSuccess?.();
      Notification.success('Success! Your information was updated.');
      return result;
    } catch (e) {
      Notification.error('Could not update your information. Please try again.');
    }
  };

  const handleUpdateAdminUser = async (
    input: UpdateAdminUserInput,
    adminUserId: number,
    adminUserNaming: string,
    onSuccess?: () => void
  ) => {
    try {
      const result = await updateAdminUser({
        variables: { input, adminUserId },
      });

      if (result.data?.updateAdminUser?.__typename === 'GenericError') {
        throw new Error(result.data.updateAdminUser.message);
      }

      onSuccess?.();
      Notification.success(`Success! ${adminUserNaming} information was updated.`);
      return result;
    } catch (e) {
      Notification.error(`Could not update ${adminUserNaming} information. Please try again.`);
    }
  };

  return {
    updateAdminUser,
    updateAdminUserLoading,
    updateMeLoading,
    updateMe,
    handleUpdateMe,
    handleUpdateAdminUser,
  };
};

export default useUpdateAdminUser;
