import { initializeApp } from 'firebase/app';
import {
  initializeAuth,
  browserLocalPersistence,
  signInWithEmailAndPassword as _signInWithEmailAndPassword,
  sendPasswordResetEmail as _sendPasswordResetEmail,
  confirmPasswordReset as _confirmPasswordReset,
  verifyPasswordResetCode as _verifyPasswordResetCode,
  signInWithCustomToken as _signInWithCustomToken,
  getIdToken as _getIdToken,
  sendEmailVerification as _sendEmailVerification,
  applyActionCode as _applyActionCode,
  getMultiFactorResolver as _getMultiFactorResolver,
  getIdTokenResult as _getIdTokenResult,
  multiFactor as _multiFactor,
  User,
  MultiFactorError,
} from 'firebase/auth';
import { firebaseConfig } from './config';
export * from './errors';

const firebaseApp = initializeApp(firebaseConfig);

export const auth = initializeAuth(firebaseApp, { persistence: browserLocalPersistence });
export const signInWithEmailAndPassword = (email: string, password: string) =>
  _signInWithEmailAndPassword(auth, email, password);
export const signOut = () => auth.signOut();
export const sendPasswordResetEmail = (email: string) => _sendPasswordResetEmail(auth, email);
export const confirmPasswordReset = (oobCode: string, newPassword: string) =>
  _confirmPasswordReset(auth, oobCode, newPassword);
export const verifyPasswordResetCode = (oobCode: string) => _verifyPasswordResetCode(auth, oobCode);
export const currentUser = () => auth.currentUser;
export const signInWithCustomToken = (token: string) => _signInWithCustomToken(auth, token);
export const applyActionCode = (oobCode: string) => _applyActionCode(auth, oobCode);
export const currentLoggedInUser = () => {
  const user = currentUser();
  if (isUserLoggedIn(user)) return user;
  throw new Error('User is not logged in');
};
export const getMultiFactorResolver = (error: MultiFactorError) =>
  _getMultiFactorResolver(auth, error);
export const sendEmailVerification = () => _sendEmailVerification(currentLoggedInUser());
export const getIdTokenResult = (user: User, forceRefresh?: boolean) =>
  _getIdTokenResult(user, !!forceRefresh);
export const getIdToken = (forceRefresh?: boolean) =>
  _getIdToken(currentLoggedInUser(), !!forceRefresh);
export const multiFactor = () => _multiFactor(currentLoggedInUser());

const isUserLoggedIn = (user: User | null): user is User => !!user;
