import React, { useMemo, useLayoutEffect, useState } from 'react';
import BaseTour, { ReactourProps } from 'reactour';
import { waitForTheElement } from 'wait-for-the-element';
import { useLocation } from 'react-router-dom';

import TutorialStepBubble from 'components/OnboardingTutorial/TutorialStepBubble';

import GlobalStylesDuringTutorial from './GlobalStylesDuringTutorial';
import { tourSteps, StepRouteMap, DISABLED_KEYS } from './constants';

type Props = Partial<Omit<ReactourProps, 'isOpen'>> & {
  isOpen: ReactourProps['isOpen'];
  step?: number;
  nextStep: () => void;
  goToEnd: () => void;
};

const Tour: React.FC<Props> = ({ step, nextStep, goToEnd, ...tourProps }) => {
  const wrappedTourSteps = useMemo(
    () => [
      ...tourSteps.map((st) => ({
        ...st,
        content: (
          <TutorialStepBubble onNext={nextStep} onClose={goToEnd}>
            {st.content}
          </TutorialStepBubble>
        ),
      })),
    ],
    [goToEnd, nextStep]
  );

  const useWaitForElement = (step?: number) => {
    const [awaitedStep, setAwaitedStep] = useState<typeof step>(undefined);
    const location = useLocation();
    const pathname = location.pathname;
    useLayoutEffect(() => {
      if (step === awaitedStep) return;
      if (step == null) return setAwaitedStep(step);
      if (pathname === StepRouteMap[(step + 1) as keyof typeof StepRouteMap]) {
        (async () => {
          const tourStep = tourSteps[step];
          if (tourStep && tourStep.selector) {
            await waitForTheElement(tourStep.selector, { timeout: 5000 });
          }
          setTimeout(() => setAwaitedStep(step), 100);
        })();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [step, pathname]);

    return awaitedStep;
  };

  const delayedStep = useWaitForElement(step);

  return (
    <>
      {delayedStep != null && <GlobalStylesDuringTutorial />}
      {delayedStep != null && (
        <BaseTour
          disableInteraction
          disableDotsNavigation
          maskClassName="onboarding-tutorial-mask"
          showNumber={false}
          showNavigation={false}
          showButtons={false}
          closeWithMask={false}
          showCloseButton={false}
          disableKeyboardNavigation={DISABLED_KEYS}
          // Disable scrolling
          inViewThreshold={Number.NEGATIVE_INFINITY}
          maskSpace={4}
          rounded={5}
          update={`${delayedStep}`}
          updateDelay={500}
          onRequestClose={goToEnd}
          goToStep={delayedStep}
          steps={wrappedTourSteps}
          {...tourProps}
        />
      )}
    </>
  );
};

export default Tour;
