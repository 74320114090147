import { useState, useEffect } from 'react';

import { useMeQuery } from 'codegen';
import { AdminUsers } from 'utils/constants';
import { StorymakerUsers } from 'utils/constants';

import useFirebaseClaims from './useFirebaseClaims';

const SUBSCRIPTION_AWARE_ROLES: readonly UserRole[] = [AdminUsers.Manager];

const useIsSubscriptionValid = () => {
  const [isValid, setValid] = useState<boolean | undefined>();
  const { claims, refetchClaims } = useFirebaseClaims();
  const { refetch: refetchMe } = useMeQuery({
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    (async () => {
      if (!claims) {
        return setValid(true);
      }

      const isReviewer = (claims?.role as unknown as string) === StorymakerUsers.Reviewer; // TODO: Retyping for unknown is work around, just for this one case. Otherwise types in whole app need to be rewritten

      if (isReviewer) {
        return setValid(true);
      }

      const shouldBotherWithSubscription =
        !claims?.role || SUBSCRIPTION_AWARE_ROLES.includes(claims.role);

      let subscriptionEnd = claims?.subscriptionEnd;
      const hasExpired = !subscriptionEnd || subscriptionEnd <= new Date().getTime();
      const isInvalid = !subscriptionEnd || hasExpired;

      if (shouldBotherWithSubscription && isInvalid) {
        const freshUser = await refetchClaims();

        subscriptionEnd = freshUser?.idTokenResult.claims.subscriptionEnd;

        if (isInvalid) {
          return setValid(false);
        }
      }

      return setValid(true);
    })();

    refetchMe();
  }, [claims, refetchClaims, refetchMe]);

  const isLoading = isValid == null;

  return [isLoading, isValid];
};

export default useIsSubscriptionValid;
