import React from 'react';
import { message } from 'antd';

import errorIconSVG from 'assets/svg/NotificationIcons/error.svg';
import successIconSVG from 'assets/svg/NotificationIcons/success.svg';

import * as S from './styles';

/**
 *
 * @param content
 * @param duration Duration in seconds
 */
const success = (content: string | React.ReactNode, duration = 6) => {
  message.success({
    content: <div data-testid="notificationSuccess">{content}</div>,
    duration,
    icon: <S.Icon src={successIconSVG} alt="User notification success icon" />,
  });
};

/**
 *
 * @param content
 * @param duration Duration in seconds
 */
const error = (content: string | React.ReactNode, duration = 4) => {
  message.error({
    content: <div data-testid="notificationError">{content}</div>,
    duration,
    icon: <S.Icon src={errorIconSVG} alt="User notification error icon" />,
  });
};

const destroy = message.destroy;

const exports = { error, success, destroy };

export default exports;
