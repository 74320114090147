import React, { useCallback } from 'react';

import { useWarning } from 'utils/hooks';

import config from './config';
import * as S from './styles';

type Props = {
  modalProps: ModalControlProps;
  isFinished?: boolean;
  onHardCancel?: () => Promise<void> | void;
  onStart?: () => void;
};

const WARNING_PROPS = {
  text: 'Are you sure you want to dismiss our onboaring tutorial?',
  okText: 'Dismiss',
  cancelText: 'Cancel',
  cancelAfterOk: true,
};

const TutorialPopup: React.FC<Props> = ({ modalProps, isFinished, onHardCancel, onStart }) => {
  const warning = useWarning();
  const copy = config[isFinished ? 'finished' : 'unfinished'];
  const handleDontShowThisAgainClick = useCallback(() => {
    if (copy.showWarning) {
      return warning.openWarning({
        ...WARNING_PROPS,
        onOk: async () => onHardCancel?.(),
      });
    }
    onHardCancel?.();
  }, [onHardCancel, warning, copy]);

  return (
    <S.Card
      cover={
        <S.CardCoverWrap>
          <S.CardCoverImg />
          <S.CardCoverClose onClick={modalProps.onCancel} />
        </S.CardCoverWrap>
      }
      $visible={!!modalProps.visible}
    >
      <S.CardBody>
        <S.Title>{copy.title}</S.Title>
        <S.Paragraph>{copy.description}</S.Paragraph>

        <S.StartButton onClick={onStart}>{copy.startButtonText}</S.StartButton>
        <S.SkipButton onClick={handleDontShowThisAgainClick} $color={copy.dontShowButtonColor}>
          Don't show this again
        </S.SkipButton>
      </S.CardBody>
    </S.Card>
  );
};

export default TutorialPopup;
