import { useState, useCallback } from 'react';
import { useApolloClient } from '@apollo/client';

import {
  StripeCustomerPortalUrlQuery,
  StripeCustomerPortalUrlQueryVariables,
  StripeCustomerPortalUrlDocument,
} from 'codegen';

const useRedirectToCustomerPortal = () => {
  const client = useApolloClient();
  const [redirectLoading, setManageLoading] = useState(false);

  const handleRedirect = useCallback(async () => {
    setManageLoading(true);
    const result = await client.query<
      StripeCustomerPortalUrlQuery,
      StripeCustomerPortalUrlQueryVariables
    >({
      query: StripeCustomerPortalUrlDocument,
      variables: { returnUrl: window.location.href },
      fetchPolicy: 'network-only',
    });

    if (result.data?.stripeCustomerPortalUrl) {
      window.location.href = result.data.stripeCustomerPortalUrl;
    }
    setManageLoading(false);
  }, [client, setManageLoading]);

  return [handleRedirect, redirectLoading] as const;
};

export default useRedirectToCustomerPortal;
