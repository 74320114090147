import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export const getUrlSearchParams = <T extends string, Result extends Record<T, Maybe<string>>>(
  params: readonly T[],
  urlSearch: Maybe<string>
): Result => {
  if (!urlSearch) return {} as Result;

  const queryParams = new URLSearchParams(urlSearch);

  return params.reduce<Result>(
    (obj, param) => ({ ...obj, [param]: queryParams.get(param) }),
    {} as Result
  );
};

export const getAllUrlSearchParams = (urlSearch: Maybe<string>) => {
  let result: { [key: string]: string } = {};
  if (!urlSearch) return result;

  const queryParams = new URLSearchParams(urlSearch);
  for (const [key, val] of queryParams.entries()) {
    result[key] = val;
  }

  return result;
};

const useUrlSearchParams = <T extends string>(params: readonly T[]) => {
  const { search } = useLocation();

  const queryParams = useMemo(() => getUrlSearchParams(params, search), [search, params]);

  return queryParams;
};

export default useUrlSearchParams;
