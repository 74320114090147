import { useState, useRef, useCallback } from 'react';
import { omit, isEqual, compact } from 'lodash';

type ValueType = string | boolean | number;
type FormData = Record<string, ValueType | null | undefined>;

type Field = {
  initialValue: ValueType;
  currentValue: ValueType;
};

type ChangeObject = { [key in string]: Field };

type ReturnType = {
  fieldChangeObject: React.MutableRefObject<ChangeObject>;
  isFormChanged: boolean;
  onInputChange: (fieldKey: string, currentValue: ValueType) => void;
  resetFormStatus: (formData?: FormData) => void;
  onInputRemove: (...fieldKey: string[]) => void;
};

const generateFieldChangeObject = (formData: FormData) =>
  Object.entries(formData).reduce((acc, [key, value]) => {
    const safeValue = value ?? '';
    return {
      ...acc,
      [key]: {
        currentValue: safeValue,
        initialValue: safeValue,
      } as Field,
    };
  }, {});

const generateInitialFieldValuesObject = (formData: FormData) => compact(Object.values(formData));

const useIsFormChanged = (formData: FormData, isFormKeyAgnostic = false): ReturnType => {
  const [isFormChanged, setFormChanged] = useState(false);

  const fieldChangeObject = useRef<ChangeObject>(generateFieldChangeObject(formData));

  const initialFieldValues = useRef<ValueType[]>(generateInitialFieldValuesObject(formData));

  const applyChange = useCallback(
    (curr: ChangeObject) => {
      const isCurrChanged = isFormKeyAgnostic
        ? !isEqual(
            initialFieldValues.current,
            compact(Object.keys(curr).map((fieldKey) => curr[fieldKey].currentValue))
          )
        : Object.keys(curr).some((key) => curr[key].currentValue !== curr[key].initialValue);

      if (isFormChanged !== isCurrChanged) {
        setFormChanged(isCurrChanged);
      }
    },
    [isFormKeyAgnostic, isFormChanged]
  );

  const onInputChange = useCallback(
    (fieldKey: string, currentValue: ValueType) => {
      // If the field wasn't there previously, add it to the fieldChangeObject
      if (!fieldChangeObject.current[fieldKey]) {
        const value = '';

        fieldChangeObject.current[fieldKey] = {
          currentValue: currentValue,
          initialValue: value,
        };
      }

      fieldChangeObject.current[fieldKey].currentValue = currentValue;

      applyChange(fieldChangeObject.current);
    },
    [applyChange]
  );

  const onInputRemove = useCallback(
    (...inputKeys: string[]) => {
      fieldChangeObject.current = omit(fieldChangeObject.current, inputKeys);

      applyChange(fieldChangeObject.current);
    },
    [applyChange]
  );

  const resetFormStatus = useCallback(
    (updatedFormData?: FormData) => {
      fieldChangeObject.current = generateFieldChangeObject(updatedFormData || formData);

      if (updatedFormData) {
        initialFieldValues.current = generateInitialFieldValuesObject(updatedFormData);
      }

      setFormChanged(false);
    },
    [formData]
  );

  return { isFormChanged, onInputChange, resetFormStatus, onInputRemove, fieldChangeObject };
};

export default useIsFormChanged;
