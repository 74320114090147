import theme from 'theme';

const config = {
  finished: {
    title: 'You’re almost set!',
    description:
      'To film your first video, download the mobile app. We already sent you the email with instructions.',
    startButtonText: 'Retake Tutorial',
    showWarning: false,
    dontShowButtonColor: theme.colors.darkGrey,
  },
  unfinished: {
    title: 'Welcome to the Storyvine Dashboard!',
    description: 'Let’s finalize the setup. You can always come back to this tutorial later.',
    startButtonText: 'Start Tutorial',
    showWarning: true,
    dontShowButtonColor: theme.colors.grey,
  },
};

export default config;
