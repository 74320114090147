import React from 'react';
import { ReactourStep, ReactourProps } from 'reactour';

export const tourSteps = [
  {
    selector: '.storymakers-nav-item',
    content: (
      <>
        Start by adding a few Storymakers to your account, these are the app users. You can add and
        manage them in the Storymakers tab.
      </>
    ),
    position: 'right',
  },
  {
    selector: '.add-storymakers-button',
    content: <>Click Add Storymaker(s) and select your preferred option.</>,
  },
  {
    selector: '.add-storymakers-email-invite-menu-item',
    content: <>Have Storymaker email addresses? Start off by sending Email Invites.</>,
  },

  {
    selector: '.add-storymakers-email-invite-modal .ant-modal-content',
    content: (
      <>
        Here, set the Filming Groups. What VideoGuide templates do you want your Storymakers to
        access? Fill out Storymaker info and we’ll fire off their email invites.
      </>
    ),
    position: 'right',
  },
  {
    selector: '.settings-brand-nav-item',
    content: <>Go to Brand under Settings to set up the brand feel of the final videos.</>,
  },
  {
    selector: '.brand-settings-wrap',
    content: <>Click Edit next to each section to customize your videos' final look.</>,
  },
  {
    selector: '.upload-asset-modal .ant-modal-content',
    content: <>Upload your logos here.</>,
    position: 'right',
  },
  {
    selector: '.edit-color-modal .ant-modal-content',
    content: <>Change your brand colors here.</>,
    position: 'right',
  },
  {
    selector: '.edit-endcard-info-modal .ant-modal-content',
    content: (
      <>
        Endcard is the final video frame, a great place to add your organization’s contact info.
        Make sure it’s up to date.
      </>
    ),
    position: 'right',
  },
] as ReactourStep[];

export const StepRouteMap = {
  0: '/videos',
  1: '/videos',
  2: '/storymakers',
  3: '/storymakers',
  4: '/storymakers',
  5: '/storymakers',
  6: '/settings/brand',
  7: '/settings/brand',
  8: '/settings/brand',
  9: '/settings/brand',
  10: '/settings/brand',
};

export enum LocalStorageKeys {
  POPUP_CLOSED = 'onboardingTutorial_popupClosed',
}

export const DISABLED_KEYS: ReactourProps['disableKeyboardNavigation'] = ['esc', 'right', 'left'];
