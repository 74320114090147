import { MouseEvent, useCallback } from 'react';

const useStopPropagation = <T extends (e: MouseEvent<any, any>) => any>(callback?: T) => {
  return useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      return callback?.(e);
    },
    [callback]
  );
};

export default useStopPropagation;
