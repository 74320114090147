import React, { createContext } from 'react';
// Title Icons
import OrganizationsTitleIcon from 'assets/svg/NavBarIcons/24x24/organizations.svg';
import VideoTitleIcon from 'assets/svg/NavBarIcons/24x24/video.svg';
import StorymakerTitleIcon from 'assets/svg/NavBarIcons/24x24/storymakers.svg';
import ReviewersTitleIcon from 'assets/svg/NavBarIcons/24x24/reviewers.svg';
import ManagersTitleIcon from 'assets/svg/NavBarIcons/24x24/managers.svg';
import TemplatesTitleIcon from 'assets/svg/NavBarIcons/24x24/templates.svg';

// NavBar Items Default Icons
import OrganizationsFaded from 'assets/svg/NavBarIcons/20x20/Faded/organizations.svg';
import VideoFaded from 'assets/svg/NavBarIcons/20x20/Faded/video.svg';
import StorymakersFaded from 'assets/svg/NavBarIcons/20x20/Faded/storymakers.svg';
import ReviewersFaded from 'assets/svg/NavBarIcons/20x20/Faded/reviewers.svg';
import ManagersFaded from 'assets/svg/NavBarIcons/20x20/Faded/managers.svg';
import TemplatesFaded from 'assets/svg/NavBarIcons/20x20/Faded/templates.svg';

// NavBar Items Hovered Icons
import Organizations from 'assets/svg/NavBarIcons/20x20/White/organizations.svg';
import Video from 'assets/svg/NavBarIcons/20x20/White/video.svg';
import Storymakers from 'assets/svg/NavBarIcons/20x20/White/storymakers.svg';
import Reviewers from 'assets/svg/NavBarIcons/20x20/White/reviewers.svg';
import Managers from 'assets/svg/NavBarIcons/20x20/White/managers.svg';
import Templates from 'assets/svg/NavBarIcons/20x20/White/templates.svg';

import { NavigationConfig } from 'utils/navigation';

/**
 *  startsWith: routes with no navigation item needs to have an active item in sidebar when visited so we add those routes to the startsWith property of the item that we want to keep active.
 *  e.g. /template route doesn't have a navigation item and we add /template to startsWith prop of filming-groups so filming-groups will be the active when /template is visited.
 */
export const NAVIGATION_BAR_ITEMS: NavigationConfig = [
  {
    key: 'organizations',
    icon: {
      hovered: Organizations,
      normal: OrganizationsFaded,
      header: OrganizationsTitleIcon,
    },
    header: 'ORGANIZATIONS',
    sidebar: 'ORGANIZATIONS',
    path: '/organizations',
    startsWith: ['/organizations'],
  },
  {
    key: 'videos',
    icon: { hovered: Video, normal: VideoFaded, header: VideoTitleIcon },
    header: 'VIDEOS',
    sidebar: 'VIDEOS',
    path: '/videos',
    startsWith: ['/videos'],
  },
  {
    key: 'storymakers',
    icon: { hovered: Storymakers, normal: StorymakersFaded, header: StorymakerTitleIcon },
    header: 'STORYMAKERS',
    className: 'storymakers-nav-item',
    sidebar: 'STORYMAKERS',
    path: '/storymakers',
    startsWith: ['/storymakers'],
  },
  {
    key: 'reviewers',
    icon: { hovered: Reviewers, normal: ReviewersFaded, header: ReviewersTitleIcon },
    header: 'REVIEWERS',
    className: 'reviewers-nav-item',
    sidebar: 'REVIEWERS',
    path: '/reviewers',
    startsWith: ['/reviewers'],
  },
  {
    key: 'admins',
    icon: { hovered: Managers, normal: ManagersFaded, header: ManagersTitleIcon },
    header: 'ADMINS',
    sidebar: 'ADMINS',
    path: '/admins',
    startsWith: ['/admins', '/managers'],
  },
  {
    key: 'templates',
    icon: { hovered: Templates, normal: TemplatesFaded, header: TemplatesTitleIcon },
    header: 'TEMPLATES',
    sidebar: 'TEMPLATES',
    path: '/templates',
    startsWith: ['/templates'],
  },
];

export const Context = createContext(NAVIGATION_BAR_ITEMS);

export const Provider: React.FC = ({ children }) => {
  return <Context.Provider value={NAVIGATION_BAR_ITEMS}>{children}</Context.Provider>;
};
