import styled from 'styled-components';

export const Wrap = styled.div`
  display: flex;
  align-items: center;
`;

export const Divider = styled.div`
  height: 16px;
  width: 1px;
  background-color: white;
  margin-right: ${({ theme }) => theme.spacings.md};
`;

export const OrganizationName = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.md};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  line-height: ${({ theme }) => theme.lineHeights.sm};
  text-transform: uppercase;
  letter-spacing: 0.5px;
  color: white;
  margin-right: ${({ theme }) => theme.spacings.md};
`;
