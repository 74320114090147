export const getUndefinedIfEmpty = (str?: string) => (!str ? undefined : str);

export const removeSearchQueryFromUrl = (url?: string | null) => {
  if (!url) return url;

  try {
    const { origin, pathname } = new URL(url);
    return `${origin}${pathname}`;
  } catch (e) {
    return url;
  }
};

export const joinNullableStrings = (
  stringArr: (string | null | undefined)[],
  joinWith: string = ' '
) =>
  stringArr
    .map((str) => str || '')
    .join(joinWith)
    .trim();

export const replaceAll = (string: string, from: string, to: string) => string.split(from).join(to);

const randomByte = () => {
  // http://caniuse.com/#feat=getrandomvalues
  if (window.crypto && window.crypto.getRandomValues) {
    const result = new Uint8Array(1);
    window.crypto.getRandomValues(result);
    return result[0];
  } else if ((window as any).msCrypto && (window as any).msCrypto.getRandomValues) {
    const result = new Uint8Array(1);
    (window as any).msCrypto.getRandomValues(result);
    return result[0];
  } else {
    return Math.floor(Math.random() * 256);
  }
};

export const randomSecure = (length = 10) => [...new Array(length)].map(randomByte).join('');

export const escapeStringRegexp = (str: string) => {
  if (typeof str !== 'string') {
    throw new TypeError('Expected a string');
  }

  // Escape characters with special meaning either inside or outside character sets.
  // Use a simple backslash escape when it’s always valid, and a `\xnn` escape when the simpler form would be disallowed by Unicode patterns’ stricter grammar.
  return str.replace(/[|\\{}()[\]^$+*?.]/g, '\\$&').replace(/-/g, '\\x2d');
};
