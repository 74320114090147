import { useRef, useCallback, DependencyList } from 'react';
import { isEqual } from 'lodash';

/**
 * Ignores callback if called twice with the same set of arguments
 */
const useUniqueCallback = <T extends (...args: any[]) => any>(
  callback: T,
  deps: DependencyList
) => {
  const previousArgs = useRef<any[]>([]);
  const previousResult = useRef<any>();

  return useCallback(
    (...newArgs: any[]) => {
      if (
        previousArgs.current?.length !== newArgs.length ||
        !newArgs.some((arg, i) => isEqual(arg, previousArgs.current[i]))
      ) {
        previousResult.current = callback(...newArgs);
      }

      previousArgs.current = newArgs;
      return previousResult.current;
    },
    // eslint-disable-next-line
    deps
  );
};

export default useUniqueCallback;
