import { useMemo } from 'react';

const usePagination = (page: number, perPage = 25) => {
  return useMemo(
    () => ({ offset: Math.max(page - 1, 0) * perPage, limit: perPage }),
    [page, perPage]
  );
};

export default usePagination;
