import theme from 'theme';

/**
 * Transforms a numeric opacity to hexadecimal value
 * @param opacity number between 0 and 1 inclusive
 */
export const hexOpacity = (opacity: number) => {
  const result = Math.ceil(255 * Math.min(Math.max(opacity, 0), 1)).toString(16);
  return `0${result}`.slice(-2); // prepend 0 if it's single character
};

/**
 *
 * @param originalColor hexadecimal value of color which the opacity should be applied to
 * @param opacity number between 0 and 1 inclusive
 */
export const applyOpacity = (originalColor: string, opacity: number = 1) =>
  `${originalColor}${hexOpacity(opacity)}`;

export const getColorOrDefault = (color?: string | null) => color || theme.colors.white;
