import { flattenDeep } from 'lodash';

export const isSpecialFlowRoute = (
  routesMap: { [key: number]: string | readonly string[] } | readonly string[],
  pathname: string
) =>
  (
    flattenDeep(
      Array.isArray(routesMap) ? routesMap : Object.values(routesMap as object)
    ) as string[]
  ).includes(pathname);
