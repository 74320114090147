import { useMemo, useRef, useCallback } from 'react';
import { mapValues } from 'lodash';
import {
  useQueryParams,
  useQueryParam,
  withDefault,
  StringParam,
  NumberParam,
} from 'use-query-params';

import useUniqueCallback from 'utils/hooks/useUniqueCallback';
import useOnChange from 'utils/hooks/useOnChange';
import useTableSorting from 'utils/hooks/useTableSorting';
import { getUndefinedIfNoKeys } from 'utils/object';

const useFilterSearchSortingAndPagination = (
  filterSchema: Parameters<typeof useQueryParams>[0],
  sortingSettings: Parameters<typeof useTableSorting>
) => {
  const sorting = useTableSorting(...sortingSettings);
  const clearSearchRef = useRef<() => void | null>();
  const clearFilterRef = useRef<() => void | null>();
  const [page, setPage] = useQueryParam('page', withDefault(NumberParam, 1));
  const [perPage, setPerPage] = useQueryParam(
    'limit',
    withDefault(NumberParam, Number(localStorage.getItem('tableLimitPerPage')) || 25)
  );
  const [internalFilter, internalSetFilter] = useQueryParams(filterSchema);
  const [search, setSearch] = useQueryParam('search', withDefault(StringParam, ''));

  const setFilter = useUniqueCallback(
    (value: Maybe<Partial<typeof filter>>) => {
      if (value == null) {
        // FIXME: Fix type returned from mapValues
        return internalSetFilter(mapValues(filter, () => value) as any);
      }
      return internalSetFilter(value);
    },
    [internalFilter, internalSetFilter]
  );

  // Derive the search values
  const derived = useMemo(
    () => [search, ...Object.values(internalFilter)].join(','),
    [search, internalFilter]
  );

  // When filtering or searching - redirect a user to page 1
  useOnChange(derived, () => page > 1 && setPage(undefined), undefined, false);

  const clearSearch = useCallback(() => {
    setSearch('');
    clearSearchRef.current?.();
  }, [setSearch]);

  const clearFilter = useCallback(() => {
    setFilter(undefined);
    clearFilterRef.current?.();
  }, [setFilter]);

  const filter = getUndefinedIfNoKeys(internalFilter);

  return {
    page,
    setPage,
    perPage,
    setPerPage,
    filter,
    setFilter,
    search,
    setSearch,
    sorting,
    clearSearchRef,
    clearFilterRef,
    clearSearch,
    clearFilter,
    clearAll: useCallback(() => {
      clearSearch();
      clearFilter();
    }, [clearSearch, clearFilter]),
    isFiltered: !!search || !!filter,
  };
};

export default useFilterSearchSortingAndPagination;
