import React from 'react';
import { Link } from 'react-router-dom';

import Notification from 'components/Notification';
import Loading from 'components/Loading';
import { useRoleFeatureAvailability } from 'utils/hooks';
import { joinNullableStrings } from 'utils/string';
import { isAdministratorRole, isReviewerRole } from 'utils/roles';
import { useMeQuery } from 'codegen';

import UserName from './UserName';
import * as S from './styles';

type Props = { logout: () => Promise<void>; role?: UserRole };

const UserInfo: React.FC<Props> = ({ logout, role }) => {
  const { data, loading } = useMeQuery({
    fetchPolicy: 'network-only',
  });
  const { naming } = useRoleFeatureAvailability();
  const isReviewer = isReviewerRole(role);
  const isAccountAdmin = isAdministratorRole(role);

  const currentAdminUser = data?.me?.__typename === 'CurrentAdminUser' ? data.me : null;
  const organizationName = currentAdminUser?.organization?.name;
  const fullName = joinNullableStrings([currentAdminUser?.firstName, currentAdminUser?.lastName]);
  const showOrganization = !!organizationName && !isReviewer;
  const showDivider = showOrganization && !!fullName;

  const genericError = data?.me?.__typename === 'GenericError' ? data?.me : null;

  if (genericError) {
    Notification.error(genericError.message);
    logout();
  }

  return (
    <S.Wrap>
      {loading ? (
        <Loading size="small" backgroundColor="white" />
      ) : (
        <>
          {showOrganization && (
            <Link to="/settings/organization">
              <S.OrganizationName data-testid="userInfo-organizationName">
                {organizationName}
              </S.OrganizationName>
            </Link>
          )}

          {showDivider && <S.Divider />}

          {fullName && (
            <UserName
              fullName={fullName}
              linkTo={
                !isReviewer && !isAccountAdmin
                  ? `/settings/${naming.me().toLowerCase()}s`
                  : undefined
              }
            />
          )}
        </>
      )}
    </S.Wrap>
  );
};

export default UserInfo;
