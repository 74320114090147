import styled from 'styled-components';
import { Menu as AntdMenu } from 'antd';

export const Menu = styled(AntdMenu)`
  &.ant-dropdown-menu {
    display: flex;
    flex-direction: column;
    margin-right: ${({ theme }) => theme.spacings.lg};
    padding: 0px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
    border-radius: ${({ theme }) => theme.radius.sm};
  }
`;
