import { useCallback } from 'react';
import Notification from 'components/Notification';

const useCopyToClipboardCallback = (
  link: string,
  options = {
    successMessage: 'Success! Link copied to clipboard.',
    successDuration: 1.5,
    errorMessage: 'Oops, could not copy the link. Please try again.',
    errorDuration: 1.5,
  }
) => {
  const { successMessage, successDuration, errorMessage, errorDuration } = options;
  return useCallback(async () => {
    try {
      await navigator.clipboard.writeText(link);
      Notification.success(successMessage, successDuration);
    } catch {
      Notification.error(errorMessage, errorDuration);
    }
  }, [link, successMessage, successDuration, errorMessage, errorDuration]);
};

export default useCopyToClipboardCallback;
