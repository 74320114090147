import styled from 'styled-components';

import { themeColor, themeOpaqueColor } from 'utils/mixins';

export const Wrap = styled.div`
  height: ${({ theme }) => theme.sizes.subHeaderHeightPx}px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  padding-left: ${({ theme }) => theme.spacings.lg};
  background-color: ${themeOpaqueColor('darkBlue', 0.9)};
  font-weight: ${({ theme }) => theme.fontWeights.medium};

  span {
    color: ${themeColor('white')};
  }
`;
