import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { MenuProps } from 'antd/lib/menu';

import Logo from 'assets/svg/Illustrations/logo.svg';
import SidebarMenuItem from 'components/Layout/Sidebar/SidebarMenuItem';
import SidebarMenuAccordion from 'components/Layout/Sidebar/SidebarMenuAccordion';
import ContactInfo from 'components/ContactInfo';
import { NavigationConfig, NavigationNested, recursiveFindItem } from 'utils/navigation';
import { validateRoles } from 'utils/roles';
import { useTutorialControls, useFirebaseClaims } from 'utils/hooks';
import theme from 'theme';
import { useFeatureGuard } from 'lib/featureFlags';
import { ApplicationFeatureFlags } from 'codegen';

import { currentNavItemFinder } from '../common';
import LegalLinks from './LegalLinks';
import * as S from './styles';

type Props = {
  navigationConfig: NavigationConfig;
};

const Sidebar: React.FC<Props> = ({ navigationConfig }) => {
  const history = useHistory();
  const [openKeys, setOpenKeys] = useState<React.Key[]>();
  const { claims } = useFirebaseClaims();
  const showReviewers = useFeatureGuard()(ApplicationFeatureFlags.Review);

  const findCurrentNavItem = currentNavItemFinder(history);

  const nestedMenuItems = useMemo(
    () => navigationConfig.filter((menuItem) => (menuItem as any).items),
    [navigationConfig]
  );

  const currentItem = recursiveFindItem(navigationConfig, findCurrentNavItem)?.key || 'videos';

  const onMenuItemClicked: MenuProps['onClick'] = useCallback(
    ({ key }) => {
      const item = recursiveFindItem(navigationConfig, (item) => item.key === key);

      if (history.location.pathname === (item as any)?.path) {
        return history.replace((item as any).path, {
          shouldRefetch: true,
        });
      }
    },
    [history, navigationConfig]
  );

  useEffect(() => {
    // Find owner of current item if its nested item
    const currentItemsOwner = nestedMenuItems.find((menuItem) =>
      (menuItem as unknown as NavigationNested).items.some((item) => item.key === currentItem)
    );

    // If it is not nested, top level menu item is clicked so close all the open sub menus
    if (!currentItemsOwner) {
      return setOpenKeys([]);
    }

    // Nested item clicked so leave only owner of this nested item open
    setOpenKeys([currentItemsOwner.key]);
  }, [currentItem, nestedMenuItems]);

  const { navMenuOpenKeys, navHighlightKeys } = useTutorialControls();

  return (
    <S.NavigationBar width={theme.sizes.sideBarWidthPx}>
      <Link to="/videos">
        <S.StoryvineLogo src={Logo} alt="Storyvine NOW brand logo for sidebar menu" />
      </Link>
      <S.Menu
        mode="inline"
        selectedKeys={[currentItem]}
        openKeys={navMenuOpenKeys.length > 0 ? navMenuOpenKeys : (openKeys as string[])}
        // Allow just one submenu to be opened
        onOpenChange={(openKeys) => setOpenKeys(openKeys ? [openKeys[openKeys.length - 1]] : [])}
        onClick={onMenuItemClicked}
      >
        {navigationConfig.map(function renderItems(item) {
          const { key, icon, sidebar, conditions } = item;

          if (key === 'reviewers' && !showReviewers) return null;

          if (conditions === undefined || (claims && validateRoles(conditions, claims))) {
            return (item as any).path ? (
              <SidebarMenuItem
                key={key}
                itemKey={key}
                icon={icon}
                className={item.className}
                currentItemKey={currentItem}
                isHighlighted={navHighlightKeys.includes(key)}
                path={(recursiveFindItem(navigationConfig, (item) => item.key === key) as any).path}
                title={sidebar}
              />
            ) : (
              <SidebarMenuAccordion
                key={key}
                className={item.className}
                title={sidebar}
                itemKey={key}
                icon={icon}
              >
                {(item as unknown as NavigationNested).items.map(renderItems)}
              </SidebarMenuAccordion>
            );
          }

          return null;
        })}
      </S.Menu>
      <S.NavigationFooter>
        <ContactInfo label={<LegalLinks />} />
      </S.NavigationFooter>
    </S.NavigationBar>
  );
};

export default Sidebar;
