import styled from 'styled-components';

import { themeColor } from 'utils/mixins';

export const Wrap = styled.div`
  max-width: 453px;
`;

export const Title = styled.h1`
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  color: ${themeColor('darkBlue')};
`;

export const Content = styled.h3`
  color: ${themeColor('darkBlue')};
  opacity: 0.85;
  margin-bottom: 40px;
`;

export const Error = styled.sub`
  color: ${themeColor('lightBlue')};
  display: flex;
  margin-bottom: 40px;
`;
