import styled, { css } from 'styled-components';

import tutorialIconSvg from 'assets/svg/tutorialIcon.svg';
import { centerFlex, themeColor, themeOpaqueColor } from 'utils/mixins';

export const Wrap = styled.div`
  position: fixed;
  bottom: 56px;
  right: 56px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  && {
    min-width: fit-content;
    z-index: 100;
  }
`;

const BOX_SHADOW_COLORS = css`
  ${themeOpaqueColor('darkBlack', 0.25)},
  0px 0px 1px ${themeOpaqueColor('darkBlack', 0.084)},
0px 1px 1px ${themeOpaqueColor('darkBlack', 0.168)}
`;

export const OrangeCircle = styled.div`
  margin-top: ${({ theme }) => theme.spacings.sm};
  width: 48px;
  height: 48px;
  ${centerFlex}

  border-radius: 48px;

  background: ${themeColor('orange')};
  box-shadow: 0px 4px 4px ${BOX_SHADOW_COLORS};

  cursor: pointer;

  &:active {
    box-shadow: 0px 2px 2px ${BOX_SHADOW_COLORS};
  }
`;

export const TrainingIcon = styled.img.attrs({
  src: tutorialIconSvg,
  alt: 'Onboarding Training icon',
})`
  user-select: none;
`;
