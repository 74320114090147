import { useMemo } from 'react';

import { getEnvVar } from 'utils/env';
import { isVideoRelease } from 'utils/videos';
import { isAdministrator, isAnyAdminRole, isReviewerRole } from 'utils/roles';
import { AdminUsers } from 'utils/constants';

import useFirebaseClaims from './useFirebaseClaims';

/**
 * Defines access list to different features available to certain roles
 */
const useRoleFeatureAvailability = () => {
  const { claims } = useFirebaseClaims();
  const role = claims?.role;
  const isPrimary = claims?.isPrimary;
  const isAdmin = role && isAdministrator(role);
  const isReviewer = role && isReviewerRole(role);
  const isAnyAdmin = role && isAnyAdminRole(role);

  return useMemo(
    () => ({
      isAdmin,
      isReviewer,
      layout: {
        sidebar: isReviewer ? 'REVIEWER' : isAdmin ? 'ADMIN' : 'MANAGER',
      },
      videos: {
        // FIXME: && !isAdmin = temporary feature flag
        createVideo: role !== AdminUsers.Manager && !isAdmin && !isReviewer,
        createVideoPage: isAdmin,

        exportVideoAssets: isAnyAdmin,
        shareToYoutube: !isAdmin,

        availableFilters: {
          filmingGroup: !isAdmin && !isReviewer,
          status: true,
          template: !isAdmin,
          organization: isAdmin,
        },

        viewOrganization: isAdmin,
      },
      storymakers: {
        // FIXME: temporary feature flags
        addStorymakers: !isReviewer,
        deleteStorymaker: isAdmin,
        resendEmailInvitation: !isReviewer,
        toggleAccess: !isReviewer,
        // TODO: enable once BE ready for bulk actions
        rowMultiSelect: false,

        availableFilters: {
          filmingGroup: !isAdmin,
          organization: isAdmin,
        },

        detail: {
          displayOrganization: isAdmin,
          displayAdditionalFields: isAnyAdmin,
          displayFilmingGroups: isAnyAdmin,
          canAddFilmingGroups: isAdmin,
        },
      },
      reviewers: {
        availableFilters: {
          filmingGroup: true,
          organization: isAdmin,
        },
      },
      capturedVideoAssets: {
        upsellFeature: role === AdminUsers.Manager,
        isUploadAvailable: (assetType: 'video' | 'image') =>
          assetType === 'image' || role !== AdminUsers.Manager,
        isEnabled: (assetKey?: string | null) =>
          role !== AdminUsers.Manager || isVideoRelease(assetKey),
        isReplaceVisible: (assetKey?: string | null) =>
          role !== AdminUsers.Manager || !isVideoRelease(assetKey),
      },
      managers: {
        // to remove other managers manager must be primary
        // moderator can remove anyone
        canRemove: claims && (role !== AdminUsers.Manager || isPrimary),
        // set as primary only works for primary manager:
        canSetAsPrimary: claims && role === AdminUsers.Manager && isPrimary,

        canEditMoreThan2Admins: claims && role !== AdminUsers.Manager,

        canUpdateOrgLevelEmailNotificationSettings: role === AdminUsers.Manager,

        availableFilters: {
          organization: isAdmin,
        },
        inviteManagerAsAdmin: isAdmin,
        inviteManagerTypes: (() => {
          switch (role) {
            case AdminUsers.AccountAdmin:
              return [
                AdminUsers.Manager,
                AdminUsers.Moderator2,
                AdminUsers.ModeratorSecure2,
              ] as AdminUserRole[];
            default:
              return [];
          }
        })(),

        toggleAccess: isAdmin,
        // email invite resend button in admin users table
        // and admin detail
        resendEmailInvitation: isAdmin,
        resetManagersPasswordAsAdmin: isAdmin,
      },
      filmingGroups: {
        noSignUpFormCopy: isAdmin ? 'No Signup Form - Build in CMS 1.0 ' : '',
        // FIXME: temporary feature flags
        storymakersHyperlink: !isAdmin,
        videosHyperlink: !isAdmin,
        addTemplate: isAdmin,
        unassignTemplate: isAdmin,
        notificationSettings: isAdmin,
      },
      contact: {
        email: !claims || role !== AdminUsers.Manager ? 'HELPME' : 'SVNOW',
        phoneNumberExtension: !claims || role !== AdminUsers.Manager ? '2' : '4',
      },
      legalLinks: {
        privacyPolicy:
          role === AdminUsers.Manager
            ? getEnvVar('STORYVINE_NOW_PRIVACY_POLICY_URL')
            : getEnvVar('STORYVINE_PRIVACY_POLICY_URL'),
        termsOfService:
          role === AdminUsers.Manager
            ? getEnvVar('STORYVINE_NOW_TERMS_AND_CONDITIONS_URL')
            : getEnvVar('STORYVINE_TERMS_AND_CONDITIONS_URL'),
      },
      naming: {
        me: () => AdminUsers.Manager,

        otherUser: (isOtherPrimary: boolean, otherUserRole?: AdminUserRole) => {
          switch (role) {
            case AdminUsers.Manager:
            case AdminUsers.Moderator2:
              return otherUserRole && otherUserRole === AdminUsers.Manager
                ? isOtherPrimary
                  ? 'Primary Manager'
                  : 'Manager 2'
                : AdminUsers.Manager;
            default:
              return AdminUsers.Manager;
          }
        },
        ownableComponentTitle: function ({
          isMe,
          isPrimary,
          role,
        }: {
          isMe: boolean;
          isPrimary: boolean;
          role?: AdminUserRole;
        }) {
          return `${this.otherUser(isPrimary && role === AdminUsers.Manager, role)} ${
            isMe ? '(you)' : ''
          }`;
        },
      },
    }),
    [role, claims, isPrimary, isAdmin, isReviewer, isAnyAdmin]
  );
};

export default useRoleFeatureAvailability;
