import React, { Fragment } from 'react';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { NavLink } from 'react-router-dom';

import routesConfig, { PATHS_TO_EXCLUDE, NON_LINKABLE } from '../routesConfig';

import * as S from './styles';

const renderBreadcrumb = (match: { url: string }, breadcrumb: React.ReactNode) => {
  if (
    NON_LINKABLE.includes(match.url) ||
    PATHS_TO_EXCLUDE.indexOf('/' + match.url.split('/')[1]) > -1
  ) {
    return breadcrumb;
  }

  return (
    <NavLink to={match.url} data-testid={match.url}>
      {breadcrumb}
    </NavLink>
  );
};

const Breadcrumb = () => {
  const breadcrumbs = useBreadcrumbs(routesConfig, { excludePaths: PATHS_TO_EXCLUDE });

  return (
    <S.Wrap>
      {breadcrumbs.map(({ match, breadcrumb }, i) => (
        <Fragment key={match.url}>
          {i > 0 && <S.Arrow />}
          {renderBreadcrumb(match, breadcrumb)}
        </Fragment>
      ))}
    </S.Wrap>
  );
};

export default Breadcrumb;
