import React from 'react';
import * as Sentry from '@sentry/browser';

import SomethingWentWrong from 'components/SomethingWentWrong';
import GaryTheGuide from 'components/GaryTheGuide';

import * as S from './styles';

class ErrorBoundary extends React.Component {
  state = {
    eventId: '',
    hasError: false,
  };

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    Sentry.withScope((scope) => {
      scope.setExtras({ ...errorInfo });
      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <S.Wrap>
          <GaryTheGuide garyType="withQuestions" />
          <S.InnerWrap>
            <SomethingWentWrong
              requiresReload
              title="Ah, DRAT."
              content="it looks like the technology gods aren't having a good day."
              error="error 500: (Something went wrong)"
            />
          </S.InnerWrap>
        </S.Wrap>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
