import { useMemo, useState } from 'react';

import { Option } from 'components/Filter';

import useOnChange from './useOnChange';

const useTableFilters = <T>(
  dataInitializer: () => T,
  optionCreator?: (rawData: T) => Option[],
  initialValue?: Option['value'][] | null
): {
  selected: [Option['value'][], React.Dispatch<React.SetStateAction<Option['value'][]>>];
  all: Option[];
  rawData: T;
} => {
  const rawData = dataInitializer();
  const options = useMemo(() => optionCreator?.(rawData), [optionCreator, rawData]);

  const selected = useState<Option['value'][]>(initialValue || []);
  const setSelected = selected[1];

  // when initial value of filter changes (e.g. from url)
  useOnChange(initialValue, (newValue) => setSelected(newValue || []));

  return {
    selected,
    rawData,
    all: options || [],
  };
};

export default useTableFilters;
