import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import Button from 'components/Button';

import * as S from './styles';

type Props = {
  title: string;
  content: string;
  error: string;
  requiresReload?: boolean;
};

const SomethingWentWrong: React.FC<Props> = ({ title, content, error, requiresReload }) => {
  const history = useHistory();

  const onHomepageClick = useCallback(() => {
    history.replace('/videos');
    requiresReload && window.location.reload();
  }, [history, requiresReload]);

  return (
    <S.Wrap>
      <S.Title>{title}</S.Title>
      <S.Content>{content}</S.Content>
      <S.Error>{error}</S.Error>
      <Button type="primary" onClick={onHomepageClick} data-testid="submit-goBackToHomepage">
        RETURN TO HOMEPAGE
      </Button>
    </S.Wrap>
  );
};

export default SomethingWentWrong;
