import { setContext } from '@apollo/client/link/context';
import { FirebaseError } from 'firebase/app';
import { currentUser, getIdToken, signOut } from 'lib/firebase';
import Notification from '../../components/Notification';

import apolloClient from './index';

const HEADER_KEYS = {
  PLATFORM: 'x-platform',
};

enum Headers {
  Platform = 'desktop',
}

export const authLink = setContext(async (_, { headers }) => {
  if (currentUser()) {
    try {
      // Gets cached token, if expired tries to get a new one from Google
      // In the event we have signed out the user forcefully it will throw an error
      const token = await getIdToken();

      return {
        headers: {
          ...headers,
          [HEADER_KEYS.PLATFORM]: Headers.Platform,
          authorization: `Bearer ${token}`,
        },
      };
    } catch (e) {
      if (e instanceof FirebaseError && e.code === 'auth/user-token-expired') {
        await apolloClient.stop();
        await signOut();
        await apolloClient.clearStore();
        // The future versions (4.10+) of Antd supports onClick handler, that could close the message on click, but we are stuck because of typescript
        // And I don't feel like going down the rabbit hole of updating it
        // Since the logout requires a user interaction it should be ok to set the duration to 10 seconds
        // I can't also easily set the notification on the login screen because this is happening outside react
        Notification.error(
          'Your session was forcefully terminated or expired, your are now logged out.',
          10
        );
      }
    }
  }
});
