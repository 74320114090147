import React from 'react';

import { useFirebaseClaims } from 'utils/hooks';

import FloatingTutorialButton from './FloatingTutorialButton';

type Props = {};

const OnboardingTutorial: React.FC<Props> = () => {
  const { claims } = useFirebaseClaims();

  return claims?.showOnboardingTutorial ? <FloatingTutorialButton /> : null;
};

export default OnboardingTutorial;
