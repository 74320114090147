import React, { useState, useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';

import { MenuIcon } from '../styles';

import * as S from './styles';

type Props = {
  itemKey: string;
  icon: { normal: string; hovered: string };
  currentItemKey: string | undefined;
  isHighlighted?: boolean;
  path: string;
  title: string;
  [key: string]: any;
};

const SidebarMenuItem: React.FC<Props> = ({
  itemKey,
  title,
  icon,
  currentItemKey,
  isHighlighted,
  link,
  path,
  ...props
}) => {
  const [hovered, setHovered] = useState('none');
  const currentIcon = useMemo(
    () => icon[itemKey !== currentItemKey && itemKey !== hovered ? 'normal' : 'hovered'],
    [currentItemKey, hovered, itemKey, icon]
  );

  return (
    <S.MenuItem
      {...props}
      key={itemKey}
      icon={<MenuIcon src={currentIcon} alt={`Main sidebar menu icon for ${title} item`} />}
      onPointerOver={useCallback(() => setHovered(itemKey), [itemKey])}
      onPointerLeave={useCallback(() => setHovered('none'), [])}
      $highlighted={isHighlighted}
    >
      <Link data-testid={`SideBarItem-${itemKey}`} to={path}>
        <span>{title}</span>
      </Link>
    </S.MenuItem>
  );
};

export default SidebarMenuItem;
