import React from 'react';
import { Route, Redirect, RouteProps, RedirectProps } from 'react-router-dom';

type Props = { redirectProps?: Omit<RedirectProps, 'to'> };

const RedirectAs404: React.FC<Props> = ({ redirectProps }) => (
  <Route
    component={(props: RouteProps) => (
      <Redirect
        {...redirectProps}
        to={{ pathname: props.location?.pathname, state: { is404: true } }}
      />
    )}
  />
);

export default RedirectAs404;
