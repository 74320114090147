import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

type LocationState = { shouldRefetch?: boolean };

const useShouldRefetch = (refetch: () => void) => {
  const history = useHistory<LocationState>();
  useEffect(() => {
    if (history.location.state?.shouldRefetch) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.state]);
};

export default useShouldRefetch;
