import { useState, useCallback } from 'react';

const useModalControls = (initialVisible = false): [() => void, ModalControlProps] => {
  const [visible, setVisible] = useState(initialVisible);

  const toggle = useCallback(() => setVisible(!visible), [visible, setVisible]);
  const show = useCallback(() => setVisible(true), [setVisible]);

  return [
    initialVisible ? toggle : show,
    {
      visible,
      onCancel: useCallback(() => setVisible(false), [setVisible]),
    },
  ];
};

export default useModalControls;
