import React, { createContext } from 'react';

// Title Icons
import StorymakerTitleIcon from 'assets/svg/NavBarIcons/24x24/storymakers.svg';
import VideoTitleIcon from 'assets/svg/NavBarIcons/24x24/video.svg';

// NavBar Items Default Icons
import VideoFaded from 'assets/svg/NavBarIcons/20x20/Faded/video.svg';
import StorymakersFaded from 'assets/svg/NavBarIcons/20x20/Faded/storymakers.svg';

// NavBar Items Hovered Icons
import Video from 'assets/svg/NavBarIcons/20x20/White/video.svg';
import Storymakers from 'assets/svg/NavBarIcons/20x20/White/storymakers.svg';

import { NavigationConfig } from 'utils/navigation';

export const NAVIGATION_BAR_ITEMS: NavigationConfig = [
  {
    key: 'videos',
    icon: { hovered: Video, normal: VideoFaded, header: VideoTitleIcon },
    header: 'VIDEOS',
    sidebar: 'VIDEOS',
    path: '/videos',
    startsWith: ['/videos'],
  },
  {
    key: 'storymakers',
    icon: { hovered: Storymakers, normal: StorymakersFaded, header: StorymakerTitleIcon },
    header: 'STORYMAKERS',
    className: 'storymakers-nav-item',
    sidebar: 'STORYMAKERS',
    path: '/storymakers',
    startsWith: ['/storymakers'],
  },
];

export const Context = createContext(NAVIGATION_BAR_ITEMS);

export const Provider: React.FC = ({ children }) => {
  return <Context.Provider value={NAVIGATION_BAR_ITEMS}>{children}</Context.Provider>;
};
