import React, { useCallback } from 'react';
import { Route, Redirect, RouteProps, useLocation } from 'react-router-dom';

import Loading from 'components/Loading';
import RedirectAs404 from 'components/RedirectAs404';
import { useIsSubscriptionValid, useFirebaseClaims, useIsPasswordExpired } from 'utils/hooks';
import { validateRoles } from 'utils/roles';
import { currentUser } from 'lib/firebase';

type ProtectedRouteProps = RouteProps & {
  component: React.ComponentType<any>;
  roleChecks?: ((roleInfo: UserRoleInfo) => boolean)[];
};

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  component: Component,
  roleChecks,
  ...rest
}) => {
  const { claims } = useFirebaseClaims();
  const [subscriptionLoading, isSubscriptionValid] = useIsSubscriptionValid();
  const [passwordExpiryLoading, isPasswordExpired] = useIsPasswordExpired();

  const location = useLocation();

  const handleRoleChecks = (component: JSX.Element) => {
    if (roleChecks) {
      if (!claims) {
        return <RedirectAs404 />;
      }

      return validateRoles(roleChecks, claims) ? component : <RedirectAs404 />;
    }
    return component;
  };

  const render = useCallback(
    (props: any) => {
      if (!currentUser()) {
        return <Redirect to={{ pathname: '/login', state: { ...(location.state ?? {}) } }} />;
      }

      if (subscriptionLoading || passwordExpiryLoading) {
        return <Loading />;
      }

      if (
        (isSubscriptionValid && !isPasswordExpired) ||
        location.pathname === '/invalid-subscription' ||
        location.pathname === '/expired-password'
      ) {
        return handleRoleChecks(<Component {...props} />);
      } else if (!isSubscriptionValid) {
        // if subscription is invalid
        // and if we're not already on /invalid-subscription (to avoid infinite redirect loop)
        // then redirect to /invalid-subscription
        return <Redirect to="/invalid-subscription" />;
      }

      return <Redirect to="/expired-password" />;
    },
    // If Component is not in the deps array, page won't change.
    // eslint-disable-next-line
    [
      claims,
      subscriptionLoading,
      isSubscriptionValid,
      location.pathname,
      handleRoleChecks,
      Component,
    ]
  );

  return <Route {...rest} render={render} />;
};

export default ProtectedRoute;
