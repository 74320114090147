import { useContext, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import FirebaseAuthProvider from 'containers/FirebaseAuthContext';
import { unarray } from 'utils/array';

const useNavigateToStep = <T extends { [key: number]: string | readonly string[] }>(
  stepMap: T,
  step: keyof T | string = 1,
  search?: string
) => {
  const { reloadIdTokenResult } = useContext(FirebaseAuthProvider.Context);
  const history = useHistory();

  return useCallback(
    async (reloadToken: boolean = true) => {
      reloadToken && (await reloadIdTokenResult());
      let pathname: string = step as any;

      if (Number.isInteger(step as number)) pathname = (stepMap as any)[step];

      history.push({
        pathname: unarray(pathname),
        search,
      });
    },
    [reloadIdTokenResult, history, step, search, stepMap]
  );
};

export default useNavigateToStep;
