//@ts-ignore
import mixpanelLib from 'mixpanel-browser';

import { tryGetEnvVar } from 'utils/env';

import mixpanelMock from './mock';
import mixpanelEvents from './events';
import mixpanelFlowsFactory from './flows';

const MIXPANEL_TOKEN = tryGetEnvVar('REACT_APP_MIXPANEL_TOKEN');

const mixpanel: typeof mixpanelLib = MIXPANEL_TOKEN ? mixpanelLib : mixpanelMock;

mixpanel.init(MIXPANEL_TOKEN || '');

export const events = mixpanelEvents;
export const flows = mixpanelFlowsFactory(mixpanel);

export default mixpanel;
