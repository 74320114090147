import styled, { css } from 'styled-components';

import crossIcon from 'assets/svg/crossIcon.svg';
import Button from 'components/Button';
import { forDeviceType } from 'utils/mixins';

export const Wrap = styled.div`
  padding: ${({ theme }) => theme.spacings.sm};
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${forDeviceType(
    'thirteenInch',
    css`
      width: 200px;
    `
  )}
`;

export const CloseIcon = styled.img.attrs({ src: crossIcon, alt: 'Close Tutorial Icon' })`
  position: absolute;
  top: 17px;
  right: 17px;
  cursor: pointer;
`;

export const NextButton = styled(Button).attrs({ customColor: 'fadedOrange' })`
  margin-top: ${({ theme }) => theme.spacings.sm};
  height: 32px;
  align-self: flex-end;
  border-radius: ${({ theme }) => theme.radius.sm};
  width: 60px;
`;
