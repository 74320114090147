import { useState, useCallback } from 'react';

import useFirebase from './useFirebase';

const useFirebaseClaims = () => {
  const [loading, setLoading] = useState(false);
  const { user, reloadIdTokenResult } = useFirebase();

  const refetchClaims = useCallback(async () => {
    setLoading(true);
    let result;
    try {
      result = await reloadIdTokenResult();
    } catch (e) {
      setLoading(false);
      throw e;
    }
    setLoading(false);
    return result;
  }, [reloadIdTokenResult, setLoading]);

  return {
    refetchClaims,
    loading,
    claims: user?.idTokenResult.claims as
      | (UserClaims & {
          [key: string]: Maybe<string | number | boolean>;
        })
      | undefined,
  };
};

export default useFirebaseClaims;
