import styled from 'styled-components';
import { Layout, Menu as AntdMenu } from 'antd';

import { bold, themeColor } from 'utils/mixins';

export const NavigationBar = styled(Layout.Sider)`
  &.ant-layout-sider {
    overflow: hidden;
    height: 100vh;
    position: fixed;
    left: 0;
    background-color: ${themeColor('darkBlue')};
    z-index: 5;
  }
`;

export const NavigationFooter = styled.div`
  position: absolute;
  bottom: 6px;
  padding: 20px;
  z-index: 1;
`;

export const Menu = styled(AntdMenu)`
  &.ant-menu {
    background-color: ${themeColor('darkBlue')};
    max-height: calc(100vh - 192px);
    overflow-y: auto;
    overflow-x: hidden;
  }

  &.ant-menu-inline {
    .ant-menu-item::after,
    .ant-menu-submenu::after {
      border-right: 0px;
    }
  }
  &.ant-menu-inline {
    border-right: 0px;
  }
  &.ant-menu:not(.ant-menu-horizontal) {
    .ant-menu-item-selected {
      padding-left: 20px;
    }

    .ant-menu-item-selected,
    .ant-menu-submenu-open .ant-menu-submenu-title {
      a {
        color: ${themeColor('white')};
        ${bold}
      }
      background: linear-gradient(90deg, rgba(89, 149, 178, 0.7) 0%, rgba(89, 149, 178, 0) 100%);
      border-left: 4px solid ${themeColor('lightBlue')};
    }

    .ant-menu-submenu .ant-menu-item-selected {
      background: none;
      border-left: none;
    }
  }
  & .ant-menu-item:active,
  & .ant-menu-submenu:active {
    background: none;
  }
  & .ant-menu-item-icon {
    margin-right: 15px;
  }

  & .ant-menu-submenu-title > img {
    margin-right: 15px;
  }
`;

export const StoryvineLogo = styled.img`
  margin-top: 13px;
  margin-left: 36px;
  margin-bottom: 27px;
  margin-right: 60px;
`;

export const MenuIcon = styled.img``;
