import * as Sentry from '@sentry/browser';
import { useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';

import FirebaseAuthProvider from 'containers/FirebaseAuthContext';
import Notification from 'components/Notification';
import {
  useProductQuery,
  SignUpData,
  GenericError,
  RefetchTokenError,
  ValidationError,
} from 'codegen';
import { getEnvVar } from 'utils/env';
import { useUrlSearchParams } from 'utils/hooks';

const REACT_APP_DEFAULT_STRIPE_PRODUCT_ID = getEnvVar('REACT_APP_DEFAULT_STRIPE_PRODUCT_ID');
const STORYVINE_NOW_MARKETING_URL = getEnvVar('STORYVINE_NOW_MARKETING_URL');

export const MONTH_INTERVAL = 'month';

export const SIGNUP_STEP_ROUTES = {
  1: '/signup/create-account',
  2: ['/signup/payment-checkout', '/signup/payment-checkout-success'],
  3: '/setup/manager',
  4: '/setup/organization',
  5: '/setup/waiting',
} as const;

const availableUrlSearchParams = ['product'] as const;

export const useFetchKit = () => {
  const location = useLocation();
  const { product } = useUrlSearchParams(availableUrlSearchParams);
  const productId = product ?? REACT_APP_DEFAULT_STRIPE_PRODUCT_ID;

  const { data, loading } = useProductQuery({
    variables: {
      productId,
    },
  });

  if (!data && !loading) {
    Sentry.captureException(
      `Product with id ${productId} was not found (url="${location.pathname}${location.search}")`
    );
  }

  const result = {
    name: data?.products?.[0]?.name,
    image: data?.products?.[0]?.images?.[0],
    // We want to obtain monthly price:
    amount: data?.products?.[0]?.prices?.find((price) => price.interval === MONTH_INTERVAL)?.amount,
    checklist: data?.products?.[0]?.checklist,
    trialDays: data?.products?.[0]?.trialDays,
  };

  return {
    loading,
    productId,
    prices: data?.products?.[0]?.prices || [],
    data: data?.products?.length ? result : undefined,
  };
};

/**
 * If condition is satisfied, redirect to NOW Marketing URL
 */
export const useRedirectHome = (condition?: boolean) => {
  const shouldRedirect = condition != null && condition;
  useEffect(() => {
    if (shouldRedirect) {
      (async () => {
        const duration = 2000;
        Notification.error('Looks like this page doesn’t exist, taking you back.', duration);
        await new Promise((resolve) => setTimeout(resolve, duration));
        window.location.href = STORYVINE_NOW_MARKETING_URL;
      })();
    }
  }, [shouldRedirect]);
};

// These errors doesn't require window reload but only refetching the token.
const SOFT_SIGNUP_ERRORS = [406];
export const useHasSignUpErrors = () => {
  const { reloadIdTokenResult } = useContext(FirebaseAuthProvider.Context);
  type PossibleErrors =
    | Pick<GenericError, '__typename' | 'message'>
    | Pick<RefetchTokenError, '__typename' | 'message' | 'code'>
    | Pick<ValidationError, '__typename' | 'message'>;
  return (
    result: Pick<SignUpData, '__typename'> | PossibleErrors,
    onSoftRefetchError?: () => void,
    notifier: { error: (message: string) => void } = Notification
  ): result is PossibleErrors => {
    if (result.__typename === 'GenericError' || result.__typename === 'ValidationError') {
      notifier.error(result.message);
    } else if (result.__typename === 'RefetchTokenError') {
      notifier.error(result.message);
      reloadIdTokenResult().then(() => {
        if (SOFT_SIGNUP_ERRORS.includes(result.code)) {
          onSoftRefetchError?.();
        } else {
          window.location.reload();
        }
      });
    }
    return result.__typename !== 'SignUpData';
  };
};
