import styled from 'styled-components';
import { Modal } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';

import Button from 'components/Button';
import { themeColor, themeOpaqueColor } from 'utils/mixins';

export const Wrap = styled(Modal)`
  max-width: 480px;
  padding: 0;
  border-radius: ${({ theme }) => theme.radius.sm};

  box-shadow: 12px 12px 18px ${themeOpaqueColor('shadowBlack', 0.2)},
    -3px -2px 18px ${themeOpaqueColor('shadowBlack', 0.2)};

  & .ant-modal-close {
    display: none;
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  max-width: 400px;
`;

export const WarningIcon = styled(ExclamationCircleFilled)`
  color: ${themeColor('orange')};
  font-size: 35px;
  margin-top: 2px;
`;

export const Text = styled.div`
  margin-top: 18px;
  font-weight: normal;
  font-size: ${({ theme }) => theme.fontSizes.md};
  line-height: ${({ theme }) => theme.lineHeights.sm};
  text-align: center;
  margin-bottom: ${({ theme }) => theme.spacings.lg};

  color: ${themeColor('black')};
`;

export const Footer = styled.div`
  display: flex;
  justify-content: center;
`;

export const ActionButton = styled(Button)`
  margin: ${({ theme }) => `0 ${theme.spacings.sm}`};
  min-width: 72px;
  min-height: 32px;
  border-radius: ${({ theme }) => theme.radius.xsm};

  text-align: center;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
`;

export const NoButton = styled(ActionButton)`
  color: ${themeColor('darkGrey')};
  background-color: ${themeOpaqueColor('darkGrey', 0.1)};

  &.ant-btn:hover,
  &.ant-btn:focus,
  &.ant-btn:active,
  &.ant-btn.active {
    background-color: ${themeOpaqueColor('darkGrey', 0.08)};
  }
`;

export const YesButton = styled(ActionButton)``;

export const DontShowAgainWrap = styled.div`
  margin-top: ${({ theme }) => theme.spacings.md};
`;
