import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { User } from 'firebase/auth';
import { difference } from 'lodash';

import { ApplicationFeatureFlags, useApplicationGlobalFeatureFlagsQuery } from '../../codegen';
import { auth } from 'lib/firebase';

export type AvailableFeatureFlags =
  typeof ApplicationFeatureFlags[keyof typeof ApplicationFeatureFlags];
export const FeatureFlagsContext = React.createContext<AvailableFeatureFlags[]>([]);

const checkFlagDifferences = (
  oldFlags: AvailableFeatureFlags[],
  newFlags: AvailableFeatureFlags[]
) => newFlags.filter((newFlag) => !oldFlags.includes(newFlag)).filter(Boolean);

export const useFeatureGuard = () => {
  const flags = useContext(FeatureFlagsContext);

  const featureGuard =
    (currentFlags: ApplicationFeatureFlags[]) =>
    (flag: AvailableFeatureFlags, withUserFeatures?: ApplicationFeatureFlags[]) => {
      // assume no flags yet loaded
      if (currentFlags.length === 0) return false;

      return withUserFeatures?.includes(flag) ?? currentFlags.includes(flag);
    };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(featureGuard(flags), [flags]);
};

export const FeatureFlagProvider: React.FC = ({ children }) => {
  const [userFlags, setUserFlags] = useState<AvailableFeatureFlags[]>([]);

  const { data } = useApplicationGlobalFeatureFlagsQuery();
  const globalAvailableFlagsFlags = useMemo(
    () => data?.globalFeatureFlags?.map(({ flag }) => flag) ?? [],
    [data]
  ) as AvailableFeatureFlags[];
  const [globalFlags, setGlobalFlags] = useState<AvailableFeatureFlags[]>([]);
  const [flags, setFlags] = useState<AvailableFeatureFlags[]>([]);

  const onIdTokenChange = useCallback(
    async (user: User | null) => {
      if (user) {
        const idToken = await user.getIdTokenResult();
        const { featureFlags: userFeatureFlags } = idToken.claims;

        if (checkFlagDifferences(userFlags, userFeatureFlags ?? []).length > 0) {
          setUserFlags(userFeatureFlags ?? []);
        }
      } else {
        if (userFlags.length > 0) setUserFlags([]);
      }
    },
    [userFlags]
  );

  useEffect(() => {
    if (difference(globalAvailableFlagsFlags, flags).length > 0)
      setGlobalFlags(globalAvailableFlagsFlags);
  }, [globalAvailableFlagsFlags, flags]);

  useEffect(() => {
    setFlags([...globalFlags, ...userFlags]);
  }, [globalFlags, userFlags]);

  useEffect(() => auth.onIdTokenChanged(onIdTokenChange), [onIdTokenChange]);

  return <FeatureFlagsContext.Provider value={flags}>{children}</FeatureFlagsContext.Provider>;
};
