import { useRef, useEffect, useCallback } from 'react';
import { Key } from 'ts-key-enum';

const useOnKeyPress = (targetKey: keyof typeof Key, callback?: () => void) => {
  const targetKeyInternal = Key[targetKey];
  const keyPressed = useRef<boolean>(false);

  const downHandler = useCallback(
    ({ key }: KeyboardEvent) => {
      if (key === targetKeyInternal) {
        keyPressed.current = true;
      }
    },
    [targetKeyInternal]
  );

  const upHandler = useCallback(
    ({ key }: KeyboardEvent) => {
      if (key === targetKeyInternal) {
        if (keyPressed.current) callback?.();
        keyPressed.current = false;
      }
    },
    [targetKeyInternal, callback]
  );

  useEffect(() => {
    window.addEventListener('keydown', downHandler);
    window.addEventListener('keyup', upHandler);

    return () => {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
    };
  }, [upHandler, downHandler]);
  return keyPressed;
};

export default useOnKeyPress;
