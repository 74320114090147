import { useState, useEffect } from 'react';

import dayjs from 'lib/dayjs';

import useFirebaseClaims from './useFirebaseClaims';

const useIsPasswordExpired = () => {
  const [isExpired, setExpired] = useState<boolean | undefined>();
  const { claims, refetchClaims } = useFirebaseClaims();

  useEffect(() => {
    (async () => {
      if (!claims) {
        return setExpired(false);
      }

      let passwordExpires = claims.passwordExpires;
      const hasExpired = () =>
        passwordExpires && dayjs.utc(passwordExpires).diff(dayjs().utc()) <= 0;

      if (hasExpired()) {
        const freshUser = await refetchClaims();
        passwordExpires = freshUser?.idTokenResult.claims.passwordExpires;

        if (hasExpired()) {
          return setExpired(true);
        }
      }

      return setExpired(false);
    })();
  }, [claims, refetchClaims]);

  const isLoading = isExpired == null;
  return [isLoading, isExpired];
};

export default useIsPasswordExpired;
