import { BreadcrumbsRoute } from 'use-react-router-breadcrumbs';

import OrganizationName from './Breadcrumb/Special/OrganizationName';
import AdminUserName from './Breadcrumb/Special/AdminUserName';
import Template from './Breadcrumb/Special/Template';
import TrainingItemName from './Breadcrumb/Special/TrainingItemName';
import StorymakerName from './Breadcrumb/Special/StorymakerName';
import TemplateName from './Breadcrumb/Special/TemplateName';

export const PATHS_TO_EXCLUDE = ['/', '/template'];
export const NON_LINKABLE = ['/settings', '/training'];

const routesConfig: BreadcrumbsRoute[] = [
  { path: '/videos/create', breadcrumb: 'create new' },
  { path: '/videos/:id', breadcrumb: 'video detail' },
  { path: '/filming-groups', breadcrumb: 'filming groups' },
  { path: '/organizations/:id/filming-groups', breadcrumb: 'filming groups' },
  { path: '/organizations/:id', breadcrumb: OrganizationName },
  { path: '/template/:id', breadcrumb: Template },
  { path: '/templates/:id', breadcrumb: TemplateName },
  { path: '/training/:section', breadcrumb: TrainingItemName },
  { path: '/(admins|managers)/:id', breadcrumb: AdminUserName },
  { path: '/storymakers/:id', breadcrumb: StorymakerName },
  { path: '/templates/:id/filming-guide', breadcrumb: 'filming guide' },
  { path: '/expired-password', breadcrumb: ' ' },
  { path: '/invalid-subscription', breadcrumb: ' ' },
];

export default routesConfig;
