import styled, { css } from 'styled-components';
import { Menu as AntdMenu } from 'antd';

import { themeOpaqueColor, themeColor, center } from 'utils/mixins';
import Loading from 'components/Loading';

export const MenuItem = styled(AntdMenu.Item)<{
  $hoverColor: Colors;
  $afterLine?: boolean;
}>`
  &.ant-dropdown-menu-item {
    padding: ${({ theme }) => `${theme.spacings.sm} 13px`};
    transition: all 0s;
    display: flex;
    align-items: center;
  }

  ${({ disabled, $hoverColor }) =>
    !disabled &&
    css`
      &.ant-dropdown-menu-item:hover {
        color: ${themeColor('white')};
        background-color: ${themeColor($hoverColor)};

        :not(:only-child) {
          :last-child {
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
          }
          :first-child {
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
          }
        }

        :only-child {
          border-radius: ${({ theme }) => theme.radius.sm};
        }
      }
    `}

  :not(:first-child) {
    margin-top: 3px;
  }

  ${({ $afterLine }) =>
    $afterLine &&
    css`  :not(:last-child)::after {
      content: '';
      position: absolute;
    top: 39px;
    left: 0px;
    width: 90%;
    left: 5%;
    height: 1px;
    background-color: ${themeOpaqueColor('darkGrey', 0.1)};`}
`;

export const BodyWrap = styled.div`
  position: relative;
`;

export const Spinner = styled(Loading)`
  ${center}
`;

export const MenuItemIcon = styled.img`
  margin-right: ${({ theme }) => theme.spacings.sm};
`;
