import React from 'react';
import { RouteChildrenProps } from 'react-router-dom';

import { useTemplatesAdminDetailQuery } from 'codegen';

const TemplateName: React.FC<RouteChildrenProps<{ id: string }>> = ({ match }) => {
  const templateIdParam = match?.params.id;
  const templateId = Number(templateIdParam);

  const { data, loading } = useTemplatesAdminDetailQuery({
    fetchPolicy: 'cache-only',
    variables: { filter: { templateId } },
    skip: isNaN(templateId) || templateIdParam == null,
  });

  const template = data?.templates.data[0];

  return <span>{loading ? '...' : template?.name || templateIdParam}</span>;
};

export default TemplateName;
