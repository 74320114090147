import React, { useState, useCallback, useEffect, createContext } from 'react';
import * as Sentry from '@sentry/browser';
import styled from 'styled-components';
import { useApolloClient } from '@apollo/client';
import { isEqual } from 'lodash';
import { auth as microsoftSSOAuth } from 'lib/OAuthProvider';

import { User, IdTokenResult, UserCredential } from 'firebase/auth';

import { auth, signInWithCustomToken, signInWithEmailAndPassword } from 'lib/firebase';

import mixpanel, { flows, events } from 'lib/mixpanel';
import Loading from 'components/Loading';
import { SsoProviders, useUserLoginTokenActionMutation } from '../../codegen';
import { AuthenticationResult } from '@azure/msal-common';

type UserType = (User & { idTokenResult: IdTokenResult }) | null;
type FirebaseAuthProviderType = React.FC & {
  Context: typeof FirebaseAuthContext;
};

const Wrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const FirebaseAuthContext = createContext<{
  userLoading: boolean;
  user: UserType;
  auth: typeof auth;
  reloadIdTokenResult: (_user?: UserCredential['user']) => Promise<UserType | null>;
  signInWithEmailAndPassword: (email: string, password: string) => Promise<UserCredential | null>;
  signInWithMicrosoft: (
    email: string,
    _token?: AuthenticationResult,
    redirectAfter?: string
  ) => Promise<UserType>;
  logout: () => Promise<any>;
}>({
  auth,
  userLoading: false,
  user: null,
  reloadIdTokenResult: async () => null,
  signInWithEmailAndPassword: () => Promise.resolve(null),
  signInWithMicrosoft: () => Promise.resolve(null),
  logout: () => Promise.resolve(),
});

const FirebaseAuthProvider: FirebaseAuthProviderType = ({ children }) => {
  const [loginToken] = useUserLoginTokenActionMutation();
  const [userLoading, setUserLoading] = useState(true);
  const [user, setUser] = useState<UserType>(null);
  const client = useApolloClient();

  useEffect(() => {
    auth.onAuthStateChanged(async (firebaseUser) => {
      const user: UserType = firebaseUser as UserType;
      const sentryUser = firebaseUser
        ? {
            email: firebaseUser.email || undefined,
            id: firebaseUser.uid,
            createdAt: firebaseUser.metadata.creationTime,
          }
        : firebaseUser;

      if (user) {
        user.idTokenResult = await user.getIdTokenResult();
      }

      flows.authentication(firebaseUser, user?.idTokenResult.claims);
      Sentry.setUser(sentryUser);
      setUser(user);
      setUserLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchIdToken = async (user: NonNullable<UserType>) => {
    const idTokenResult = await user.getIdTokenResult(true);
    flows.trackUserClaims(idTokenResult.claims);

    return idTokenResult;
  };

  const setFirebaseUser = useCallback(
    async (user: NonNullable<UserType>, onlyIfClaimsChanged = false) => {
      const idTokenResult = await fetchIdToken(user);

      const newUser: UserType = Object.assign(Object.create(Object.getPrototypeOf(user)), {
        ...user,
        idTokenResult,
      });

      const shouldIgnoreSetUser =
        onlyIfClaimsChanged && isEqual(idTokenResult.claims, user.idTokenResult.claims);

      if (!shouldIgnoreSetUser) {
        setUser(newUser);
      }

      return newUser;
    },
    []
  );

  const handleReloadIdTokenResult = useCallback(
    async (_user?: UserCredential['user']) => {
      if (user || _user) {
        // @ts-ignore
        return setFirebaseUser(user || _user, true);
      }
      return null;
    },
    [user, setFirebaseUser]
  );

  const signInWithMicrosoft = useCallback(
    async (email: string, _token?: AuthenticationResult, redirectAfter?: string) => {
      const token = _token ?? (await microsoftSSOAuth(email, true, redirectAfter));

      if (!token) return null;

      const { data } = await loginToken({
        variables: {
          email: email!,
          token: token.idToken,
          ssoProvider: SsoProviders.Microsoft,
        },
      });

      if (data?.userLoginTokenAction.__typename === 'GenericError') throw new Error('error');

      if (data?.userLoginTokenAction.__typename === 'LoginToken') {
        const { user: returnedUser } = await signInWithCustomToken(
          data?.userLoginTokenAction.token
        );

        const firebaseUser = returnedUser as UserType;

        if (firebaseUser) {
          mixpanel.track(events.LOGIN);
          await setFirebaseUser(firebaseUser);
        }

        return firebaseUser;
      } else {
        return {} as UserType;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setFirebaseUser, loginToken]
  );

  const signInEmailAndPassword = useCallback(
    async (email: string, password: string) => {
      const credentials = await signInWithEmailAndPassword(email, password);
      const user = credentials.user as UserType;

      if (user) {
        mixpanel.track(events.LOGIN);
        await setFirebaseUser(user);
      }

      return credentials;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setFirebaseUser]
  );

  const logout = useCallback(async () => {
    mixpanel.track(events.LOGOUT);
    await auth.signOut();
    await client.resetStore();
    return true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client]);

  return userLoading ? (
    <Wrap>
      <Loading />
    </Wrap>
  ) : (
    <FirebaseAuthContext.Provider
      value={{
        auth,
        userLoading,
        user,
        logout,
        reloadIdTokenResult: handleReloadIdTokenResult,
        signInWithEmailAndPassword: signInEmailAndPassword,
        signInWithMicrosoft,
      }}
    >
      {children}
    </FirebaseAuthContext.Provider>
  );
};

FirebaseAuthProvider.Context = FirebaseAuthContext;

export default FirebaseAuthProvider;
