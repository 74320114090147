import styled, { css } from 'styled-components';
import { Menu } from 'antd';

import { themeColor, themeOpaqueColor, bold } from 'utils/mixins';

export const MenuItem = styled(Menu.Item)<{ $highlighted?: boolean }>`
  &.ant-menu-item a {
    color: ${themeOpaqueColor('white', 0.6)};

    :hover {
      color: ${({ theme }) => theme.colors.white};
      ${bold}
    }
  }

  &.ant-menu-item {
    transition: color 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    color: ${themeOpaqueColor('white', 0.6)};
    display: flex;
    margin-bottom: 20px !important;
    height: 48px !important;
    align-items: center;

    ${({ $highlighted }) =>
      $highlighted &&
      css`
        border-left: 4px solid ${themeColor('lightBlue')};
        background: linear-gradient(90deg, rgba(89, 149, 178, 0.7) 0%, rgba(89, 149, 178, 0) 100%);
      `}
  }
`;
