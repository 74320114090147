import styled, { css } from 'styled-components';
import { Button as ANTDButton } from 'antd';
import { ButtonProps } from 'antd/lib/button';

import { themeOpaqueColor, themeColor, bold, antdDisabledButtonStyle } from 'utils/mixins';

import * as buttonTheme from './theme';

export type ButtonColorOptions = buttonTheme.ButtonColorOptions;

export const Button = styled(ANTDButton)<{ $customColor?: buttonTheme.ButtonColorOptions }>`
  box-shadow: 0px 6px 18px ${themeOpaqueColor('black', 0.06)};
  border-radius: ${({ theme }) => theme.radius.sm};
  ${bold};
  text-transform: uppercase;

  ${({ type }) =>
    type === 'default'
      ? css`
          border-radius: ${({ theme }) => theme.radius.sm};
          border: 2px solid ${themeColor('orange')};
          border-radius: ${({ theme }) => theme.radius.sm};
          background-color: ${themeColor('white')};
          color: ${themeColor('orange')};
          font-weight: bold;
        `
      : ``};

  ${({ $customColor }) => {
    // If button has customColor, we apply specific styles for both active & disabled state.
    if ($customColor != null) {
      const { defaultColor, primary, hover, active, focus, disabled } =
        buttonTheme.BUTTON_COLOR_MAP[$customColor];
      return css`
        ${defaultColor}
        box-shadow: 0px 1px 1px ${themeOpaqueColor('darkBlack', 0.17)},
          0px 0px 1px ${themeOpaqueColor('darkBlack', 0.08)};
        color: ${themeColor(primary)};
        font-size: ${({ theme }) => theme.fontSizes.sm};
        border: 0px;
        text-transform: none;

        &:hover {
          ${hover}
          color: ${themeColor(primary)};
        }

        &:active {
          ${active}
          color: ${themeColor(primary)}
        }

        &:focus {
          ${focus}
          color: ${themeColor(primary)}
        }

        ${disabled}
      `;
    }

    // Else, we apply disabled state styles for non-grey buttons.
    return antdDisabledButtonStyle(css<ButtonProps>`
      border-color: ${({ type }) =>
        !type || type === 'default'
          ? themeOpaqueColor('orange', 0.6)
          : themeOpaqueColor('orange', 0.01)};
      background: ${({ type }) =>
        !type || type === 'default' ? themeColor('white') : themeColor('disabledOrange')};
      color: ${({ type }) =>
        !type || type === 'default' ? themeOpaqueColor('orange', 0.6) : themeColor('white')};
    `);
  }}
`;
