import React, { useCallback, useMemo } from 'react';

import TutorialPopup from 'components/OnboardingTutorial/TutorialPopup';
import { useOnboardingTutorial } from 'utils/hooks';

import * as S from './styles';

type Props = {};

const FloatingTutorialButton: React.FC<Props> = () => {
  const onboardingTutorial = useOnboardingTutorial();

  const handleHardCancel = useCallback(() => onboardingTutorial?.skip(), [onboardingTutorial]);
  const handleClosePopup = useCallback(
    () => onboardingTutorial?.setPopUpOpen(false),
    [onboardingTutorial]
  );
  const togglePopupOpen = useCallback(
    () => onboardingTutorial?.setPopUpOpen(!onboardingTutorial?.isPopUpOpen),
    [onboardingTutorial]
  );
  const popupModalProps = useMemo(
    () => ({ onCancel: handleClosePopup, visible: onboardingTutorial?.isPopUpOpen }),
    [handleClosePopup, onboardingTutorial]
  );

  return onboardingTutorial && onboardingTutorial.step === 0 ? (
    <S.Wrap>
      <TutorialPopup
        modalProps={popupModalProps}
        onHardCancel={handleHardCancel}
        onStart={onboardingTutorial.start}
        isFinished={onboardingTutorial.isFinished}
      />
      <S.OrangeCircle onClick={togglePopupOpen}>
        <S.TrainingIcon />
      </S.OrangeCircle>
    </S.Wrap>
  ) : null;
};

export default FloatingTutorialButton;
