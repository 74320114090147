import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import DropdownMenu from 'components/DropdownMenu';
import { recursiveFindItem, NavigationConfig } from 'utils/navigation';
import { useFirebase, useRoleFeatureAvailability } from 'utils/hooks';

import { currentNavItemFinder } from '../common';
import MENU_ITEMS from './config';
import UserInfo from './UserInfo';
import * as S from './styles';

type Props = {
  navigationConfig: NavigationConfig;
};

const Header: React.FC<Props> = ({ navigationConfig }) => {
  const { logout, user } = useFirebase();
  const history = useHistory();
  const { naming } = useRoleFeatureAvailability();
  const findCurrentNavItem = currentNavItemFinder(history);

  const menuItemsClickEvents = useMemo(
    () => ({
      logout,
      adminUser: () => history.push(`/settings/${naming.me().toLowerCase()}s`),
    }),
    [logout, history, naming]
  );

  const currentPage = useMemo(
    () => recursiveFindItem(navigationConfig, findCurrentNavItem) || navigationConfig[0],
    [navigationConfig, findCurrentNavItem]
  );

  const menuItems = useMemo(
    () =>
      MENU_ITEMS.map(({ key, text, icon }) => ({
        afterLine: true,
        onClick: menuItemsClickEvents[key],
        itemKey: key,
        text,
        icon,
        key,
      })),
    [menuItemsClickEvents]
  );

  return (
    <S.Header>
      <S.Title>
        <S.TitleIcon
          src={currentPage.icon.header}
          alt={`Header icon for ${currentPage.sidebar} page`}
        />
        {currentPage.header}
      </S.Title>

      <S.UserInfoBlock>
        <UserInfo logout={logout} role={user?.idTokenResult.claims.role} />
        <DropdownMenu placement="bottomRight" menuItems={menuItems}>
          <S.MenuTrigger>
            <S.UserIcon />
          </S.MenuTrigger>
        </DropdownMenu>
      </S.UserInfoBlock>
    </S.Header>
  );
};

export default Header;
