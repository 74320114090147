import styled from 'styled-components';

export const Wrap = styled.div`
  display: flex;

  min-width: 100%;
  & > div {
    min-width: 100%;
  }
`;
