import styled, { createGlobalStyle } from 'styled-components';
import { Layout as BaseLayout } from 'antd';

import { themeOpaqueColor } from 'utils/mixins';

import SidebarBase from '../Sidebar';
import ContentBase from '../Content';
import SubHeaderBase from '../Header/SubHeader';
import HeaderBase from '../Header';

export const Layout = styled(BaseLayout)`
  background: linear-gradient(
    180deg,
    ${themeOpaqueColor('lightBlue', 0.3)} 0%,
    ${themeOpaqueColor('white', 0.3)} 100%
  );
  margin-left: ${({ theme }) => theme.spacings.sm};
  overflow-x: hidden;
  width: auto !important;
`;

export const HeaderWrap = styled.div`
  position: fixed;
  width: calc(100vw - ${({ theme }) => theme.sizes.sideBarWidthPx}px);
  z-index: 99999;
`;

export const Content = styled(ContentBase)``;
export const Sidebar = styled(SidebarBase)``;
export const Header = styled(HeaderBase)``;

export const SubHeader = styled(SubHeaderBase)`
  margin-top: ${({ theme }) => theme.spacings.xlg};
`;

export const PaddingWrap = styled.div`
  padding: ${({ theme }) => theme.sizes.mainContentPaddingPx}px;
`;

export const FullPageGlobalStyle = createGlobalStyle`
  html {
    background: linear-gradient(180deg, #5995b24d 0%, #ffffff4d 100%) 
  }
`;
