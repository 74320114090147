import React from 'react';
import { CheckboxProps } from 'antd/lib/checkbox';

import * as S from './styles';

type Props = CheckboxProps & { readOnly?: boolean };

const Checkbox: React.FC<Props> = (props) => {
  return <S.Checkbox $readOnly={props.readOnly} {...props} />;
};

export default Checkbox;
