import React from 'react';

import * as S from './styles';

type Props = {
  onClose?: () => void;
  onNext?: () => void;
};

const TutorialStepBubble: React.FC<Props> = ({ onClose, onNext, children }) => {
  return (
    <S.Wrap>
      {children}
      <S.CloseIcon onClick={onClose} />
      <S.NextButton onClick={onNext}>Next</S.NextButton>
    </S.Wrap>
  );
};

export default TutorialStepBubble;
