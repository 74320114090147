import * as Sentry from '@sentry/browser';
import { ApolloLink, Operation, NextLink } from '@apollo/client';
import { getOperationDescriptor } from 'utils/graphql';

export class SentryBreadcrumbLink extends ApolloLink {
  request(operation: Operation, forward: NextLink) {
    Sentry.addBreadcrumb({
      category: 'graphqlRequest',
      level: 'info',
      message: getOperationDescriptor(operation, 'GraphQL Request: '),
      data: operation?.variables,
    });
    return forward(operation).map((data) => {
      Sentry.addBreadcrumb({
        data: data,
        level: 'info',
        category: 'graphqlResponse',
        message: getOperationDescriptor(operation, 'GraphQL Response: '),
      });
      return data;
    });
  }
}
