export const ALLOWED_ROUTES_FROM_INVITE_OR_SIGNUP_FLOWS = [
  '/login',
  '/login-cms',
  '/forgot-password',
  '/auth-redirect',
  '/new-password',
  '/invite',
  '/oauth-redirect',
];

export const ALLOWED_ROUTES_WHEN_USER_LOGGED = [
  '/invite',
  '/forgot-password',
  '/auth-redirect',
  '/2fa',
];
