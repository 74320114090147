import * as msal from '@azure/msal-browser';
import { useEffect, useRef, useState } from 'react';
import { useFirebase } from '../../utils/hooks';
import { useLocation } from 'react-router-dom';
import { getEnvVar } from '../../utils/env';
import { getErrorMessage } from 'utils/error';

const msalConfig: msal.Configuration = {
  auth: {
    clientId: getEnvVar('REACT_APP_AZURE_APP_ID'),
    navigateToLoginRequestUrl: true,
  },
};

// There is no method that can do this on the Microsoft class
// however it can be extended like this since "config" property is only "protected",
// therefore accessible by extending the class (yay classes!)
class StoryvineMsal extends msal.PublicClientApplication {
  changeNavigateToLoginRequestUrl = (val: boolean) => {
    this.config.auth.navigateToLoginRequestUrl = val;
  };
}

export const msalInstance = new StoryvineMsal(msalConfig);

export const auth = async (
  email?: string,
  popup = true,
  redirectAfter = '/'
): Promise<msal.AuthenticationResult | void> => {
  try {
    if (popup) {
      const currentQuery = window.location.search;
      const currentPath = window.location.href.replace(currentQuery, '');
      const queryObject = [...new URLSearchParams(currentQuery).entries()].reduce<
        Record<string, unknown>
      >(
        (acc, [value, key]) => ({
          ...acc,
          [key]: value,
        }),
        {}
      );

      return await msalInstance.acquireTokenPopup({
        scopes: [],
        loginHint: email ?? '',
        redirectUri: currentPath,
        state: JSON.stringify({ query: queryObject }),
      });
    } else {
      if (!email) throw new Error('Email has to be defined for redirect flow!');

      msalInstance.changeNavigateToLoginRequestUrl(false);

      return await msalInstance.acquireTokenRedirect({
        scopes: [],
        loginHint: email,
        redirectUri: `${window.location.origin}/oauth-redirect`,
        redirectStartPage: `${window.location.origin}/oauth-redirect`,
        state: JSON.stringify({ isOauthRedirect: true, email, redirectAfter }),
      });
    }
  } catch (error) {
    const eMessage = getErrorMessage(error);
    if (eMessage.includes('popup_window_error')) return auth(email, false, redirectAfter);

    throw new Error(`[OAuthProvider:auth]: ${error}`);
  }
};

export const useHandleMicrosoftRedirectFlow = () => {
  const firebase = useFirebase();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const redirectAfter = useRef('');

  const params = new URLSearchParams(location.hash.replace('#', '?'));
  const state: { redirectAfter?: string; isOauthRedirect?: boolean; email?: string } = JSON.parse(
    decodeURIComponent((params.get('state') ?? '').split('|')[1] ?? '{}')
  );

  const isOauthRedirect = state.isOauthRedirect;
  const userEmail = state.email;

  useEffect(() => {
    if (state.redirectAfter) redirectAfter.current = state.redirectAfter;
  }, [state.redirectAfter]);

  useEffect(() => {
    (async () => {
      if (isOauthRedirect) {
        try {
          const token = await msalInstance.handleRedirectPromise();
          if (token && userEmail) await firebase.signInWithMicrosoft(userEmail, token);

          setIsLoading(false);
        } catch (e) {
          setIsLoading(false);
          console.error(e);
        }
      } else {
        setIsLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading: isLoading,
    redirectAfter: redirectAfter.current,
  };
};
