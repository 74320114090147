import styled from 'styled-components';
import { Spin } from 'antd';

import { center } from 'utils/mixins';

export const Wrap = styled.div<{ $loadingColor: Colors }>`
  & .ant-spin-dot-item {
    background-color: ${({ $loadingColor, theme }) => theme.colors[$loadingColor]};
  }
`;

export const Spinner = styled(Spin)`
  ${center}
`;
