import React, { useCallback } from 'react';
import { Layout } from 'antd';

import OnboardingTutorialProvider from 'containers/OnboardingTutorialContext/lazy';

import { NavigationConsumer } from './config';
import * as S from './styles';

const FullPageLayout: React.FC = ({ children }) => {
  return (
    <OnboardingTutorialProvider>
      <S.FullPageGlobalStyle />
      <NavigationConsumer>
        {useCallback(
          (NAVIGATION_ROUTES) => (
            <Layout>
              <Layout.Sider>
                <S.Sidebar navigationConfig={NAVIGATION_ROUTES} />
              </Layout.Sider>
              <S.Layout>
                <S.HeaderWrap>
                  <S.Header navigationConfig={NAVIGATION_ROUTES} />
                </S.HeaderWrap>
                <S.Content>
                  <S.SubHeader />
                  <S.PaddingWrap>{children}</S.PaddingWrap>
                </S.Content>
              </S.Layout>
            </Layout>
          ),
          [children]
        )}
      </NavigationConsumer>
    </OnboardingTutorialProvider>
  );
};

export default FullPageLayout;
