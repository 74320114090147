import {
  css,
  FlattenSimpleInterpolation,
  FlattenInterpolation,
  ThemeProps,
  keyframes,
} from 'styled-components';

import theme from 'theme';

import { CustomTheme } from '../../typings/styled-components';
import { applyOpacity } from './styling';

type CssResult =
  | string
  | FlattenSimpleInterpolation
  | FlattenInterpolation<ThemeProps<CustomTheme>>;

export const center = `
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const centerFlex = `
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const centerBlock = `
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

export const bold = `
  font-family: 'Proxima Nova Semibold';
  font-weight: bold;
`;

export const antdDisabledButtonStyle = (innerCss: CssResult) => css`
  &.ant-btn-disabled,
  &.ant-btn.disabled,
  &.ant-btn[disabled],
  &.ant-btn-disabled:hover,
  &.ant-btn.disabled:hover,
  &.ant-btn[disabled]:hover,
  &.ant-btn-disabled:focus,
  &.ant-btn.disabled:focus,
  &.ant-btn[disabled]:focus,
  &.ant-btn-disabled:active,
  &.ant-btn.disabled:active,
  &.ant-btn[disabled]:active,
  &.ant-btn-disabled.active,
  &.ant-btn.disabled.active,
  &.ant-btn[disabled].active {
    ${innerCss}
  }
`;

export const themeColor = (color: Colors) => (props: { theme: CustomTheme }) =>
  props.theme.colors[color];

export const themeOpaqueColor =
  (color: Colors, opacity: number = 1) =>
  (props: { theme: CustomTheme }) =>
    applyOpacity(props.theme.colors[color], opacity);

export const forDeviceType = (deviceType: keyof typeof theme.devices, innerCss: CssResult) => css`
  @media ${({ theme }) => theme.devices[deviceType]} {
    ${innerCss}
  }
`;

export const givePointer = ({ $clickable }: { $clickable?: boolean }) => css`
  cursor: ${$clickable ? 'pointer' : 'default'};
`;

export const boldDetails = css`
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  font-size: ${({ theme }) => theme.fontSizes.xsm};
  line-height: ${({ theme }) => theme.lineHeights.xsm};
  letter-spacing: 0.2px;
  text-transform: uppercase;
`;

export const sub = css`
  font-size: ${({ theme }) => theme.fontSizes.sm};
  line-height: ${({ theme }) => theme.lineHeights.sm};
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: ${themeColor('black')};
`;

export const pipeBetween = css`
  :not(:last-child)::after {
    content: '';
    height: 13px;
    width: 1px;
    background: ${themeColor('black')};
  }
`;

export const selectAllCheckboxStyle = css`
  & tr .ant-table-selection-column .ant-table-selection .ant-checkbox {
    position: absolute;
    top: -52px;
    right: 12px;
  }
`;

const sectionBorderHighlightFrames = keyframes`
  0% {
    border-color: ${theme.colors.grey};
  }
  25% {
    border-color: ${theme.colors.white};
  }
  50% {
    border-color: ${theme.colors.grey};
  }
  100% {
    border-color: ${theme.colors.white};
  }
`;

export const sectionHighlightAnimation = css`
  animation: 6s ${sectionBorderHighlightFrames} ease-in-out;
`;

export const transitionDefault = (t = 'all', d = '0.3s') => css`
  transition: ${t} ${d} cubic-bezier(0.645, 0.045, 0.355, 1);
`;
