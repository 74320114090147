import React, { useCallback } from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';

import {
  ALLOWED_ROUTES_FROM_INVITE_OR_SIGNUP_FLOWS,
  ALLOWED_ROUTES_WHEN_USER_LOGGED,
} from 'components/SignUpPage/DropOffRedirect/config';
import { useFirebaseClaims } from 'utils/hooks';

import { isSpecialFlowRoute } from './utils';

type PublicRouteProps = RouteProps & {
  component: React.ComponentType<any>;
};

const isArray = (value: any): value is readonly string[] => Array.isArray(value);

const PublicRoute: React.FC<PublicRouteProps> = ({ component: Component, ...rest }) => {
  const { claims } = useFirebaseClaims();
  const isAuthenticated = !!claims;

  const isUserInSignUpOrAcceptInviteFlow = (path: string) =>
    (!!claims?.signUpStep || !!claims?.acceptSecondManagerInviteStep) &&
    isSpecialFlowRoute(ALLOWED_ROUTES_FROM_INVITE_OR_SIGNUP_FLOWS, path);

  // user MUST BE able to log in / reset password while still being in Sign Up process (NOW-580, NOW-579 comments):
  // user MUST BE able to visit invitation flow when logged in
  const isException =
    !!rest.path &&
    !isArray(rest.path) &&
    (isUserInSignUpOrAcceptInviteFlow(rest.path) ||
      isSpecialFlowRoute(ALLOWED_ROUTES_WHEN_USER_LOGGED, rest.path));

  const render = useCallback(
    (props: any) =>
      isAuthenticated && !isException ? <Redirect to="/videos" /> : <Component {...props} />,
    // If Component is not in the deps array, page won't change.
    // Remove Claims from deps array, so it only runs on initial change and not on login
    // eslint-disable-next-line
    [Component]
  );

  return <Route {...rest} render={render} />;
};

export default PublicRoute;
