import { AuthError } from 'firebase/auth';

const AuthErrorMessages: Record<string, string> = {
  default: 'Authentication failed',
  'auth/email-already-in-use': 'You have already registered. Please log in.',
  'auth/operation-not-allowed': 'Operation not allowed.',
  'auth/weak-password': 'Weak password',
  'auth/invalid-email': 'Invalid email address',
  'auth/user-disabled': 'User banned',
  'auth/user-not-found': 'User not found',
  'auth/wrong-password': 'The password is invalid or the user does not have a password.',
};

export const getFirebaseAuthErrorMessage = (error: AuthError) => {
  const { code } = error;
  return (
    AuthErrorMessages[code] ||
    `${AuthErrorMessages.default}: ${code.replace(/.*\//g, '').replace(/-/g, ' ')}`
  );
};
