import React from 'react';

import garyTheGuideSVG from 'assets/svg/Illustrations/garyTheGuide.svg';
import garyTheGuideWithQuestionsSVG from 'assets/svg/Illustrations/garryTheGuideWithQuestions.svg';

import * as S from './styles';

type Props = {
  garyType?: 'normal' | 'withQuestions';
};

const GaryTheGuide: React.FC<Props> = ({ garyType = 'normal' }) => {
  return (
    <S.Wrap>
      <S.Image
        alt="Background Illustration of Gary the Guide"
        src={garyType === 'normal' ? garyTheGuideSVG : garyTheGuideWithQuestionsSVG}
      />
    </S.Wrap>
  );
};

export default GaryTheGuide;
