import React from 'react';
import { RouteChildrenProps } from 'react-router-dom';

import { normalizeTrainingSectionPath } from 'utils/navigation';

const TrainingItemName: React.FC<RouteChildrenProps<{ section: string }>> = ({ match }) => {
  const sectionName = match?.params.section;
  return sectionName ? <span>{normalizeTrainingSectionPath(sectionName)}</span> : null;
};

export default TrainingItemName;
