import React from 'react';

import { useRoleFeatureAvailability } from 'utils/hooks';

import * as S from './styles';

type Props = {};

const LegalLinks: React.FC<Props> = () => {
  const { legalLinks } = useRoleFeatureAvailability();

  return (
    <S.LegalDocsWrap>
      <S.LegalDoc href={legalLinks.privacyPolicy} target="_blank">
        Privacy Policy
      </S.LegalDoc>
      <S.LegalDoc href={legalLinks.termsOfService} target="_blank">
        Terms of Service
      </S.LegalDoc>
    </S.LegalDocsWrap>
  );
};

export default LegalLinks;
