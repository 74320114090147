import React from 'react';

import { getEnvVar } from 'utils/env';
import { useRoleFeatureAvailability } from 'utils/hooks';

import * as S from './styles';

type Props = {
  welcome?: boolean;
  label?: JSX.Element;
};
const emailDomain = getEnvVar('STORYVINE_EMAIL_DOMAIN');
const phoneBase = getEnvVar('STORYVINE_PHONE_BASE');

const ContactInfo: React.FC<Props> = ({ welcome, label }) => {
  const { contact } = useRoleFeatureAvailability();

  return (
    <S.Wrap $welcome={welcome} data-testid="contactInfo">
      {label && label}
      <S.Value>
        <S.ContactWrap>
          <S.PhoneIcon />
          <S.Link
            href={`tel:+${phoneBase}${contact.phoneNumberExtension}`}
            data-testid="contactInfo-phone"
            target="_blank"
          >
            {phoneBase}
            {contact.phoneNumberExtension}
          </S.Link>
        </S.ContactWrap>
        <S.ContactWrap>
          <S.LetterIcon />
          <S.Link
            href={`mailto:${contact.email}@${emailDomain}`}
            data-testid="contactInfo-email"
            target="_blank"
          >
            {contact.email}@{emailDomain}
          </S.Link>
        </S.ContactWrap>
      </S.Value>
    </S.Wrap>
  );
};

export default ContactInfo;
