export const useDownload = (dataUrl: string | undefined | null) => {
  const createDownloadLinkAndDownload = (fileName: string) => () => {
    if (!dataUrl) return;

    const link = document.createElement('a');

    link.href = dataUrl;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
  };

  return [createDownloadLinkAndDownload];
};

export default useDownload;
