import logoutIconDarkBlue from 'assets/svg/ProfileDropdownIcons/logoutDarkBlue.svg';
import logoutIconWhite from 'assets/svg/ProfileDropdownIcons/logout.svg';

export default [
  {
    key: 'logout',
    text: 'Log Out',
    icon: { normal: logoutIconDarkBlue, hovered: logoutIconWhite },
  },
] as const;
