import React, { createContext, useContext, useState, useCallback, useMemo } from 'react';
import { Redirect } from 'react-router-dom';

import FirebaseAuthProvider from 'containers/FirebaseAuthContext';
import Notification from 'components/Notification';
import OnboardingTutorial from 'components/OnboardingTutorial';
import { useFinishTutorialMutation } from 'codegen';
import { useStartTutorial } from 'utils/hooks';

import Tour from './Tour';
import useRouterController from './useRouterController';
import { LocalStorageKeys, tourSteps } from './constants';

type OnboardingTutorialProviderType = React.FC & { Context: typeof OnboardingTutorialContext };

type OnboardingTutorialValue = {
  step: number;
  isPopUpOpen: boolean;
  isFinished?: boolean;
  setPopUpOpen: (open: boolean) => void;
  nextStep: () => void;
  previousStep: () => void;
  skip: () => Promise<void>;
  start: () => void;
} | null;

const OnboardingTutorialContext = createContext<OnboardingTutorialValue>(null);

const OnboardingTutorialProvider: OnboardingTutorialProviderType = ({ children }) => {
  const [value, setValue] = useState<Pick<
    NonNullable<OnboardingTutorialValue>,
    'step' | 'isFinished'
  > | null>({ step: 0 });
  const [isPopUpOpen, internalSetPopUpOpen] = useState(
    localStorage.getItem(LocalStorageKeys.POPUP_CLOSED) !== 'true'
  );
  const [finishTutorial] = useFinishTutorialMutation();
  const { reloadIdTokenResult } = useContext(FirebaseAuthProvider.Context);

  // Callbacks
  const goToEnd = useCallback(() => setValue({ step: 0, isFinished: true }), [setValue]);
  const nextStep = useCallback(() => {
    if (value && value.step >= tourSteps.length) {
      goToEnd();
      return;
    }
    setValue(value && { ...value, step: value.step + 1 });
  }, [value, goToEnd, setValue]);
  const previousStep = useCallback(
    () => setValue(value && { ...value, step: value.step > 1 ? value.step - 1 : value.step }),
    [value, setValue]
  );
  const start = useCallback(() => setValue({ step: 1 }), [setValue]);

  const setPopUpOpen = useCallback(
    (value: boolean) => {
      internalSetPopUpOpen(value);
      localStorage.setItem(LocalStorageKeys.POPUP_CLOSED, (!value).toString());
    },
    [internalSetPopUpOpen]
  );

  const skip = useCallback(async () => {
    const result = await finishTutorial();
    if (result.data?.finishTutorial) {
      await reloadIdTokenResult();
      return setValue(null);
    }
    Notification.error('Failed to dismiss the onboarding tutorial. Please try again.');
  }, [finishTutorial, reloadIdTokenResult]);

  const routeToRedirect = useRouterController(value?.step);

  useStartTutorial(() => {
    start();
    setPopUpOpen(true);
  });

  // Adding callbacks and static stuff into the value
  const finalValue = useMemo(() => {
    return value
      ? {
          ...value,
          isPopUpOpen,
          setPopUpOpen,
          nextStep,
          previousStep,
          skip,
          start,
        }
      : null;
  }, [value, isPopUpOpen, setPopUpOpen, skip, start, nextStep, previousStep]);

  const step = finalValue?.step ? finalValue.step - 1 : undefined;

  return (
    <OnboardingTutorialContext.Provider value={finalValue}>
      {routeToRedirect && <Redirect to={routeToRedirect} />}
      {children}
      <OnboardingTutorial />
      <Tour goToEnd={goToEnd} nextStep={nextStep} step={step} isOpen={!!value && value.step > 0} />
    </OnboardingTutorialContext.Provider>
  );
};
OnboardingTutorialProvider.Context = OnboardingTutorialContext;

export default OnboardingTutorialProvider;
