import { useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';

const scrollToElement = (
  hash: string,
  options: ScrollIntoViewOptions | boolean = { behavior: 'smooth', block: 'end' }
) => {
  if (hash) {
    const id = hash.substr(1);
    const element = id && document.getElementById(id);

    if (element) {
      element.scrollIntoView(options);
    }
  }
};

const useScrollToHash = (config?: {
  scrollIntoViewOptions?: ScrollIntoViewOptions | boolean;
  aimedHash?: string;
}) => {
  const { hash } = useLocation();

  const doesHashMatch = !config?.aimedHash || `#${config.aimedHash}` === hash;

  useLayoutEffect(() => {
    if (!config?.aimedHash || `#${config.aimedHash}` === hash) {
      scrollToElement(hash, config?.scrollIntoViewOptions);
    }

    // We don't want to scroll whenever scrollIntoViewOptions changes so eliminate from dependency array and disable next line
    // eslint-disable-next-line
  }, [hash, config?.aimedHash]);

  return doesHashMatch;
};

export default useScrollToHash;
