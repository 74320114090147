import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { getEnvVar } from 'utils/env';

type Props = {};

const stripePromise = loadStripe(getEnvVar('STRIPE_PUBLISHABLE_KEY'));

const ELEMENT_PROPS = {
  fonts: [
    {
      family: 'ProximaNova',
      style: 'normal',
      src: `url(${process.env.PUBLIC_URL}/fonts/ProximaNova-Regular.otf)`,
    },
  ],
} as ComponentProps<typeof Elements>['options'];

const StripeProvider: React.FC<Props> = ({ children }) => {
  return (
    <Elements stripe={stripePromise} options={ELEMENT_PROPS}>
      {children}
    </Elements>
  );
};

export default StripeProvider;
