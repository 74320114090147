import React, { createContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { omit } from 'lodash';

import { useApplicationConfigQuery, Config } from 'codegen';
import Loading from 'components/Loading';
import { centerFlex } from 'utils/mixins';

import defaultConfig from './defaultConfig';

type ApplicationConfigProviderType = React.FC & { Context: typeof ApplicationConfigContext };
type ApplicationConfig = Omit<Config, '__typename' | 'humanizedVideoStatuses'>;

const Wrap = styled.div`
  ${centerFlex}
  height: 100%;
`;

const ApplicationConfigContext = createContext<ApplicationConfig>(defaultConfig);

const ApplicationConfigProvider: ApplicationConfigProviderType = ({ children }) => {
  const { data, loading, error } = useApplicationConfigQuery();
  const [config, setConfig] = useState<ApplicationConfig>(defaultConfig);

  useEffect(() => {
    if (error || !data) {
      return setConfig(defaultConfig);
    }

    setConfig(omit(data.applicationConfig, '__typename'));
  }, [data, error]);

  return loading ? (
    <Wrap>
      <Loading />
    </Wrap>
  ) : (
    <ApplicationConfigContext.Provider value={config}>{children}</ApplicationConfigContext.Provider>
  );
};

ApplicationConfigProvider.Context = ApplicationConfigContext;

export default ApplicationConfigProvider;
