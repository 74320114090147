import React from 'react';
import { RouteChildrenProps } from 'react-router-dom';

import { useStorymakerNamesQuery } from 'codegen';
import { joinNullableStrings } from 'utils/string';

const StorymakerName: React.FC<RouteChildrenProps<{ id: string }>> = ({ match }) => {
  const storymakerIdParam = match?.params.id;
  const storymakerId = Number(storymakerIdParam);

  const { data, loading } = useStorymakerNamesQuery({
    fetchPolicy: 'cache-only',
    variables: { filter: { appUserId: storymakerId } },
    skip: isNaN(storymakerId) || storymakerIdParam == null,
  });

  const storymaker = data?.appUsers.data[0];

  return (
    <span>
      {loading
        ? '...'
        : joinNullableStrings([storymaker?.firstName, storymaker?.lastName]) || storymakerIdParam}
    </span>
  );
};

export default StorymakerName;
