import styled from 'styled-components';

import breadcrumbArrowSvg from 'assets/svg/ArrowIcons/breadcrumbArrow.svg';
import { themeColor } from 'utils/mixins';

export const Wrap = styled.div`
  & a.active > span {
    color: ${themeColor('white')};
  }

  & > a.active:last-child > span {
    color: ${themeColor('orange')};
  }
`;

export const Arrow = styled.img.attrs({ src: breadcrumbArrowSvg, alt: 'Breadcrumb arrow' })`
  display: inline;
  margin: ${({ theme }) => `0 ${theme.spacings.sm}`};
  position: relative;
  top: -1px;
`;
