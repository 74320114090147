import { EnvironmentError } from 'errors';

export enum Environments {
  Production = 'production',
  Develop = 'development',
  Test = 'test',
}

/**
 * Get the environment variable if available or throw an error.
 * @param name The environment variable name to get. It can be given without REACT_APP_ prefix.
 */
export const getEnvVar = (name: string) => {
  const value = process.env[`REACT_APP_${name}`] || process.env[name];
  if (!value || !value.trim().length) {
    throw new EnvironmentError(`Environment variable '${name}' not found or is empty.`);
  }

  return value.trim();
};

/**
 * Use responsibly mostly for flags
 * @param name The environment variable name to get. It can be given without REACT_APP_ prefix.
 */
export const tryGetEnvVar = (name: string) => {
  try {
    return getEnvVar(name);
  } catch (e) {
    return null;
  }
};

export const getEnvironment = () => process.env.NODE_ENV;

export const isDevelopEnv = getEnvironment() === Environments.Develop;
