import React, { createContext, useMemo } from 'react';
// Title Icons
import TrainingTitleIcon from 'assets/svg/NavBarIcons/24x24/training.svg';
import StorymakerTitleIcon from 'assets/svg/NavBarIcons/24x24/storymakers.svg';
import ReviewersTitleIcon from 'assets/svg/NavBarIcons/24x24/reviewers.svg';
import SettingsTitleIcon from 'assets/svg/NavBarIcons/24x24/settings.svg';
import VideoTitleIcon from 'assets/svg/NavBarIcons/24x24/video.svg';
import FilmingGroupsTitleIcon from 'assets/svg/NavBarIcons/24x24/filmingGroups.svg';

// NavBar Items Default Icons
import VideoFaded from 'assets/svg/NavBarIcons/20x20/Faded/video.svg';
import SettingsFaded from 'assets/svg/NavBarIcons/20x20/Faded/settings.svg';
import StorymakersFaded from 'assets/svg/NavBarIcons/20x20/Faded/storymakers.svg';
import ReviewersFaded from 'assets/svg/NavBarIcons/20x20/Faded/reviewers.svg';
import TrainingFaded from 'assets/svg/NavBarIcons/20x20/Faded/training.svg';
import FilmingGroupsFaded from 'assets/svg/NavBarIcons/20x20/Faded/filmingGroups.svg';
import SubscriptionFaded from 'assets/svg/NavBarIcons/16x16/Faded/subscription.svg';
import BrandFaded from 'assets/svg/NavBarIcons/16x16/Faded/brand.svg';
import OrganizationFaded from 'assets/svg/NavBarIcons/16x16/Faded/organization.svg';
import ManagerFaded from 'assets/svg/NavBarIcons/16x16/Faded/managers.svg';

// NavBar Items Hovered Icons
import Video from 'assets/svg/NavBarIcons/20x20/White/video.svg';
import Settings from 'assets/svg/NavBarIcons/20x20/White/settings.svg';
import Storymakers from 'assets/svg/NavBarIcons/20x20/White/storymakers.svg';
import Reviewers from 'assets/svg/NavBarIcons/20x20/White/reviewers.svg';
import Training from 'assets/svg/NavBarIcons/20x20/White/training.svg';
import FilmingGroups from 'assets/svg/NavBarIcons/20x20/White/filmingGroups.svg';
import Subscription from 'assets/svg/NavBarIcons/16x16/White/subscription.svg';
import Brand from 'assets/svg/NavBarIcons/16x16/White/brand.svg';
import Organization from 'assets/svg/NavBarIcons/16x16/White/organization.svg';
import Manager from 'assets/svg/NavBarIcons/16x16/White/managers.svg';

import roleChecks, { and } from 'utils/roles';
import {
  NavigationConfig,
  NavigationPath,
  normalizeTrainingSectionPath,
  NavigationNested,
} from 'utils/navigation';
import { useTrainingSectionsQuery, TrainingSectionsQuery } from 'codegen';

/**
 *  startsWith: routes with no navigation item needs to have an active item in sidebar when visited so we add those routes to the startsWith property of the item that we want to keep active.
 *  e.g. /template route doesn't have a navigation item and we add /template to startsWith prop of filming-groups so filming-groups will be the active when /template is visited.
 */
export const NAVIGATION_BAR_ITEMS: NavigationConfig = [
  {
    key: 'videos',
    icon: { hovered: Video, normal: VideoFaded, header: VideoTitleIcon },
    header: 'VIDEOS',
    sidebar: 'VIDEOS',
    path: '/videos',
    startsWith: ['/videos'],
  },
  {
    key: 'storymakers',
    icon: { hovered: Storymakers, normal: StorymakersFaded, header: StorymakerTitleIcon },
    header: 'STORYMAKERS',
    className: 'storymakers-nav-item',
    sidebar: 'STORYMAKERS',
    path: '/storymakers',
    startsWith: ['/storymakers'],
  },
  {
    key: 'reviewers',
    icon: { hovered: Reviewers, normal: ReviewersFaded, header: ReviewersTitleIcon },
    header: 'REVIEWERS',
    className: 'reviewers-nav-item',
    sidebar: 'REVIEWERS',
    path: '/reviewers',
    startsWith: ['/reviewers'],
  },
  {
    key: 'filming-groups',
    icon: {
      hovered: FilmingGroups,
      normal: FilmingGroupsFaded,
      header: FilmingGroupsTitleIcon,
    },
    header: 'FILMING GROUPS',
    sidebar: 'FILMING GROUPS',
    path: '/filming-groups',
    startsWith: ['/filming-groups', '/template'],
  },
  {
    key: 'settings',
    icon: { hovered: Settings, normal: SettingsFaded },
    header: 'SETTINGS',
    sidebar: 'SETTINGS',
    tutorialOpenKey: 'settingsNavMenuOpen',
    items: [
      {
        key: 'brand',
        header: 'SETTINGS',
        sidebar: 'BRAND',
        className: 'settings-brand-nav-item',
        icon: {
          hovered: Brand,
          normal: BrandFaded,
          header: SettingsTitleIcon,
        },
        path: '/settings/brand',
      },
      {
        key: 'organization',
        header: 'SETTINGS',
        sidebar: 'ORGANIZATION',
        icon: {
          hovered: Organization,
          normal: OrganizationFaded,
          header: SettingsTitleIcon,
        },
        path: '/settings/organization',
      },
      {
        key: 'managers',
        header: 'SETTINGS',
        sidebar: 'MANAGERS',
        icon: {
          hovered: Manager,
          normal: ManagerFaded,
          header: SettingsTitleIcon,
        },
        path: '/settings/managers',
      },
      {
        key: 'subscription',
        header: 'SETTINGS',
        sidebar: 'SUBSCRIPTION',
        conditions: and(roleChecks.isPrimary, roleChecks.isManager),
        icon: {
          hovered: Subscription,
          normal: SubscriptionFaded,
          header: SettingsTitleIcon,
        },
        path: '/settings/subscription',
      },
    ],
  },
];

export const trainingSection = {
  icon: { hovered: Training, normal: TrainingFaded, header: TrainingTitleIcon },
  key: 'training',
  header: 'TRAINING',
  sidebar: 'TRAINING',
  items: [],
};

export const navigationBarItemsWithTraining = [
  ...NAVIGATION_BAR_ITEMS,
  {
    ...trainingSection,
  },
];

export const Context = createContext(navigationBarItemsWithTraining);

const populateTrainingRoutes = (data: TrainingSectionsQuery) =>
  data.trainingSections.map((section) => {
    const path = normalizeTrainingSectionPath(section.sidebarName!);
    return {
      path: `/training/${path}`,
      key: `training-${path}`,
      icon: {
        hovered: section.sidebarHoverIconUrl,
        normal: section.sidebarIconUrl,
        header: TrainingTitleIcon,
      },
      sidebar: section.sidebarName?.toLocaleUpperCase(),
      header: 'TRAINING',
    } as NavigationPath;
  });

export const Provider: React.FC = ({ children }) => {
  const { data, loading } = useTrainingSectionsQuery();

  const navigationConfig = useMemo(
    () =>
      loading || !data
        ? navigationBarItemsWithTraining
        : ([
            ...navigationBarItemsWithTraining.filter((item) => item.key !== trainingSection.key),
            {
              ...trainingSection,
              items: populateTrainingRoutes(data),
            } as NavigationNested,
          ] as NavigationConfig),

    [data, loading]
  );

  const value = useMemo(
    () => [
      ...navigationConfig.map((route) =>
        route.key === 'settings'
          ? {
              ...route,
              items: (route as NavigationNested).items,
            }
          : route
      ),
    ],
    [navigationConfig]
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
};
